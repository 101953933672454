import api from "../components/common/APIValues";


export async function get(url) {
    try {
      const response = await fetch(api.partialURL + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("secretToken")}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  
  export async function searchByInputs(url, inputs) {
    try {
      const response = await fetch(api.partialURL + url + '/' + inputs, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("secretToken")}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  
  export async function create(url, data) {
    try {
      const response = await fetch(api.partialURL + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("secretToken")}`,
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  
  export async function update(url, data, id) {
    try {
      const response = await fetch(api.partialURL + url + "/" + id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("secretToken")}`,
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  
  export async function remove(url, id) {
    try {
      const response = await fetch(api.partialURL + url + "/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("secretToken")}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }