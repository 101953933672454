import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItem, ListItemText, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import routeService from '../services/Routing';
import constant from '../components/common/Constants';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  marginLeft: {
    marginLeft: theme.spacing(7),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1,
  },
  footerBar: {
    backgroundColor: '#c2272d', 
    width: '100%', 
    position: 'absolute', 
    bottom: 0, 
    margin: 0,
  },
}));

export default function BottomAppBar() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const history = useHistory();

  const aboutUs = () => {
    routeService.routeFuncPage(history, constant.aboutUs);
  }

  const privacy = () => {
    routeService.routeFuncPage(history, constant.privacyPolicy);
  }

  return (
    <React.Fragment>
            { matches ?
            <Grid container spacing={4} className={classes.footerBar}>
                <Grid item className={classes.marginLeft} style={{padding: 0}}>
                    <ListItem button onClick={aboutUs}>
                        <ListItemText className="drawerFooterItems" primary='About' />
                    </ListItem>
                </Grid>
                <Grid item style={{padding: 0}}>
                    <ListItem button onClick={privacy}>
                        <ListItemText className="drawerFooterItems" primary='Privacy' />
                    </ListItem>
                </Grid>
            </Grid> :

            <React.Fragment>
                <Grid container spacing={0} className={classes.footerBar}>
                  <Grid item>
                    <ListItem button onClick={aboutUs}>
                        <ListItemText className="drawerFooterItems" primary='About' />
                    </ListItem>
                  </Grid>
                  <Grid item>
                    <ListItem button onClick={privacy}>
                        <ListItemText className="drawerFooterItems" primary='Privacy' />
                    </ListItem>
                  </Grid>
              </Grid>              
            </React.Fragment> }
    </React.Fragment>
  );
}
