import React, { Component, Fragment } from "react";
import { CommentCount } from "@hyvor/hyvor-talk-react";
import {
  Grid,
  withStyles,
  useMediaQuery,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Divider,
  Backdrop,
  MenuList,
  Button,
  Link,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useStyles } from "./common/useStyles";
import "./common/Common.css";
import classNames from "classnames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import QuestionIcon from "./images/Question.png";
import MessageIcon from "./images/mesage.png";
import LikeIcon from "./images/like.png";
import VoteIcon from "./images/votes_icon.png";
import LikeIconColor from "./images/like_h.png";
import VoteIconColor from "./images/votes_h.png";
import PollIcon from "./images/poll.png";
import api from "./common/APIValues";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import EDWhatLogoIcon from "./images/EdWhat-01-01.png";
import ReactHtmlParser from "react-html-parser";
import CommonFunc from "./common/CommonFunc";
import DefaultCoverPic from "./images/CoverPic.jpg";
import colorJsonData from "../pages/json/CommunityColorTheme.json";
import routeService from "../services/Routing";
import constant from "../components/common/Constants";
import imageSizes from "../pages/json/ImageSize.json";
import { get } from "../services/ApiService";

const withMediaQuery =
  (...args) =>
    (Component) =>
      (props) => {
        const mediaQuery = useMediaQuery(...args);
        return <Component mediaQuery={mediaQuery} {...props} />;
      };

class Topics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      userTopics: [],
      topCommunities: [],
      myCommunities: [],
      loader: false,
      disableAddQue: false,
      currentPage: 1,
      sortBy: 0,
      pages: 0,
      is_visible: false,
      scrollDownLoader: false,
      initialLoader: true,
      searchedHashtag: "",
      isBackVisible: false,
      commentDetails: [],
      cachedNewQuePoll: false,
      websiteId: 0,
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });

    sessionStorage.setItem("topic_id", "");
    this.setState({
      disableAddQue:
        this.props.userEmail && this.props.userAliasName ? false : true,
    });

    this.getDefaultCommunityDetails();
    this.loadTopCommunities("all", 0, "");
    this.loadMyCommunities();
  }

  loadTopCommunities = (communityType, categoryId, groupName) => {
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    const originName = window.location.origin.replace("www.", "");
    let url =
      api.partialURL +
      "Community/GetTopCommunities?CommunityType=" +
      communityType +
      "&GroupName=" +
      groupName +
      "&CategoryId=" +
      categoryId +
      "&UserId=" +
      this.props.userEmail +
      "&ENV=" +
      api.env +
      "&Limit=5" +
      "&originName=" +
      originName;
    let params = {
      CommunityType: communityType,
      GroupName: groupName,
      CategoryId: categoryId,
      UserId: this.props.userEmail,
    };

    if (hasCache(url, params)) {
      this.setTopCommunities(url, params, getCache(url, params).data);
    } else {
      this.fetchTopCommunities(url, params);
    }
  };

  fetchTopCommunities = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setTopCommunities(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  setTopCommunities = (url, params, topCommunities) => {
    this.setState({
      topCommunities: topCommunities,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, topCommunities);
  };

  loadMyCommunities = () => {
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    const originName = window.location.origin.replace("www.", "");
    let url =
      api.partialURL +
      "MyProfile/GetMyCommunities?UserId=" +
      this.props.userEmail +
      "&Env=" +
      api.env +
      "&limitCount=5&originName=" +
      originName;
    let params = {
      UserId: this.props.userEmail,
    };

    let action = sessionStorage.getItem("home_page_action");
    if (action === "exit_from_community") {
      sessionStorage.setItem("home_page_action", "");
      this.fetchMyCommunities(url, params);
    } else {
      if (hasCache(url, params)) {
        this.setMyCommunities(url, params, getCache(url, params).data);
      } else {
        this.fetchMyCommunities(url, params);
      }
    }
  };

  fetchMyCommunities = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setMyCommunities(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  setMyCommunities = (url, params, myCommunities) => {
    this.setState({
      myCommunities: myCommunities,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, myCommunities);
  };

  toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      this.setState({ is_visible: true });
    } else {
      this.setState({ is_visible: false });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName
    ) {
      this.setState({
        disableAddQue:
          this.props.userEmail && this.props.userAliasName ? false : true,
      });
      this.loadMyCommunities();
    }

    if (
      this.props.sortBy !== prevProps.sortBy ||
      this.props.searchValue !== prevProps.searchValue ||
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName
    ) {
      if (this.props.sortBy !== prevProps.sortBy) {
        this.setState({ initialLoader: true });
      }

      if (this.props.searchValue !== prevProps.searchValue) {
        setTimeout(() => {
          this.loadUserTopics(
            this.props.sortBy,
            false,
            undefined,
            undefined,
            this.state.websiteId
          );
        }, 500);
      } else {
        this.loadUserTopics(
          this.props.sortBy,
          false,
          undefined,
          undefined,
          this.state.websiteId
        );
      }
    }
  };

  redirectToLogin = () => {
    let pathName = this.props.location.pathname.replace(
      "/is_login_url=true",
      ""
    );
    const { history } = this.props;

    if (history)
      history.push({
        pathname:
          pathName.slice(-1) === "/"
            ? pathName + "is_login_url=true"
            : pathName + "/is_login_url=true",
        topicData: this.state.userTopics,
      });
  };

  addToSearchInput = (searchHashtag) => {
    this.loadUserTopics(
      this.props.sortBy,
      false,
      searchHashtag,
      undefined,
      this.state.websiteId
    );
  };

  reloadAllTopics = () => {
    this.loadUserTopics(
      this.props.sortBy,
      false,
      "###",
      undefined,
      this.state.websiteId
    );
  };

  loadUserTopics(sortInd, scrollDownLoader, searchHashtag, likeObj, websiteId) {
    const { hasCache, getCache } = this.props.cacheActions;
    const originName = window.location.origin.replace("www.", "");

    this.setState({ scrollDownLoader: scrollDownLoader });
    let searchValue = this.props.searchValue
      ? this.props.searchValue
      : searchHashtag
        ? searchHashtag.replace("#", "")
        : "";
    let ind = 0;
    if (
      searchHashtag !== undefined &&
      searchHashtag !== "" &&
      searchHashtag !== null &&
      searchHashtag !== "###"
    ) {
      ind = 1;
      this.setState({ searchedHashtag: searchValue, isBackVisible: true });
    }

    if (this.state.searchedHashtag !== "" && ind === 0) {
      ind = 1;
      searchValue = this.state.searchedHashtag;
      this.setState({ isBackVisible: true });
    }

    if (searchHashtag === "###") {
      ind = 0;
      searchValue = "";
      this.setState({ isBackVisible: false, searchedHashtag: "" });
    }

    let action = sessionStorage.getItem("add_question_page");
    let url =
      api.partialURL +
      "UserTopic/GetUserTopics?SORT_IND=" +
      sortInd +
      "&PAGE_NUM=" +
      this.state.currentPage +
      "&USER_ID=" +
      this.props.userEmail +
      "&SRCH_VAL=" +
      searchValue +
      "&SRCH_HASHTAG=" +
      ind +
      "&ENV=" +
      api.env +
      "&originName=" +
      originName;
    let params = {
      SORT_IND: sortInd,
      PAGE_NUM: this.state.currentPage,
      USER_ID: this.props.userEmail,
      SRCH_VAL: searchValue,
      SRCH_HASHTAG: ind,
    };

    if (action === "created_que_poll_topics") {
      sessionStorage.setItem("add_question_page", "");
      this.fetchUserTopics(url, params, websiteId);
    } else {
      if (hasCache(url, params)) {
        let cachedData = getCache(url, params).data;
        if (likeObj) {
          cachedData.userTopics.map((item, index) => {
            if (
              item.TOPIC_POST_TYPE === likeObj.postType &&
              item.TOPIC_ID === likeObj.topicId
            ) {
              if (cachedData.userTopics[index].LIKE_IND === 0) {
                if (likeObj.postType === "POLL") {
                  cachedData.userTopics[index].TOPIC_POLL_LIKE =
                    cachedData.userTopics[index].TOPIC_POLL_LIKE + 1;
                } else {
                  cachedData.userTopics[index].TOPIC_LIKE_VOTE =
                    cachedData.userTopics[index].TOPIC_LIKE_VOTE + 1;
                }
                cachedData.userTopics[index].LIKE_IND = 1;
              } else if (cachedData.userTopics[index].LIKE_IND === 1) {
                if (likeObj.postType === "POLL") {
                  cachedData.userTopics[index].TOPIC_POLL_LIKE =
                    cachedData.userTopics[index].TOPIC_POLL_LIKE - 1;
                } else {
                  cachedData.userTopics[index].TOPIC_LIKE_VOTE =
                    cachedData.userTopics[index].TOPIC_LIKE_VOTE - 1;
                }
                cachedData.userTopics[index].LIKE_IND = 0;
              }
            }
          });
        }
        this.setTopics(url, params, cachedData);
      } else {
        this.fetchUserTopics(url, params, websiteId);
      }
    }
  }

  setComments = (url, params, commentDetails) => {
    this.setState({
      commentDetails: commentDetails.data,
    });
    this.props.cacheActions.setCache(url, params, commentDetails);
  };

  setTopics = (url, params, topics) => {
    this.setState({
      userTopics: topics.userTopics,
      pages: topics.pages,
      loader: false,
      initialLoader: false,
    });
    this.props.cacheActions.setCache(url, params, topics);
  };

  getDefaultCommunityDetails = () => {
    const originName = window.location.origin.replace("www.", "");
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then((response) => {
      fetch(response.S3ConfigUrl)
        .then((r) => r.json())
        .then((jsonData) => {
          const websiteId = jsonData.websiteId;
          const searchHashtagDtl = this.props.location.searchHashtagDtl;
          const topicData = this.props.location.topicData;

          this.setState({ websiteId: websiteId });
          if (topicData === undefined || topicData === null)
            this.loadUserTopics(
              this.props.sortBy,
              false,
              searchHashtagDtl,
              undefined,
              websiteId
            );
          else this.setState({ userTopics: topicData });
        });
    });
  };

  fetchUserTopics = (url, params, websiteId) => {
    this.setState({ loader: true });
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({ userTopics: [] });
        let topicDetails = result.userTopics;
        let pagesCount = result.totalPages;
        let offset = 0;

        if (websiteId > 0) {
          for (let iCount = pagesCount; iCount > 0;) {
            let limit = iCount > 250 ? 250 : iCount;

            topicDetails.map((item1, index1) => {
              //filter media link
              let desc = topicDetails[index1].TOPIC_DESC;
              let mediaLink = desc.slice(
                desc.indexOf('<oembed url="') + 13,
                desc.lastIndexOf('"></oembed>')
              );
              if (
                mediaLink.indexOf("https://youtube.com/") > -1 ||
                mediaLink.indexOf("https://youtu.be/") ||
                mediaLink.indexOf("https://www.youtube.com/") > -1 ||
                mediaLink.indexOf("https://www.youtu.be/")
              ) {
                let videoId = CommonFunc.getYoutubeId(mediaLink);
                let iframeMarkup =
                  '<iframe frameborder="0" style="width:560px;height:315px;" src="https://www.youtube.com/embed/' +
                  videoId +
                  '" title="YouTube Video" allowFullScreen webkitAllowFullScreen></iframe>';
                let oembedMarkup =
                  '<figure class="media"><oembed url="' +
                  mediaLink +
                  '"></oembed></figure>';

                topicDetails[index1].TOPIC_DESC = desc.replace(
                  oembedMarkup,
                  iframeMarkup
                );
              }
            });

            result.userTopics = topicDetails;
            this.setTopics(url, params, result);

            iCount = iCount - 250;
            offset = offset + 10;
          }
        }
        if (topicDetails.length === 0) {
          this.setState({ loader: false });
        }
      })
      .catch((err) => console.log(err));
  };

  viewTopicDetails = (topicId, topicPostType, topicTitle) => {
    let pathName =
      topicPostType === "QUESTION"
        ? constant.queDetails +
        topicId +
        "/" +
        topicTitle.toLowerCase().replace(/ /g, "-")
        : constant.pollDetails +
        topicId +
        "/" +
        topicTitle.toLowerCase().replace(/ /g, "-");
    routeService.routePage(this.props, pathName);
  };

  fetchMoreData = () => {
    if (this.state.pages - this.state.currentPage > 0) {
      this.setState({ currentPage: this.state.currentPage + 1 });
      this.loadUserTopics(
        this.props.sortBy,
        true,
        undefined,
        undefined,
        this.state.websiteId
      );
    }
  };

  saveUserQueLike = (topicId, postType, title, likeInd) => {
    if (postType === "POLL") {
      this.viewTopicDetails(topicId, postType, title);
    } else {
      if (this.props.userEmail && this.props.userAliasName) {
        this.setState({ loader: true, scrollDownLoader: false });
        let rowData = {
          TOPIC_ID: topicId,
          TOPIC_UPDT_USER_ID: this.props.userEmail,
          TOPIC_UPDT_USER_NM: this.props.userAliasName,
          ENV: api.env,
        };

        axios({
          method: "post",
          url: api.partialURL + "UserQueTopic/SaveUserQueLike",
          data: JSON.stringify(rowData),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        })
          .then((res) => {
            if (res) {
              if (res.data === "success") {
              }
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });

        let queLikeObj = {
          topicId: topicId,
          postType: postType,
        };
        this.loadUserTopics(
          this.props.sortBy,
          false,
          null,
          queLikeObj,
          this.state.websiteId
        );
      } else if (!this.props.userEmail || !this.props.userAliasName) {
        this.redirectToLogin();
      }
    }
  };

  saveUserPollLike = (topicId, postType, likeInd) => {
    if (this.props.userEmail && this.props.userAliasName) {
      this.setState({ loader: true, scrollDownLoader: false });
      let rowData = {
        TOPIC_ID: topicId,
        TOPIC_VOTE_USER_ID: this.props.userEmail,
        TOPIC_VOTE_USER_NM: this.props.userAliasName,
        ENV: api.env,
      };

      axios({
        method: "post",
        url: api.partialURL + "UserPollTopic/SaveUserPollLike",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            if (res.data === "success") {
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });

      let pollLikeObj = {
        topicId: topicId,
        postType: postType,
      };
      this.loadUserTopics(
        this.props.sortBy,
        false,
        null,
        pollLikeObj,
        this.state.websiteId
      );
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      this.redirectToLogin();
    }
  };

  backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  redirectToTopCommunities = () => {
    routeService.routePage(this.props, constant.topCommunities);
  };

  redirectToMyProfile = () => {
    routeService.routePage(this.props, constant.myProfile);
  };

  redirectToCommunityHome = (groupId) => {
    if (Number(groupId) > 0) {
      let pathName = constant.communityHome + groupId;
      routeService.routePage(this.props, pathName);
    }
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const col8 = mediaQuery ? 8 : 12;
    const col4 = mediaQuery ? 4 : 12;
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const subHeaderX = classNames(classes.posTopX, classes.subHeader);
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const buttonSpacing = classNames(
      classes.customButtonError,
      classes.buttonSpacing
    );

    return (
      <Fragment>
        {!this.state.scrollDownLoader && this.state.initialLoader && (
          <Backdrop className={classes.backdrop} open={this.state.loader}>
            <img src={EDWhatLogoIcon} height={50} alt="Logo" />
          </Backdrop>
        )}

        <Grid
          container
          spacing={4}
          style={{ marginTop: mediaQuery ? "45px" : "90px" }}
          className={mediaQuery ? classes.contentHome : null}
        >
          {this.state.isBackVisible && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={buttonSpacing}
                  size="small"
                  onClick={this.reloadAllTopics}
                  style={{ marginLeft: 15 }}
                >
                  Clear # Search
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={col8}>
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={this.state.userTopics.length}
              next={this.fetchMoreData}
              hasMore={this.state.pages - this.state.currentPage > 0}
            >
              <Grid container spacing={2}>
                {this.state.userTopics.map((topic, index) => (
                  <Grid item xs={12} key={topic.TOPIC_ID}>
                    <Card>
                      <Typography
                        className={classes.subHeaderBold}
                        style={{ padding: "16px 0px 0px 5px" }}
                      >
                        <Chip
                          onClick={() =>
                            this.redirectToCommunityHome(topic.GROUP_ID)
                          }
                          icon={<img src={topic.LOGO_URL} alt="logoicon" />}
                          label={topic.GROUP_NM}
                          size="medium"
                          className={classes.chipCard}
                        //style={{ backgroundColor: index % 2 === 0 ? '#acd1e9' : '#e8d0a9' }}
                        />
                      </Typography>
                      <CardHeader
                        style={{
                          cursor: "pointer",
                          padding: "16px 16px 0px 16px",
                        }}
                        classes={{
                          title: classes.title,
                          subheader: classes.subTitle,
                        }}
                        onClick={() =>
                          this.viewTopicDetails(
                            topic.TOPIC_ID,
                            topic.TOPIC_POST_TYPE,
                            topic.TOPIC_TITLE
                          )
                        }
                        title={
                          <span
                            style={{
                              color: topic.COLOR_THEME_NM
                                ? colorJsonData.find(
                                  (x) => x.name === "Default Color"
                                ).title
                                : null,
                            }}
                          >
                            {topic.TOPIC_TITLE}
                          </span>
                        }
                        avatar={
                          <IconButton
                            aria-label="questions"
                            style={{ marginTop: -32, padding: 0 }}
                          >
                            {topic.TOPIC_POST_TYPE === "QUESTION" ? (
                              <img
                                src={QuestionIcon}
                                height={20}
                                alt="QuestionIcon"
                              />
                            ) : (
                              <img src={PollIcon} height={20} alt="PollIcon" />
                            )}
                          </IconButton>
                        }
                        subheader={
                          <span
                            style={{
                              color: topic.COLOR_THEME_NM
                                ? colorJsonData.find(
                                  (x) => x.name === "Default Color"
                                ).subtitle
                                : null,
                            }}
                          >
                            {"posted by " +
                              topic.TOPIC_UPDT_USER_NM +
                              " " +
                              topic.TOPIC_UPDT_DTM +
                              " ago"}
                          </span>
                        }
                      />
                      <CardContent style={{ marginLeft: 37 }}>
                        <Typography
                          className={
                            topic.TOPIC_DESC.substring(0, 23) ===
                              '<iframe frameborder="0"'
                              ? subHeaderX
                              : subHeader
                          }
                          gutterBottom
                          onClick={() =>
                            this.viewTopicDetails(
                              topic.TOPIC_ID,
                              topic.TOPIC_POST_TYPE,
                              topic.TOPIC_TITLE
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            style={{
                              color: topic.COLOR_THEME_NM
                                ? colorJsonData.find(
                                  (x) => x.name === "Default Color"
                                ).desc
                                : null,
                            }}
                            className="topics-ellipsis"
                          >
                            {mediaQuery
                              ? ReactHtmlParser(topic.TOPIC_DESC)
                              : ReactHtmlParser(
                                topic.TOPIC_DESC.replace(
                                  'style="width:560px;height:315px;"',
                                  ""
                                )
                              )}
                          </div>
                        </Typography>
                        {topic.TOPIC_POST_TYPE === "QUESTION" &&
                          topic.TOPIC_IMG_URL && topic.TOPIC_IMG_URL.split(".")[topic.TOPIC_IMG_URL.split(".").length -1] != "pdf" &&(
                            <Typography
                              onClick={() =>
                                this.viewTopicDetails(
                                  topic.TOPIC_ID,
                                  topic.TOPIC_POST_TYPE,
                                  topic.TOPIC_TITLE
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={topic.TOPIC_IMG_URL}
                                style={{
                                  width: "100%",
                                  padding: mediaQuery
                                    ? "10px 150px 10px 150px"
                                    : "10px 10px 10px 10px",
                                }}
                                alt="topicimg"
                              />
                            </Typography>
                            )
                          }
                          {topic.TOPIC_POST_TYPE === "QUESTION" &&
                            topic.TOPIC_IMG_URL && topic.TOPIC_IMG_URL.split(".")[topic.TOPIC_IMG_URL.split(".").length -1] == "pdf" && (
                              <Typography>
                                <Link href={topic.TOPIC_IMG_URL} target="_blank">
                                  <PictureAsPdfIcon/>
                                  {topic.TOPIC_IMG_URL.split("/")[topic.TOPIC_IMG_URL.split("/").length - 1]}
                                </Link>
                              </Typography>
                            )
                          }
                        <Typography
                          className={classes.hashTag}
                          gutterBottom
                          style={{
                            marginTop:
                              topic.TOPIC_POST_TYPE === "QUESTION" &&
                                topic.TOPIC_IMG_URL
                                ? "0px"
                                : "10px",
                          }}
                        >
                          {topic.TOPIC_HASHTAG &&
                            topic.TOPIC_HASHTAG.map((item, index) => (
                              <Link
                                onClick={() => this.addToSearchInput(item)}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: index === 0 ? 0 : 10,
                                }}
                              >
                                {item}
                              </Link>
                            ))}
                        </Typography>
                        <Typography
                          style={{ marginTop: "10px" }}
                          className={wrapIcon}
                          color="textSecondary"
                        >
                          <IconButton
                            onClick={() =>
                              this.saveUserQueLike(
                                topic.TOPIC_ID,
                                topic.TOPIC_POST_TYPE,
                                topic.TOPIC_TITLE,
                                topic.LIKE_IND
                              )
                            }
                            style={{
                              padding: 0,
                              fontSize: 13,
                              fontFamily: "poppins",
                              marginLeft: "0px",
                            }}
                            aria-label="share"
                          >
                            <img
                              src={
                                topic.TOPIC_POST_TYPE === "QUESTION"
                                  ? topic.LIKE_IND === 1
                                    ? LikeIconColor
                                    : LikeIcon
                                  : topic.VOTE_IND === 1
                                    ? VoteIconColor
                                    : VoteIcon
                              }
                              height={15}
                              style={{ marginRight: "3px" }}
                              alt="quepoll"
                            />
                            {topic.TOPIC_LIKE_VOTE}{" "}
                            {topic.TOPIC_POST_TYPE === "QUESTION"
                              ? mediaQuery
                                ? "Likes"
                                : ""
                              : topic.TOPIC_LIKE_VOTE > 1
                                ? "Votes"
                                : "Vote"}
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            flexItem
                            className={classes.marginLeftRight}
                          />
                          {topic.TOPIC_POST_TYPE === "POLL" && (
                            <IconButton
                              onClick={() =>
                                this.saveUserPollLike(
                                  topic.TOPIC_ID,
                                  topic.TOPIC_POST_TYPE,
                                  topic.LIKE_IND
                                )
                              }
                              style={{
                                padding: 0,
                                fontSize: 13,
                                fontFamily: "poppins",
                                marginLeft: "0px",
                              }}
                              aria-label="share"
                            >
                              <img
                                src={
                                  topic.LIKE_IND === 1
                                    ? LikeIconColor
                                    : LikeIcon
                                }
                                height={15}
                                style={{ marginRight: "3px" }}
                                alt="likeicon"
                              />
                              {topic.TOPIC_POLL_LIKE}{" "}
                              {mediaQuery ? " Likes" : ""}
                            </IconButton>
                          )}
                          {topic.TOPIC_POST_TYPE === "POLL" && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                          )}
                          <img
                            src={MessageIcon}
                            height={15}
                            style={{ marginRight: "3px" }}
                            alt="MessageIcon"
                          />
                          <CommentCount
                            website-id={this.state.websiteId}
                            page-id={
                              topic.TOPIC_POST_TYPE === "POLL"
                                ? "poll" + topic.TOPIC_ID
                                : "que" + topic.TOPIC_ID
                            }
                          />
                          &nbsp;{mediaQuery ? "" : ""}
                          <Divider
                            orientation="vertical"
                            flexItem
                            className={classes.marginLeftRight}
                          />
                          <VisibilityIcon
                            className={classes.iconSize}
                            style={{ marginRight: "3px" }}
                          />
                          <span style={{ fontSize: 13, fontFamily: "poppins" }}>
                            {topic.TOPIC_VIEW}
                          </span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                {this.state.userTopics &&
                  this.state.userTopics.length === 0 &&
                  this.props.searchValue && (
                    <Grid item xs={12}>
                      <Card style={{ textAlign: "center" }}>
                        <CardContent>No Topics Found</CardContent>
                      </Card>
                    </Grid>
                  )}
              </Grid>
            </InfiniteScroll>
          </Grid>
          <Grid item xs={col4}>
            {mediaQuery && (
              <Grid container spacing={2}>
                {this.state.topCommunities &&
                  this.state.topCommunities.length > 0 && (
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={1}>
                            <MenuList>
                              <MenuItem>
                              <Typography className={classes.title}>
                                  Top Communities
                                </Typography>
                              </MenuItem>
                              {this.state.topCommunities.map((group, row) => (
                                <Fragment>
                                  <MenuItem
                                    style={{ marginTop: 10 }}
                                    onClick={() =>
                                      this.redirectToCommunityHome(
                                        group.GroupId
                                      )
                                    }
                                  >
                                    <img
                                      src={group.LogoUrl}
                                      alt="img"
                                      className={classes.leftIcon}
                                      style={{
                                        width: imageSizes.logo_icon.width,
                                        height: imageSizes.logo_icon.height,
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    <span
                                      style={{
                                        fontSize: 13,
                                        lineHeight: 1.2,
                                        wordWrap: "break-word",
                                        whiteSpace: "normal", // Ensure the text wraps normally
                                        maxWidth: "100%", // Restrict the width to allow wrapping
                                      }}
                                    >
                                      {group.GroupName}
                                    </span>
                                  </MenuItem>
                                  <Divider />
                                </Fragment>
                              ))}
                              <div style={{ textAlign: "center" }}>
                                <MenuItem
                                  style={{
                                    marginTop: 20,
                                    display: "inline-block",
                                    textAlign: "left",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    className={classes.customButtonFocus}
                                    size="small"
                                    onClick={this.redirectToTopCommunities}
                                  >
                                    View All
                                  </Button>
                                </MenuItem>
                              </div>
                            </MenuList>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                {this.props.userEmail &&
                  this.state.myCommunities &&
                  this.state.myCommunities.length > 0 && (
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={1}>
                            <MenuList style={{ width: "100%" }}>
                              <MenuItem>
                                <Typography className={classes.title}>
                                  My Communities
                                </Typography>
                              </MenuItem>
                              {this.state.myCommunities.map((group, row) => (
                                <Fragment>
                                  <MenuItem
                                    style={{ marginTop: 10 }}
                                    onClick={() =>
                                      this.redirectToCommunityHome(
                                        group.GroupId
                                      )
                                    }
                                  >
                                    <img
                                      src={group.LogoUrl}
                                      alt="img"
                                      className={classes.leftIcon}
                                      style={{
                                        width: imageSizes.logo_icon.width,
                                        height: imageSizes.logo_icon.height,
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    <span
                                      style={{
                                        fontSize: 13,
                                        lineHeight: 1.2,
                                        wordWrap: "break-word",
                                        whiteSpace: "normal", // Ensure the text wraps normally
                                        maxWidth: "100%", // Restrict the width to allow wrapping
                                      }}
                                    >
                                      {group.GroupName}
                                    </span>
                                  </MenuItem>
                                  <Divider />
                                </Fragment>
                              ))}
                              <div style={{ textAlign: "center" }}>
                                <MenuItem
                                  style={{
                                    marginTop: 20,
                                    display: "inline-block",
                                    textAlign: "left",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    className={classes.customButtonFocus}
                                    size="small"
                                    onClick={this.redirectToMyProfile}
                                  >
                                    View All
                                  </Button>
                                </MenuItem>
                              </div>
                            </MenuList>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
              </Grid>
            )}
            {this.state.is_visible && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={buttonSpacing}
                  size="small"
                  onClick={this.backToTop}
                  style={{
                    position: "fixed",
                    bottom: mediaQuery ? "20px" : "10px",
                    right: mediaQuery ? "75px" : "10px",
                  }}
                >
                  Back To Top
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(Topics))
);
