const useStyles = theme => ({
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    topMargin: {
        marginTop: 16,
    },
    topMarginTab: {
        marginTop: 10,
    },
    topMarginSm: {
        marginTop: 5,
    },
    topMarginMd: {
        marginTop: 35,
    },
    root: {
        display: 'flex',
    },
    rootEdit: {
        fontSize: 12, height: '2.1rem', marginTop: 35,
        backgroundColor: "#2b494b",
        "&:hover": {
            backgroundColor: "#2b494b"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
    },
    rootCreate: {
        fontSize: 12, height: '2.1rem', marginTop: 30,
        backgroundColor: "#2b494b",
        "&:hover": {
            backgroundColor: "#2b494b"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
    },
    rootModal: {
        fontSize: 12, height: '2.1rem',
        backgroundColor: "#2b494b",
        "&:hover": {
            backgroundColor: "#2b494b"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
    },
    table: {
        minWidth: 300,
    },
    tabBar: {
        backgroundColor: "white", color: 'orangered'
    },
    tabLinks: {
        fontSize: 12, "&:hover": {
            textDecoration: 'none',
        },
    },
    tabLinksActive: {
        fontSize: 12, backgroundColor: "#347f58", color: 'white',
        "&:hover": {
            textDecoration: 'none',
        },
    },
    rootCard: {
        minWidth: 275, marginTop: 20,
      },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    title: {
        fontSize: 20,
        fontFamily: 'Poppins',
        marginBottom: 10,
        fontWeight: 700,
        color: '#000000'
    },
    titleInfo: {
        fontSize: 12,
    },
    optionSize: {
        fontSize: 15
    },
    subTitle: {
        fontSize: 12,
        fontFamily: 'raleway',
        fontWeight: 500
    },
    subTitle11: {
        fontSize: 11,
    },
    subHeader: {
        fontSize: 15,
        fontFamily: 'raleway',
        fontWeight: 500,
        color: '#000000'
    },
    subHeaderBold: {
        fontSize: 17,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#000000'
    },
    subHeaderSemiBold: {
        fontSize: 17,
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#000000'
    },
    hashTag: {
        fontSize: 12,
        fontFamily: 'raleway',
        fontWeight: 500
    },
    likesComments: {
        fontSize: 13,
        fontFamily: 'poppins',
        fontWeight: 500
    },
    posTop: {
        marginTop: -20
    },
    posTopX: {
        marginTop: -5
    },
    pos: {
        marginBottom: 20
    },
    posGraph: {
        marginBottom: 0, marginRight: 10, marginLeft: 10, marginTop: 0
    },
    posBottom: {
        marginBottom: 20
    },    
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: '100%',
        height: 600,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    customButtonError: {
        borderRadius: '8px',
        color: '#c2272d',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "#e0e0e0",
            color: '#c2272d'
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    customButtonRegular: {
        borderRadius: '8px',
        color: 'black',
        backgroundColor: "#e7e7e7",
        "&:hover": {
            backgroundColor: "#e7e7e7"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    customButtonRegular1: {
        borderRadius: '8px',
        color: 'black',
        backgroundColor: "#e7e7e7",
        "&:hover": {
            backgroundColor: "#e7e7e7"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        textTransform: 'none'
    },
    customButtonRegular2: {
        borderRadius: '8px',
        color: 'black',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        textTransform: 'none'
    },
    customButtonInfo: {
        borderRadius: '8px',
        color: '#0000008A',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    customButtonPrimary: {        
        backgroundColor: "#3179c1",
        "&:hover": {
            backgroundColor: "#3179c1"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    customButtonFocus: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#c2272d",
        "&:hover": {
            backgroundColor: "#c2272d"
        },
        "&:disabled": {
            backgroundColor: "#c2272d",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    tableHeadCell: {
        color: 'white',
        fontFamily: 'poppins',
        fontWeight: 700
    },
    tableBodyCell: {
        fontFamily: 'poppins',
        fontWeight: 500,
        textAlign: 'center'
    },
    customButtonSuccess: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#2e7d32",
        "&:hover": {
            backgroundColor: "#2e7d32"
        },
        "&:disabled": {
            backgroundColor: "#2e7d32",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    radioColor: {
        color: "#3179c1",
    },
    iconColor: {
        color: '#333233',
        fontSize: '2.4rem',        
    },
    iconRedColor: {
        color: '#c2272d',
    },
    activeColor: {
        color: '#333233',
    },
    inactiveColor: {
        color: '#a19b9b',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
    },
    alignment: {
        flexGrow: 1,
    },
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
    badgeColor: {
        backgroundColor: '#c2272d'
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    iconSize: {        
        fontSize: '1.1rem',
        color: '#a19b9b',       
    },
    marginLeftRight: {
        margin: '0px 10px 0px 10px'
    },
    buttonSpacing: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    iconLargeSize: {        
        fontSize: '2rem',
        color: '#c2272d',
    },
    iconMedSize: {
        fontSize: '1.2rem',
        color: '#c2272d',
    },
    iconMedSize1: {        
        fontSize: '2rem',
        color: '#333233',
    },    
    iconXLargeSize: {        
        fontSize: '2.5rem',
        color: '#333233',
    },
    headerFont: {
        color: '#333233',
        textAlign: 'center',
        margin: '30px 0px 10px 0px'
    },
    contentHome: {
        padding: theme.spacing(0, 14, 0, 14),
    },
    buttonShadow: {
        backgroundColor: '#F6F7F2',
        boxShadow: 'none'
    },
    totalCountText: {
        fontFamily: 'poppins',
        fontWeight: 500,
        color: '#c2272d',
        fontSize: 15
    },
    topCommunityTitle: {
        fontFamily: 'poppins',
        fontWeight: 500,
        color: '#000000',
        fontSize: 15
    },
    totalCount: {
        fontFamily: 'poppins',
        fontWeight: 500,
        fontSize: 24
    },
    noTopics: {
        marginTop: 20,
        textAlign: 'center',
        fontFamily: 'poppins',
        fontWeight: 500,
        fontSize: 15
    },
    aboutTitle: {
        fontFamily: 'poppins',
        fontWeight: 600,
        fontSize: 24
    },
    aboutSubTitle: {
        fontFamily: 'poppins',
        fontWeight: 400,
        fontSize: 20,
        color: '#c2272d',
    },
    aboutTopContent: {
        fontFamily: 'poppins',
        fontWeight: 600,
        fontSize: 18,
        color: '#222',
    },
    aboutContent: {
        fontFamily: 'poppins',
        fontWeight: 400,
        fontSize: 17,
        color: '#222',
    },
    alignTextWithIcon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    alignTextWithIcon2: {
        display: 'flex',
        alignItems: 'center',
    },
    cardCenter: {
        margin: 'auto',
        alignItems: 'center'
    },
    chipCard: {
        marginLeft: 10,
        fontSize: 12, 
        fontWeight: 400,
        padding: 10,
        fontFamily: 'Poppins',
    },
    chipCardNoMargin: {
        fontSize: 12, 
        fontWeight: 400,
        padding: 10,
        fontFamily: 'Poppins',
    },
    search: {
        position: 'relative',
        marginLeft: '0px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#c2272d',
        borderRadius: '17px 0px 0px 17px',
        color: 'white'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(7)}px)`,
        transition: theme.transitions.create('width'),
        color: '#292929',
        border: '1px solid #d9d9d9',
        borderRadius: '15px',
        width: '100%',
        height: '25px',
        [theme.breakpoints.up('md')]: {
          width: '100%',
        }
    },
    inputSize: {
        fontSize: '15px',
    },
    chip: {
        margin: 2
    },
    caption: {
        fontWeight: 400,
        fontSize: 11,
        color: '#00000099',
    },
    disableUnderlineTextfield: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&&:after": {
            borderBottom: "none"
        },
    },
    deleteAction: {
        color: '#c2272d',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    accordionSubTitle: {
        fontFamily: 'poppins',
        fontWeight: 500,
        fontSize: 13,
        color: '#c2272d',
    },
    accordionSubText: {
        fontFamily: 'poppins',
        fontWeight: 600,
        fontSize: 14,
        color: '#000000',
    },
    dividerMargin: {
        marginBottom: 5,
        marginTop: 5,
    },
    buttonOwnerMember: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#fa6e00",
        "&:hover": {
            backgroundColor: "#fa6e00"
        },
        "&:disabled": {
            backgroundColor: "#fa6e00",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    buttonJoinDenied: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#ea3a08",
        "&:hover": {
            backgroundColor: "#ea3a08"
        },
        "&:disabled": {
            backgroundColor: "#ea3a08",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    buttonAcceptRequested: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#fa994a",
        "&:hover": {
            backgroundColor: "#fa994a"
        },
        "&:disabled": {
            backgroundColor: "#fa994a",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    cardAction: {
        float: "right",
        marginBottom: 5,
        marginRight: 5,
    }
});

export {useStyles}