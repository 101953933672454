import React, { Component, Fragment } from 'react';
import { Button, withStyles, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useStyles } from './useStyles';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class ActionRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    approveRequest = () => {
        this.props.context.componentParent.approveRequest(this.props.node.data);
    }

    rejectRequest = () => {
        this.props.context.componentParent.rejectRequest(this.props.node.data);
    }

    render() {
        const { classes, mediaQuery } = this.props;
        const customButtonFocus = classNames(classes.customButtonFocus, classes.rightIcon);
        return (
            <Fragment>
                {mediaQuery ?
                    <span>
                        <Button variant="contained" className={classes.customButtonSuccess} size="small"
                            onClick={this.approveRequest}>
                            Approve
                        </Button>
                        <Button variant="contained" className={customButtonFocus} size="small"
                            onClick={this.rejectRequest}>
                            Reject
                        </Button>
                    </span> :
                    <span>
                        <IconButton color="primary" onClick={this.approveRequest} style={{ color: '#02a959' }}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={this.rejectRequest} style={{ color: '#e64226' }}>
                            <BlockIcon />
                        </IconButton>
                    </span>}
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(ActionRenderer)))