import crypto from "crypto-js";

// Utility function for generating signon data
export const generateSignonData = (inputParams) => {
  const HYVOR_TALK_SSO_PRIVATE_KEY = inputParams.privateKey;
  const user = {};

  if (inputParams.isLoggedIn === "true" || inputParams.isLoggedIn) {
    user.timestamp = Math.floor(Date.now() / 1000);
    user.id = inputParams.userId;
    user.name = inputParams.name;
    user.email = inputParams.email;
    user.picture_url = inputParams.picture;
    user.website_url = "";
  }

  const userData = Buffer.from(JSON.stringify(user)).toString("base64");
  const hash = crypto
    .HmacSHA256(userData, HYVOR_TALK_SSO_PRIVATE_KEY)
    .toString();

  return { userData, hash };
};

// Function to be called globally
export const handleHyvorTalkSignon = (rowData) => {
  const signonData = generateSignonData(rowData);

  // Return the generated data
  return signonData;
};
