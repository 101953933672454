import React, { Component, Fragment } from 'react';
import { 
    Grid, withStyles, Button, Typography, Backdrop, IconButton, TextField, 
    useMediaQuery, Popover
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useStyles } from './common/useStyles';
import './common/Common.css'
import api from './common/APIValues';
import HelpIcon from '@material-ui/icons/Help';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CommonFunc from './common/CommonFunc';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PollIcon from './images/poll.png';
import EDWhatLogoIcon from './images/EdWhat-01-01.png';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import routeService from '../services/Routing';
import constant from '../components/common/Constants';
import { get } from "../services/ApiService";

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class AddQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            userTopics: [], loader: false, myProfileCount: {}, type: 'question',
            topic_title: '', topic_desc: '', err_msg: '', options: [], optionCount: 2, disableAddOption: false, topic_hashtag: '',
            fileType: null, fileName: null, fileBase64String: null, disableRemoveOption: true, preViewImage: null,
            Option1: '', 
            Option2: '',
            Option3: '', 
            Option4: '',
            Option5: '', 
            Option6: '',
            Option7: '', 
            Option8: '',
            Option9: '', 
            Option10: '',
            errors: {
                topic_title: '',
                topic_hashtag: '',
                topic_desc: '',
                request_type: '',
                Option1: '',
                topic_image: '',
            },
            anchorEl: null,
        }
    }

    componentDidMount() {
        if(!this.props.userEmail) {
            routeService.routePage(this.props, constant.home);
        }
        this.validateUserDefaultGroupPermission();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });   
        let options = [
            { option : 'Option 1', value: 1 },
            { option : 'Option 2', value: 2 }
        ];
        this.setState({ options });
    }

    validateUserDefaultGroupPermission = () => {
        const permissionName = 'Create Topic';
        const originName = window.location.origin.replace('www.', '');
        const inputs = `GroupId=0&Env=${api.env}&UserId=${this.props.userEmail}&PermissionName=${permissionName}&originName=${originName}`;
    
        get("Permission/ValidateUserPermission?" + inputs)
          .then((response) => {
            if(!response.PermissionName) {
                routeService.routePage(this.props, constant.home);
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });
    };

    addMoreOptions = () => {
        let val = this.state.optionCount + 1;
        let option = { option : 'Option ' + val, value: val };
        let options = this.state.options;
        options.push(option);
        this.setState({ 
            options: options, 
            optionCount: val, 
            disableAddOption: val === 10 ? true : false,
            disableRemoveOption: val === 2 ? true : false 
        });
    }

    removeOptions = () => {
        let val = this.state.optionCount - 1;
        let options = this.state.options;
        options.pop();
        this.setState({ 
            options: options, 
            optionCount: val, 
            disableRemoveOption: val === 2 ? true : false,
            disableAddOption: val === 10 ? true : false, 
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.searchValue !== prevProps.searchValue) {
            routeService.routePage(this.props, constant.home);
        }
    }

    requestType = (type) => {
        this.setState({ type: type });
        let errors = this.state.errors;
        errors.request_type = '';
        this.setState({ errors });
    }

    handleChangeCKEditor = (e, editor) => {
        let data = editor.getData();
        let errors = this.state.errors;
        errors.topic_desc = data.length <= 0 ? 'Please enter question description' : '';
        this.setState({ errors: errors, topic_desc: data });
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'topic_title':
                errors.topic_title = value.length <= 0 ? 'Please enter question title' : '';
                break;
            case 'topic_desc':
                errors.topic_desc = value.length <= 0 ? 'Please enter question description' : '';
                break;
            case 'topic_hashtag':
                let topic_hashtags = value.split(' ');
                //errors.topic_hashtag = value.length <= 0 ? 'Please enter question hashtag' : '';

                if(value.length > 0)
                {
                    let valid = true;
                    topic_hashtags.map((item, index) => {
                        if(!item.startsWith("#")) {
                            valid = false;
                        }
                    });
                    errors.topic_hashtag = !valid ? 'Each hashtag should starts with #' : '';
                } else {
                    errors.topic_hashtag = '';
                }
                break;            
            case 'Option2':
                errors.Option1 = value.length <= 0 ? 'Please enter option 2' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option1 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 2 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 2 has duplicate value' : '';
                break;
            case 'Option1':
                errors.Option1 = value.length <= 0 ? 'Please enter option 1' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 1 has duplicate value' : '';
                if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 1 has duplicate value' : '';
                break;
            case 'Option3':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 3 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 3 has duplicate value' : '';
                }
                break;
            case 'Option4':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 4 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 4 has duplicate value' : '';
                }
                break;
            case 'Option5':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 5 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 5 has duplicate value' : '';
                }
                break;
            case 'Option6':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 6 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 6 has duplicate value' : '';
                }
                break;
            case 'Option7':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 7 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 7 has duplicate value' : '';
                }                
                break;
            case 'Option8':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 8 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 8 has duplicate value' : '';
                }
                break;
            case 'Option9':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 9 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option10 ? 'Option 9 has duplicate value' : '';
                }
                break;
            case 'Option 10':
                if(value) {
                    errors.Option1 = value === this.state.Option1 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option2 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option3 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option4 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option5 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option6 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option7 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option8 ? 'Option 10 has duplicate value' : '';
                    if(errors.Option1 === '') errors.Option1 = value === this.state.Option9 ? 'Option 10 has duplicate value' : '';
                }
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value });
    }

    isPdfFile(fileName) {
        if (fileName) {
            const parts = fileName.split('.');
            return parts.length > 1 && parts.pop().toLowerCase() === 'pdf';
        }
        else
            return false;
    }
    
    validateInputs() 
    {
        if (!this.state.topic_title) {
            return "No title provided";
        }
    
        if (!this.state.topic_desc) {
            return "No description provided";
        }
    
        if (this.state.fileName) {
            const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'application/pdf'];
    
            if (!validFileTypes.includes(this.state.fileType)) {
                return "Invalid file type. Allowed types are jpeg, jpg, png, gif, pdf";
            }
    
            if (this.state.fileSize >= 5 * 1024 * 1024) {
                return "File size exceeds 5 MB";
            }
        }
        return "Valid";
    };
    
    validateQueInputs()
    {
        const validationResult = this.validateInputs();
        if (validationResult !== "Valid") {
            alert(`Error: ${validationResult}`);
        } 
        else {
            return true; 
        }
    }

    validatePollInputs()
    {
        if(!this.state.topic_title || !this.state.topic_desc || !this.state.Option1 || !this.state.Option2) {
            return false; 
        }
        else{ return true; }
    }

    createQuestionPoll = () => {
        if(this.state.type) {            
            if(this.state.type === 'question')
            {                
                this.createQuestion();
            }
            else if(this.state.type === 'poll')
                this.createPoll();
        } else {
            let errors = this.state.errors;
            errors.request_type = 'Please choose question or poll type';
            this.setState({ errors });
        }
    }

    createPoll = () => {        
        if (CommonFunc.validateForm(this.state.errors) && this.validatePollInputs()) {
            let options = [];
            this.setState({ loader: true });

            if(this.state.Option1){
                options.push(this.state.Option1);
            }
            if(this.state.Option2){
                options.push(this.state.Option2);
            }
            if(this.state.Option3){
                options.push(this.state.Option3);
            }
            if(this.state.Option4){
                options.push(this.state.Option4);
            }
            if(this.state.Option5){
                options.push(this.state.Option5);
            }
            if(this.state.Option6){
                options.push(this.state.Option6);
            }
            if(this.state.Option7){
                options.push(this.state.Option7);
            }
            if(this.state.Option8){
                options.push(this.state.Option8);
            }
            if(this.state.Option9){
                options.push(this.state.Option9);
            }
            if(this.state.Option10){
                options.push(this.state.Option10);
            }

            const originName = window.location.origin.replace('www.', '');
            let groupId = this.props.match.params.groupId ? this.props.match.params.groupId : -1;
            let rowData = {
                TOPIC_TITLE: this.state.topic_title,
                TOPIC_HASHTAG: this.state.topic_hashtag,
                TOPIC_DESC: this.state.topic_desc,                
                TOPIC_CREATE_USER_ID: this.props.userEmail,
                TOPIC_CREATE_USER_NM: this.props.userAliasName,
                TOPIC_VOTE_OPTNS: options,
                ENV: api.env,
                GROUP_ID: Number(groupId),
                ORIGIN_NM: originName,
            }

            axios({
                method: 'post',
                url: api.partialURL + 'AddPoll/CreateUserPoll',
                data: JSON.stringify(rowData),
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
            })
            .then(res => {
                if(res) {
                    this.setState({ loader: false });
                    if (res.data.result === 'success') {
                        sessionStorage.setItem('add_question_page', 'created_que_poll_topics');
                        sessionStorage.setItem('add_question_page_1', 'created_que_poll_mythreads');
                        sessionStorage.setItem('add_question_page_2', 'created_que_poll_myprofile');
                        
                        let pathName = constant.pollDetails + res.data.topicId + '/' + this.state.topic_title.toLowerCase().replace(/ /g, '-');
                        routeService.routePage(this.props, pathName);
                    } else {
                        this.setState({ err_msg: 'Failed to create poll' });
                    }
                }
            })
            .catch(function (response) {
                this.setState({ err_msg: response });
            });
        } else {
            let errors = this.state.errors;
            if (!this.state.topic_title) {
                errors.topic_title = 'Please enter question title';
            }            
            if (!this.state.topic_desc) {
                errors.topic_desc = 'Please enter question description';
            }            
            if (!this.state.Option2) {
                errors.Option1 = 'Please enter option 2';
            }
            if (!this.state.Option1) {
                errors.Option1 = 'Please enter option 1';
            }
            this.setState({ errors });
        }
    }

    createQuestion = () => {        
        if (CommonFunc.validateForm(this.state.errors) && this.validateQueInputs()) {
            this.setState({ loader: true });
            const originName = window.location.origin.replace('www.', '');
            let groupId = this.props.match.params.groupId ? this.props.match.params.groupId : -1;
            let rowData = {
                TOPIC_TITLE: this.state.topic_title,
                TOPIC_HASHTAG: this.state.topic_hashtag,
                TOPIC_DESC: this.state.topic_desc,
                TOPIC_FILE_NM: this.state.fileName,
                TOPIC_CREATE_USER_ID: this.props.userEmail,
                TOPIC_CREATE_USER_NM: this.props.userAliasName,
                ENV: api.env,
                GROUP_ID: Number(groupId),
                ORIGIN_NM: originName,
                TOPIC_FILE_BASE_64_STRNG: this.state.fileBase64String,
            }

            axios({
                method: 'post',
                url: api.partialURL + 'AddQuestion/CreateUserQuestion',
                data: JSON.stringify(rowData),
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
            })
            .then(res => {
                if(res) {
                    this.setState({ loader: false });
                    if (res.data.result === 'success') {
                        sessionStorage.setItem('add_question_page', 'created_que_poll_topics');
                        sessionStorage.setItem('add_question_page_1', 'created_que_poll_mythreads');
                        sessionStorage.setItem('add_question_page_2', 'created_que_poll_myprofile');
                        
                        let pathName = constant.queDetails + res.data.topicId + '/' + this.state.topic_title.toLowerCase().replace(/ /g, '-');
                        routeService.routePage(this.props, pathName);
                    } else {
                        this.setState({ err_msg: res.data.result });
                    }
                }
            })
            .catch(function (response) {
                this.setState({ err_msg: response, loader: false });
            });
        } else {
            let errors = this.state.errors;
            if (!this.state.topic_title) {
                errors.topic_title = 'Please enter question title';
            }            
            if (!this.state.topic_desc) {
                errors.topic_desc = 'Please enter question description';
            }
            if(this.state.fileName && (this.state.fileType !== 'image/jpeg' || this.state.fileType !== 'image/jpg' ||
                this.state.fileType !== 'image/png' || this.state.fileType !== 'image/gif')) {
                errors.topic_image = 'This file format is not supported';
            } else {
                errors.topic_image = '';
            }
            this.setState({ errors });
        }
    }

    onFileUploadChange = (event) => {
        var file = event.target.files[0];
        if(file) {
            if (this.isPdfFile(file.name) ) {
                this.state.preViewImage = file.name;
            }
            else {
                this.setState({ preViewImage: URL.createObjectURL(file) });
            }
            var blob = file.slice();
            var reader = new FileReader();

            reader.onloadend = function (evt) {
                if (evt.target.readyState == FileReader.DONE) {
                    var cont = evt.target.result
                    var base64String = CommonFunc.getB64Str(cont);

                    this.setState ({
                        fileType: file.type,
                        fileBase64String: base64String,
                        fileName: file.name,
                        fileSize: file.size
                    });
                }
            }.bind(this);

            reader.readAsArrayBuffer(blob);
        } else {
            this.setState ({
                fileType: null,
                fileBase64String: null,
                fileName: null
            });
        }
    }

    closeImageIcon = () => {
        this.setState({ preViewImage: false });
    }

    showPopover = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };
    
    closePopover = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const { classes, mediaQuery } = this.props;
        const col212 = mediaQuery ? 2 : 12;
        const col26 = mediaQuery ? 2 : 6;
        const col106 = mediaQuery ? 10 : 6;
        const col1 = mediaQuery ? 2 : 1;
        const col3 = mediaQuery ? 3 : 6;
        const col211 = mediaQuery ? 2 : 11;
        const buttonIcon = classNames(classes.iconMedSize, classes.rightIcon);
        const buttonLeftIcon = classNames(classes.iconMedSize, classes.leftIcon);
        const customButton = classNames(classes.customButtonRegular1, classes.buttonShadow);
        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;

        return (
            <Fragment>
                <Backdrop className={classes.backdrop} open={this.state.loader}>
                    <img src={EDWhatLogoIcon} height={50} alt="Logo" />
                </Backdrop>

                <Grid container spacing={0} style={{ marginTop: mediaQuery ? '55px' : '90px' }}>
                    <Grid item xs={ !mediaQuery ? 1 : 0}>
                        <InfoIcon className={buttonLeftIcon} onClick={this.showPopover} />

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.closePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            PaperProps={{
                                style: {
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    borderRadius: 0,
                                    paddingRight: 10
                                }
                            }}
                        >
                            <Typography sx={{ p: 1, backgroundColor: "white" }}>
                                <span style={{ 
                                    color: "#c2272d", 
                                    fontWeight: "bold", 
                                    marginLeft: 15,
                                    fontSize: 11 
                                }}>
                                    Tips getting answers quickly
                                </span>
                                <br />
                                <Typography style={{ marginTop: 10 }}>
                                    <ul style={{fontSize: 10}}>
                                    <li>Frame the question which can generate conversation</li>
                                    <li>Keep the question shorter and to the point</li>
                                    <li>Do not use any hate speech or slangs</li>
                                    <li>Double check grammer and spelling</li>
                                    </ul>
                                </Typography>
                            </Typography>
                        </Popover>
                    </Grid>
                    <Grid item xs={!mediaQuery ? 11 : 0}>
                        <Typography className={classes.titleInfo} color="textSecondary">                            
                            Please mind your language. Try to keep it short and to the point. Make sure it is education related.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item style={{marginTop:5}}>
                        Choose Type:
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" 
                            className={this.state.type === 'question' ? classes.customButtonRegular1 : customButton}
                            onClick={()=> this.requestType('question')} size="small">
                                Question
                                <HelpIcon className={buttonIcon} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary"
                            className={this.state.type === 'poll' ? classes.customButtonRegular1 : customButton}
                            onClick={()=> this.requestType('poll')} size="small">
                                Poll
                                <img src={PollIcon} className={buttonIcon} height={16} />
                        </Button>                        
                    </Grid>
                </Grid>

                { this.state.errors.request_type.length > 0 && 
                            <span className='error'>{this.state.errors.request_type}</span> }

                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item xs={12}>
                        <TextField fullWidth name="topic_title" label="Type your question here" required
                            onChange={this.handleChange} noValidate value={this.state.topic_title} size="small"
                            variant="outlined" style={{ backgroundColor: '#e7e7e7' }} />
                        { this.state.errors.topic_title.length > 0 && 
                            <span className='error'>{this.state.errors.topic_title}</span> }
                    </Grid>                    
                    <Grid item xs={12}>
                        <CKEditor 
                            size="small"
                            editor={ClassicEditor} 
                            data={this.state.topic_desc} 
                            onChange={this.handleChangeCKEditor}
                            config={{
                                toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "link",
                                    "mediaEmbed",
                                    "|",
                                    "undo",
                                    "redo",
                                ],
                                placeholder: "Type your description here *",
                            }}
                        />
                        { this.state.errors.topic_desc.length > 0 && 
                            <span className='error'>{this.state.errors.topic_desc}</span> }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth name="topic_hashtag" label="Type your #tag here (optional)"
                            onChange={this.handleChange} noValidate value={this.state.topic_hashtag} size="small"
                            variant="outlined" style={{ backgroundColor: '#e7e7e7' }} />
                        { this.state.errors.topic_hashtag.length > 0 && 
                            <span className='error'>{this.state.errors.topic_hashtag}</span> }
                    </Grid>
                        { this.state.type === 'question' &&
                            <Grid item xs={12}>
                                <Typography className={classes.subTitle11} color="textSecondary">
                                    Upload image (.jpeg | .png | .jpg | .gif | .pdf)
                                </Typography>
                            </Grid>
                        }
                        { this.state.type === 'question' &&
                            <Grid item xs={col106}>                           
                                <label htmlFor="topic_image">
                                    <input
                                        style={{ display: "none" }}
                                        id="topic_image"
                                        name="topic_image"
                                        type="file"
                                        onChange={ e => {this.onFileUploadChange(e)}}
                                    />
                                    <Button color="secondary" className={classes.customButtonError} 
                                        variant="contained" component="span" size="small">
                                        Choose File
                                    </Button>
                                </label>
                            </Grid>
                        }
                    { this.state.type === 'question' &&
                        <Grid item xs={col26}>
                            <Button fullWidth variant="contained" color="primary" className={classes.customButtonFocus}
                                onClick={this.createQuestionPoll} size="small">
                                    Create Topic
                            </Button>                        
                        </Grid>
                    }                    
                    { this.state.type === 'poll' &&
                        <Grid item xs={12}>
                            <Typography className={classes.subTitle11} color="textSecondary">
                                * Minimum 2 options for poll and max 10
                            </Typography>
                        </Grid>
                    }
                    { this.state.type === 'poll' && this.state.options.map((option, row) => (
                        <Grid item xs={col3}>
                            <TextField fullWidth name={option.option.slice(0, -2) + '' + option.value} label={option.option}
                                onChange={this.handleChange} noValidate required={ option.value > 2 ? false : true}
                                value={                                    
                                    option.value === 1 ? this.state.Option1 : option.value === 2 ? this.state.Option2 :
                                    option.value === 3 ? this.state.Option3 : option.value === 4 ? this.state.Option4 :
                                    option.value === 5 ? this.state.Option5 : option.value === 6 ? this.state.Option6 :
                                    option.value === 7 ? this.state.Option7 : option.value === 8 ? this.state.Option8 :
                                    option.value === 9 ? this.state.Option9 : this.state.option10
                                }
                            variant="outlined" style={{ backgroundColor: '#e7e7e7' }} size="small" />
                        </Grid>
                    ))}
                    { this.state.type === 'poll' &&
                        <Grid item xs={12}>
                            { this.state.errors.Option1.length > 0 && 
                            <span className='error'>{this.state.errors.Option1}</span> }
                        </Grid>
                    }
                </Grid>
                
                { this.state.preViewImage && !this.isPdfFile(this.state.preViewImage) &&
                    <Grid container spacing={0} style={{ marginTop: '10px' }}>                        
                        <Grid item xs={col211}>
                            <img src={this.state.preViewImage} style={{width: '100%'}}
                                alt="pre-view image" />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton style={{marginTop: '-18px', marginLeft: '-10px'}} onClick={this.closeImageIcon} 
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                }
                
                { this.state.preViewImage && this.isPdfFile(this.state.preViewImage) &&
                    <Grid container spacing={0} style={{ marginTop: '10px' }}>                        
                        <Grid item xs={col211}>
                            <PictureAsPdfIcon/>
                            {this.state.preViewImage}
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton style={{marginTop: '-18px', marginLeft: '-10px'}} onClick={this.closeImageIcon} 
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={col1} style={{ marginTop: '5px' }}>
                    { this.state.type === 'poll' && !this.state.disableAddOption &&
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.customButtonError}
                                onClick={this.addMoreOptions} size="small">
                                    Add More Options
                            </Button>                        
                        </Grid>
                    }
                    { this.state.type === 'poll' && !this.state.disableRemoveOption &&
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.customButtonError}
                                onClick={this.removeOptions} size="small">
                                    Remove Options
                            </Button>                        
                        </Grid>
                    }
                    { this.state.type === 'poll' &&
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.customButtonFocus}
                                onClick={this.createQuestionPoll} size="small">
                                    Create Poll
                            </Button>                        
                        </Grid>
                    }
                    <Grid item xs={12}>
                        { this.state.err_msg.length > 0 && <span className='error'>{this.state.err_msg}</span> }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(AddQuestion)))