import React, { Component, Fragment } from 'react';
import {
    Grid, withStyles, Button, Card, CardContent, Typography, useMediaQuery,
    Backdrop
} from '@material-ui/core';
import { useStyles } from './common/useStyles';
import './common/Common.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import api from './common/APIValues';
import HelpIcon from '@material-ui/icons/Help';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VoteBigIcon from './images/votes_h.png';
import { withRouter } from 'react-router-dom';
import EDWhatLogoIcon from './images/EdWhat-01-01.png';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import routeService from '../services/Routing';
import constant from '../components/common/Constants';
import imageSizes from "../pages/json/ImageSize.json";
import { get } from "../services/ApiService";

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            myProfileCount: {},
            myCommunities: [],
        }
    }

    componentDidMount() {
        if (!this.props.userEmail) {
            routeService.routePage(this.props, constant.home);
        }
        this.loadMyProfile(this.props.userEmail);
        this.loadMyCommunities(this.props.userEmail);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.userEmail !== prevProps.userEmail || this.props.userAliasName !== prevProps.userAliasName) {
            this.loadMyProfile(this.props.userEmail, true);
            this.loadMyCommunities(this.props.userEmail);
        }
        this.setWindowScrollTo();
    }

    loadMyCommunities(userId) {
        const originName = window.location.origin.replace('www.', '');
        this.setState({ loader: true });
        get('MyProfile/GetMyCommunities?UserId=' + userId + '&Env=' + api.env + '&limitCount=-1' + '&originName=' + originName)
            .then(response => {
                if(response) {
                    this.setState({
                        myCommunities: response,
                        loader: false,
                    })
                }
            })
            .catch(function (response) {
                console.log(response);
            });
    }

    loadMyProfile(userId, isUpdate) {
        const { hasCache, getCache } = this.props.cacheActions;
        this.setState({ loader: true });
        const originName = window.location.origin.replace('www.', '');
        let action = sessionStorage.getItem('add_question_page_2');
        let url = api.partialURL + 'MyProfile/GetMyProfileDetails?USER_ID=' + userId + '&ENV=' + api.env + '&originName=' + originName;
        let params = {
            USER_ID: userId
        }

        if (action === 'created_que_poll_myprofile' || (action === 'delete_my_account' && !isUpdate)) {
            sessionStorage.setItem('add_question_page_2', '');
            this.fetchMyProfile(url, params);
        } else {
            if (hasCache(url, params)) {
                this.setMyProfile(url, params, getCache(url, params).data);
            } else {
                this.fetchMyProfile(url, params);
            }
        }
    }

    fetchMyProfile = (url, params) => {
        fetch(url)
            .then(res => res.json())
            .then(result => {
                this.setMyProfile(url, params, result);                
            })
            .catch(err => console.log(err));
    }

    setWindowScrollTo = () => {
        let inputData = this.props.history.location.inputData;
        window.scrollTo({
            top: inputData && inputData.myCommunities ? 505 : 0,
            behavior: "smooth"
        });
    }

    setMyProfile = (url, params, myProfile) => {
        this.setState({
            myProfileCount: myProfile,            
            loader: false,
        });
        this.props.cacheActions.setCache(url, params, myProfile);
        this.setWindowScrollTo();
    }

    showMyTopics = () => {
        routeService.routePage(this.props, constant.myThreads);
    }

    logout = () => {
        routeService.routePage(this.props, constant.home);
    }

    redirectToCommunityHome = (groupId) => {
        let pathName = constant.communityHome + groupId;
        routeService.routePage(this.props, pathName);
    }

    render() {
        const { classes, mediaQuery } = this.props;
        const col3 = mediaQuery ? 3 : 12;
        const col2 = mediaQuery ? 2 : 6;
        const col5 = mediaQuery ? 5 : 3;
        const col05 = mediaQuery ? 5 : 0;
        const col4 = mediaQuery ? 4 : 12;

        return (
            <Fragment>
                <Backdrop className={classes.backdrop} open={this.state.loader}>
                    <img src={EDWhatLogoIcon} height={50} alt="Logo" />
                </Backdrop>

                <Grid container spacing={2} style={{ marginTop: mediaQuery ? '100px' : '130px' }}>
                    <Grid item xs={col3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <Grid item xs={11}>
                                        <Typography className={classes.totalCountText}>
                                            Total Questions
                                        </Typography>
                                        <Typography className={classes.totalCount}>
                                            {this.state.myProfileCount.TOTAL_QUE}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <HelpIcon className={classes.iconMedSize} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={col3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <Grid item xs={11}>
                                        <Typography className={classes.totalCountText}>
                                            Total Likes
                                        </Typography>
                                        <Typography className={classes.totalCount}>
                                            {this.state.myProfileCount.TOTAL_LIKE}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ThumbUpIcon className={classes.iconMedSize} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={col3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <Grid item xs={11}>
                                        <Typography className={classes.totalCountText}>
                                            Total Votes
                                        </Typography>
                                        <Typography className={classes.totalCount}>
                                            {this.state.myProfileCount.TOTAL_VOTE}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <img src={VoteBigIcon} width='50%' />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={col3}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <Grid item xs={11}>
                                        <Typography className={classes.totalCountText}>
                                            Total Views
                                        </Typography>
                                        <Typography className={classes.totalCount}>
                                            {this.state.myProfileCount.TOTAL_VIEW}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <VisibilityIcon className={classes.iconMedSize} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: !mediaQuery ? 10 : 20 }}>
                    <Grid item xs={col5}>
                    </Grid>
                    <Grid item xs={col2}>
                        <Button fullWidth variant="contained" className={classes.customButtonFocus}
                            size="small" onClick={this.showMyTopics}>
                            My Threads
                            <ArrowRightAltIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>
                    <Grid item xs={col05}>
                    </Grid>
                </Grid>

                <Typography className={classes.title} style={{ marginTop: 10 }}>
                    My Communities
                </Typography>

                <Grid container spacing={3}>
                    {this.state.myCommunities.map((group, row) => (
                        <Grid item xs={col4}>
                            <Card style={{ cursor: 'pointer', height: 121 }} onClick={() => this.redirectToCommunityHome(group.GroupId)}>
                                <CardContent>
                                    <Grid container spacing={0}>
                                        <Grid item xs={11}>
                                            <Typography className={classes.totalCountText}>
                                                {group.GroupName}
                                            </Typography>
                                            <Typography className="text-ellipsis likes-comments">
                                                {group.GroupDesc}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img src={group.LogoUrl} alt="img" 
                                                style={{ width: imageSizes.logo_icon.width, height: imageSizes.logo_icon.height }} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(MyProfile)))