import React, { Component } from 'react';
import { 
    DialogContent, IconButton, Grid, Dialog, TextField, DialogActions, Button, FormControl, RadioGroup, 
    FormControlLabel, Radio, useMediaQuery, Typography, withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import api from './common/APIValues';
import CommonFunc from './common/CommonFunc';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useStyles } from './common/useStyles';

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loader: false, reportOptions: [], reportOption: '', otherOption: '', err_msg: '',
            errors: {
                reportOption: '',
                otherOption: '',
            },
        }
    }

    componentDidMount() {
        this.loadReportOptions();
    }

    loadReportOptions = () => {
        const { hasCache, getCache } = this.props.cacheActions;
        this.setState({ loader: true });
        let url = api.partialURL + 'TopicReport/GetTopicReportOptions?ENV=' + api.env;
        let params = {
            RPT_OPTNS: true
        }

        if (hasCache(url, params)) {
            this.setReport(url, params, getCache(url, params).data);
        } else {
            this.fetchReportOptions(url, params);
        }
    }

    fetchReportOptions = (url, params) => {
        fetch(url)
            .then(res => res.json())
            .then(result => {
                this.setReport(url, params, result);
            })
            .catch(err => console.log(err));
    }

    setReport = (url, params, reportOptions) => {
        this.setState({
            reportOptions: reportOptions,
            loader: false,
        });
        this.props.cacheActions.setCache(url, params, reportOptions);
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'reportOption':
                this.state.reportOption = value;
                errors.reportOption = value.length <= 0 ? 'Please select report option' : '';

                if(this.state.reportOption !== 'Others') {
                    this.state.otherOption = '';
                    errors.otherOption = '';
                }
                break;
            case 'otherOption':
                this.state.otherOption = value;
                errors.otherOption = value.length <= 0 ? 'Please enter other option' : '';
                break;
        }
        this.setState({ errors, [name]: value });
        this.setState({ err_msg: '' });
    }

    validateAllInputs(){
        if(!this.state.reportOption || (!this.state.otherOption && this.state.reportOption === 'Others')) {
            return false;
        }
        else{ return true; }
    }

    saveChanges = () => {
        if(this.validateAllInputs() && CommonFunc.validateForm(this.state.errors)) {
            let rowData = {
                TOPIC_ID: Number(this.props.topicId),
                TOPIC_RPT_OPTN: this.state.reportOption,
                TOPIC_RPT_OTHR_OPTN: this.state.reportOption === 'Others' ? this.state.otherOption : '',
                TOPIC_RPT_USER_ID: this.props.userEmail,
                TOPIC_RPT_USER_NM: this.props.userAliasName,
                ENV: api.env
            }

            let path = this.props.postType === 'poll' ? 'PollReport/SavePollReport' : 'QueReport/SaveQueReport';

            axios({
                method: 'post',
                url: api.partialURL + path,
                data: JSON.stringify(rowData),
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
            })
            .then(res => {
                if(res) {
                    this.setState({ loader: false });
                    if (res.data === 'success') {
                        
                    } else {
                        this.setState({ err_msg: 'Failed to save report' });
                    }
                }
            })
            .catch(function (response) {
                this.setState({ err_msg: response });
            });

            this.props.handleStateUpdate();
        } else {
            let errors = this.state.errors;
            if (!this.state.reportOption) {
                errors.reportOption = 'Please select report option';
            }
            if (!this.state.otherOption && this.state.reportOption === 'Others') {
                errors.otherOption = 'Please enter other option';
            }
            this.setState({ errors });
        }
    }

    render() {
        const { classes, mediaQuery } = this.props;
        const postType = this.props.postType === 'poll' ? 'poll' : 'question';

        return (
            <React.Fragment>
                <Dialog fullWidth open={this.props.open}
                    onClose={this.props.handleReportClose} disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={ mediaQuery ? 11 : 10 }>
                                <Typography className={classes.title}>
                                    Report this {postType}
                                </Typography>
                                <Typography className={classes.subHeader}>
                                    Help us understand what's happening with this {postType}. How would you describe it?
                                </Typography>
                            </Grid>
                            <Grid item xs={ mediaQuery ? 1 : 2 }>
                                <IconButton style={{ marginLeft: '22px', marginTop: '-20px' }} 
                                    onClick={this.props.handleReportClose} aria-label="settings">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} style={{ marginTop: '20px' }}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup name="reportOption" value={this.state.reportOption} onChange={this.handleChange}>
                                        { this.state.reportOptions && 
                                            this.state.reportOptions.map((option, row) => (
                                            <FormControlLabel value={option} control={<Radio color="primary" />} 
                                                label={<span className={classes.subHeader}>{option}</span>} />
                                        ))}
                                    </RadioGroup>
                                    { this.state.errors.reportOption.length > 0 && 
                                        <span className='error'>{this.state.errors.reportOption}</span> }                                                    
                                </FormControl>
                            </Grid>
                            { this.state.reportOption === 'Others' &&
                                <Grid item xs={12}>
                                    <TextField fullWidth name="otherOption" label="Enter other option" required onChange={this.handleChange} noValidate 
                                        value={this.state.otherOption} variant="outlined" style={{ backgroundColor: 'white' }}
                                    />
                                    { this.state.errors.otherOption.length > 0 && <span className='error'>{this.state.errors.otherOption}</span> }
                                </Grid>
                            }
                            <Grid item xs={12}>
                                { this.state.err_msg.length > 0 && 
                                    <span className='error'>{this.state.err_msg}</span> }
                            </Grid>
                        </Grid>
                        
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#f6f7f2' }}>
                        <Button onClick={this.saveChanges} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(Report)))
