import React from "react";
import {
  ListItemText,
  List,
  ListItem,
  IconButton,
  useMediaQuery,
  ListItemAvatar,
  makeStyles,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Divider,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  iconRead: {
    fontSize: "15px",
    marginLeft: "20px",
    color: "#c2272d",
  },
  title: {
    fontSize: 20,
    fontFamily: "Poppins",
    marginBottom: 10,
    fontWeight: 700,
    color: "#c2272d",
  },
  subHeader: {
    fontFamily: "raleway",
    fontWeight: 500,
    color: "#000000",
  },
  listItem: {
    cursor: "pointer",
    marginLeft: -16,
    paddingRight: 0,
  },
}));

export default function Notifications(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ backgroundColor: "#f6f7f2" }}>
          <Grid container spacing={0}>
            <Grid item xs={matches ? 11 : 10}>
              <Typography
                className={classes.title}
                style={{ marginBottom: 10 }}
                variant="h6"
              >
                Notifications
              </Typography>
            </Grid>
            <Grid item xs={matches ? 1 : 2}>
              <IconButton
                style={{ marginLeft: "22px", marginTop: "-10px" }}
                onClick={props.handleClose}
                aria-label="settings"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <List>
                {props.userNotifications.length > 0 ? (
                  props.userNotifications.map((notification, row) =>
                    notification.EVNT_TYPE === "Community" ? (
                      <React.Fragment>
                        <ListItem
                          className={classes.listItem}
                          onClick={() =>
                            props.redirectToApproveRequests(
                              notification.TOPIC_ID,
                              notification.NOTIFICATION_ID,
                              notification.IS_READ
                            )
                          }
                        >
                          <ListItemText
                            primary={
                              <span className={classes.subHeader}>
                                {notification.NOTIFICATION_MSG}
                              </span>
                            }
                            secondary={
                              <span className={classes.subHeader}>
                                {notification.CMNT_CREATED_AGO + " ago"}
                              </span>
                            }
                          />
                          {notification.IS_READ === 0 && (
                            <ListItemAvatar>
                              <FiberManualRecordIcon
                                className={classes.iconRead}
                              />
                            </ListItemAvatar>
                          )}
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ListItem
                          className={classes.listItem}
                          onClick={() =>
                            props.viewTopicDetails(
                              notification.TOPIC_ID,
                              notification.TOPIC_TITLE,
                              notification.TOPIC_TYPE,
                              notification.NOTIFICATION_ID,
                              notification.IS_READ
                            )
                          }
                        >
                          <ListItemText
                            primary={
                              <span className={classes.subHeader}>
                                {notification.NOTIFICATION_MSG}
                              </span>
                            }
                            secondary={
                              <span className={classes.subHeader}>
                                {notification.CMNT_CREATED_AGO + " ago"}
                              </span>
                            }
                          />
                          {notification.IS_READ === 0 && (
                            <ListItemAvatar>
                              <FiberManualRecordIcon
                                className={classes.iconRead}
                              />
                            </ListItemAvatar>
                          )}
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )
                ) : (
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="No Notifications" />
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
