import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Typography,
  Chip,
  useMediaQuery,
  Button,
  Backdrop,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import { withRouter } from "react-router-dom";
import DefaultCoverPic from "../../components/images/CoverPic.jpg";
import api from "../../components/common/APIValues";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import SuccessInfo from "../modal/SuccessInfo";
import axios from "axios";
import loginService from "../../services/Login";
import routeService from "../../services/Routing";
import constant from "../../components/common/Constants";
import CommonFunc from "../../components/common/CommonFunc";
import { create, remove, get } from "../../services/ApiService";
import ConfirmationDialog from "../modal/ConfirmationDialog";
import MemberIcon from "../../components/images/Member.png";
import RequestedIcon from "../../components/images/Requested.png";
import DeniedIcon from "../../components/images/Denied.png";
import AcceptedIcon from "../../components/images/Accepted.png";
import JoinIcon from "../../components/images/Join.png";
import OwnerIcon from "../../components/images/Owner.png";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class Communities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communities: [],
      openInfo: false,
      textMsg: "",
      loader: false,
      htmlUserToAdmin: "",
      htmlUserToUser: "",
      openConfirmDialog: false,
      groupIdForRemove: null,
      defaultCommunity: {},
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadCommunities(this.props.searchValue, this.props.sortBy);
    this.getDefaultCommunityDetails();    
  }

  getHtmlText = (s3BaseUrl) => {
    const htmlUserToAdminUrl = `${s3BaseUrl}/email-template/UserToAdminOnJoin.txt`;
    const htmlUserToUserUrl = `${s3BaseUrl}/email-template/UserToUserOnJoin.txt`;

    fetch(htmlUserToAdminUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlUserToAdmin: text });
      });

    fetch(htmlUserToUserUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlUserToUser: text });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.searchValue !== prevProps.searchValue ||
      this.props.sortBy !== prevProps.sortBy ||
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName
    ) {
      setTimeout(() => {
        this.loadCommunities(this.props.searchValue, this.props.sortBy);
      }, 500);
    }
  };

  getDefaultCommunityDetails = () => {
    this.setState({ loader: true });
    const originName = window.location.origin.replace('www.', '');
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then(
      (response) => {
        this.setState({
          defaultCommunity: response,
          loader: false,
        });
        
        const s3BaseUrl = response.S3ConfigUrl.replace('/configuration/config.json', '');
        this.getHtmlText(s3BaseUrl);
      }
    );
  };

  loadCommunities = (searchValue, sortBy) => {
    let srchVal = searchValue ? searchValue : "";
    const originName = window.location.origin.replace('www.', '');
    this.setState({ loader: true });
    let url = api.partialURL + "Community/GetCommunities?UserId=" + this.props.userEmail +
      "&ENV=" + api.env +
      "&SearchValue=" + srchVal +
      "&SortInd=" + sortBy +
      '&originName=' + originName;
    this.fetchCommunities(url);
  };

  fetchCommunities = (url) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setCommunities(result);
      })
      .catch((err) => console.log(err));
  };

  setCommunities = (communities) => {
    this.setState({
      communities: communities,
      loader: false,
    });
  };

  sendEmailToAdmin = (
    groupId,
    groupName,
    logoUrl,
    userEmail,
    communityOwner
  ) => {
    const originName = window.location.origin.replace('www.', '');
    const loginUrl = `${originName}/#`;
    let subject = "{user_name} has requested to join {community_name}";
    subject = subject
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", groupName);
    const htmlUserToAdmin = this.state.htmlUserToAdmin
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", groupName)
      .replace("{community_icon_url}", logoUrl)
      .replaceAll("{domain_name}", loginUrl)
      .replaceAll("{group_id}", Number(groupId))
      .replace("{logo_url}", this.state.defaultCommunity.LogoUrl.replace('{device_type}', 'desktop'))
      .replace("{team_name}", this.state.defaultCommunity.GroupName)
      .replace("{email_address}", userEmail);

    const rowData = {
      HtmlBody: htmlUserToAdmin,
      Subject: subject,
      ToAddress: communityOwner,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmailToUser = (groupName, userEmail) => {
    const originName = window.location.origin.replace('www.', '');
    let subject = "{user_name} has requested to join {community_name}";
    subject = subject
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", groupName);
    const htmlUserToUser = this.state.htmlUserToUser
      .replace("{user_name}", this.props.userAliasName)
      .replace("{logo_url}", this.state.defaultCommunity.LogoUrl.replace('{device_type}', 'desktop'))
      .replace("{team_name}", this.state.defaultCommunity.GroupName)
      .replace("{community_name}", groupName);

    const rowData = {
      HtmlBody: htmlUserToUser,
      Subject: subject,
      ToAddress: userEmail,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmail = (rowData) => {
    axios({
      method: "post",
      url: api.partialURL + "EmailService/SendEmail",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data === "error") {
            //log information in database table
          }
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response });
      });
  };

  saveWebNotification = (communityOwnerId, groupId) => {
    let rowData = {
      UserId: this.props.userEmail,
      CommunityOwner: communityOwnerId,
      GroupId: Number(groupId),
      Status: "User Requested",
      NotificationType: "Community",
      Env: api.env,
    };
    create("CommunityNotification/SaveCommunityNotification", rowData)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  joinCommunityGroup = (
    groupId,
    communityType,
    userGroupStatus,
    groupName,
    logoUrl,
    userEmail,
    communityOwner,
    communityOwnerId
  ) => {
    if (userGroupStatus === "Member") {
      this.setState({ openConfirmDialog: true, groupIdForRemove: groupId });
    } else {
      if (this.props.userEmail && this.props.userAliasName) {
        this.setState({ loader: true });
        let rowData = {};
        rowData.GroupId = Number(groupId);
        rowData.SubscriptionId = 2;
        rowData.ExpiryDate = "8888-12-31";
        rowData.Status =
          communityType === "private" && userGroupStatus === ""
            ? "User Requested"
            : "Approved";
        rowData.CreatedUserId = this.props.userEmail;
        rowData.Env = api.env;
        rowData.CommunityType = communityType;

        axios({
          method: "post",
          url: api.partialURL + "Community/JoinCommunity",
          data: JSON.stringify(rowData),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        })
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              if (res.data === "success") {
                if (communityType === "private" && userGroupStatus === "") {
                  this.setState({
                    openInfo: true,
                    textMsg: "Your request is successfully sent to the admin !",
                  });

                  this.sendEmailToAdmin(
                    groupId,
                    groupName,
                    logoUrl,
                    userEmail,
                    communityOwner
                  );
                  this.sendEmailToUser(groupName, userEmail);
                  this.saveWebNotification(communityOwnerId, groupId);
                }
                this.loadCommunities("", this.props.sortBy);
              } else {
                this.setState({ err_msg: "Failed to join community." });
              }
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });
      } else if (!this.props.userEmail || !this.props.userAliasName) {
        loginService.redirectToLogin(this.props);
      }
    }
  };

  redirectToCommunityHome = (groupId) => {
    let pathName = constant.communityHome + groupId;
    let inputData = {
      isJoined: sessionStorage.getItem("isJoinedCommunities"),
    };
    routeService.routePage(this.props, pathName, inputData);
  };

  handleClose = () => {
    this.setState({ openInfo: false });
  };

  handleConfirmDialogClose = () => {
    this.setState({ openConfirmDialog: false });
  };

  handleDelete = () => {
    this.removeUser(this.props.userEmail, this.state.groupIdForRemove);
    sessionStorage.setItem("add_question_page_2", "delete_my_account");
    sessionStorage.setItem("home_page_action", "exit_from_community");
  };

  removeUser = (userId, groupId) => {
    let input = Number(groupId) + "/" + userId + "/" + api.env;
    remove("ManageUsers/DeleteUsers", input)
      .then((response) => {
        if (response.StatusCode === 200) {
          this.loadCommunities(this.props.searchValue, this.props.sortBy);
          this.handleConfirmDialogClose();
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  render() {
    const { classes, mediaQuery } = this.props;

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <SuccessInfo
          open={this.state.openInfo}
          textMsg={this.state.textMsg}
          handleClose={this.handleClose}
          infoType='success'
        ></SuccessInfo>

        <ConfirmationDialog
          open={this.state.openConfirmDialog}
          handleClose={this.handleConfirmDialogClose}
          titleText={"Exit from community?"}
          contentText1={
            "This action will allow you to exit from community. We cannot recover this later."
          }
          contentText2={"from this community?"}
          contentActionText={"exit"}
          actionText={"Yes, exit"}
          handleDelete={this.handleDelete}
        ></ConfirmationDialog>

        <Grid
          container
          spacing={2}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          {this.state.communities.map((group, row) => (
            <Grid item xs={12} key={group.GroupId}>
              <Card>
                <CardContent>
                  {mediaQuery ? (
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={1}
                        onClick={() =>
                          this.redirectToCommunityHome(group.GroupId)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            group.CoverUrl ? group.CoverUrl : DefaultCoverPic
                          }
                          alt="img"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        onClick={() =>
                          this.redirectToCommunityHome(group.GroupId)
                        }
                        className={classes.cardCenter}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography className={classes.subHeaderBold}>
                          {group.GroupName +
                            ", " +
                            group.MembersCount +
                            " Members"}
                          <Chip
                            label={CommonFunc.Capitalize(group.CommunityType)}
                            size="small"
                            className={classes.chipCard}
                          />
                        </Typography>
                        <Typography className={classes.likesComments}>
                          {group.GroupDesc}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.cardCenter}>
                        <Button
                          variant="contained"
                          disabled={
                            group.UserGroupStatus === "" ||
                            group.UserGroupStatus === "Accept" ||
                            group.UserGroupStatus === "Member"
                              ? false
                              : true
                          }
                          className={
                            group.UserGroupStatus === "Owner" ||
                            group.UserGroupStatus === "Member"
                              ? classes.buttonOwnerMember
                              : group.UserGroupStatus === "Requested" ||
                                group.UserGroupStatus === "Accept"
                              ? classes.buttonAcceptRequested
                              : classes.buttonJoinDenied
                          }
                          size="small"
                          onClick={() =>
                            this.joinCommunityGroup(
                              group.GroupId,
                              group.CommunityType,
                              group.UserGroupStatus,
                              group.GroupName,
                              group.LogoUrl,
                              group.CommunityOwner,
                              group.UserEmail,
                              group.CommunityOwnerId
                            )
                          }
                        >
                          <img
                            className={classes.leftIcon}
                            alt="member"
                            src={
                              group.UserGroupStatus === "Member"
                                ? MemberIcon
                                : group.UserGroupStatus === "Owner"
                                ? OwnerIcon
                                : group.UserGroupStatus === "Requested"
                                ? RequestedIcon
                                : group.UserGroupStatus === "Accept"
                                ? AcceptedIcon
                                : group.UserGroupStatus === "Denied"
                                ? DeniedIcon
                                : JoinIcon
                            }
                          />
                          {group.UserGroupStatus === ""
                            ? "Join"
                            : group.UserGroupStatus}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        onClick={() =>
                          this.redirectToCommunityHome(group.GroupId)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            group.CoverUrl ? group.CoverUrl : DefaultCoverPic
                          }
                          alt="img"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          style={{ float: "right", marginLeft: 10 }}
                          variant="contained"
                          disabled={
                            group.UserGroupStatus === "" ||
                            group.UserGroupStatus === "Accept" ||
                            group.UserGroupStatus === "Member"
                              ? false
                              : true
                          }
                          className={
                            group.UserGroupStatus === "Owner" ||
                            group.UserGroupStatus === "Member"
                              ? classes.buttonOwnerMember
                              : group.UserGroupStatus === "Requested" ||
                                group.UserGroupStatus === "Accept"
                              ? classes.buttonAcceptRequested
                              : classes.buttonJoinDenied
                          }
                          size="small"
                          onClick={() =>
                            this.joinCommunityGroup(
                              group.GroupId,
                              group.CommunityType,
                              group.UserGroupStatus,
                              group.GroupName,
                              group.LogoUrl,
                              group.CommunityOwner,
                              group.UserEmail,
                              group.CommunityOwnerId
                            )
                          }
                        >
                          <img
                            className={classes.leftIcon}
                            alt="member"
                            src={
                              group.UserGroupStatus === "Member"
                                ? MemberIcon
                                : group.UserGroupStatus === "Owner"
                                ? OwnerIcon
                                : group.UserGroupStatus === "Requested"
                                ? RequestedIcon
                                : group.UserGroupStatus === "Accept"
                                ? AcceptedIcon
                                : group.UserGroupStatus === "Denied"
                                ? DeniedIcon
                                : JoinIcon
                            }
                          />
                          {group.UserGroupStatus === ""
                            ? "Join"
                            : group.UserGroupStatus}
                        </Button>
                        <Chip
                          label={CommonFunc.Capitalize(group.CommunityType)}
                          className={classes.chipCard}
                          style={{ float: "right" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        onClick={() =>
                          this.redirectToCommunityHome(group.GroupId)
                        }
                        className={classes.cardCenter}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography className={classes.subHeaderBold}>
                          {group.GroupName +
                            ", " +
                            group.MembersCount +
                            " Members"}
                        </Typography>
                        <Typography className={classes.likesComments}>
                          {group.GroupDesc}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(Communities))
);
