import React, { useState } from 'react';
import { DialogContent, IconButton, Grid, Dialog, MenuItem, Select, InputLabel, FormControl, 
    DialogActions, Button, useMediaQuery, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import colorJsonData from '../json/CommunityColorTheme.json';

const useStyles = makeStyles(theme => ({
    customButtonFocus: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#c2272d",
        "&:hover": {
            backgroundColor: "#c2272d"
        },
        "&:disabled": {
            backgroundColor: "#c2272d",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
}));

export default function ApplyColor(props) {    
    const [colorThemeName, setColorThemeName] = useState('Default Color');
    const [color_name_err, setColorNameErr] = useState('');
    const matches = useMediaQuery('(min-width:600px)');
    const colorThemes = colorJsonData.map((color) =>
        <MenuItem value={color.name}>{color.name}</MenuItem>
    );
    const classes = useStyles();

    const handleChange = (event) => {
        setColorThemeName(event.target.value);
        setColorNameErr(event.target.value.length <= 0 ? 'Please select color theme' : '');
    }

    const handleApplyClose = () => {
        setColorThemeName('');
        setColorNameErr('');
        props.handleApplyClose();
    }

    const saveChanges = () => {
        if(validateAllInputs() && !color_name_err) {
            let colorData = {};
            colorJsonData.map((color) => {
                if(color.name === colorThemeName) {
                    colorData.title = color.title;
                    colorData.subtitle = color.subtitle;
                    colorData.desc = color.desc;
                    colorData.name = color.name;
                }
            })
            props.onApplyColorTheme(colorData);
        } else {
            if (!colorThemeName) {
                setColorNameErr('Please select color theme');
            }
        }
    }

    const validateAllInputs = () => {
        if(!colorThemeName) {
            return false; 
        }
        else{ return true; }
    }

    return (
        <React.Fragment>
            <Dialog fullWidth open={props.showApplyColor}
                onClose={handleApplyClose} disableBackdropClick
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={ matches ? 11 : 9 }></Grid>
                        <Grid item xs={ matches ? 1 : 3 }>
                            <IconButton style={{ float: "right", marginTop: '-20px' }} 
                                onClick={handleApplyClose} 
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <FormControl fullWidth variant="outlined" required>
                        <InputLabel id="demo-simple-select-label">Select Color Theme</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={colorThemeName}
                            label="Select Color Theme"
                            onChange={handleChange}                                
                            style={{ backgroundColor: 'white' }}
                            name="colorThemeName">
                            {colorThemes}
                        </Select>
                    </FormControl>
                    { color_name_err.length > 0 && <span className='error'>{color_name_err}</span> }
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#f6f7f2', padding: '8px 24px 16px 24px' }}>
                    <Button onClick={saveChanges} color="primary" className={classes.customButtonFocus} size="small">
                        Apply Color
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
