import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, HashRouter, BrowserRouter } from 'react-router-dom';
import App from './App';
import { Route } from 'react-router';
import * as serviceWorker from './serviceWorker';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";
import { createBrowserHistory } from 'history';
import { createStore } from 'redux';
import rootReducers from './redux-pages/reducers';
import { Provider } from 'react-redux';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const store = createStore(rootReducers);

export const history = createBrowserHistory();

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Provider store={store}>
                <Route component={App} />
            </Provider>
        </Switch>
    </HashRouter>,
rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
