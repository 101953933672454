import React from "react";
import {
  DialogContent,
  IconButton,
  Grid,
  Dialog,
  makeStyles,
  useMediaQuery,
  Typography,
  Button,
  DialogActions
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    subHeader: {
        fontSize: 15,
        fontFamily: 'raleway',
        fontWeight: 500,
        color: '#000000'
    },
    title: {
        fontSize: 20,
        fontFamily: 'Poppins',
        marginBottom: 10,
        fontWeight: 700,
        color: '#000000'
    },
}));

export default function ConfirmationDialog(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.handleClose}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ backgroundColor: "#f6f7f2" }}>
          <Grid container spacing={0}>
            <Grid item xs={matches ? 11 : 10}>
              <Typography
                className={classes.title}
                style={{ marginBottom: 30 }}
              >
                {props.titleText}
              </Typography>
              <Typography
                className={classes.subHeader}
                style={{ marginBottom: 10 }}
              >
                {props.contentText1}
              </Typography>
              <Typography className={classes.subHeader}>
                Are you sure you want to{" "}
                <span style={{ color: "#c2272d" }}>permanently {props.contentActionText}</span>{" "}
                {props.contentText2}
              </Typography>
            </Grid>
            <Grid item xs={matches ? 1 : 2}>
              <IconButton
                style={{ float: "right", marginTop: "-20px" }}
                onClick={props.handleClose}
                aria-label="settings"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#f6f7f2" }}>
          <Button
            onClick={props.handleClose}
            color="secondary"
            style={{ color: "#c2272d" }}
          >
            Cancel
          </Button>
          <Button onClick={props.handleDelete} color="primary">
            {props.actionText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
