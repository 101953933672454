import React, { Component, Fragment } from 'react';
import { 
    Grid, withStyles, Card, CardContent, Typography, IconButton, useMediaQuery
} from '@material-ui/core';
import { useStyles } from '../../components/common/useStyles';
import '../../components/common/Common.css'
import { withRouter } from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import routeService from '../../services/Routing';
import constant from '../../components/common/Constants';
import DefaultCoverPic from "../../components/images/CoverPic.jpg";

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class CommunityTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if(!this.props.userEmail) {
            routeService.routePage(this.props, constant.home);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    previewTemplate = () => {
        routeService.routePage(this.props, constant.previewTemplate);
    }

    render() {
        const { classes, mediaQuery } = this.props;
        const col2 = mediaQuery ? 2 : 12;
        const col1 = mediaQuery ? 1 : 3;

        return (
            <Fragment>
                <Typography className={classes.title} style={{marginTop: mediaQuery ? '55px' : '90px'}}>
                    Select the Community Template
                </Typography>

                <Grid container spacing={2} style={{marginTop: '10px'}}>
                    <Grid item xs={12}>
                        <Card onClick={this.previewTemplate} style={{cursor: 'pointer'}}>
                            <CardContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={col2}>
                                        <img src={DefaultCoverPic} alt="img" style={{ width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={9} className={classes.cardCenter}>
                                        <Typography className={classes.subHeaderBold}>
                                            Default Template
                                        </Typography>
                                        <Typography className={classes.likesComments}>
                                            Visualize and advance your project forward using issues on a powerful board
                                        </Typography>                                        
                                    </Grid>
                                    <Grid item xs={col1} className={classes.cardCenter}>
                                        <IconButton aria-label="Continue">
                                            <ArrowForward className={classes.iconXLargeSize} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>                    
                </Grid>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(CommunityTemplate)))