export default {
    redirectToLogin(props, inputs) {
        let pathName = props.location.pathname.replace('/is_login_url=true','');
        const { history } = props;

        if (history) history.push({
            pathname: pathName.slice(-1) === '/' ? pathName + 'is_login_url=true' : pathName + '/is_login_url=true',
            inputs: inputs
        });
    }
}