import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Divider,
  useMediaQuery,
  Backdrop,
  Dialog,
  Avatar,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { useStyles } from "./common/useStyles";
import "./common/Common.css";
import classNames from "classnames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import QuestionIcon from "./images/Question.png";
import MessageIcon from "./images/mesage.png";
import LikeIcon from "./images/like.png";
import PollIcon from "./images/poll.png";
import api from "./common/APIValues";
import VoteIcon from "./images/votes_icon.png";
import { withRouter } from "react-router-dom";
import EDWhatLogoIcon from "./images/EdWhat-01-01.png";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import CommonFunc from "./common/CommonFunc";
import routeService from "../services/Routing";
import constant from "../components/common/Constants";
import { get } from "../services/ApiService";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class MyThreads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTopics: [],
      loader: false,
      open: false,
      topicId: null,
      topicType: null,
      noTopics: false,
      websiteId: 0,
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getDefaultCommunityDetails();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName ||
      this.props.searchValue !== prevProps.searchValue
    ) {
      this.loadMyProfile(
        this.props.userEmail,
        null,
        true,
        this.state.websiteId
      );
    }
  };

  getDefaultCommunityDetails = () => {
    const originName = window.location.origin.replace("www.", "");
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then((response) => {
      fetch(response.S3ConfigUrl)
        .then((r) => r.json())
        .then((jsonData) => {
          const websiteId = jsonData.websiteId;
          this.setState({ websiteId: websiteId });
          this.loadMyProfile(
            this.props.userEmail,
            undefined,
            undefined,
            websiteId
          );
        });
    });
  };

  loadMyProfile(userId, deleteObj, isUpdate, websiteId) {
    const { hasCache, getCache } = this.props.cacheActions;
    let searchValue = this.props.searchValue ? this.props.searchValue : "%%";
    const originName = window.location.origin.replace("www.", "");
    let action = sessionStorage.getItem("add_question_page_1");
    let url =
      api.partialURL +
      "MyThreads/GetMyThreads?USER_ID=" +
      userId +
      "&ENV=" +
      api.env +
      "&SRCH_VAL=%" +
      searchValue +
      "%" +
      "&originName=" +
      originName;
    let params = {
      USER_ID: userId,
      SRCH_VAL: searchValue,
    };

    if (
      action === "created_que_poll_mythreads" ||
      (action === "delete_my_account" && !isUpdate)
    ) {
      sessionStorage.setItem("add_question_page_1", "");
      this.fetchMyThreads(url, params, websiteId);
    } else {
      if (hasCache(url, params)) {
        let cachedData = getCache(url, params).data;
        if (deleteObj) {
          cachedData.map((item, index) => {
            if (
              item.TOPIC_POST_TYPE === deleteObj.topicPostType &&
              item.TOPIC_ID === deleteObj.topicId
            ) {
              cachedData.splice(index, 1);
            }
          });
        }
        this.setMyThreads(url, params, cachedData);
      } else {
        this.fetchMyThreads(url, params, websiteId);
      }
    }
  }

  setMyThreads = (url, params, topics) => {
    this.setState({
      userTopics: topics,
      loader: false,
      noTopics: topics.length === 0 ? true : false,
    });
    this.props.cacheActions.setCache(url, params, topics);
  };

  fetchMyThreads = (url, params, websiteId) => {
    this.setState({ loader: true });

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({ userTopics: [] });
        let topicDetails = result;

        topicDetails.map((item1, index1) => {
          //filter media link
          let desc = topicDetails[index1].TOPIC_DESC;
          let mediaLink = desc.slice(
            desc.indexOf('<oembed url="') + 13,
            desc.lastIndexOf('"></oembed>')
          );
          if (
            mediaLink.indexOf("https://youtube.com/") > -1 ||
            mediaLink.indexOf("https://youtu.be/") ||
            mediaLink.indexOf("https://www.youtube.com/") > -1 ||
            mediaLink.indexOf("https://www.youtu.be/")
          ) {
            let videoId = CommonFunc.getYoutubeId(mediaLink);
            let iframeMarkup =
              '<iframe frameborder="0" style="width:560px;height:315px;" src="https://www.youtube.com/embed/' +
              videoId +
              '" allowfullscreen></iframe>';
            let oembedMarkup =
              '<figure class="media"><oembed url="' +
              mediaLink +
              '"></oembed></figure>';

            topicDetails[index1].TOPIC_DESC = desc.replace(
              oembedMarkup,
              iframeMarkup
            );
          }
        });

        this.setMyThreads(url, params, topicDetails);
      })
      .catch((err) => console.log(err));
  };

  viewTopicDetails = (topicId, topicPostType, topicTitle, event) => {
    let elementId = event.target.id;
    let tagName = event.target.tagName;
    if (
      elementId === "btnDeleteThread" ||
      elementId === "iconDeleteThread" ||
      tagName === "path"
    ) {
      this.showConfirmPopup(topicId, topicPostType);
    } else {
      let pathName =
        topicPostType === "QUESTION"
          ? constant.queDetails +
            topicId +
            "/" +
            topicTitle.toLowerCase().replace(/ /g, "-")
          : constant.pollDetails +
            topicId +
            "/" +
            topicTitle.toLowerCase().replace(/ /g, "-");
      routeService.routePage(this.props, pathName);
    }
  };

  logout = () => {
    routeService.routePage(this.props, constant.home);
  };

  showConfirmPopup = (topicId, topicPostType) => {
    this.setState({
      topicId: topicId,
      topicType: topicPostType,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      topicId: null,
      topicType: null,
      open: false,
    });
  };

  deleteThread = () => {
    let rowData = {
      TOPIC_ID: this.state.topicId,
      TOPIC_POST_TYPE: this.state.topicType,
      ENV: api.env,
    };

    axios({
      method: "post",
      url: api.partialURL + "MyThreads/DeleteMyThread",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data === "success") {
          } else {
            this.setState({ err_msg: "Failed to delete my thread" });
          }
          this.setState({ loader: false });
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });

    let deleteObj = {
      topicId: this.state.topicId,
      topicPostType: this.state.topicType,
    };
    this.loadMyProfile(
      this.props.userEmail,
      deleteObj,
      undefined,
      this.state.websiteId
    );
    this.handleClose();
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const subHeaderX = classNames(classes.posTopX, classes.subHeader);

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <Dialog
          fullWidth
          open={this.state.open}
          onClose={this.handleClose}
          disableBackdropClick
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ backgroundColor: "#f6f7f2" }}>
            <Grid container spacing={0}>
              <Grid item xs={mediaQuery ? 11 : 10}>
                <Typography
                  className={classes.title}
                  style={{ marginBottom: 30 }}
                >
                  Delete this post?
                </Typography>
                <Typography
                  className={classes.subHeader}
                  style={{ marginBottom: 10 }}
                >
                  This action will delete the post, including comments. We
                  cannot recover this post later.
                </Typography>
                <Typography className={classes.subHeader}>
                  Are you sure you want to{" "}
                  <span style={{ color: "#c2272d" }}>permanently delete</span>{" "}
                  this post?
                </Typography>
              </Grid>
              <Grid item xs={mediaQuery ? 1 : 2}>
                <IconButton
                  style={{ marginLeft: "22px", marginTop: "-20px" }}
                  onClick={this.handleClose}
                  aria-label="settings"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#f6f7f2" }}>
            <Button
              onClick={this.handleClose}
              color="secondary"
              style={{ color: "#c2272d" }}
            >
              Cancel
            </Button>
            <Button onClick={this.deleteThread} color="primary">
              Yes, delete it
            </Button>
          </DialogActions>
        </Dialog>

        <Grid
          container
          spacing={2}
          style={{ marginTop: mediaQuery ? "100px" : "130px" }}
        >
          {this.state.userTopics.map((topic, row) => (
            <Grid item xs={12} key={topic.TOPIC_ID}>
              <Card>
                <CardHeader
                  style={{ cursor: "pointer", padding: "16px 16px 0px 16px" }}
                  classes={{
                    title: classes.title,
                    subheader: classes.subTitle,
                  }}
                  onClick={(e) =>
                    this.viewTopicDetails(
                      topic.TOPIC_ID,
                      topic.TOPIC_POST_TYPE,
                      topic.TOPIC_TITLE,
                      e
                    )
                  }
                  title={topic.TOPIC_TITLE}
                  avatar={
                    <IconButton
                      aria-label="questions"
                      style={{ marginTop: -32, padding: 0 }}
                    >
                      {topic.TOPIC_POST_TYPE === "QUESTION" ? (
                        <img
                          src={QuestionIcon}
                          height={20}
                          alt="QuestionIcon"
                        />
                      ) : (
                        <img src={PollIcon} height={20} alt="PollIcon" />
                      )}
                    </IconButton>
                  }
                  action={
                    topic.PERMISSION_LEVEL === "Remove Topic" && (
                      <IconButton id="btnDeleteThread" aria-label="delete">
                        <DeleteIcon
                          id="iconDeleteThread"
                          className={classes.iconMedSize}
                        />
                      </IconButton>
                    )
                  }
                  subheader={
                    "posted by " +
                    topic.TOPIC_UPDT_USER_NM +
                    " " +
                    topic.TOPIC_UPDT_DTM +
                    " ago"
                  }
                />
                <CardContent style={{ marginLeft: 37 }}>
                  <div>
                    <Typography
                      className={
                        topic.TOPIC_DESC.substring(0, 23) ===
                        '<iframe frameborder="0"'
                          ? subHeaderX
                          : subHeader
                      }
                      gutterBottom
                      onClick={(e) =>
                        this.viewTopicDetails(
                          topic.TOPIC_ID,
                          topic.TOPIC_POST_TYPE,
                          topic.TOPIC_TITLE,
                          e
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        {mediaQuery ? (
                          <span>
                            {ReactHtmlParser(
                              topic.TOPIC_DESC.substring(0, 360)
                            )}
                            {topic.TOPIC_DESC.length > 360 ? " ..." : ""}
                          </span>
                        ) : (
                          <span>
                            {topic.TOPIC_DESC.indexOf(
                              "https://www.youtube.com/embed/"
                            ) > -1 ? (
                              <span>
                                {ReactHtmlParser(
                                  topic.TOPIC_DESC.replace(
                                    'style="width:560px;height:315px;"',
                                    ""
                                  ).substring(0, 360)
                                )}
                                {topic.TOPIC_DESC.length > 360 ? " ..." : ""}
                              </span>
                            ) : (
                              <span>
                                {ReactHtmlParser(
                                  topic.TOPIC_DESC.substring(0, 100)
                                )}
                                {topic.TOPIC_DESC.length > 100 ? " ..." : ""}
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    </Typography>
                    {topic.TOPIC_POST_TYPE === "QUESTION" &&
                      topic.TOPIC_IMG_URL && (
                        <Typography
                          onClick={(e) =>
                            this.viewTopicDetails(
                              topic.TOPIC_ID,
                              topic.TOPIC_POST_TYPE,
                              topic.TOPIC_TITLE,
                              e
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={topic.TOPIC_IMG_URL}
                            style={{
                              width: "100%",
                              padding: mediaQuery
                                ? "10px 150px 10px 150px"
                                : "10px 10px 10px 10px",
                            }}
                          />
                        </Typography>
                      )}
                    <Typography
                      className={classes.hashTag}
                      gutterBottom
                      style={{
                        marginTop:
                          topic.TOPIC_POST_TYPE === "QUESTION" &&
                          topic.TOPIC_IMG_URL
                            ? "0px"
                            : "10px",
                      }}
                    >
                      {topic.TOPIC_HASHTAG &&
                        topic.TOPIC_HASHTAG.map((item, index) => (
                          <span
                            style={{
                              marginLeft: index === 0 ? 0 : 10,
                              color: "#3F51B5",
                            }}
                          >
                            {item}
                          </span>
                        ))}
                    </Typography>
                  </div>
                  <Typography
                    style={{ marginTop: "10px" }}
                    className={wrapIcon}
                    color="textSecondary"
                  >
                    {/* <IconButton style={{ padding: 0, fontSize: 13, fontFamily: 'poppins', marginLeft:'0px', cursor: 'none' }}
                                                        aria-label="share" > */}
                    <img
                      src={
                        topic.TOPIC_POST_TYPE === "QUESTION"
                          ? LikeIcon
                          : VoteIcon
                      }
                      height={15}
                      style={{ marginRight: "3px" }}
                    />
                    {topic.TOPIC_LIKE_VOTE}{" "}
                    {topic.TOPIC_POST_TYPE === "QUESTION"
                      ? mediaQuery
                        ? "Likes"
                        : ""
                      : topic.TOPIC_LIKE_VOTE > 1
                      ? "Votes"
                      : "Vote"}
                    {/* </IconButton> */}
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.marginLeftRight}
                    />
                    {
                      topic.TOPIC_POST_TYPE === "POLL" && (
                        // <IconButton style={{ padding: 0, fontSize: 13, fontFamily: 'poppins', marginLeft:'0px' }}
                        //     aria-label="share" >
                        <Fragment>
                          <img
                            src={LikeIcon}
                            height={15}
                            style={{ marginRight: "3px" }}
                          />
                          {topic.TOPIC_POLL_LIKE} {mediaQuery ? " Likes" : ""}
                        </Fragment>
                      )
                      // </IconButton>
                    }
                    {topic.TOPIC_POST_TYPE === "POLL" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.marginLeftRight}
                      />
                    )}
                    <img
                      src={MessageIcon}
                      height={15}
                      style={{ marginRight: "3px" }}
                      alt="MessageIcon"
                    />
                    {topic.TOPIC_CMNT}&nbsp;{mediaQuery ? "Comments" : ""}
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.marginLeftRight}
                    />
                    <VisibilityIcon
                      className={classes.iconSize}
                      style={{ marginRight: "3px" }}
                    />
                    <span style={{ fontSize: 13, fontFamily: "poppins" }}>
                      {topic.TOPIC_VIEW}
                    </span>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {this.state.userTopics &&
          this.state.userTopics.length === 0 &&
          this.state.noTopics && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className={classes.noTopics}>No topics created</div>
              </Grid>
            </Grid>
          )}
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(MyThreads))
);
