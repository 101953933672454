/* eslint-disable default-case */
import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Table,
  TableHead,
  Typography,
  FormControl,
  Select,
  useMediaQuery,
  Backdrop,
  MenuItem,
  InputBase,
  InputAdornment,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Divider,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import api from "../../components/common/APIValues";
import { withRouter } from "react-router-dom";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import axios from "axios";
import PersonIcon from "@material-ui/icons/Person";
import SuccessInfo from "../modal/SuccessInfo";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";
import RemoveIcon from "@material-ui/icons/Delete";
import { remove } from "../../services/ApiService";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import imageSizes from "../json/ImageSize.json";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const MuiAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon style={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .AccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(45deg)",
  },
  "& .AccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      email: "",
      community: {},
      openInfo: false,
      textMsg: "",
      rowData: [],
      userRoles: [],
      userSubscriptions: [],
      err_msg: "",
      isEdit: false,
      expanded: null,
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }

    let groupId = this.props.match.params.groupId;
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadDropdownValues(groupId);
  }

  loadDropdownValues = (groupId) => {
    const { hasCache, getCache } = this.props.cacheActions;
    const originName = window.location.origin.replace('www.', '');
    this.setState({ loader: true });
    let url = api.partialURL + "ManageUsers/GetRolesAndSubscriptions?ENV=" + api.env +
      "&GroupId=" + groupId +
      "&UserId=" + this.props.userEmail +
      '&originName=' + originName;
    let params = {
      GroupId: groupId,
    };

    if (hasCache(url, params)) {
      this.setDropdownValues(url, params, getCache(url, params).data);
    } else {
      this.fetchDropdownValues(url, params);
    }
  };

  fetchDropdownValues = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setDropdownValues(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  setDropdownValues = (url, params, result) => {
    let groupId = this.props.match.params.groupId;
    this.loadUsers(groupId, this.state.email);

    let rolesObject = {};
    result.roles.map((role) => {
      rolesObject[role.RoleId] = role.RoleName;
    });

    this.setState({
      userSubscriptions: result.subscriptions,
      community: result.community,
      loader: false,
      userRoles: rolesObject,
    });
    if (result.community.UserGroupStatus !== "Owner") {
      routeService.routePage(this.props, constant.home);
    }
    this.props.cacheActions.setCache(url, params, result);
  };

  loadUsers = (groupId, email) => {
    const originName = window.location.origin.replace('www.', '');
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    email = email ? email : "";
    let url = api.partialURL + "ManageUsers/GetUsers?GroupId=" + groupId +
      "&Email=" + email +
      "&ENV=" + api.env +
      '&originName=' + originName;
    let params = {
      GroupId: groupId,
      Email: email,
    };

    if (hasCache(url, params)) {
      this.setUsers(url, params, getCache(url, params).data);
    } else {
      this.fetchUsers(url, params);
    }

    fetch(
      api.partialURL + "ManageUsers/GetUsers?GroupId=" + groupId +
        "&Email=" + email +
        "&ENV=" + api.env +
        '&originName=' + originName
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          rowData: result,
          loader: false,
        });
      })
      .catch((err) => console.log(err));
  };

  fetchUsers = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setUsers(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  setUsers = (url, params, rowData) => {
    this.setState({
      rowData: rowData,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, rowData);
  };

  clearSearchInput = () => {
    this.setState({ email: "" });
    let groupId = this.props.match.params.groupId;
    this.loadUsers(groupId, "");
  };

  handleClose = () => {
    this.setState({ openInfo: false });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let groupId = this.props.match.params.groupId;
    this.setState({ [name]: value });
    this.loadUsers(groupId, value);
  };

  inviteUsers = () => {
    let pathName = constant.inviteUsers + this.props.match.params.groupId;
    let inputData = {
      groupName: this.state.community.GroupName,
      logoImgUrl: this.state.community.LogoImgUrl,
    };
    routeService.routePage(this.props, pathName, inputData);
  };

  saveChanges = () => {
    let rowData = this.state.rowData.filter((x) => x.IsEdit == true);
    if (rowData.length > 0) {
      rowData.map((data) => {
        let roleIds = [];
        data.RoleId.map((role) => {
          roleIds.push(Number(role));
        });
        data.RoleId = roleIds;
      });

      let finalData = {
        UserGroupRole: rowData,
        GroupId: Number(this.props.match.params.groupId),
        CreatedUserId: this.props.userEmail,
        Env: api.env,
      };
      this.setState({ loader: true });
      axios({
        method: "post",
        url: api.partialURL + "ManageUsers/SaveUsers",
        data: JSON.stringify(finalData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            if (res.data === "success") {
              let groupId = this.props.match.params.groupId;
              this.loadUsers(groupId, this.state.email);

              this.setState({
                openInfo: true,
                textMsg: "Updated changes are saved successfully !",
              });
            } else {
              this.setState({ err_msg: "Failed to save updated records." });
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });
    } else {
      this.setState({ err_msg: "No changes found to update record(s)." });
    }
  };

  removeUser = (userId) => {
    let groupId = this.props.match.params.groupId;
    let input = Number(groupId) + "/" + userId + "/" + api.env;
    remove("ManageUsers/DeleteUsers", input)
      .then((response) => {
        if (response.StatusCode === 200) {
          this.loadUsers(groupId, this.state.email);
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  onChange = (e, UserId, index) => {
    const { name, value } = e.target;
    let rowData = this.state.rowData;
    let isEdit = false;

    this.state.rowData.map((data) => {
      if (UserId === data.UserId) {
        switch (name) {
          case "UserRole":
            let userRoles =
              typeof value === "string" ? value.split(",") : value;

            let lastElement = userRoles.pop();
            let isDuplicate = false;
            userRoles.map((item, index) => {
              if (Number(lastElement) === Number(item)) {
                userRoles.splice(index, 1);
                isDuplicate = true;
              }
            });
            if (!isDuplicate) {
              userRoles.push(lastElement);
            }

            rowData[index].RoleId = userRoles;
            break;
          case "Subscription":
            rowData[index].SubscriptionId = value;
            break;
        }
        rowData[index].IsEdit = true;
        isEdit = true;
      }
    });
    this.setState({ rowData, isEdit });
  };

  handleAccordionChange = (panel) => (event, newExpanded) => {
    console.log("handleAccordionChange", event, newExpanded);
    this.setState({ expanded: newExpanded ? panel : false });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const col3 = mediaQuery ? 3 : 12;
    const col7 = mediaQuery ? 7 : 12;
    const col2 = mediaQuery ? 2 : 12;
    const searchInput = classNames(
      classes.inputInput,
      !mediaQuery ? classes.inputSize : null
    );
    const subTitleWithIcon = classNames(
      classes.subTitle,
      classes.alignTextWithIcon
    );

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <SuccessInfo
          open={this.state.openInfo}
          textMsg={this.state.textMsg}
          handleClose={this.handleClose}
          infoType='success'
        ></SuccessInfo>

        <Typography
          className={classes.title}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          Manage Users
        </Typography>

        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid item xs={col3}>
            <Typography className={subTitleWithIcon}>
              <img
                src={this.state.community.LogoUrl}
                className={classes.leftIcon}
                style={{
                  width: imageSizes.logo_icon.width,
                  height: imageSizes.logo_icon.height,
                }}
                alt="logoicon"
              />
              <Typography>{this.state.community.GroupName}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={col7}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={this.handleChange}
                fullWidth
                name="email"
                placeholder="Search with email"
                value={this.state.email}
                style={{
                  position: "initial",
                  width: !mediaQuery ? "102%" : null,
                }}
                classes={{
                  root: classes.inputRoot,
                  input: searchInput,
                }}
                inputProps={{
                  "aria-label": "search",
                  style: {
                    fontSize: 14,
                    fontFamily: "poppins",
                    height: 18,
                    backgroundColor: "white",
                  },
                }}
                endAdornment={
                  <InputAdornment position="start">
                    {this.state.email && (
                      <CloseIcon
                        style={{
                          color: "#A19B9B",
                          cursor: "pointer",
                          marginLeft: 1,
                        }}
                        onClick={this.clearSearchInput}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </div>
          </Grid>
          <Grid item xs={col2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.customButtonFocus}
              onClick={this.inviteUsers}
              size="small"
              fullWidth
            >
              Invite Users
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ padding: !mediaQuery ? 8 : 0 }}>
          {mediaQuery ? (
            <Grid item xs={12}>
              <Table aria-label="caption table" size="small">
                <TableHead style={{ backgroundColor: "#c2272d" }}>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell} align="center">
                      User Name
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Current Status
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Current User Role
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Subscription Type
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rowData.map((row, index) => (
                    <TableRow key={row.UserId}>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="center"
                      >
                        {row.UserName}
                        <div className={classes.caption}>{row.UserEmail}</div>
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                        align="center"
                      >
                        {row.Status}
                      </TableCell>
                      {row.Status === "Invited" ? (
                        <TableCell
                          className={classes.tableBodyCell}
                          align="center"
                        >
                          NA
                        </TableCell>
                      ) : (
                        <TableCell
                          className={classes.tableBodyCell}
                          align="center"
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={row.RoleId}
                              onChange={(e) =>
                                this.onChange(e, row.UserId, index)
                              }
                              name="UserRole"
                              disableUnderline
                              renderValue={(selected) => (
                                <fragment>
                                  {selected.length === 1
                                    ? this.state.userRoles[selected[0]]
                                    : "Multiple Roles (" +
                                      selected.length +
                                      ")"}
                                </fragment>
                              )}
                            >
                              {Object.keys(this.state.userRoles).map(
                                (roleId) => (
                                  <MenuItem key={roleId} value={roleId}>
                                    <Checkbox
                                      checked={
                                        (row.RoleId.toString() + ",").indexOf(
                                          roleId.toString() + ","
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={this.state.userRoles[roleId]}
                                    />
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </TableCell>
                      )}
                      {row.Status === "Invited" ? (
                        <TableCell
                          className={classes.tableBodyCell}
                          align="center"
                        >
                          NA
                        </TableCell>
                      ) : (
                        <TableCell
                          className={classes.tableBodyCell}
                          align="center"
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={row.SubscriptionId}
                              onChange={(e) =>
                                this.onChange(e, row.UserId, index)
                              }
                              name="Subscription"
                              disableUnderline
                            >
                              {this.state.userSubscriptions.map((item) => (
                                <MenuItem value={item.SubscriptionId}>
                                  {item.SubscriptionType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {row.RoleName.indexOf("Community Admin") > -1 ? (
                          "NA"
                        ) : (
                          <RemoveIcon
                            onClick={() => this.removeUser(row.UserId)}
                            className={classes.deleteAction}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          ) : (
            this.state.rowData.map((row, index) => (
              <MuiAccordion
                expanded={this.state.expanded === row.UserId}
                onChange={this.handleAccordionChange(row.UserId)}
                style={{ width: "100%" }}
              >
                <MuiAccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Grid container spacing={0}>
                    <Grid item xs={11}>
                      <Typography className={classes.accordionSubText}>
                        {row.UserName}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      {row.RoleName.indexOf("Community Admin") === -1 && (
                        <RemoveIcon
                          onClick={() => this.removeUser(row.UserId)}
                          className={classes.deleteAction}
                        />
                      )}
                    </Grid>
                  </Grid>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                  <Typography>
                    <div>
                      <span className={classes.accordionSubTitle}>
                        User Name :{" "}
                      </span>
                      <span className={classes.accordionSubText}>
                        {row.UserName}
                      </span>
                    </div>
                    <Divider className={classes.dividerMargin} />
                    <div>
                      <span className={classes.accordionSubTitle}>
                        User Email :{" "}
                      </span>
                      <span className={classes.accordionSubText}>
                        {row.UserEmail}
                      </span>
                    </div>
                    <Divider className={classes.dividerMargin} />
                    <div>
                      <span className={classes.accordionSubTitle}>
                        Current Status :{" "}
                      </span>
                      <span className={classes.accordionSubText}>
                        {row.Status}
                      </span>
                    </div>
                    <Divider className={classes.dividerMargin} />
                    <div>
                      <span className={classes.accordionSubTitle}>
                        Current User Role :{" "}
                      </span>
                      {row.Status === "Invited" ? (
                        "NA"
                      ) : (
                        <FormControl style={{ marginTop: "-3px" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={row.RoleId}
                            onChange={(e) =>
                              this.onChange(e, row.UserId, index)
                            }
                            name="UserRole"
                            disableUnderline
                            renderValue={(selected) => (
                              <span className={classes.accordionSubText}>
                                {selected.length === 1
                                  ? this.state.userRoles[selected[0]]
                                  : "Multiple Roles (" + selected.length + ")"}
                              </span>
                            )}
                          >
                            {Object.keys(this.state.userRoles).map((roleId) => (
                              <MenuItem key={roleId} value={roleId}>
                                <Checkbox
                                  checked={
                                    (row.RoleId.toString() + ",").indexOf(
                                      roleId.toString() + ","
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={this.state.userRoles[roleId]}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                    <Divider className={classes.dividerMargin} />
                    <div>
                      <span className={classes.accordionSubTitle}>
                        Subscription Type :{" "}
                      </span>
                      {row.Status === "Invited" ? (
                        "NA"
                      ) : (
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={row.SubscriptionId}
                            onChange={(e) =>
                              this.onChange(e, row.UserId, index)
                            }
                            name="Subscription"
                            disableUnderline
                            className={classes.accordionSubText}
                          >
                            {this.state.userSubscriptions.map((item) => (
                              <MenuItem value={item.SubscriptionId}>
                                {item.SubscriptionType}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </Typography>
                </MuiAccordionDetails>
              </MuiAccordion>
            ))
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              className={this.state.isEdit ? classes.customButtonFocus : null}
              onClick={this.saveChanges}
              size="small"
              disabled={!this.state.isEdit}
            >
              Save Changes
            </Button>
            {this.state.err_msg.length > 0 && (
              <span className="error" style={{ marginLeft: 10 }}>
                {this.state.err_msg}
              </span>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(ManageUser))
);
