import React, { Component, Fragment } from 'react';
import {
    AppBar, withStyles, Toolbar, CssBaseline, Grid, useMediaQuery, InputBase, Typography, Tooltip, Avatar,
    Badge, IconButton, Breadcrumbs, Button, Link, MenuItem, Menu, ListItemText, Divider, InputAdornment,
    Dialog, DialogContent, DialogActions, Backdrop, FormControl, Select
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import './common/Common.css'
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AddQuestionIcon from './images/Add-a-Question.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';
import api from './common/APIValues';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import LoginIcon from '@material-ui/icons/LockOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import EDWhatLogoIcon from './images/EdWhat-01-01.png';
import FeedbackIcon from '@material-ui/icons/Feedback';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import routeService from '../services/Routing';
import constant from '../components/common/Constants';
import { searchByInputs, get } from '../services/ApiService';
import AllCommunityIcon from '@material-ui/icons/Forum';
import Notifications from './Notifications';
import MyCommunityIcon from '@material-ui/icons/LocalLibrary';
import Logoedwhat from './images/246_X_78-02.svg';

const drawerWidth = 240;
var CryptoJS = require("crypto-js");

const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
    },
    appBar1: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#f6f7f2',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        marginTop: 50,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    alignment: {
        flexGrow: 1,
    },
    logoAlignment: {
        flexGrow: 1,
        marginTop: 8
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconColor: {
        color: 'white'
    },
    textColor: {
        color: 'white'
    },
    btnText: {
        fontSize: 12, color: 'white'
    },
    search: {
        position: 'relative',
        marginLeft: '0px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(5),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#c2272d',
        borderRadius: '17px 0px 0px 17px',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(7)}px)`,
        transition: theme.transitions.create('width'),
        color: '#292929',
        border: '1px solid #d9d9d9',
        borderRadius: '15px',
        width: '100%',
        height: '25px',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        }
    },
    inputSize: {
        fontSize: '15px',
    },
    iconColor: {
        color: '#333233',
        fontSize: '2.4rem',
    },
    customButtonError: {
        borderRadius: '8px',
        color: '#c2272d',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    buttonSpacing: {
        margin: theme.spacing(1),
    },
    customButtonInfo: {
        borderRadius: '8px',
        color: '#0000008A',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    customButtonFocus: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#c2272d",
        "&:hover": {
            backgroundColor: "#c2272d"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    navMenuIcon: {
        marginTop: 12
    },
    profileTitle: {
        fontFamily: 'poppins',
        fontWeight: 600,
        fontSize: 17,
        color: '#000000'
    },
    profileSubTitle: {
        fontFamily: 'poppins',
        fontWeight: 400,
        fontSize: 11,
        color: '#000000'
    },
    iconMedSize: {
        fontSize: '1.2rem',
        color: '#c2272d',
    },
    customButtonRegular2: {
        borderRadius: '8px',
        color: 'black',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        textTransform: 'none'
    },
    subHeader: {
        fontSize: 15,
        fontFamily: 'raleway',
        fontWeight: 500,
        color: '#000000'
    },
    title: {
        fontSize: 20,
        fontFamily: 'Poppins',
        marginBottom: 30,
        fontWeight: 700,
        color: '#000000'
    },
});

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 6,
        top: 12,
        padding: '0 4px',
    },
}))(Badge);

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class NavMenu extends Component {
    static displayName = NavMenu.name;
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            currentStatus: '',
            openProducts: false,
            openVendors: false,
            openStocks: false,
            openCustomers: false,
            openOrders: false,
            openPayments: false,
            openConfigurations: false,
            openReports: false,
            inputBase: 'initial value',
            userNotifications: [],
            notificationCount: null,
            isSearchInput: false,
            openConfirmDialog: false,
            loader: false,
            email_address: '',
            isPosts: true,
            searchInputDisplay: true,
            breadcrumbText1: '',
            breadcrumbText2: '',
            breadcrumbText3: '',
            currentPathName: '',
            groupId: 0,
            openNotification: false,
            navigationLogoUrl: '',
            askGroupName: '',
            encryptKey: '',
        };
    }

    navigateToHome = () => {
        routeService.routePage(this.props, constant.home);
    }

    navigateToHomePage = (pageName) => {
        let pathName;
        switch (pageName) {
            case 'Home':
                pathName = constant.home;
                break;
            case 'Community':
                pathName = constant.communities;
                break;
            case 'Template':
                pathName = constant.templates;
                break;
            case 'Settings':
                let groupId = this.props.location.pathname.split('/').pop();
                pathName = constant.communityHome + groupId
                break;
            case 'Community Home':
                pathName = constant.communityHome + this.state.groupId;
                break;
            default:
                break;
        }
        routeService.routePage(this.props, pathName);
    }

    navigateToDetailPage = () => {
        routeService.routePage(this.props, this.props.prev_page);
    }

    notifications = () => {
        routeService.routePage(this.props, constant.notifications);
    }

    handleNotificationOpen = () => {
        this.setState({ openNotification: true });
    };

    handleNotificationClose = () => {
        this.setState({ openNotification: false });
    };

    showSearchInput = () => {
        this.setState({ isSearchInput: true });
    };

    closeSearchInput = () => {
        this.setState({ isSearchInput: false });
    }

    loadUserNotifications = () => {
        const originName = window.location.origin.replace('www.', '');
        fetch(api.partialURL + 'WebNotification/GetWebNotifications?USER_EMAIL=' + this.props.userEmail + 
                '&ENV=' + api.env +
                '&originName=' + originName
            )
            .then(res => res.json())
            .then(result => {
                this.setState({
                    userNotifications: result.dataList,
                    notificationCount: result.NOTIFICATION_CNT
                });
            })
            .catch(err => console.log(err));
    }

    componentDidMount = () => {
        let pathName = this.props.location.pathname;
        this.getDefaultCommunityDetails();
        this.setState({
            isPosts: pathName.indexOf('/communities') > -1 ? false : true,
            currentPathName: pathName
        });
        this.showAddQuestion(pathName);        
        this.loadUserNotifications();
        this.displayBreadcrumbForDetailPage(pathName);
    }

    getDefaultCommunityDetails = () => {
        this.setState({ loader: true });
        const originName = window.location.origin.replace('www.', '');
        const inputs = `env=${api.env}&originName=${originName}`;
        get("Home/GetDefaultCommunityDetails?" + inputs).then(
          (response) => {
            if(response) {
                this.setState({
                    navigationLogoUrl: response.LogoUrl ? response.LogoUrl.replace('{device_type}', 'desktop') : '',
                    askGroupName: response.GroupName,
                    loader: false,
                });

                fetch(response.S3ConfigUrl)
                    .then((r) => r.json())
                    .then((jsonData) => {
                        const encryptKey = jsonData.encryptKey;
                        this.setState({ encryptKey: encryptKey });
                        this.decodeEmailAddress(encryptKey);
                    });
            }            
          }
        );
    };

    displayBreadcrumbForDetailPage = (pathName) => {
        if (pathName.indexOf('/topic/') > -1 || pathName.indexOf('/poll/') > -1) {
            let pathParams = pathName.split('/');
            let inputs = pathParams[2] + '/' + pathParams[1] + '/' + api.env;
            searchByInputs('UserTopic/GetCommunityByTopic', inputs)
                .then(response => {
                    if (Number(response) > 0) {
                        this.setState({
                            groupId: Number(response),
                            breadcrumbText1: 'Community Home',
                            breadcrumbText2: pathParams[1] === 'topic' ? 'Question' : 'Poll'
                        });
                    } else {
                        this.setState({
                            breadcrumbText1: 'Home',
                            breadcrumbText2: pathParams[1] === 'topic' ? 'Question' : 'Poll'
                        });
                    }
                })
        }
    }

    showAddQuestion = (pathName) => {
        let breadcrumbText1, breadcrumbText2, breadcrumbText3;

        // show/hide Add A Question button
        if (pathName === '/community/templates' || pathName.indexOf('/community/requests/') > -1 ||
            pathName === '/community/types' || pathName.indexOf('/create/community/') > -1 ||
            pathName === '/community/template/preview' || pathName.indexOf('/community/home/') > -1 ||
            pathName.indexOf('/community/inviteusers/') > -1 || pathName.indexOf('/community/managepermissions/') > -1 ||
            pathName.indexOf('/community/manageusers/') > -1 || pathName.indexOf('/community/premium/') > -1) {
            this.setState({ addQuestion: false });
        } else {
            this.setState({ addQuestion: true });
        }

        // show/hide Search Input field
        if (pathName === '/topcommunities' || pathName.indexOf('/community/manageusers/') > -1) {
            this.setState({ searchInputDisplay: false });
        } else {
            this.setState({ searchInputDisplay: true });
        }

        // breadcrumb text
        switch (pathName) {
            case '/community/templates':
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Templates';
                break;
            case '/community/types':
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Types';
                break;
            case '/community/template/preview':
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Template';
                breadcrumbText3 = 'Preview';
                break;
            case '/topcommunities':
                breadcrumbText1 = 'Home';
                breadcrumbText2 = 'Top Communities';
                break;
            default:
                break;
        }

        switch (true) {
            case (pathName.indexOf('/create/question/ask') > -1):
                breadcrumbText1 = 'Home';
                breadcrumbText2 = 'Ask ' + this.state.askGroupName;
                break;
            case (pathName.indexOf('/create/community/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Create';
                break;
            case (pathName.indexOf('/community/home/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Home';
                break;
            case (pathName.indexOf('/community/about/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'About';
                break;
            case (pathName.indexOf('/community/inviteusers/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Invite Users';
                break;
            case (pathName.indexOf('/community/managepermissions/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Manage Permissions';
                break;
            case (pathName.indexOf('/community/manageusers/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Manage Users';
                break;
            case (pathName.indexOf('/community/requests/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Manage Requests';
                break;
            case (pathName.indexOf('/community/premium/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Premium';
                break;
            default:
                break;
        }

        this.setState({ breadcrumbText1, breadcrumbText2, breadcrumbText3 });
    }

    decodeEmailAddress = (encryptKey) => {
        const _email = localStorage.getItem('user_encoded_email');
        if (_email && _email !== '') {
            let bytes = CryptoJS.AES.decrypt(_email, encryptKey);
            let email_address = bytes.toString(CryptoJS.enc.Utf8);
            this.setState({ email_address: email_address });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.userEmail !== prevProps.userEmail) {
            this.decodeEmailAddress(this.state.encryptKey);
            this.loadUserNotifications();
        }

        if (this.props.searchValue !== prevProps.searchValue) {
            if (!this.props.searchValue) {
                this.setState({ isSearchInput: false });
            }
        }

        if (this.props.location.pathname !== prevState.currentPathName) {
            let pathName = this.props.location.pathname;
            this.showAddQuestion(pathName);
            this.setState({ currentPathName: pathName });
            this.displayBreadcrumbForDetailPage(pathName);
        }
    }

    updateReadNotification = (pathName, isRead, rowData) => {
        if (isRead === 0) {
            axios({
                method: 'post',
                url: api.partialURL + 'WebNotification/UpdateReadNotification',
                data: JSON.stringify(rowData),
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
            })
                .then(res => {
                    if (res) {
                        this.setState({ openNotification: false });
                        this.loadUserNotifications();
                        routeService.routePage(this.props, pathName);
                    }
                })
                .catch(function (response) {
                    this.setState({ err_msg: response });
                });
        } else {
            this.setState({ openNotification: false });
            routeService.routePage(this.props, pathName);
        }
    }

    redirectToApproveRequests = (groupId, notificationId, isRead) => {
        let pathName = constant.approveUsers + groupId;
        let rowData = {
            NOTIFICATION_ID: notificationId,
            ENV: api.env
        };
        sessionStorage.setItem('group_id', groupId);
        sessionStorage.setItem('is_community_admin', true);
        this.updateReadNotification(pathName, isRead, rowData);
    }

    viewTopicDetails = (topicId, topicTitle, topicPostType, notificationId, isRead) => {
        let pathName = topicPostType === 'question' ? constant.queDetails + topicId + '/' + topicTitle.toLowerCase().replace(/ /g, '-')
            : constant.pollDetails + topicId + '/' + topicTitle.toLowerCase().replace(/ /g, '-');
        let rowData = {
            NOTIFICATION_ID: notificationId,
            ENV: api.env
        };

        this.updateReadNotification(pathName, isRead, rowData);
    }

    handleClose = () => {
        this.setState({
            openConfirmDialog: false,
        })
    }

    showConfirmDialog = () => {
        this.setState({
            openConfirmDialog: true,
        })
    }

    deleteProfile = () => {
        this.handleClose();
        let rowData = {
            TOPIC_UPDT_USER_NM: this.props.userEmail,
            ENV: api.env
        }
        this.setState({ loader: true });

        axios({
            method: 'post',
            url: api.partialURL + 'MyProfile/DeleteMyProfile',
            data: JSON.stringify(rowData),
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
        })
            .then(res => {
                if (res) {
                    if (res.data === 'success') {
                        sessionStorage.setItem('add_question_page_1', 'delete_my_account');
                        sessionStorage.setItem('add_question_page_2', 'delete_my_account');
                        this.props.userLogout('/myprofile');
                    } else {
                        this.setState({ err_msg: 'Failed to delete my profile' });
                    }
                    this.setState({ loader: false });
                }
            })
            .catch(function (response) {
                this.setState({ err_msg: response, loader: false });
            });
    }

    redirectToPosts = () => {
        this.setState({ isPosts: true });
        routeService.routePage(this.props, constant.home);
    }

    redirectToCommunities = () => {
        this.setState({ isPosts: false });
        routeService.routePage(this.props, constant.communities);
    }

    render() {
        const { classes } = this.props;
        const pathName = this.props.location.pathname;
        const forPosts = classNames(this.state.isPosts ? classes.customButtonFocus : classes.customButtonError,
            classes.buttonSpacing);
        const buttonError = classNames(classes.customButtonError, classes.buttonSpacing);
        const buttonRegular = classNames(classes.customButtonRegular2, classes.buttonSpacing);
        const searchInput = classNames(classes.inputInput);
        const image_url = localStorage.getItem('image_url');
        const buttonLeftIcon = classNames(classes.iconMedSize, classes.leftIcon);

        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={this.state.loader}>
                    <img src={EDWhatLogoIcon} height={50} alt="Logo" />
                </Backdrop>

                <CssBaseline />

                <Dialog
                    fullWidth
                    open={this.state.openConfirmDialog}
                    onClose={this.handleClose}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={11}>
                                <Typography className={classes.title}>
                                    Delete for all eternity?
                                </Typography>
                                <Typography className={classes.subHeader} style={{ marginBottom: 10 }}>
                                    This action will delete all your user data, including your posts and comments. We cannot recover them later.
                                </Typography>
                                <Typography className={classes.subHeader}>
                                    Are you sure you want to <span style={{ color: '#c2272d' }}>permanently delete</span> your account?
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton style={{ marginLeft: '22px', marginTop: '-20px' }} onClick={this.handleClose}
                                    aria-label="settings">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#f6f7f2' }}>
                        <Button onClick={this.handleClose} color="secondary" style={{ color: '#c2272d' }}>
                            Cancel
                        </Button>
                        <Button onClick={this.deleteProfile} color="primary">
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>

                <Notifications
                    open={this.state.openNotification}
                    userNotifications={this.state.userNotifications}
                    handleClose={this.handleNotificationClose}
                    redirectToApproveRequests={this.redirectToApproveRequests}
                    viewTopicDetails={this.viewTopicDetails}>                    
                </Notifications>

                <AppBar position="fixed" className={classes.appBar} style={{ boxShadow: '0px 0px 0px 0px' }}>
                    <Toolbar className="header-color">
                        <Typography className={classes.alignment} style={{ marginLeft: 5 }}>
                            <img onClick={this.navigateToHome} style={{ cursor: 'pointer' }} height={50} alt="Logo"
                                src={this.state.navigationLogoUrl ? this.state.navigationLogoUrl : Logoedwhat} />
                        </Typography>
                        <Grid container spacing={0}>
                            <Grid item xs={11}>
                                {this.state.searchInputDisplay &&
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase onChange={this.props.searchTopics} fullWidth
                                            placeholder="search topics and communities" value={this.props.searchValue}
                                            style={{
                                                position: 'initial'
                                            }}
                                            classes={{
                                                root: classes.inputRoot,
                                                input: searchInput,
                                            }}
                                            inputProps={{
                                                'aria-label': 'search',
                                                'style': { fontSize: 18, fontFamily: 'poppins' }
                                            }}
                                            endAdornment={this.props.searchValue &&
                                                <InputAdornment position="start">
                                                    <CloseIcon style={{ color: '#A19B9B', cursor: 'pointer', marginLeft: 5 }}
                                                        onClick={this.props.clearSearchInput} />
                                                </InputAdornment>
                                            }
                                        />
                                    </div>}
                            </Grid>
                            <Fragment>
                                <Typography className={classes.alignment}>
                                    <StyledBadge badgeContent={this.state.notificationCount} color="error"
                                        onClick={this.handleNotificationOpen}>
                                        <NotificationsIcon className={classes.iconColor} style={{cursor: 'pointer'}} />
                                    </StyledBadge>
                                </Typography>

                                {/* <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.anchorElNotification}
                                    keepMounted
                                    open={Boolean(this.state.anchorElNotification)}
                                    onClose={this.handleNotificationClose}
                                >
                                    {this.state.userNotifications.length > 0 &&
                                        <MenuItem style={{ backgroundColor: '#c2272d' }}>
                                            <IconButton edge="start" color="inherit" onClick={this.handleNotificationClose}
                                                aria-label="close" className={classes.textColor}>
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography variant="h6" className={classes.textColor}>
                                                Notifications
                                            </Typography>
                                        </MenuItem>}
                                    {this.state.userNotifications.length > 0 ?
                                        this.state.userNotifications.map((notification, row) => (
                                            notification.EVNT_TYPE === 'Community' ?
                                                <MenuItem onClick={() => this.redirectToApproveRequests(notification.TOPIC_ID, notification.NOTIFICATION_ID, notification.IS_READ)}>
                                                    <ListItemText primary={notification.NOTIFICATION_MSG}
                                                        secondary={notification.CMNT_CREATE_DTM + ' ago'} />
                                                    {notification.IS_READ === 0 &&
                                                        <FiberManualRecordIcon style={{ fontSize: '15px', marginLeft: '20px', color: '#c2272d' }} />
                                                    }
                                                </MenuItem> :
                                                <MenuItem onClick={() => this.viewTopicDetails(notification.TOPIC_ID, notification.TOPIC_TITLE,
                                                    notification.TOPIC_TYPE, notification.NOTIFICATION_ID, notification.IS_READ)} >
                                                    <ListItemText primary={notification.NOTIFICATION_MSG}
                                                        secondary={notification.CMNT_CREATE_DTM + ' ago'} />
                                                    {notification.IS_READ === 0 &&
                                                        <FiberManualRecordIcon style={{ fontSize: '15px', marginLeft: '20px', color: '#c2272d' }} />
                                                    }
                                                </MenuItem>
                                        )) :
                                        <MenuItem>
                                            <span style={{ fontSize: '14px' }}>No Notifications</span>
                                        </MenuItem>
                                    }
                                </Menu> */}

                                <Tooltip title={this.props.userEmail && this.props.userAliasName ? this.props.userAliasName : ''}>
                                    <Avatar style={{ cursor: 'pointer' }}
                                        onClick={this.props.handleUserProfileOpen}
                                        src={image_url}
                                        alt={this.props.userEmail && this.props.userAliasName ? this.props.userAliasName.toUpperCase() : null} />
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.props.anchorElProfile}
                                    keepMounted
                                    open={Boolean(this.props.anchorElProfile)}
                                    onClose={this.props.handleUserProfileClose}
                                >
                                    {this.props.userEmail &&
                                        <Fragment>
                                            <MenuItem>
                                                <Avatar
                                                    src={image_url}
                                                    alt={this.props.userAliasName ? this.props.userAliasName.toUpperCase() : null} />
                                                &nbsp;&nbsp;<span style={{ fontSize: 13 }}>{this.state.email_address}</span>
                                            </MenuItem>
                                            <Divider />
                                        </Fragment>
                                    }
                                    {this.props.userAliasName && this.props.userEmail &&
                                        <MenuItem onClick={this.props.userLogin} >
                                            <PersonIcon style={{ cursor: 'pointer', color: '#a19b9b' }}
                                                src="/static/images/avatar/1.jpg" />
                                            &nbsp;&nbsp;<span style={{ fontSize: 13 }}>
                                                My Profile
                                            </span>
                                        </MenuItem>
                                    }
                                    {this.props.userAliasName && this.props.userEmail &&
                                        <MenuItem onClick={this.props.redirectToCommunity} >
                                            <GroupAddIcon style={{ cursor: 'pointer', color: '#a19b9b' }}
                                                src="/static/images/avatar/1.jpg" />
                                            &nbsp;&nbsp;<span style={{ fontSize: 13 }}>
                                                Create Community
                                            </span>
                                        </MenuItem>
                                    }
                                    {this.props.userAliasName && this.props.userEmail &&
                                        <MenuItem onClick={this.props.redirectToMyProfile} >
                                            <MyCommunityIcon style={{ cursor: 'pointer', color:'#a19b9b' }}
                                                src="/static/images/avatar/1.jpg" />
                                            &nbsp;&nbsp;<span style={{ fontSize: 13 }}>
                                                My Communities
                                            </span>
                                        </MenuItem>
                                    }                                    
                                    <MenuItem onClick={this.props.redirectToTopCommunities} >
                                        <AllCommunityIcon style={{ cursor: 'pointer', color:'#a19b9b' }}
                                            src="/static/images/avatar/1.jpg" />
                                        &nbsp;&nbsp;<span style={{ fontSize: 13 }}>
                                            All Communities
                                        </span>
                                    </MenuItem>                                    
                                    {this.props.userAliasName && this.props.userEmail &&
                                        <MenuItem onClick={this.props.redirectToFeedbackForm} >
                                            <FeedbackIcon style={{ cursor: 'pointer', color:'#a19b9b' }} />
                                            &nbsp;&nbsp;<span style={{ fontSize: 13 }}>
                                                Feedback
                                            </span>
                                        </MenuItem>
                                    }
                                    {this.props.userAliasName && this.props.userEmail &&
                                        <MenuItem onClick={() => this.props.userLogout(pathName)} >
                                            <PowerSettingsNewIcon style={{ cursor: 'pointer', color: '#a19b9b' }}
                                                src="/static/images/avatar/1.jpg" />
                                            &nbsp;&nbsp;<span style={{ fontSize: 13 }}>Logout</span>
                                        </MenuItem>
                                    }
                                    {(!this.props.userAliasName || !this.props.userEmail) &&
                                        <MenuItem onClick={this.props.userLogin} >
                                            <LoginIcon style={{ cursor: 'pointer', color: '#a19b9b' }}
                                                src="/static/images/avatar/1.jpg" />
                                            &nbsp;&nbsp;<span style={{ fontSize: 13 }}>Login</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </Fragment>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <AppBar position="fixed" className={classes.appBar}
                    style={{ boxShadow: '0px 0px 0px 0px', marginTop: '80px', borderTop: '1px solid #0000001F', zIndex: 1024 }}>
                    <Toolbar className="topic-header-color">
                        {(pathName === '/home' || pathName === '/' || pathName === '/home/' || pathName === '/communities' || pathName === '/communities/') ?
                            (
                                <Typography variant="h6" className={classes.alignment}>
                                    <Fragment>
                                        <span style={{ fontSize: 13, color: 'black', fontFamily: 'poppins' }}>Sort By :</span>
                                        <FormControl variant="outlined" size="small" style={{ marginLeft: 5 }}>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={this.props.sortBy}
                                                onChange={this.props.handleSortBy}
                                                style={{
                                                    fontSize: 13,
                                                    fontFamily: 'poppins',
                                                    height: 30,
                                                    marginTop: (this.props.searchValue || pathName === '/communities' || pathName === '/communities/') ? 9 : 4
                                                }}
                                                name="sortBy">
                                                <MenuItem value='1'>Popular</MenuItem>
                                                <MenuItem value='0'>New</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Fragment>
                                    {(this.props.searchValue || pathName === '/communities' || pathName === '/communities/') &&
                                        <Fragment>
                                            <Button variant="contained" color="primary" className={forPosts} size="small"
                                                onClick={this.redirectToPosts}>Posts
                                            </Button>
                                            <Button variant="contained" className={!this.state.isPosts ? classes.customButtonFocus : classes.customButtonInfo}
                                                size="small" onClick={this.redirectToCommunities}>Communities
                                            </Button>
                                        </Fragment>
                                    }
                                </Typography>
                            ) : (
                                <Typography className={classes.alignment} style={{ marginLeft: 10 }}>
                                    {this.state.breadcrumbText1 &&
                                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                            <Link onClick={(e) => this.navigateToHomePage(this.state.breadcrumbText1)} style={{ textDecoration: 'none', cursor: 'pointer' }} color="inherit">
                                                {this.state.breadcrumbText1}
                                            </Link>
                                            {this.state.breadcrumbText3 ?
                                                <Link onClick={(e) => this.navigateToHomePage(this.state.breadcrumbText2)} style={{ textDecoration: 'none', cursor: 'pointer' }} color="inherit">
                                                    {this.state.breadcrumbText2}
                                                </Link> :
                                                <Typography color="textPrimary">
                                                    {this.state.breadcrumbText2}
                                                </Typography>
                                            }
                                            {this.state.breadcrumbText3 &&
                                                <Typography color="textPrimary">
                                                    {this.state.breadcrumbText3}
                                                </Typography>
                                            }
                                        </Breadcrumbs>}
                                </Typography>)}

                        {this.state.addQuestion && pathName !== '/about' && pathName !== '/privacypolicy' &&
                            <Fragment>
                                <Button variant="contained" color="secondary" className={buttonError} size="small"
                                    onClick={() => this.props.addQuestion(pathName === '/communities' ? true : false)}>
                                    {pathName !== '/communities' &&
                                        <img src={AddQuestionIcon} height={20} className={classes.leftIcon} alt="QuestionIcon" />}
                                    {pathName === '/communities' ? 'Create Community' : 'Add Topic'}
                                </Button>
                            </Fragment>
                        }
                    </Toolbar>
                </AppBar>

                {/* My Profile & My Threads */}
                {(pathName === '/myprofile' || pathName === '/mythreads') &&
                    <AppBar position="fixed" className={classes.appBar1}
                        style={{ boxShadow: '0px 0px 0px 0px', marginTop: 145, zIndex: 1024 }}>
                        <Toolbar className="topic-header-color-2"
                            style={{ backgroundColor: '#f6f7f2' }}>
                            <Typography className={classes.alignment}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Avatar style={{ backgroundColor: '#a19b9b' }} onClick={this.props.userLogin}
                                            src={image_url}
                                            alt={this.props.userAliasName ? this.props.userAliasName.toUpperCase() : null} />
                                    </Grid>
                                    <Grid item style={{ marginTop: 5 }}>
                                        <Typography>
                                            <span className={classes.profileTitle}>{this.props.userAliasName}</span>
                                            <span className={classes.profileSubTitle}>{' (' + this.state.email_address + ')'}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Typography>
                            <Fragment>
                                <Button variant="contained" color="primary" className={buttonRegular} size="small"
                                    onClick={this.showConfirmDialog}>
                                    <DeleteIcon className={buttonLeftIcon} />
                                    Delete My Account
                                </Button>
                                <Button variant="contained" color="secondary" className={classes.customButtonRegular2} size="small"
                                    onClick={() => this.props.userLogout('/myprofile')}>
                                    <PowerSettingsNewIcon className={buttonLeftIcon} />
                                    Logout
                                </Button>
                            </Fragment>
                        </Toolbar>
                    </AppBar>
                }
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(NavMenu)))