/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

export default function FeedbackForm() {
    const matches = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <div style={{marginTop: matches ? '55px' : '90px'}}>
            <iframe 
                src="https://forms.gle/ijnPeqz5fXZDk5v37" 
                style={{
                    width: '100%', 
                    height: '1250px',
                }} 
                frameborder="0">
            </iframe>
        </div>
    );
}
