import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  Chip,
  Backdrop,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import DefaultCoverPic from "../../components/images/CoverPic.jpg";
import { connect } from "react-redux";
import api from "../../components/common/APIValues";
import colorJsonData from "../json/CommunityColorTheme.json";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import CommonFunc from "../../components/common/CommonFunc";
import CommunityNavMenu from "../users/CommunityNavMenu";
import { update } from "../../services/ApiService";
import imageSizes from "../json/ImageSize.json";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class CommunityAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      community: {},
      loader: false,
      openInfo: false,
      textMsg: "",
      colorData: {},
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let groupId = this.props.match.params.groupId;
    this.loadCommunity(groupId);
  }

  loadCommunity = (groupId) => {
    const originName = window.location.origin.replace('www.', '');
    this.setState({ loader: true });
    fetch(
      api.partialURL + "Community/GetCommunity?GroupId=" + groupId +
        "&UserId=" + this.props.userEmail +
        "&ENV=" + api.env +
        '&originName=' + originName
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({ community: result, loader: false });
        let colorData = {};
        colorJsonData.map((color) => {
          if (color.name === result.ColorThemeName) {
            colorData.title = color.title;
            colorData.subtitle = color.subtitle;
            colorData.desc = color.desc;
          }
        });
      })
      .catch((err) => console.log(err));
  };

  onApplyColorTheme = (colorData) => {
    let groupId = this.props.match.params.groupId;
    this.setState({ colorData: colorData });
    let rowData = {
      ColorThemeName: colorData.name,
      Env: api.env,
      UpdatedUserId: this.props.userEmail,
    };

    update("CommunitySettings/UpdateColorTheme", rowData, groupId)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  handleFileSave = (type, imageUrl) => {
    let community = this.state.community;
    if (type === "cover_pic") {
      community.CoverUrl = imageUrl;
      this.updateCoverPic(imageUrl);
    } else {
      community.LogoUrl = imageUrl;
      this.updateLogoIcon(imageUrl);
    }
    this.setState({ community: community });
  };

  updateCoverPic = (coverUrl) => {
    let groupId = this.props.match.params.groupId;
    let rowData = {
      CoverUrl: coverUrl,
      Env: api.env,
      UpdatedUserId: this.props.userEmail,
    };

    update("CommunitySettings/UpdateCoverPic", rowData, groupId)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  updateLogoIcon = (logoUrl) => {
    let groupId = this.props.match.params.groupId;
    let rowData = {
      LogoUrl: logoUrl,
      Env: api.env,
      UpdatedUserId: this.props.userEmail,
    };

    update("CommunitySettings/UpdateLogoIcon", rowData, groupId)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subTitleWithIcon = classNames(
      classes.subHeader,
      classes.alignTextWithIcon
    );
    const col3 = mediaQuery ? 3 : 12;
    const col9 = mediaQuery ? 9 : 12;

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              style={{
                backgroundColor: "#F9F9F9",
                marginLeft: mediaQuery ? 135 : 0,
                marginRight: mediaQuery ? 135 : 0,
                marginTop: mediaQuery ? 55 : 90,
              }}
            >
              <CardContent>
                <Grid container spacing={4}>
                  {mediaQuery && (
                    <Grid item xs={col3}>
                      <Card>
                        <CardContent>
                          <CommunityNavMenu
                            groupId={this.props.match.params.groupId}
                            selectedMenu="about"
                            isCommunityAdmin={
                              this.state.community.UserGroupStatus === "Owner"
                                ? true
                                : false
                            }
                            onApplyColorTheme={this.onApplyColorTheme}
                            handleFileSave={this.handleFileSave}
                            GroupName={this.state.community.GroupName}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  <Grid item xs={col9}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <img
                          src={
                            this.state.community.CoverUrl
                              ? this.state.community.CoverUrl
                              : DefaultCoverPic
                          }
                          alt="img"
                          style={{
                            width: mediaQuery ? imageSizes.cover_pic.width : '100%',
                            height: mediaQuery ? imageSizes.cover_pic.height : '100%',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <Typography className={subTitleWithIcon}>
                          <img
                            src={this.state.community.LogoUrl}
                            alt="img"
                            className={classes.leftIcon}
                            style={{
                              width: imageSizes.logo_icon.width,
                              height: imageSizes.logo_icon.height,
                            }}
                          />
                          <Typography className={classes.subHeaderSemiBold}>
                            {this.state.community.GroupName}
                            <Chip
                              label={CommonFunc.Capitalize(
                                this.state.community.CommunityType
                              )}
                              size="small"
                              className={classes.chipCard}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <Typography className={classes.subHeader}>
                          {this.state.community.GroupDesc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const communityData = state.communityReducer;
  return { communityData };
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(
      connect(mapStateToProps)(CommunityAbout)
    )
  )
);
