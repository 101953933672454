import React, { Component, Fragment } from 'react';
import {
    AppBar, withStyles, Toolbar, CssBaseline, Grid, useMediaQuery, InputBase, Typography, Tooltip, Avatar,
    Badge, IconButton, Breadcrumbs, Button, Link, MenuItem, Menu, ListItemText, Divider, InputAdornment,
    Dialog, DialogContent, DialogActions, Backdrop, FormControl, Select, Drawer, List, ListItem, Box, Chip
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import './common/Common.css'
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';
import api from './common/APIValues';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import LoginIcon from '@material-ui/icons/LockOpen';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Logoedwhatsmall from './images/126_X_40-02.svg';
import SearchIconTop from './images/search_icon.png';
import DeleteIcon from '@material-ui/icons/Delete';
import EDWhatLogoIcon from './images/EdWhat-01-01.png';
import FeedbackIcon from '@material-ui/icons/Feedback';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import routeService from '../services/Routing';
import constant from '../components/common/Constants';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import CommunityNavMenu from '../pages/users/CommunityNavMenu';
import { searchByInputs, update, get } from '../services/ApiService';
import AllCommunityIcon from '@material-ui/icons/Forum';
import Notifications from './Notifications';
import imageSizes from "../pages/json/ImageSize.json";
import MyCommunityIcon from '@material-ui/icons/LocalLibrary';

const drawerWidth = 240;
var CryptoJS = require("crypto-js");

const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
    },
    appBar1: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#f6f7f2',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        marginTop: 50,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    alignment: {
        flexGrow: 1,
    },
    logoAlignment: {
        flexGrow: 1,
        marginTop: 8
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconColor: {
        color: 'white'
    },
    textColor: {
        color: 'white'
    },
    btnText: {
        fontSize: 12, color: 'white'
    },
    search: {
        position: 'relative',
        marginLeft: '0px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(5),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#c2272d',
        borderRadius: '17px 0px 0px 17px',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(7)}px)`,
        transition: theme.transitions.create('width'),
        color: '#292929',
        border: '1px solid #d9d9d9',
        borderRadius: '15px',
        width: '100%',
        height: '25px',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        }
    },
    inputSize: {
        fontSize: '15px',
    },
    iconColor: {
        color: '#333233',
        fontSize: '2.4rem',
    },
    customButtonError: {
        borderRadius: '8px',
        color: '#c2272d',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    buttonSpacing: {
        margin: theme.spacing(1),
    },
    customButtonInfo: {
        borderRadius: '8px',
        color: '#0000008A',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    customButtonFocus: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#c2272d",
        "&:hover": {
            backgroundColor: "#c2272d"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
    navMenuIcon: {
        marginTop: 12
    },
    profileTitle: {
        fontFamily: 'poppins',
        fontWeight: 600,
        fontSize: 17,
        color: '#000000'
    },
    profileSubTitle: {
        fontFamily: 'poppins',
        fontWeight: 400,
        fontSize: 11,
        color: '#000000'
    },
    iconMedSize: {
        fontSize: '1.2rem',
        color: '#c2272d',
    },
    customButtonRegular2: {
        borderRadius: '8px',
        color: 'black',
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#f9f9f9"
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        fontFamily: 'poppins',
        textTransform: 'none'
    },
    subHeader: {
        fontSize: 15,
        fontFamily: 'raleway',
        fontWeight: 500,
        color: '#000000'
    },
    title: {
        fontSize: 20,
        fontFamily: 'Poppins',
        marginBottom: 30,
        fontWeight: 700,
        color: '#000000'
    },
    iconSmallSize: {
        fontSize: '1rem',
        color: '#333233',
    },
    chipCard: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Poppins',
        borderRadius: 10,
        padding: '22px 5px 22px 5px'
    },
});

const StyledBadgeMobile = withStyles((theme) => ({
    badge: {
        top: 2,
        padding: '0 4px',
    },
}))(Badge);

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class SideNavMenu extends Component {
    static displayName = SideNavMenu.name;
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            currentStatus: '',
            openProducts: false,
            openVendors: false,
            openStocks: false,
            openCustomers: false,
            openOrders: false,
            openPayments: false,
            openConfigurations: false,
            openReports: false,
            inputBase: 'initial value',
            userNotifications: [],
            notificationCount: null,
            isSearchInput: false,
            openConfirmDialog: false,
            loader: false,
            email_address: '',
            isPosts: true,
            searchInputDisplay: true,
            breadcrumbText1: '',
            breadcrumbText2: '',
            breadcrumbText3: '',
            currentPathName: '',
            openLeftDrawer: false,
            openSettings: false,
            groupId: this.props.location.pathname.split('/').pop(),
            community: {},
            showApplyColor: false,
            selectedMenu: '',
            openNotification: false,
            navigationLogoUrl: '',
            askGroupName: '',
            encryptKey: '',
        };
    }

    navigateToHome = () => {
        routeService.routePage(this.props, constant.home);
    }

    navigateToHomePage = (pageName) => {
        let pathName;
        let groupId;
        switch (pageName) {
            case 'Home':
                pathName = constant.home;
                break;
            case 'Community':
                pathName = constant.communities;
                break;
            case 'Template':
                pathName = constant.templates;
                break;
            case 'Settings':
                groupId = this.props.location.pathname.split('/').pop();
                pathName = constant.communityHome + groupId;
                break;
            case 'Community Home':
                pathName = constant.communityHome + this.state.groupId;
                break;
            default:
                break;
        }
        routeService.routePage(this.props, pathName);
    }

    navigateToDetailPage = () => {
        routeService.routePage(this.props, this.props.prev_page);
    }

    notifications = () => {
        routeService.routePage(this.props, constant.notifications);
    }

    handleNotificationOpen = (event) => {
        this.setState({ 
            openNotification: true, 
            openLeftDrawer: false,
            selectedMenu: 'notification'
        });
    };

    handleFeedbackForm = () => {
        this.setState({selectedMenu: 'feedbackform'});
        window.open("https://forms.gle/ijnPeqz5fXZDk5v37", "_blank");
    }

    handleNotificationClose = () => {
        this.setState({ openNotification: false });
    };

    showSearchInput = () => {
        this.setState({ isSearchInput: true });
    };

    closeSearchInput = () => {
        this.setState({ isSearchInput: false });
    }

    loadUserNotifications = () => {
        const originName = window.location.origin.replace('www.', '');
        fetch(api.partialURL + 'WebNotification/GetWebNotifications?USER_EMAIL=' + this.props.userEmail + 
                '&ENV=' + api.env +
                '&originName=' + originName
            )
            .then(res => res.json())
            .then(result => {
                this.setState({
                    userNotifications: result.dataList,
                    notificationCount: result.NOTIFICATION_CNT
                });
            })
            .catch(err => console.log(err));
    }

    loadCommunity = () => {
        const originName = window.location.origin.replace('www.', '');
        const groupId = this.props.location.pathname.split('/').pop();
        fetch(api.partialURL + 'Community/GetCommunity?GroupId=' + groupId + 
            '&UserId=' + this.props.userEmail + 
            '&ENV=' + api.env +
            '&originName=' + originName)
            .then(res => res.json())
            .then(result => {
                this.setState({ community: result });
            })
            .catch(err => console.log(err));
    }

    getDefaultCommunityDetails = () => {
        this.setState({ loader: true });
        const originName = window.location.origin.replace('www.', '');
        const inputs = `env=${api.env}&originName=${originName}`;
        get("Home/GetDefaultCommunityDetails?" + inputs).then(
          (response) => {
            if(response) {
                this.setState({
                    navigationLogoUrl: response.LogoUrl ? response.LogoUrl.replace('{device_type}', 'mobile') : '',
                    askGroupName: response.GroupName,
                    loader: false,
                });

                fetch(response.S3ConfigUrl)
                    .then((r) => r.json())
                    .then((jsonData) => {
                        const encryptKey = jsonData.encryptKey;
                        this.setState({ encryptKey: encryptKey });
                        this.decodeEmailAddress(encryptKey);
                    });
            }            
          }
        );
    };

    componentDidMount = () => {
        let pathName = this.props.location.pathname;
        this.getDefaultCommunityDetails();
        this.setState({
            isPosts: pathName.indexOf('/communities') > -1 ? false : true,
            currentPathName: pathName
        });
        this.showAddQuestion(pathName);
        this.loadUserNotifications();

        if (pathName.indexOf('/community/home/') > -1 || pathName.indexOf('/community/premium/') > -1) {
            this.loadCommunity();
        }
        this.displayBreadcrumbForDetailPage(pathName);
    }

    displayBreadcrumbForDetailPage = (pathName) => {
        if (pathName.indexOf('/topic/') > -1 || pathName.indexOf('/poll/') > -1) {
            let pathParams = pathName.split('/');
            let inputs = pathParams[2] + '/' + pathParams[1] + '/' + api.env;
            searchByInputs('UserTopic/GetCommunityByTopic', inputs)
                .then(response => {
                    if (Number(response) > 0) {
                        this.setState({
                            groupId: Number(response),
                            breadcrumbText1: 'Community Home',
                            breadcrumbText2: pathParams[1] === 'topic' ? 'Question' : 'Poll'
                        });
                    } else {
                        this.setState({
                            breadcrumbText1: 'Home',
                            breadcrumbText2: pathParams[1] === 'topic' ? 'Question' : 'Poll'
                        });
                    }
                })
        }
    }

    showAddQuestion = (pathName) => {
        let breadcrumbText1, breadcrumbText2, breadcrumbText3, selectedMenu;

        // show/hide Add A Question button
        if (pathName === '/community/templates' || pathName.indexOf('/community/requests/') > -1 ||
            pathName === '/community/types' || pathName.indexOf('/create/community/') > -1 ||
            pathName === '/community/template/preview' || pathName.indexOf('/community/home/') > -1 ||
            pathName.indexOf('/community/inviteusers/') > -1 || pathName.indexOf('/community/managepermissions/') > -1 ||
            pathName.indexOf('/community/manageusers/') > -1 || pathName.indexOf('/community/premium/') > -1) {
            this.setState({ addQuestion: false });
        } else {
            this.setState({ addQuestion: true });
        }

        // show/hide Search Input field
        if (pathName === '/topcommunities' || pathName.indexOf('/community/manageusers/') > -1) {
            this.setState({ searchInputDisplay: false });
        } else {
            this.setState({ searchInputDisplay: true });
        }

        // breadcrumb text
        switch (pathName) {
            case '/community/templates':
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Templates';
                selectedMenu = 'create_community';
                break;
            case '/community/types':
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Types';
                selectedMenu = 'create_community';
                break;
            case '/community/template/preview':
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Template';
                breadcrumbText3 = 'Preview';
                selectedMenu = 'create_community';
                break;
            case '/topcommunities':
                breadcrumbText1 = 'Home';
                breadcrumbText2 = 'Top Communities';
                selectedMenu = 'allcommunities';
                break;
            case '/myprofile':
            case '/mythreads':
                selectedMenu = 'myprofile';
                break;
            default:
                break;
        }

        switch (true) {
            case (pathName.indexOf('/create/question/ask') > -1):
                breadcrumbText1 = 'Home';
                breadcrumbText2 = 'Ask ' + this.state.askGroupName;
                break;
            case (pathName.indexOf('/create/community/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Create';
                selectedMenu = 'create_community';
                break;
            case (pathName.indexOf('/community/home/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Home';
                selectedMenu = 'home';
                break;
            case (pathName.indexOf('/community/about/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'About';
                selectedMenu = 'about';
                break;
            case (pathName.indexOf('/community/inviteusers/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Invite Users';
                break;
            case (pathName.indexOf('/community/managepermissions/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Permissions';
                break;
            case (pathName.indexOf('/community/manageusers/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Manage Users';
                break;
            case (pathName.indexOf('/community/requests/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Settings';
                breadcrumbText3 = 'Requests';
                break;
            case (pathName.indexOf('/community/premium/') > -1):
                breadcrumbText1 = 'Community';
                breadcrumbText2 = 'Premium';
                selectedMenu = 'premium';
                break;
            default:
                    break;
        }

        this.setState({ breadcrumbText1, breadcrumbText2, breadcrumbText3, selectedMenu });
    }

    decodeEmailAddress = (encryptKey) => {
        const _email = localStorage.getItem('user_encoded_email');
        if (_email && _email !== '') {
            let bytes = CryptoJS.AES.decrypt(_email, encryptKey);
            let email_address = bytes.toString(CryptoJS.enc.Utf8);
            this.setState({ email_address: email_address });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.userEmail !== prevProps.userEmail) {
            this.decodeEmailAddress(this.state.encryptKey);
            this.loadUserNotifications();
        }

        if (this.props.searchValue !== prevProps.searchValue) {
            if (!this.props.searchValue) {
                this.setState({ isSearchInput: false });
            }
        }

        let pathName = this.props.location.pathname;
        if (pathName !== prevState.currentPathName) {
            this.showAddQuestion(pathName);
            if (pathName.indexOf('/community/home/') > -1 || pathName.indexOf('/community/premium/') > -1) {
                this.loadCommunity();
            }
            this.setState({ currentPathName: pathName, openLeftDrawer: false });
            this.displayBreadcrumbForDetailPage(pathName);
        }
    }

    updateReadNotification = (pathName, isRead, rowData) => {
        if (isRead === 0) {
            axios({
                method: 'post',
                url: api.partialURL + 'WebNotification/UpdateReadNotification',
                data: JSON.stringify(rowData),
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
            })
                .then(res => {
                    if (res) {
                        this.setState({ openNotification: false });
                        this.loadUserNotifications();
                        routeService.routePage(this.props, pathName);
                    }
                })
                .catch(function (response) {
                    this.setState({ err_msg: response });
                });
        } else {
            this.setState({ openNotification: false });
            routeService.routePage(this.props, pathName);
        }
    }

    redirectToApproveRequests = (groupId, notificationId, isRead) => {
        let pathName = constant.approveUsers + groupId;
        let rowData = {
            NOTIFICATION_ID: notificationId,
            ENV: api.env
        };
        
        this.updateReadNotification(pathName, isRead, rowData);
    }

    viewTopicDetails = (topicId, topicTitle, topicPostType, notificationId, isRead) => {
        let pathName = topicPostType === 'question' ? constant.queDetails + topicId + '/' + topicTitle.toLowerCase().replace(/ /g, '-')
            : constant.pollDetails + topicId + '/' + topicTitle.toLowerCase().replace(/ /g, '-');
        let rowData = {
            NOTIFICATION_ID: notificationId,
            ENV: api.env
        };

        this.updateReadNotification(pathName, isRead, rowData);
    }

    handleClose = () => {
        this.setState({
            openConfirmDialog: false,
        })
    }

    showConfirmDialog = () => {
        this.setState({
            openConfirmDialog: true,
        })
    }

    deleteProfile = () => {
        this.handleClose();
        let rowData = {
            TOPIC_UPDT_USER_NM: this.props.userEmail,
            ENV: api.env
        }
        this.setState({ loader: true });

        axios({
            method: 'post',
            url: api.partialURL + 'MyProfile/DeleteMyProfile',
            data: JSON.stringify(rowData),
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
        })
            .then(res => {
                if (res) {
                    if (res.data === 'success') {
                        sessionStorage.setItem('add_question_page_1', 'delete_my_account');
                        sessionStorage.setItem('add_question_page_2', 'delete_my_account');
                        this.props.userLogout('/myprofile');
                    } else {
                        this.setState({ err_msg: 'Failed to delete my profile' });
                    }
                    this.setState({ loader: false });
                }
            })
            .catch(function (response) {
                this.setState({ err_msg: response, loader: false });
            });
    }

    redirectToPosts = () => {
        this.setState({ isPosts: true });
        routeService.routePage(this.props, constant.home);
    }

    redirectToCommunities = () => {
        this.setState({ isPosts: false });
        routeService.routePage(this.props, constant.communities);
    }

    toggleDrawer = (open) => {
        this.setState({ openLeftDrawer: open });
    }

    // redirectToCommunity = () => {
    //     routeService.routePage(this.props, constant.communityTypes);
    // }

    redirectToMyProfile = () => {
        let inputData = { myCommunities: true };
        this.setState({ selectedMenu: 'mycommunities' });
        routeService.routePage(this.props, constant.myProfile, inputData);
    }

    redirectToTopCommunities = () => {
        this.setState({ selectedMenu: 'allcommunities' });
        routeService.routePage(this.props, constant.topCommunities);
    }

    handleFileSave = (type, imageUrl) => {
        let community = this.state.community;
        if (type === 'cover_pic') {
            community.CoverUrl = imageUrl;
            this.updateCoverPic(imageUrl);
        }
        else {
            community.LogoUrl = imageUrl;
            this.updateLogoIcon(imageUrl);
        }
        this.setState({ community: community, openLeftDrawer: false });
        this.props.updateCommunityGroup(community);
    }

    updateCoverPic = (coverUrl) => {
        let rowData = {
            CoverUrl: coverUrl,
            Env: api.env,
            UpdatedUserId: this.props.userEmail
        }
        let groupId = this.props.location.pathname.split('/').pop();
        update('CommunitySettings/UpdateCoverPic', rowData, groupId)
            .then(response => {
            })
            .catch(function (response) {
                this.setState({ err_msg: response, loader: false });
            });
    }

    updateLogoIcon = (logoUrl) => {
        let rowData = {
            LogoUrl: logoUrl,
            Env: api.env,
            UpdatedUserId: this.props.userEmail
        }
        let groupId = this.props.location.pathname.split('/').pop();
        update('CommunitySettings/UpdateLogoIcon', rowData, groupId)
            .then(response => {
            })
            .catch(function (response) {
                this.setState({ err_msg: response, loader: false });
            });
    }

    onApplyColorTheme = (colorData) => {
        let rowData = {
            ColorThemeName: colorData.name,
            Env: api.env,
            UpdatedUserId: this.props.userEmail
        }
        let groupId = this.props.location.pathname.split('/').pop();
        update('CommunitySettings/UpdateColorTheme', rowData, groupId)
            .then(response => {
            })
            .catch(function (response) {
                this.setState({ err_msg: response, loader: false });
            });

        let community = this.state.community;
        community.ColorThemeName = colorData.name;
        this.setState({ community: community, openLeftDrawer: false });
        this.props.updateCommunityGroup(community);
    }

    render() {
        const { classes } = this.props;
        const pathName = this.props.location.pathname;
        const forPosts = classNames(this.state.isPosts ? classes.customButtonFocus : classes.customButtonError,
            classes.buttonSpacing);
        const searchInput = classNames(classes.inputInput, classes.inputSize);
        const image_url = localStorage.getItem('image_url');
        const buttonLeftIcon = classNames(classes.iconMedSize, classes.leftIcon);

        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={this.state.loader}>
                    <img src={EDWhatLogoIcon} height={50} alt="Logo" />
                </Backdrop>

                <CssBaseline />

                <Notifications
                    open={this.state.openNotification}
                    userNotifications={this.state.userNotifications}
                    handleClose={this.handleNotificationClose}
                    redirectToApproveRequests={this.redirectToApproveRequests}
                    viewTopicDetails={this.viewTopicDetails}>                    
                </Notifications>

                <Dialog
                    fullWidth
                    open={this.state.openConfirmDialog}
                    onClose={this.handleClose}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={10}>
                                <Typography className={classes.title}>
                                    Delete for all eternity?
                                </Typography>
                                <Typography className={classes.subHeader} style={{ marginBottom: 10 }}>
                                    This action will delete all your user data, including your posts and comments. We cannot recover them later.
                                </Typography>
                                <Typography className={classes.subHeader}>
                                    Are you sure you want to <span style={{ color: '#c2272d' }}>permanently delete</span> your account?
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton style={{ marginLeft: '22px', marginTop: '-20px' }} onClick={this.handleClose}
                                    aria-label="settings">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#f6f7f2' }}>
                        <Button onClick={this.handleClose} color="secondary" style={{ color: '#c2272d' }}>
                            Cancel
                        </Button>
                        <Button onClick={this.deleteProfile} color="primary">
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppBar position="fixed" className={classes.appBar} style={{ boxShadow: '0px 0px 0px 0px' }}>
                    <Toolbar className="header-color" style={{ marginLeft: '-10px', marginRight: '-15px' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={this.state.isSearchInput ? 2 : 4}>
                                <IconButton
                                    aria-label="show more"
                                    aria-haspopup="true"
                                    onClick={(e) => this.toggleDrawer(true)}
                                    className={classes.activeColor}
                                >
                                    <DehazeIcon style={{ fontSize: '2rem', color: '#000000' }} />
                                </IconButton>
                            </Grid>
                            {
                                !this.state.isSearchInput ?
                                    <Fragment>
                                        <Grid item xs={4}>
                                            <Typography className={classes.logoAlignment}>
                                                <img onClick={this.navigateToHome} style={{ cursor: 'pointer' }} height={40} alt="Logo"
                                                    src={this.state.navigationLogoUrl ? this.state.navigationLogoUrl : Logoedwhatsmall} />
                                            </Typography>
                                        </Grid>
                                        { this.state.searchInputDisplay &&
                                            <Grid item xs={4}>
                                                <img src={SearchIconTop} height={30} className={classes.navMenuIcon}
                                                    onClick={this.showSearchInput} style={{ cursor: 'pointer', float: 'right', marginRight: 10 }}
                                                />
                                            </Grid>
                                        }
                                    </Fragment>
                                    :
                                    <Grid item xs={10} style={{ marginTop: 9 }}>
                                        {this.state.searchInputDisplay &&
                                            <div className={classes.search}>
                                                <div className={classes.searchIcon}>
                                                    <SearchIcon />
                                                </div>
                                                <InputBase onChange={this.props.searchTopics} fullWidth
                                                    placeholder="Search topics and communities" value={this.props.searchValue}
                                                    style={{
                                                        position: 'initial',
                                                        width: '100%'
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: searchInput,
                                                    }}
                                                    inputProps={{
                                                        'aria-label': 'search',
                                                        'style': { fontSize: 14, fontFamily: 'poppins', height: 18 }
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            {this.props.searchValue ?
                                                                <CloseIcon style={{ color: '#A19B9B', cursor: 'pointer', marginLeft: 1 }}
                                                                    onClick={this.props.clearSearchInput} />
                                                                :
                                                                <CloseIcon style={{ color: '#A19B9B', cursor: 'pointer', marginLeft: 1 }}
                                                                    onClick={this.closeSearchInput} />
                                                            }
                                                        </InputAdornment>
                                                    }
                                                />
                                            </div>}
                                    </Grid>
                            }
                        </Grid>
                    </Toolbar>
                </AppBar>

                <AppBar position="fixed" className={classes.appBar}
                    style={{ boxShadow: '0px 0px 0px 0px', marginTop: '80px', borderTop: '1px solid #0000001F', zIndex: 1024 }}>
                    <Toolbar className="topic-header-color-mobileView">
                        {(pathName === '/home' || pathName === '/' || pathName === '/home/' || pathName === '/communities' || pathName === '/communities/') ?
                            (
                                <Typography variant="h6" className={classes.alignment}>
                                    <Fragment>
                                        <span style={{ fontSize: 13, color: 'black', fontFamily: 'poppins' }}>Sort By :</span>
                                        <FormControl variant="outlined" size="small" style={{ marginLeft: 5 }}>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={this.props.sortBy}
                                                onChange={this.props.handleSortBy}
                                                style={{
                                                    fontSize: 13,
                                                    fontFamily: 'poppins',
                                                    height: 30,
                                                    marginTop: (this.props.searchValue || pathName === '/communities' || pathName === '/communities/') ? 9 : 4
                                                }}
                                                name="sortBy">
                                                <MenuItem value='1'>Popular</MenuItem>
                                                <MenuItem value='0'>New</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Fragment>
                                    {(this.props.searchValue || pathName === '/communities' || pathName === '/communities/') &&
                                        <Fragment>
                                            <Button variant="contained" color="primary" className={forPosts} size="small"
                                                onClick={this.redirectToPosts}>Posts
                                            </Button>
                                            <Button variant="contained" className={!this.state.isPosts ? classes.customButtonFocus : classes.customButtonInfo}
                                                size="small" onClick={this.redirectToCommunities}>Communities
                                            </Button>
                                        </Fragment>
                                    }
                                </Typography>
                            ) : (
                                <Typography className={classes.alignment} style={{ marginLeft: 10 }}>
                                    {this.state.breadcrumbText1 &&
                                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                            <Link onClick={(e) => this.navigateToHomePage(this.state.breadcrumbText1)} style={{ textDecoration: 'none', cursor: 'pointer' }} color="inherit">
                                                {this.state.breadcrumbText1}
                                            </Link>
                                            {this.state.breadcrumbText3 ?
                                                <Link onClick={(e) => this.navigateToHomePage(this.state.breadcrumbText2)} style={{ textDecoration: 'none', cursor: 'pointer' }} color="inherit">
                                                    {this.state.breadcrumbText2}
                                                </Link> :
                                                <Typography color="textPrimary">
                                                    {this.state.breadcrumbText2}
                                                </Typography>
                                            }
                                            {this.state.breadcrumbText3 &&
                                                <Typography color="textPrimary">
                                                    {this.state.breadcrumbText3}
                                                </Typography>
                                            }
                                        </Breadcrumbs>}
                                </Typography>)}
                        {this.state.addQuestion && !this.props.searchValue && pathName !== '/communities' && pathName !== '/communities/' &&
                            pathName !== '/about' && pathName !== '/privacypolicy' &&
                            <Fragment>
                                <Button variant="contained" color="secondary" className={classes.customButtonError} size="small"
                                    onClick={() => this.props.addQuestion(pathName === '/communities' ? true : false)}>
                                    {pathName === '/communities' ? 'Create Community' : 'Add Topic'}
                                </Button>
                            </Fragment>}
                    </Toolbar>
                </AppBar>

                {/* My Profile & My Threads */}
                {(pathName === '/myprofile' || pathName === '/mythreads') &&
                    <AppBar position="fixed" className={classes.appBar1}
                        style={{ boxShadow: '0px 0px 0px 0px', marginTop: 129, zIndex: 1024 }}>
                        <Toolbar className={"topic-header-color-mobileView"}
                            style={{ backgroundColor: '#f6f7f2' }}>
                            <Typography className={classes.alignment}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <Avatar style={{ backgroundColor: '#a19b9b' }} onClick={this.props.userLogin}
                                            src={image_url}
                                            alt={this.props.userAliasName ? this.props.userAliasName.toUpperCase() : null} />
                                    </Grid>
                                    <Grid item xs={8} style={{ marginTop: 5 }}>
                                        <Typography>
                                            <span className={classes.profileTitle}>{this.props.userAliasName}</span>
                                            <span className={classes.profileSubTitle}>{' (' + this.state.email_address + ')'}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton edge="start" color="inherit" onClick={this.showConfirmDialog}
                                            aria-label="close">
                                            <DeleteIcon className={buttonLeftIcon} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton edge="start" color="inherit" onClick={() => this.props.userLogout('/myprofile')}
                                            aria-label="close">
                                            <PowerSettingsNewIcon className={buttonLeftIcon} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                }

                <Drawer
                    anchor={'left'}
                    open={this.state.openLeftDrawer}
                    onClose={(e) => this.toggleDrawer(false)}
                >
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                    >
                        <div style={{ padding: 16, backgroundColor: '#D3D3D3' }}>
                            <Avatar src={image_url} alt={this.props.userAliasName ? this.props.userAliasName.toUpperCase() : null} />
                            <div style={{ marginTop: 10 }} className="sideDrawerItemsText">{this.state.email_address}</div>
                        </div>
                        <List onClick={(e) => this.toggleDrawer(false)}>
                            {this.props.userAliasName && this.props.userEmail &&
                                <ListItem button onClick={this.props.userLogin}>
                                    <Tooltip title="My Profile">
                                        <PersonIcon className="sideDrawerItems" style={{color: this.state.selectedMenu === 'myprofile' ? '#c2272d' : null}} />
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText" style={{color: this.state.selectedMenu === 'myprofile' ? '#c2272d' : null}}>
                                            My Profile
                                        </span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} style={{color: this.state.selectedMenu === 'myprofile' ? '#c2272d' : null}} />
                                    </IconButton>
                                </ListItem>}
                            {this.props.userAliasName && this.props.userEmail &&
                                <Divider />}

                            {this.props.userAliasName && this.props.userEmail &&
                                <ListItem button onClick={this.props.redirectToCommunity}>
                                    <Tooltip title="Create Community">
                                        <GroupAddIcon className="sideDrawerItems" style={{color: this.state.selectedMenu === 'create_community' ? '#c2272d' : null}} />
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText" style={{color: this.state.selectedMenu === 'create_community' ? '#c2272d' : null}}>
                                            Create Community
                                        </span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} style={{color: this.state.selectedMenu === 'create_community' ? '#c2272d' : null}} />
                                    </IconButton>
                                </ListItem>}
                            {this.props.userAliasName && this.props.userEmail &&
                                <Divider />}

                            {this.props.userAliasName && this.props.userEmail &&
                                <ListItem button onClick={this.redirectToMyProfile}>
                                    <Tooltip title="My Communities">
                                        <MyCommunityIcon className="sideDrawerItems" style={{color: this.state.selectedMenu === 'mycommunities' ? '#c2272d' : null}} />
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText" style={{color: this.state.selectedMenu === 'mycommunities' ? '#c2272d' : null}}>
                                            My Communities
                                        </span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} style={{color: this.state.selectedMenu === 'mycommunities' ? '#c2272d' : null}} />
                                    </IconButton>
                                </ListItem>}
                            {this.props.userAliasName && this.props.userEmail &&
                                <Divider />}

                            <ListItem button onClick={this.redirectToTopCommunities}>
                                <Tooltip title="All Communities">
                                    <AllCommunityIcon className="sideDrawerItems" style={{color: this.state.selectedMenu === 'allcommunities' ? '#c2272d' : null}} />
                                </Tooltip>
                                <ListItemText className="drawerItemsText">
                                    <span className="sideDrawerItemsText" style={{color: this.state.selectedMenu === 'allcommunities' ? '#c2272d' : null}}>
                                        All Communities
                                    </span>
                                </ListItemText>
                                <IconButton aria-label="Continue">
                                    <ArrowForward className={classes.iconSmallSize} style={{color: this.state.selectedMenu === 'allcommunities' ? '#c2272d' : null}} />
                                </IconButton>
                            </ListItem>
                            <Divider />

                            {this.props.userAliasName && this.props.userEmail &&
                                <ListItem button onClick={this.handleFeedbackForm}>
                                    <Tooltip title="Feedback">
                                        <FeedbackIcon className="sideDrawerItems" style={{color: this.state.selectedMenu === 'feedbackform' ? '#c2272d' : null}} />
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText" style={{color: this.state.selectedMenu === 'feedbackform' ? '#c2272d' : null}}>
                                            Feedback
                                        </span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} style={{color: this.state.selectedMenu === 'feedbackform' ? '#c2272d' : null}} />
                                    </IconButton>
                                </ListItem>}
                            {this.props.userAliasName && this.props.userEmail &&
                                <Divider />}

                            {this.props.userAliasName && this.props.userEmail &&
                                <ListItem button onClick={this.handleNotificationOpen}>
                                    <Tooltip title="Notifications">
                                        <StyledBadgeMobile badgeContent={this.state.notificationCount} color="error">
                                            <NotificationsIcon className="sideDrawerItems" style={{color: this.state.selectedMenu === 'notification' ? '#c2272d' : null}} />
                                        </StyledBadgeMobile>
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText" style={{color: this.state.selectedMenu === 'notification' ? '#c2272d' : null}}>
                                            Notifications
                                        </span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} style={{color: this.state.selectedMenu === 'notification' ? '#c2272d' : null}} />
                                    </IconButton>
                                </ListItem>}
                            {this.props.userAliasName && this.props.userEmail &&
                                <Divider />}

                            {this.props.userAliasName && this.props.userEmail &&
                                <ListItem button onClick={() => this.props.userLogout(pathName)}>
                                    <Tooltip title="Logout">
                                        <PowerSettingsNewIcon className="sideDrawerItems" />
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText">Logout</span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} />
                                    </IconButton>
                                </ListItem>}
                            {this.props.userAliasName && this.props.userEmail &&
                                <Divider />}

                            {(!this.props.userAliasName || !this.props.userEmail) &&
                                <ListItem button onClick={this.props.userLogin}>
                                    <Tooltip title="Login">
                                        <LoginIcon className="sideDrawerItems" />
                                    </Tooltip>
                                    <ListItemText className="drawerItemsText">
                                        <span className="sideDrawerItemsText">Login</span>
                                    </ListItemText>
                                    <IconButton aria-label="Continue">
                                        <ArrowForward className={classes.iconSmallSize} />
                                    </IconButton>
                                </ListItem>}
                        </List>

                        {(pathName.indexOf('/community/home/') > -1 || pathName.indexOf('/community/premium/') > -1 ||
                            pathName.indexOf('/community/about/') > -1) &&
                            <Fragment>
                                <div style={{ padding: '10px 0px 10px 0px', textAlign: 'center' }}>                                    
                                    <Chip avatar={<Avatar src={this.state.community.LogoUrl} style={{ width: imageSizes.logo_icon.width, height: imageSizes.logo_icon.height }} />} 
                                        label={this.state.community.GroupName}
                                        size="medium" className={classes.chipCard}
                                    />
                                </div>

                                <CommunityNavMenu
                                    groupId={this.props.location.pathname.split('/').pop()}
                                    selectedMenu={this.state.selectedMenu}
                                    isCommunityAdmin={this.state.community.UserGroupStatus === 'Owner' ? true : false}
                                    onApplyColorTheme={this.onApplyColorTheme}
                                    toggleDrawer={this.toggleDrawer}
                                    isMobileView={true}
                                    handleFileSave={this.handleFileSave}
                                    GroupName={this.state.community.GroupName}
                                />
                            </Fragment>}
                    </Box>
                </Drawer>
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(SideNavMenu)))