import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Typography,
  Backdrop,
  InputLabel,
  TextField,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import api from "../../components/common/APIValues";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CommonFunc from "../../components/common/CommonFunc";
import classNames from "classnames";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import { createCommunity } from "../../redux-pages/actions";
import { connect } from "react-redux";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";
import { get } from "../../services/ApiService";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class CreateCommunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: "",
      groupDesc: "",
      groupCategory: "",
      err_msg: "",
      errors: {
        groupName: "",
        groupDesc: "",
        groupCategory: "",
      },
      categories: [],
      loader: false,
      openTooltip: false,
      openGuidelines: false,
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }
    this.validateUserDefaultGroupPermission();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadCategories();
  }

  validateUserDefaultGroupPermission = () => {
    const permissionName = 'Create Community';
    const originName = window.location.origin.replace('www.', '');
    const inputs = `GroupId=0&Env=${api.env}&UserId=${this.props.userEmail}&PermissionName=${permissionName}&originName=${originName}`;

    get("Permission/ValidateUserPermission?" + inputs)
      .then((response) => {
        if(!response.PermissionName) {
            routeService.routePage(this.props, constant.home);
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "groupName":
        errors.groupName =
          value.length <= 0 ? "Please enter community name" : "";
        break;
      case "groupDesc":
        errors.groupDesc =
          value.length <= 0 ? "Please enter community description" : "";
        break;
      case "groupCategory":
        errors.groupCategory =
          value.length <= 0 ? "Please select community category" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  validateInputs() {
    if (
      !this.state.groupName ||
      !this.state.groupDesc ||
      !this.state.groupCategory
    ) {
      return false;
    } else {
      return true;
    }
  }

  createCommunity = () => {
    if (CommonFunc.validateForm(this.state.errors) && this.validateInputs()) {
      let rowData = {
        GroupName: this.state.groupName,
        CategoryId: this.state.groupCategory,
        GroupDesc: this.state.groupDesc,
        CommunityType: this.props.match.params.communityType,
        CreatedUserId: this.props.userEmail,
        Env: api.env,
      };

      this.props.dispatch(createCommunity(rowData));
      routeService.routePage(this.props, constant.templates);
    } else {
      let errors = this.state.errors;
      if (!this.state.groupCategory) {
        errors.groupCategory = "Please select community category";
      }
      if (!this.state.groupDesc) {
        errors.groupDesc = "Please enter community description";
      }
      if (!this.state.groupName) {
        errors.groupName = "Please enter community name";
      }
      this.setState({ errors });
    }
  };

  loadCategories = () => {
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    let url =
      api.partialURL + "Community/GetCommunityCategories?ENV=" + api.env;
    let params = {};

    if (hasCache(url, params)) {
      this.setCategories(url, params, getCache(url, params).data);
    } else {
      this.fetchCategories(url, params);
    }
  };

  fetchCategories = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setCategories(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  setCategories = (url, params, categories) => {
    this.setState({
      categories: categories,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, categories);
  };

  goBack = () => {
    routeService.routePage(this.props, constant.communityTypes);
  };

  handleGuidelinesOpen = () => {
    const originName = window.location.origin.replace('www.', '');
    const loginUrl = `${originName}/#`;
    window.open(loginUrl + constant.communityGuidelines, "_blank");
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const col11 = mediaQuery ? 11 : 10;
    const col1 = mediaQuery ? 1 : 2;
    const pageTitle =
      this.props.match.params.communityType === "private"
        ? "Create Private Community"
        : "Create Public Community";
    const subTitleWithIcon = classNames(
      classes.subTitle,
      classes.alignTextWithIcon
    );
    let categories = this.state.categories.map((category) => (
      <MenuItem value={category.CategoryId}>{category.CategoryName}</MenuItem>
    ));

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>
        
        <Grid
          container
          spacing={0}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          <Grid item xs={col11}>
            <Typography className={classes.title}>{pageTitle}</Typography>
          </Grid>
          <Grid item xs={col1} style={{ marginTop: mediaQuery ? 10 : 0 }}>
            <Typography className={subTitleWithIcon}>
              Guidelines
              <AssignmentIcon
                onClick={this.handleGuidelinesOpen}
                className={classes.leftIcon}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="groupName"
              label="Community Name"
              required
              size="small"
              onChange={this.handleChange}
              noValidate
              value={this.state.groupName}
              variant="outlined"
              style={{ backgroundColor: "#e7e7e7" }}
            />

            {this.state.errors.groupName.length > 0 && (
              <span className="error">{this.state.errors.groupName}</span>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required size="small">
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.groupCategory}
                label="Select Category"
                onChange={this.handleChange}
                style={{ backgroundColor: "#e7e7e7" }}
                name="groupCategory"
              >
                {categories}
              </Select>
            </FormControl>

            {this.state.errors.groupCategory.length > 0 && (
              <span className="error">{this.state.errors.groupCategory}</span>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="groupDesc"
              label="Community Description"
              required
              onChange={this.handleChange}
              noValidate
              value={this.state.groupDesc}
              size="small"
              variant="outlined"
              style={{ backgroundColor: "#e7e7e7" }}
              multiline
              rows={4}
            />

            {this.state.errors.groupDesc.length > 0 && (
              <span className="error">{this.state.errors.groupDesc}</span>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.customButtonFocus}
              onClick={this.createCommunity}
              size="small"
            >
              Continue
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.customButtonError}
              onClick={this.goBack}
              size="small"
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12}>
            {this.state.err_msg.length > 0 && (
              <span className="error">{this.state.err_msg}</span>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(
      connect(mapStateToProps)(CreateCommunity)
    )
  )
);
