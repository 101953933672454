import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  Backdrop,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import DefaultCoverPic from "../../components/images/CoverPic.jpg";
import PeopleIcon from "@material-ui/icons/People";
import { connect } from "react-redux";
import api from "../../components/common/APIValues";
import axios from "axios";
import colorJsonData from "../json/CommunityColorTheme.json";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import SuccessInfo from "../modal/SuccessInfo";
import CommunityNavMenu from "./CommunityNavMenu";
import routeService from "../../services/Routing";
import constant from "../../components/common/Constants";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class Premium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      community: {},
      loader: false,
      openInfo: false,
      textMsg: "",
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    let groupId = this.props.match.params.groupId;
    this.loadCommunity(groupId);
  }

  loadCommunity = (groupId) => {
    const originName = window.location.origin.replace('www.', '');
    this.setState({ loader: true });
    fetch(api.partialURL + "Community/GetCommunity?GroupId=" + groupId +
        "&UserId=" + this.props.userEmail +
        "&ENV=" + api.env +
        '&originName=' + originName
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({ community: result, loader: false });
        let colorData = {};
        colorJsonData.map((color) => {
          if (color.name === result.ColorThemeName) {
            colorData.title = color.title;
            colorData.subtitle = color.subtitle;
            colorData.desc = color.desc;
          }
        });
      })
      .catch((err) => console.log(err));
  };

  joinCommunityGroup = () => {
    this.setState({ loader: true });
    let rowData = {};
    rowData.GroupId = Number(this.state.groupId);
    rowData.SubscriptionId = 2;
    rowData.ExpiryDate = "8888-12-31";
    rowData.Status =
      this.state.community.CommunityType === "private"
        ? "User Requested"
        : "Approved";
    rowData.CreatedUserId = this.props.userEmail;
    rowData.Env = api.env;
    rowData.CommunityType = this.state.community.CommunityType;

    axios({
      method: "post",
      url: api.partialURL + "Community/JoinCommunity",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          if (res.data === "success") {
            if (this.state.community.CommunityType === "private") {
              this.setState({
                openInfo: true,
                textMsg: "Your request is successfully sent to the admin !",
              });
            }
            this.loadCommunity(this.state.groupId);
          } else {
            this.setState({ err_msg: "Failed to join community group" });
          }
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  handleClose = () => {
    this.setState({ openInfo: false });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subTitleWithIcon = classNames(
      classes.subHeader,
      classes.alignTextWithIcon
    );
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const col3 = mediaQuery ? 3 : 12;
    const col9 = mediaQuery ? 9 : 12;
    const subHeaderX = classNames(classes.posTopX, classes.subHeader);

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <SuccessInfo
          open={this.state.openInfo}
          textMsg={this.state.textMsg}
          handleClose={this.handleClose}
          infoType='success'
        ></SuccessInfo>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              style={{
                backgroundColor: "#F9F9F9",
                marginLeft: mediaQuery ? 135 : 0,
                marginRight: mediaQuery ? 135 : 0,
                marginTop: mediaQuery ? 55 : 90,
              }}
            >
              <CardContent>
                <Grid container spacing={4}>
                  {mediaQuery && (
                    <Grid item xs={col3}>
                      <Card>
                        <CardContent>
                          <CommunityNavMenu
                            groupId={this.props.match.params.groupId}
                            selectedMenu="premium"
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  <Grid item xs={col9}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <img
                          src={
                            this.state.community.CoverUrl
                              ? this.state.community.CoverUrl
                              : DefaultCoverPic
                          }
                          alt="img"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <Typography className={subTitleWithIcon}>
                          <img
                            src={this.state.community.LogoUrl}
                            alt="img"
                            className={classes.leftIcon}
                          />
                          <Typography className={classes.subHeaderBold}>
                            {this.state.community.GroupName}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <Typography className={classes.subHeader}>
                          The content is premium, looks like you dont have
                          access would you like to request admin?
                        </Typography>
                        <Typography
                          style={{ marginTop: "10px" }}
                          className={classes.subHeader}
                        >
                          Hello Admin,
                        </Typography>
                        <Typography className={classes.subHeader}>
                          I Amy would want to get premium content.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ margin: "10px" }}>
                        <Button
                          variant="contained"
                          className={classes.customButtonFocus}
                          size="small"
                          onClick={this.requestAccess}
                          style={{ float: "right" }}
                        >
                          Request Access
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const communityData = state.communityReducer;
  return { communityData };
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(connect(mapStateToProps)(Premium))
  )
);
