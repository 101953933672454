import React, { useState } from 'react';
import { DialogContent, IconButton, Grid, Dialog, makeStyles, useMediaQuery, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    subHeaderSemiBold: {
        fontSize: 17,
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#000000',
        marginLeft: '5%',
    },
    subHeader: {
        fontSize: 15,
        fontFamily: 'raleway',
        fontWeight: 500,
        color: '#000000'
    },
    alignTextWithIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
}));

export default function SuccessInfo(props) {
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();
    const subTitleWithIcon = classNames(classes.subHeader, classes.alignTextWithIcon);

    return (
        <React.Fragment>
            <Dialog fullWidth open={props.open}
                onClose={props.handleClose} disableBackdropClick
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{marginBottom:30}}>
                    <Grid container spacing={0}>
                        <Grid item xs={ matches ? 11 : 10 }></Grid>
                        <Grid item xs={ matches ? 1 : 2 }>
                            <IconButton style={{ float: "right", marginTop: '-20px' }} 
                                onClick={props.handleClose} 
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography className={subTitleWithIcon}>
                        { props.infoType === 'error' ?
                            <CancelIcon className={classes.leftIcon} 
                            style={{fontSize:'3.5rem', color: '#c2272d'}} />
                            :
                            <CheckCircleIcon className={classes.leftIcon} 
                                style={{fontSize:'3.5rem', color: '#2e7d32'}} />
                        }
                        <Typography className={classes.subHeaderSemiBold}>
                            {props.textMsg}
                        </Typography>
                    </Typography>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
