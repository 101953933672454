/* eslint-disable default-case */
import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  useMediaQuery,
  Backdrop,
  MenuItem,
  InputBase,
  InputAdornment,
  CardActions,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import api from "../../components/common/APIValues";
import { withRouter } from "react-router-dom";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import PeopleIcon from "@material-ui/icons/People";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import axios from "axios";
import loginService from "../../services/Login";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";
import { create, remove, get } from "../../services/ApiService";
import ConfirmationDialog from "../modal/ConfirmationDialog";
import imageSizes from "../json/ImageSize.json";
import MemberIcon from "../../components/images/Member.png";
import RequestedIcon from "../../components/images/Requested.png";
import DeniedIcon from "../../components/images/Denied.png";
import AcceptedIcon from "../../components/images/Accepted.png";
import JoinIcon from "../../components/images/Join.png";
import OwnerIcon from "../../components/images/Owner.png";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class TopCommunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      topCommunities: [],
      categories: [],
      communityType: "all",
      categoryId: 0,
      groupName: "",
      htmlUserToAdmin: "",
      htmlUserToUser: "",
      openConfirmDialog: false,
      groupIdForRemove: null,
      defaultCommunity: {},
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadCategories();
    this.loadTopCommunities();
    this.getDefaultCommunityDetails();
  }

  getHtmlText = (s3BaseUrl) => {
    const htmlUserToAdminUrl = `${s3BaseUrl}/email-template/UserToAdminOnJoin.txt`;
    const htmlUserToUserUrl = `${s3BaseUrl}/email-template/UserToUserOnJoin.txt`;

    fetch(htmlUserToAdminUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlUserToAdmin: text });
      });

    fetch(htmlUserToUserUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlUserToUser: text });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName
    ) {
      this.loadTopCommunities();
    }
  };

  getDefaultCommunityDetails = () => {
    this.setState({ loader: true });
    const originName = window.location.origin.replace('www.', '');
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then(
      (response) => {
        this.setState({
          defaultCommunity: response,
          loader: false,
        });

        const s3BaseUrl = response.S3ConfigUrl.replace('/configuration/config.json', '');
        this.getHtmlText(s3BaseUrl);
      }
    );
  };

  loadCategories = () => {
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    let url =
      api.partialURL + "Community/GetCommunityCategories?ENV=" + api.env;
    let params = {};

    if (hasCache(url, params)) {
      this.setCategories(url, params, getCache(url, params).data);
    } else {
      this.fetchCategories(url, params);
    }
  };

  fetchCategories = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setCategories(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  setCategories = (url, params, categories) => {
    this.setState({
      categories: categories,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, categories);
  };

  loadTopCommunities = (communityType, categoryId, groupName) => {
    const originName = window.location.origin.replace('www.', '');
    this.setState({ loader: true });
    communityType = communityType ? communityType : "all";
    categoryId = categoryId ? categoryId : 0;
    groupName = groupName ? groupName : "";
    let url = api.partialURL + "Community/GetTopCommunities?CommunityType=" + communityType +
      "&GroupName=" + groupName +
      "&CategoryId=" + categoryId +
      "&UserId=" + this.props.userEmail +
      "&ENV=" + api.env +
      "&Limit=" + 0 +
      '&originName=' + originName;
    this.fetchTopCommunities(url);
  };

  fetchTopCommunities = (url) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setTopCommunities(result);
      })
      .catch((err) => console.log(err));
  };

  setTopCommunities = (topCommunities) => {
    this.setState({
      topCommunities: topCommunities,
      loader: false,
    });
  };

  clearSearchInput = () => {
    this.setState({ groupName: "" });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let communityType, categoryId, groupName;

    switch (name) {
      case "communityType":
        communityType = value;
        break;
      case "categoryId":
        categoryId = value;
        break;
      case "groupName":
        groupName = value;
        break;
    }
    this.setState({ [name]: value });

    if (name === "groupName") {
      setTimeout(() => {
        this.loadTopCommunities(communityType, categoryId, groupName);
      }, 500);
    } else {
      this.loadTopCommunities(communityType, categoryId, groupName);
    }
  };

  redirectToCommunityHome = (groupId) => {
    let pathName = constant.communityHome + groupId;
    routeService.routePage(this.props, pathName);
  };

  sendEmailToAdmin = (
    groupId,
    groupName,
    logoUrl,
    userEmail,
    communityOwner
  ) => {
    const originName = window.location.origin.replace('www.', '');
    const loginUrl = `${originName}/#`;
    let subject = "{user_name} has requested to join {community_name}";
    subject = subject
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", groupName);
    const htmlUserToAdmin = this.state.htmlUserToAdmin
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", groupName)
      .replace("{community_icon_url}", logoUrl)
      .replaceAll("{domain_name}", loginUrl)
      .replaceAll("{group_id}", Number(groupId))
      .replace("{logo_url}", this.state.defaultCommunity.LogoUrl.replace('{device_type}', 'desktop'))
      .replace("{team_name}", this.state.defaultCommunity.GroupName)
      .replace("{email_address}", userEmail);

    const rowData = {
      HtmlBody: htmlUserToAdmin,
      Subject: subject,
      ToAddress: communityOwner,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmailToUser = (groupName, userEmail) => {
    const originName = window.location.origin.replace('www.', '');
    let subject = "{user_name} has requested to join {community_name}";
    subject = subject
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", groupName);
    const htmlUserToUser = this.state.htmlUserToUser
      .replace("{user_name}", this.props.userAliasName)
      .replace("{logo_url}", this.state.defaultCommunity.LogoUrl.replace('{device_type}', 'desktop'))
      .replace("{team_name}", this.state.defaultCommunity.GroupName)
      .replace("{community_name}", groupName);

    const rowData = {
      HtmlBody: htmlUserToUser,
      Subject: subject,
      ToAddress: userEmail,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmail = (rowData) => {
    axios({
      method: "post",
      url: api.partialURL + "EmailService/SendEmail",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data === "error") {
            //log information in database table
          }
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response });
      });
  };

  saveWebNotification = (communityOwnerId, groupId) => {
    let rowData = {
      UserId: this.props.userEmail,
      CommunityOwner: communityOwnerId,
      GroupId: Number(groupId),
      Status: "User Requested",
      NotificationType: "Community",
      Env: api.env,
    };
    create("CommunityNotification/SaveCommunityNotification", rowData)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  joinCommunityGroup = (
    groupId,
    communityType,
    userGroupStatus,
    groupName,
    logoUrl,
    communityOwner,
    userEmail,
    communityOwnerId
  ) => {
    if (userGroupStatus === "Member") {
      this.setState({ openConfirmDialog: true, groupIdForRemove: groupId });
    } else {
      if (this.props.userEmail && this.props.userAliasName) {
        this.setState({ loader: true });
        let rowData = {};
        rowData.GroupId = Number(groupId);
        rowData.SubscriptionId = 2;
        rowData.ExpiryDate = "8888-12-31";
        rowData.Status =
          communityType === "private" && userGroupStatus === ""
            ? "User Requested"
            : "Approved";
        rowData.CreatedUserId = this.props.userEmail;
        rowData.Env = api.env;
        rowData.CommunityType = communityType;

        axios({
          method: "post",
          url: api.partialURL + "Community/JoinCommunity",
          data: JSON.stringify(rowData),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        })
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              if (res.data === "success") {
                if (communityType === "private" && userGroupStatus === "") {
                  this.setState({
                    openInfo: true,
                    textMsg: "Your request is successfully sent to the admin !",
                  });

                  this.sendEmailToAdmin(
                    groupId,
                    groupName,
                    logoUrl,
                    userEmail,
                    communityOwner
                  );
                  this.sendEmailToUser(groupName, userEmail);
                  this.saveWebNotification(communityOwnerId, groupId);
                }
                this.loadTopCommunities();
              } else {
                this.setState({ err_msg: "Failed to join community group" });
              }
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });
      } else if (!this.props.userEmail || !this.props.userAliasName) {
        loginService.redirectToLogin(this.props);
      }
    }
  };

  handleConfirmDialogClose = () => {
    this.setState({ openConfirmDialog: false });
  };

  handleDelete = () => {
    this.removeUser(this.props.userEmail, this.state.groupIdForRemove);
    sessionStorage.setItem("add_question_page_2", "delete_my_account");
    sessionStorage.setItem("home_page_action", "exit_from_community");
  };

  removeUser = (userId, groupId) => {
    let input = Number(groupId) + "/" + userId + "/" + api.env;
    remove("ManageUsers/DeleteUsers", input)
      .then((response) => {
        if (response.StatusCode === 200) {
          this.loadTopCommunities();
          this.handleConfirmDialogClose();
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const col3 = mediaQuery ? 3 : 12;
    const col6 = mediaQuery ? 6 : 12;
    const col4 = mediaQuery ? 4 : 12;
    const searchInput = classNames(
      classes.inputInput,
      !mediaQuery ? classes.inputSize : null
    );
    let categories = this.state.categories.map((category) => (
      <MenuItem value={category.CategoryId}>{category.CategoryName}</MenuItem>
    ));
    const subTitleWithIcon = classNames(
      classes.subHeader,
      classes.alignTextWithIcon2
    );

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <ConfirmationDialog
          open={this.state.openConfirmDialog}
          handleClose={this.handleConfirmDialogClose}
          titleText={"Exit from community?"}
          contentText1={
            "This action will allow you to exit from community. We cannot recover this later."
          }
          contentText2={"from this community?"}
          contentActionText={"exit"}
          actionText={"Yes, exit"}
          handleDelete={this.handleDelete}
        ></ConfirmationDialog>

        <Typography
          className={classes.title}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          Top Communities
        </Typography>

        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 20 }}>
          <Grid item xs={col3}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">
                Community Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.communityType}
                label="Community Type"
                onChange={this.handleChange}
                style={{ backgroundColor: "white" }}
                name="communityType"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={col3}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.categoryId}
                label="Category"
                onChange={this.handleChange}
                style={{ backgroundColor: "white" }}
                name="categoryId"
              >
                <MenuItem value="0">All</MenuItem>
                {categories}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={col6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={this.handleChange}
                fullWidth
                name="groupName"
                placeholder="Search community or category"
                value={this.state.groupName}
                style={{
                  position: "initial",
                  width: !mediaQuery ? "100%" : null,
                }}
                classes={{
                  root: classes.inputRoot,
                  input: searchInput,
                }}
                inputProps={{
                  "aria-label": "search",
                  style: {
                    fontSize: 14,
                    fontFamily: "poppins",
                    height: 18,
                    backgroundColor: "white",
                  },
                }}
                endAdornment={
                  <InputAdornment position="start">
                    {this.state.groupName && (
                      <CloseIcon
                        style={{
                          color: "#A19B9B",
                          cursor: "pointer",
                          marginLeft: 1,
                        }}
                        onClick={this.clearSearchInput}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {this.state.topCommunities.map((group, row) => (
            <Grid item xs={col4}>
              <Card>
                <CardContent style={{ height: 135 }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.redirectToCommunityHome(group.GroupId)
                      }
                    >
                      <Typography className={subTitleWithIcon}>
                        <img
                          src={group.LogoUrl}
                          alt="img"
                          style={{
                            width: imageSizes.logo_icon.width,
                            height: imageSizes.logo_icon.height,
                          }}
                          className={classes.leftIcon}
                        />
                        <Typography className={classes.topCommunityTitle}>
                          {group.GroupName +
                            ", " +
                            group.MembersCount +
                            " Members"}
                        </Typography>
                      </Typography>
                      <Typography className="text-ellipsis likes-comments" style={{marginTop: 10}}>
                        {group.GroupDesc}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.cardAction}>
                  <Button
                    variant="contained"
                    disabled={
                      group.UserGroupStatus === "" ||
                      group.UserGroupStatus === "Accept" ||
                      group.UserGroupStatus === "Member"
                        ? false
                        : true
                    }
                    className={
                      group.UserGroupStatus === "Owner" ||
                      group.UserGroupStatus === "Member"
                        ? classes.buttonOwnerMember
                        : group.UserGroupStatus === "Requested" ||
                          group.UserGroupStatus === "Accept"
                        ? classes.buttonAcceptRequested
                        : classes.buttonJoinDenied
                    }
                    size="small"
                    onClick={() =>
                      this.joinCommunityGroup(
                        group.GroupId,
                        group.CommunityType,
                        group.UserGroupStatus,
                        group.GroupName,
                        group.LogoUrl,
                        group.CommunityOwner,
                        group.UserEmail,
                        group.CommunityOwnerId
                      )
                    }
                  >
                    <img
                      className={classes.leftIcon}
                      alt="member"
                      src={
                        group.UserGroupStatus === "Member"
                          ? MemberIcon
                          : group.UserGroupStatus === "Owner"
                          ? OwnerIcon
                          : group.UserGroupStatus === "Requested"
                          ? RequestedIcon
                          : group.UserGroupStatus === "Accept"
                          ? AcceptedIcon
                          : group.UserGroupStatus === "Denied"
                          ? DeniedIcon
                          : JoinIcon
                      }
                    />
                    {group.UserGroupStatus === ""
                      ? "Join"
                      : group.UserGroupStatus}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(TopCommunities))
);
