import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Typography,
  Backdrop,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  Checkbox,
  Card,
  CardContent,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import api from "../../components/common/APIValues";
import PersonIcon from "@material-ui/icons/Person";
import classNames from "classnames";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import { connect } from "react-redux";
import axios from "axios";
import CreateRole from "../modal/CreateRole";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class ManagePermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      roles: [],
      permissions: [],
      roleId: 2,
      roleType: "M",
      roleName: "Community Admin",
      roleIdErr: "",
      permissionSelects: [],
      showCreateRole: false,
      editedRoleName: "",
      editedRoleId: 0,
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }

    let groupId = this.props.match.params.groupId;
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadRoles(groupId);
    this.loadPermissions(groupId, this.state.roleId);
  }

  loadRoles = (groupId) => {
    const originName = window.location.origin.replace('www.', '');
    this.setState({ loader: true });
    fetch(
      api.partialURL + "Permission/GetRoles?ENV=" + api.env +
        "&GroupId=" + groupId +
        "&UserId=" + this.props.userEmail +
        '&originName=' + originName
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          roles: result.roles,
          loader: false,
        });
        if (result.community.UserGroupStatus !== "Owner") {
          routeService.routePage(this.props, constant.home);
        }
      })
      .catch((err) => console.log(err));
  };

  loadPermissions = (groupId, roleId) => {
    const originName = window.location.origin.replace('www.', '');
    fetch(
      api.partialURL + "Permission/GetPermissions?GroupId=" + groupId +
        "&RoleId=" + roleId +
        "&ENV=" + api.env +
        '&originName=' + originName
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          permissions: result,
        });
      })
      .catch((err) => console.log(err));
  };

  validateInputs() {
    if (this.state.roleId <= 0) {
      return false;
    } else {
      return true;
    }
  }

  createNewRole = () => {
    this.setState({
      showCreateRole: true,
      editedRoleName: "",
      editedRoleId: 0,
    });
  };

  saveChanges = () => {
    if (this.state.roleIdErr === "" && this.validateInputs()) {
      this.setState({ loader: true });
      let rowData = {
        GroupId: Number(this.props.match.params.groupId),
        RoleId: this.state.roleId,
        Permissions: this.state.permissions,
        CreatedUserId: this.props.userEmail,
        Env: api.env,
      };

      axios({
        method: "post",
        url: api.partialURL + "Permission/SavePermissions",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            if (res.data.result === "success") {
              this.setState({
                openInfo: true,
                textMsg: "Your permissions are saved successfully !",
              });
            } else {
              this.setState({ err_msg: "Failed to save permissions" });
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });
    } else {
      this.setState({ userEmailError: "Please select role" });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let roleVals = name.split("_");
    this.setState({
      roleId: Number(value),
      roleType: roleVals[0],
      roleName: roleVals[1],
    });
    this.loadPermissions(
      Number(this.props.match.params.groupId),
      Number(value)
    );
  };

  handlePermissionChange = (event, index) => {
    let value = Number(event.target.value);
    let permissions = this.state.permissions;

    this.state.permissions.map((data) => {
      if (value === data.PermissionId) {
        permissions[index].IsChecked = event.target.checked;
      }
    });
    this.setState({ permissions });
  };

  onCreateNewRole = (role) => {
    this.setState({
      roleId: role.RoleId,
      roleName: role.RoleName,
      roleType: role.RoleType,
      showCreateRole: false,
    });
    this.loadRoles(Number(this.props.match.params.groupId));
    this.loadPermissions(
      Number(this.props.match.params.groupId),
      Number(role.RoleId)
    );
  };

  handleRoleClose = () => {
    this.setState({ showCreateRole: false });
  };

  editRole = () => {
    this.setState({
      showCreateRole: true,
      editedRoleName: this.state.roleName,
      editedRoleId: this.state.roleId,
    });
  };

  deleteRole = () => {
    let rowData = {
      RoleId: this.state.roleId,
      Env: api.env,
    };

    axios({
      method: "post",
      url: api.partialURL + "Role/DeleteRole",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data === "success") {
            this.setState({ roleId: 2, roleType: "M" });
            this.loadRoles(Number(this.props.match.params.groupId));
            this.loadPermissions(Number(this.props.match.params.groupId), 2);
          } else {
            //setRoleNameErr('Failed to create role name');
          }
        }
      })
      .catch(function (response) {
        //setRoleNameErr(response);
      });
  };

  handleRoleChange = (event) => {
    this.setState({
      editedRoleName: event.target.value,
    });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subTitleWithIcon = classNames(
      classes.subTitle,
      classes.alignTextWithIcon
    );
    const col3 = mediaQuery ? 3 : 6;
    const col4 = mediaQuery ? 4 : 12;
    const col8 = mediaQuery ? 8 : 6;
    const col9 = mediaQuery ? 9 : 12;
    const col1 = mediaQuery ? 1 : 12;
    const col33 = mediaQuery ? 3 : 12;
    const space3 = mediaQuery ? 3 : 2;

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <CreateRole
          showCreateRole={this.state.showCreateRole}
          handleRoleClose={this.handleRoleClose}
          onCreateNewRole={this.onCreateNewRole}
          handleRoleChange={this.handleRoleChange}
          roleName={this.state.editedRoleName}
          roleId={this.state.editedRoleId}
          userId={this.props.userEmail}
          groupId={this.props.match.params.groupId}
        ></CreateRole>

        <Grid
          container
          spacing={0}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>
              Manage Permissions
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: "10px" }}>
          <Grid item xs={col3}>
            <Typography className={subTitleWithIcon}>
              <PersonIcon className={classes.leftIcon} />
              <Typography>Community Admin</Typography>
            </Typography>
          </Grid>
          <Grid item xs={col8}>
            {this.state.roleType === "C" ? (
              <Typography
                className={subTitleWithIcon}
                style={{ float: !mediaQuery ? "right" : null }}
              >
                <Typography style={{ color: "#c2272d" }}>
                  {this.state.roleName}
                </Typography>
                <EditIcon
                  className={classes.rightIcon}
                  onClick={this.editRole}
                />
                <DeleteIcon
                  className={classes.rightIcon}
                  onClick={this.deleteRole}
                />
              </Typography>
            ) : (
              mediaQuery &&
              this.state.roleName === "Community Admin" && (
                <Typography
                  style={{ color: "#c2272d", fontSize: 14, marginLeft: 5 }}
                >
                  You cannot edit permissions for default roles.
                </Typography>
              )
            )}
          </Grid>
          {mediaQuery && (
            <Grid item xs={col1}>
              <Typography>Permissions</Typography>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={space3} style={{ marginTop: "10px" }}>
          <Grid item xs={col33}>
            <Card style={{ borderRadius: 10 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="roleId"
                        value={this.state.roleId}
                        onChange={this.handleChange}
                      >
                        {this.state.roles &&
                          this.state.roles.map((role, row) => (
                            <FormControlLabel
                              value={role.RoleId}
                              name={role.RoleType + "_" + role.RoleName}
                              control={<Radio color="primary" />}
                              label={
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontFamily: "poppins",
                                  }}
                                >
                                  {role.RoleName}
                                </span>
                              }
                              style={{ marginTop: row === 0 ? 0 : 10 }}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {!mediaQuery && (
            <Grid item xs={12}>
              <Typography>Permissions</Typography>
              {this.state.roleType === "M" &&
                this.state.roleName === "Community Admin" && (
                  <Typography style={{ color: "#c2272d", fontSize: 13 }}>
                    (You cannot edit permissions for default roles.)
                  </Typography>
                )}
            </Grid>
          )}
          <Grid item xs={col9}>
            <Card style={{ borderRadius: 10 }}>
              <CardContent>
                <Grid container spacing={2}>
                  {this.state.permissions &&
                    this.state.permissions.map((permission, row) => (
                      <Grid item xs={col4}>
                        <FormControlLabel
                          value={permission.PermissionId}
                          name="permissionId"
                          control={
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                this.handlePermissionChange(event, row)
                              }
                              checked={permission.IsChecked}
                              disabled={
                                this.state.roleType === "M" &&
                                this.state.roleName === "Community Admin"
                                  ? true
                                  : false
                              }
                            />
                          }
                          label={
                            <span
                              style={{ fontSize: 13, fontFamily: "poppins" }}
                            >
                              {permission.PermissionName}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.customButtonError}
              onClick={this.createNewRole}
              size="small"
            >
              Create New Role
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              className={
                this.state.roleType === "M" &&
                this.state.roleName === "Community Admin"
                  ? null
                  : classes.customButtonFocus
              }
              onClick={this.saveChanges}
              size="small"
              style={{ float: "right" }}
              disabled={
                this.state.roleType === "M" &&
                this.state.roleName === "Community Admin"
                  ? true
                  : false
              }
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(
      connect(mapStateToProps)(ManagePermissions)
    )
  )
);
