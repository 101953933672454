import React, { Component } from 'react';
import { 
    DialogContent, IconButton, Grid, Dialog, useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class ViewImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { mediaQuery } = this.props;

        return (
            <React.Fragment>
                <Dialog fullWidth open={this.props.open}
                    onClose={this.props.handleViewImgClose} disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={ mediaQuery ? 11 : 10 }>
                            </Grid>
                            <Grid item xs={ mediaQuery ? 1 : 2 }>
                                <IconButton style={{ marginLeft: '22px', marginTop: '-20px' }} onClick={this.props.handleViewImgClose} 
                                    aria-label="settings">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <img src={this.props.preViewImage} style={{width: '100%', padding: '10px 10px 10px 10px'}} 
                                    alt="pre-view image" />
                            </Grid>
                        </Grid>                        
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withRouter(withMediaQuery('(min-width:600px)')(ViewImage))