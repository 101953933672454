import React, { useState, useEffect } from 'react';
import { DialogContent, IconButton, Grid, Dialog, TextField, DialogActions, Button, 
    useMediaQuery, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import api from '../../components/common/APIValues';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    customButtonFocus: {
        borderRadius: '8px',
        color: 'white',
        backgroundColor: "#c2272d",
        "&:hover": {
            backgroundColor: "#c2272d"
        },
        "&:disabled": {
            backgroundColor: "#c2272d",
            color: 'white',
        },
        fontFamily: 'poppins',
        fontWeight: 700,
        textTransform: 'none'
    },
}));

export default function CreateRole(props) {
    const [role_name_err, setRoleNameErr] = useState('');
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();

    const createRole = () => {
        if(validateAllInputs()) {
            let rowData = {};
            rowData.GroupId = Number(props.groupId);
            rowData.RoleId = props.roleId;
            rowData.RoleName = props.roleName;
            rowData.RoleType = 'C';
            rowData.CreatedUserId = props.userId;
            rowData.Env = api.env;

            axios({
                method: 'post',
                url: api.partialURL + 'Role/CreateNewRole',
                data: JSON.stringify(rowData),
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
            })
            .then(res => {
                if(res) {
                    if (res.data.result === 'success' && res.data.roleId > 0) {
                        let role = {
                            RoleId: res.data.roleId,
                            RoleName: props.roleName,
                            RoleType: 'C'
                        }
                        props.onCreateNewRole(role);
                    } else if (res.data.result === 'error') {
                        setRoleNameErr('Role name is already exists');
                    } else {
                        setRoleNameErr('Failed to create role name');
                    } 
                }
            })
            .catch(function (response) {
                setRoleNameErr(response);
            });
        } else {
            if (!props.roleName) {
                setRoleNameErr('Please enter role name');
            }
        }
    }

    const validateAllInputs = () => {
        if(!props.roleName) {
            return false; 
        }
        else{ return true; }
    }

    return (
        <React.Fragment>
            <Dialog fullWidth open={props.showCreateRole}
                onClose={props.handleRoleClose} disableBackdropClick
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={ matches ? 11 : 9 }></Grid>
                        <Grid item xs={ matches ? 1 : 3 }>
                            <IconButton style={{ float: "right", marginTop: '-20px' }} 
                                onClick={props.handleRoleClose} 
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <TextField fullWidth name="roleName" label="Role Name" required size="small"
                        onChange={props.handleRoleChange} noValidate value={props.roleName}
                        variant="outlined" style={{ backgroundColor: 'white' }} />                        
                    { role_name_err.length > 0 && <span className='error'>{role_name_err}</span> }
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#f6f7f2' }}>
                    <Button onClick={createRole} color="primary" className={classes.customButtonFocus} size="small">
                        { props.roleId > 0 ? 'Update Role' : 'Create Role' }
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
