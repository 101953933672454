import React, { useState } from "react";
import {
  DialogContent,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  Button,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../components/common/APIValues";
import axios from "axios";
import CommonFunc from '../../components/common/CommonFunc';

const useStyles = makeStyles((theme) => ({
  customButtonFocus: {
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#c2272d",
    "&:hover": {
      backgroundColor: "#c2272d",
    },
    "&:disabled": {
      backgroundColor: "#c2272d",
      color: "white",
    },
    fontFamily: "poppins",
    fontWeight: 700,
    textTransform: "none",
  },
  customButtonError: {
    borderRadius: "8px",
    color: "#c2272d",
    backgroundColor: "#f9f9f9",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#c2272d",
    },
    fontFamily: "poppins",
    fontWeight: 700,
    textTransform: "none",
  },
}));

export default function UploadImage(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [fileName, setFileName] = useState(null);
  const [fileBase64String, setFileBase64String] = useState(null);
  const [file_name_err, setFileNameErr] = useState("");
  const [preViewImage, setPreviewImage] = useState(null);
  const classes = useStyles();
  const size =
    props.type === "cover_pic"
      ? "height - 150px | width - 600px"
      : "height - 24px | width - 24px";

  const saveChanges = () => {
    if (validateAllInputs() && !file_name_err) {
      const origin = window.location.origin.replace('www.', '').replace('https://', '').replace('http://', '');
      let rowData = {
        DirName: "community/" + props.type + "/" + props.groupName,
        FileName: fileName,
        OriginName: origin,
        FileBase64String: fileBase64String,
      };

      axios({
        method: "post",
        url: api.partialURL + "UploadFileS3/UploadFile",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            if (res.data.imageUrl !== "") {
              setFileName(null);
              setFileBase64String(null);
              setPreviewImage(null);
              props.onFileSaveChange(props.type, res.data.imageUrl);
            } else {
              setFileNameErr(res.data.errorMessage);
            }
          }
        })
        .catch(function (response) {
          setFileNameErr(response);
        });
    } else {
      if (!fileName) {
        setFileNameErr("Please upload image file");
      }
    }
  };

  const validateAllInputs = () => {
    if (!fileName) {
      return false;
    } else {
      return true;
    }
  };

  const handleModalClose = () => {
    setFileNameErr("");
    setFileName(null);
    setFileBase64String(null);
    setPreviewImage(null);
    props.handleModalClose();
  };

  const onFileUploadChange = (event) => {
    var file = event.target.files[0];
    if (file) {
      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setFileNameErr("Image format is not valid.");
      } else {
        setPreviewImage(URL.createObjectURL(file));
        setFileName(file.name);
        setFileNameErr("");

        var blob = file.slice();
        var reader = new FileReader();

        reader.onloadend = function (evt) {
            if (evt.target.readyState === FileReader.DONE) {
                var cont = evt.target.result
                var base64String = CommonFunc.getB64Str(cont);                
                setFileName(file.name);
                setFileBase64String(base64String);
            }
        }.bind(this);

        reader.readAsArrayBuffer(blob);
      }
    } else {
      setFileName(null);
      setFileBase64String(null);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={props.show}
        onClose={handleModalClose}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ backgroundColor: "#f6f7f2" }}>
          <Grid container spacing={0}>
            <Grid item xs={matches ? 11 : 9}></Grid>
            <Grid item xs={matches ? 1 : 3}>
              <IconButton
                style={{ float: "right", marginTop: "-20px" }}
                onClick={handleModalClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <label htmlFor="topic_image">
            <input
              style={{ display: "none" }}
              id="topic_image"
              name="topic_image"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif"
              onChange={(e) => {
                onFileUploadChange(e);
              }}
            />
            <Button
              className={classes.customButtonError}
              variant="contained"
              component="span"
              size="small"
            >
              {props.type === "cover_pic"
                ? "Choose Cover Pic"
                : "Choose Logo or Icon"}
            </Button>
          </label>
          <label style={{ marginLeft: 10 }}>{fileName}</label>
          <div style={{ fontSize: 12, marginTop: 10 }}>
            <b>Formats allowed:</b> jpg | jpeg | png | gif, <b>Size:</b> {size}
          </div>
          {preViewImage && (
            <div style={{ marginTop: 10 }}>
              {/* <ImageCropper
                            imageToCrop={imageToCrop}
                            fileName={fileName}
                            imageSize={props.imageSize}
                            onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                        /> */}
              <img
                src={preViewImage}
                style={{ 
                  width: props.type === "cover_pic" ? (matches ? props.imageSize.width : '100%') : props.imageSize.width, 
                  height: props.type === "cover_pic" ? (matches ? props.imageSize.height : '100%') : props.imageSize.height, 
                }}
                alt="previewimage"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#f6f7f2" }}>
          {file_name_err.length > 0 && (
            <span className="error">{file_name_err}</span>
          )}
          <Button
            onClick={saveChanges}
            color="primary"
            className={classes.customButtonFocus}
            size="small"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
