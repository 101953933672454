import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Typography,
  Backdrop,
  useMediaQuery,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import api from "../../components/common/APIValues";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import { connect } from "react-redux";
import axios from "axios";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";
import { create, get } from "../../services/ApiService";
import "./InviteUser.css";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class InviteNewUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupId: "",
      err_msg: "",
      loader: false,
      selectedUsers: [],
      htmlInviteUser: "",
      value: "",
      error: null,
      defaultCommunity: {},
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getDefaultCommunityDetails();
  }

  getHtmlText = (s3BaseUrl) => {
    const htmlInviteUserUrl = `${s3BaseUrl}/email-template/InviteUser.txt`;
    
    fetch(htmlInviteUserUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlInviteUser: text });
      });
  }

  getDefaultCommunityDetails = () => {
    this.setState({ loader: true });
    const originName = window.location.origin.replace('www.', '');
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then(
      (response) => {
        this.setState({
          defaultCommunity: response,
          loader: false,
        });

        const s3BaseUrl = response.S3ConfigUrl.replace('/configuration/config.json', '');
        this.getHtmlText(s3BaseUrl);
      }
    );
  };

  validateInputs() {
    if (this.state.selectedUsers.length === 0) {
      this.setState({ error: "Please enter at least one user" });
      return false;
    } else if (this.state.selectedUsers.length > 3) {
      this.setState({ error: "You can invite maximum 50 users at a time." });
      return false;
    } else {
      return true;
    }
  }

  inviteUsers = () => {
    if (this.state.error === null && this.validateInputs()) {
      this.setState({ loader: true });
      const originName = window.location.origin.replace('www.', '');
      let rowData = {
        GroupId: Number(this.props.match.params.groupId),
        UserEmails: this.state.selectedUsers,
        CreatedUserId: this.props.userEmail,
        Env: api.env,
        OriginName: originName,
      };
      axios({
        method: "post",
        url: api.partialURL + "InviteUsers/InviteNewUsers",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            if (res.data === "success") {
              this.sendEmailToUser();
              let pathName = constant.manageUsers + this.props.match.params.groupId;
              routeService.routePage(this.props, pathName);
            } else if (res.data === "error") {
              this.setState({
                err_msg:
                  "These user(s) are already invited/joined to this community",
              });
            } else {
              this.setState({ err_msg: "Failed to invite user(s)" });
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });
    }
  };

  sendEmailToUser = () => {
    const originName = window.location.origin.replace('www.', '');
    const loginUrl = `${originName}/#`;
    const inputData = this.props.history.location.inputData;
    let toAddresses = "";
    this.state.selectedUsers.map((user) => {
      toAddresses += user + ",";
    });
    let subject = "Invitation to join {community_name}";
    subject = subject.replace("{community_name}", inputData.groupName);
    const htmlInviteUser = this.state.htmlInviteUser
      .replaceAll("{community_name}", inputData.groupName)
      .replaceAll("{domain_name}", loginUrl)
      .replaceAll("{group_id}", this.props.match.params.groupId)
      .replace("{logo_url}", this.state.defaultCommunity.LogoUrl.replace('{device_type}', 'desktop'))
      .replace("{team_name}", this.state.defaultCommunity.GroupName);

    const rowData = {
      HtmlBody: htmlInviteUser,
      Subject: subject,
      ToAddress: toAddresses,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmail = (rowData) => {
    create("EmailService/SendEmail", rowData)
      .then((response) => {
        if (response) {
          if (response.data === "error") {
            //log information in database table
          }
        }
      })
      .catch(function (error) {
        this.setState({ err_msg: error });
      });
  };

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let value = this.state.value.trim();

      if (value && this.isValid(value)) {
        this.setState({
          selectedUsers: [...this.state.selectedUsers, this.state.value],
          value: "",
          error: null,
        });
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
    });
  };

  handleDelete = (item) => {
    this.setState({
      selectedUsers: this.state.selectedUsers.filter((i) => i !== item),
      error: null,
    });
  };

  handlePaste = (evt) => {
    evt.preventDefault();
    let paste = evt.clipboardData.getData("text");
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !this.isInList(email));
      this.setState({
        selectedUsers: [...this.state.selectedUsers, ...toBeAdded],
        error: null,
      });
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      this.setState({ error });
      return false;
    }
    return true;
  }

  isInList(email) {
    return this.state.selectedUsers.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  render() {
    const { classes, mediaQuery } = this.props;
    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <Grid
          container
          spacing={0}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>Invite Users</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {this.state.selectedUsers.map((item) => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => this.handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            ))}

            <input
              className={"input " + (this.state.error && " has-error")}
              value={this.state.value}
              placeholder="Type or paste email addresses and press `Enter` or `Tab`"
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              onPaste={this.handlePaste}
            />

            {this.state.error && <p className="error">{this.state.error}</p>}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.customButtonFocus}
              onClick={this.inviteUsers}
              size="small"
              style={{ float: "right" }}
            >
              Invite Users
            </Button>
          </Grid>
          <Grid item xs={12}>
            {this.state.err_msg.length > 0 && (
              <span className="error">{this.state.err_msg}</span>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(
      connect(mapStateToProps)(InviteNewUsers)
    )
  )
);
