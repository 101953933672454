import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Divider,
  useMediaQuery,
  Link,
  Tooltip,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import AddPhoto from "@material-ui/icons/AddPhotoAlternateOutlined";
import DefaultCoverPic from "../../components/images/CoverPic.jpg";
import PollIcon from "../../components/images/poll.png";
import LikeIcon from "../../components/images/like.png";
import VoteIcon from "../../components/images/votes_icon.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MessageIcon from "../../components/images/mesage.png";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import EventIcon from "@material-ui/icons/EventAvailableOutlined";
import GroupIcon from "@material-ui/icons/GroupOutlined";
import UploadImage from "../modal/UploadImage";
import ApplyColor from "../modal/ApplyColor";
import { connect } from "react-redux";
import api from "../../components/common/APIValues";
import axios from "axios";
import PremiumIcon from "@material-ui/icons/Stars";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";
import imageSizes from "../json/ImageSize.json";
import { searchByInputs } from "../../services/ApiService";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class PreviewCommunityTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpload: false,
      showApplyColor: false,
      uploadType: "",
      logoIconUrl: "",
      coverPicUrl: "",
      colorData: {},
      err_msg: "",
      imageSize: {},
      communityCategory: {},
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getCommunityCategoryById();
  }

  getCommunityCategoryById = () => {
    let categoryId = this.props.communityData.CategoryId;
    let inputs = categoryId + "/" + api.env;
    searchByInputs("CommunityCategory/GetCommunityCategoryById", inputs).then(
      (response) => {
        if (response) {
          this.setState({
            communityCategory: response,
          });
        }
      }
    );
  };

  uploadLogoIcon = () => {
    this.setState({
      showUpload: true,
      uploadType: "logo_icon",
      imageSize: {
        height: imageSizes.logo_icon.height,
        width: imageSizes.logo_icon.width,
      },
    });
  };

  uploadCoverPic = () => {
    this.setState({
      showUpload: true,
      uploadType: "cover_pic",
      imageSize: {
        height: imageSizes.cover_pic.height,
        width: imageSizes.cover_pic.width,
      },
    });
  };

  changeColor = () => {
    this.setState({ showApplyColor: true });
  };

  handleModalClose = () => {
    this.setState({ showUpload: false, uploadType: "" });
  };

  handleApplyClose = () => {
    this.setState({ showApplyColor: false });
  };

  createCommunity = () => {
    const originName = window.location.origin.replace('www.', '');
    let rowData = this.props.communityData;
    if (rowData.GroupName) {
      rowData.CoverUrl = this.state.coverPicUrl;
      rowData.LogoUrl = this.state.logoIconUrl;
      rowData.ColorThemeName = this.state.colorData.name
        ? this.state.colorData.name
        : "Default Color";
      rowData.SubscriptionId = 1;
      rowData.OriginName = originName;

      axios({
        method: "post",
        url: api.partialURL + "Community/CreateCommunity",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            if (res.data.result === "success" && res.data.groupId > 0) {
              let pathName = constant.communityHome + res.data.groupId;
              routeService.routePage(this.props, pathName);
            } else if (res.data.result === "error") {
              this.setState({
                err_msg: rowData.CommunityType + " community is already exists",
              });
            } else {
              this.setState({
                err_msg:
                  "Failed to create" + rowData.CommunityType + " community",
              });
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });
    } else {
      this.setState({
        err_msg:
          "One or more fields are missing to create public/private community",
      });
    }
  };

  handleFileSave = (type, imageUrl) => {
    if (type === "cover_pic") this.setState({ coverPicUrl: imageUrl });
    else this.setState({ logoIconUrl: imageUrl });
    this.setState({ showUpload: false, uploadType: "" });
  };

  handleApplyColor = (colorData) => {
    this.setState({ colorData: colorData, showApplyColor: false });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subTitleWithIcon = classNames(
      classes.subHeader,
      classes.alignTextWithIcon
    );
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const col3 = mediaQuery ? 3 : 12;
    const col9 = mediaQuery ? 9 : 12;
    const hashtags = ["#electroniccity", "#playschool", "#bengaluru"];

    return (
      <Fragment>
        <UploadImage
          show={this.state.showUpload}
          type={this.state.uploadType}
          handleModalClose={this.handleModalClose}
          onFileSaveChange={this.handleFileSave}
          groupName={this.props.communityData.GroupName}
          imageSize={this.state.imageSize}
        ></UploadImage>

        <ApplyColor
          showApplyColor={this.state.showApplyColor}
          handleApplyClose={this.handleApplyClose}
          onApplyColorTheme={this.handleApplyColor}
        ></ApplyColor>

        <Typography
          className={classes.title}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          {mediaQuery ? "Preview Community Template" : ""}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={col9}>
            {mediaQuery ? (
              <Card style={{ backgroundColor: "#F9F9F9" }}>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={col3}>
                      <Card style={{ margin: "10px", marginRight: "0px" }}>
                        <CardContent>
                          <Grid container spacing={1}>
                            <List>
                              <ListItem button className={classes.iconRedColor}>
                                <Tooltip title="Home">
                                  <HomeIcon
                                    className="drawerItems"
                                    style={{ color: "#c2272d" }}
                                  />
                                </Tooltip>
                                <ListItemText className="drawerItemsText">
                                  <span style={{ fontSize: 13 }}>Home</span>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                              <ListItem button>
                                <Tooltip title="About">
                                  <InfoIcon className="drawerItems" />
                                </Tooltip>
                                <ListItemText className="drawerItemsText">
                                  <span style={{ fontSize: 13 }}>About</span>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                              <ListItem button>
                                <Tooltip title="Events">
                                  <EventIcon className="drawerItems" />
                                </Tooltip>
                                <ListItemText className="drawerItemsText">
                                  <span style={{ fontSize: 13 }}>Events</span>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                              <ListItem button>
                                <Tooltip title="Premium">
                                  <PremiumIcon className="drawerItems" />
                                </Tooltip>
                                <ListItemText className="drawerItemsText">
                                  <span style={{ fontSize: 13 }}>Premium</span>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                              <ListItem button>
                                <Tooltip title="Members">
                                  <GroupIcon className="drawerItems" />
                                </Tooltip>
                                <ListItemText className="drawerItemsText">
                                  <span style={{ fontSize: 13 }}>Members</span>
                                </ListItemText>
                              </ListItem>
                            </List>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={col9}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} style={{ margin: "10px" }}>
                          <img
                            src={
                              this.state.coverPicUrl
                                ? this.state.coverPicUrl
                                : DefaultCoverPic
                            }
                            alt="img"
                            style={{
                              width: imageSizes.cover_pic.width,
                              height: imageSizes.cover_pic.height,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ margin: "10px" }}>
                          <Typography className={subTitleWithIcon}>
                            <img
                              src={
                                this.state.logoIconUrl
                                  ? this.state.logoIconUrl
                                  : this.state.communityCategory
                                      .DefaultLogoIconUrl
                              }
                              alt="img"
                              className={classes.leftIcon}
                              style={{
                                width: imageSizes.logo_icon.width,
                                height: imageSizes.logo_icon.height,
                              }}
                            />
                            <Typography className={classes.subHeaderSemiBold}>
                              {this.props.communityData.GroupName}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Card style={{ margin: "10px" }}>
                        <CardHeader
                          style={{ padding: "16px 16px 0px 16px" }}
                          classes={{
                            title: classes.title,
                            subheader: classes.subTitle,
                          }}
                          title={
                            <span style={{ color: this.state.colorData.title }}>
                              Need some info on good play schools in electronic
                              city phase-1 in Bengaluru
                            </span>
                          }
                          avatar={
                            <IconButton
                              aria-label="questions"
                              style={{ marginTop: -32, padding: 0 }}
                            >
                              <img src={PollIcon} height={20} alt="PollIcon" />
                            </IconButton>
                          }
                          subheader={
                            <span
                              style={{ color: this.state.colorData.subtitle }}
                            >
                              posted by manish 3w ago
                            </span>
                          }
                        />
                        <CardContent
                          style={{ marginLeft: 37, marginTop: "10px" }}
                        >
                          <div>
                            <Typography
                              className={subHeader}
                              gutterBottom
                              style={{ color: this.state.colorData.desc }}
                            >
                              Guys, I am looking for a good play school for my
                              niece (3 years) near electronic city phase-1. Any
                              suggestions would be great.
                            </Typography>
                            <Typography
                              className={classes.hashTag}
                              gutterBottom
                              style={{ marginTop: "10px" }}
                            >
                              {hashtags &&
                                hashtags.map((item, index) => (
                                  <Link
                                    style={{ marginLeft: index === 0 ? 0 : 10 }}
                                  >
                                    {item}
                                  </Link>
                                ))}
                            </Typography>
                          </div>
                          <Typography
                            style={{ marginTop: "10px" }}
                            className={wrapIcon}
                            color="textSecondary"
                          >
                            <IconButton
                              style={{
                                padding: 0,
                                fontSize: 13,
                                fontFamily: "poppins",
                                marginLeft: "0px",
                              }}
                              aria-label="vote"
                            >
                              <img
                                src={VoteIcon}
                                height={15}
                                style={{ marginRight: "3px" }}
                                alt="voteicon"
                              />
                              2300 Votes
                            </IconButton>
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                            <IconButton
                              style={{
                                padding: 0,
                                fontSize: 13,
                                fontFamily: "poppins",
                                marginLeft: "0px",
                              }}
                              aria-label="like"
                            >
                              <img
                                src={LikeIcon}
                                height={15}
                                style={{ marginRight: "3px" }}
                                alt="likeicon"
                              />
                              4600 Likes
                            </IconButton>
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                            <img
                              src={MessageIcon}
                              height={15}
                              style={{ marginRight: "3px" }}
                              alt="message"
                            />
                            500
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                            <VisibilityIcon
                              className={classes.iconSize}
                              style={{ marginRight: "3px" }}
                            />
                            <span
                              style={{ fontSize: 13, fontFamily: "poppins" }}
                            >
                              245000
                            </span>
                          </Typography>
                        </CardContent>
                      </Card>

                      <Card style={{ margin: "10px", marginTop: "20px" }}>
                        <CardHeader
                          style={{ padding: "16px 16px 0px 16px" }}
                          classes={{
                            title: classes.title,
                            subheader: classes.subTitle,
                          }}
                          title={
                            <span style={{ color: this.state.colorData.title }}>
                              Need some info on good play schools in electronic
                              city phase-1 in Bengaluru
                            </span>
                          }
                          avatar={
                            <IconButton
                              aria-label="questions"
                              style={{ marginTop: -32, padding: 0 }}
                            >
                              <img src={PollIcon} height={20} alt="PollIcon" />
                            </IconButton>
                          }
                          subheader={
                            <span
                              style={{ color: this.state.colorData.subtitle }}
                            >
                              posted by manish 3w ago
                            </span>
                          }
                        />
                        <CardContent
                          style={{ marginLeft: 37, marginTop: "10px" }}
                        >
                          <div>
                            <Typography
                              className={subHeader}
                              gutterBottom
                              style={{ color: this.state.colorData.desc }}
                            >
                              Guys, I am looking for a good play school for my
                              niece (3 years) near electronic city phase-1. Any
                              suggestions would be great.
                            </Typography>
                            <Typography
                              className={classes.hashTag}
                              gutterBottom
                              style={{ marginTop: "10px" }}
                            >
                              {hashtags &&
                                hashtags.map((item, index) => (
                                  <Link
                                    style={{ marginLeft: index === 0 ? 0 : 10 }}
                                  >
                                    {item}
                                  </Link>
                                ))}
                            </Typography>
                          </div>
                          <Typography
                            style={{ marginTop: "10px" }}
                            className={wrapIcon}
                            color="textSecondary"
                          >
                            <IconButton
                              style={{
                                padding: 0,
                                fontSize: 13,
                                fontFamily: "poppins",
                                marginLeft: "0px",
                              }}
                              aria-label="vote"
                            >
                              <img
                                src={VoteIcon}
                                height={15}
                                style={{ marginRight: "3px" }}
                                alt="voteicon"
                              />
                              2300 Votes
                            </IconButton>
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                            <IconButton
                              style={{
                                padding: 0,
                                fontSize: 13,
                                fontFamily: "poppins",
                                marginLeft: "0px",
                              }}
                              aria-label="like"
                            >
                              <img
                                src={LikeIcon}
                                height={15}
                                style={{ marginRight: "3px" }}
                                alt="likeicon"
                              />
                              4600 Likes
                            </IconButton>
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                            <img
                              src={MessageIcon}
                              height={15}
                              style={{ marginRight: "3px" }}
                              alt="message"
                            />
                            500
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.marginLeftRight}
                            />
                            <VisibilityIcon
                              className={classes.iconSize}
                              style={{ marginRight: "3px" }}
                            />
                            <span
                              style={{ fontSize: 13, fontFamily: "poppins" }}
                            >
                              245000
                            </span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Grid container spacing={0}>
                <Grid item xs={col9}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <img
                        src={
                          this.state.coverPicUrl
                            ? this.state.coverPicUrl
                            : DefaultCoverPic
                        }
                        alt="img"
                        style={{
                          width: '100%'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Typography className={subTitleWithIcon}>
                        <img
                          src={
                            this.state.logoIconUrl
                              ? this.state.logoIconUrl
                              : this.state.communityCategory.DefaultLogoIconUrl
                          }
                          alt="img"
                          className={classes.leftIcon}
                          style={{
                            width: imageSizes.logo_icon.width,
                            height: imageSizes.logo_icon.height,
                          }}
                        />
                        <Typography className={classes.subHeaderSemiBold}>
                          {this.props.communityData.GroupName}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Card style={{ marginTop: "10px" }}>
                    <CardHeader
                      style={{ padding: "16px 16px 0px 16px" }}
                      classes={{
                        title: classes.title,
                        subheader: classes.subTitle,
                      }}
                      title={
                        <span style={{ color: this.state.colorData.title }}>
                          Need some info on good play schools in electronic city
                          phase-1 in Bengaluru
                        </span>
                      }
                      avatar={
                        <IconButton
                          aria-label="questions"
                          style={{ marginTop: -32, padding: 0 }}
                        >
                          <img src={PollIcon} height={20} alt="PollIcon" />
                        </IconButton>
                      }
                      subheader={
                        <span style={{ color: this.state.colorData.subtitle }}>
                          posted by manish 3w ago
                        </span>
                      }
                    />
                    <CardContent style={{ marginLeft: 37, marginTop: "10px" }}>
                      <div>
                        <Typography
                          className={subHeader}
                          gutterBottom
                          style={{ color: this.state.colorData.desc }}
                        >
                          Guys, I am looking for a good play school for my niece
                          (3 years) near electronic city phase-1. Any
                          suggestions would be great.
                        </Typography>
                        <Typography
                          className={classes.hashTag}
                          gutterBottom
                          style={{ marginTop: "10px" }}
                        >
                          {hashtags &&
                            hashtags.map((item, index) => (
                              <Link
                                style={{ marginLeft: index === 0 ? 0 : 10 }}
                              >
                                {item}
                              </Link>
                            ))}
                        </Typography>
                      </div>
                      <Typography
                        style={{ marginTop: "10px" }}
                        className={wrapIcon}
                        color="textSecondary"
                      >
                        <IconButton
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "0px",
                          }}
                          aria-label="vote"
                        >
                          <img
                            src={VoteIcon}
                            height={15}
                            style={{ marginRight: "3px" }}
                            alt="voteicon"
                          />
                          2300
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          flexItem
                          className={classes.marginLeftRight}
                        />
                        <IconButton
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "0px",
                          }}
                          aria-label="like"
                        >
                          <img
                            src={LikeIcon}
                            height={15}
                            style={{ marginRight: "3px" }}
                            alt="likeicon"
                          />
                          4600
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          flexItem
                          className={classes.marginLeftRight}
                        />
                        <img
                          src={MessageIcon}
                          height={15}
                          style={{ marginRight: "3px" }}
                          alt="message"
                        />
                        500
                        <Divider
                          orientation="vertical"
                          flexItem
                          className={classes.marginLeftRight}
                        />
                        <VisibilityIcon
                          className={classes.iconSize}
                          style={{ marginRight: "3px" }}
                        />
                        <span style={{ fontSize: 13, fontFamily: "poppins" }}>
                          245
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card style={{ marginTop: "20px" }}>
                    <CardHeader
                      style={{ padding: "16px 16px 0px 16px" }}
                      classes={{
                        title: classes.title,
                        subheader: classes.subTitle,
                      }}
                      title={
                        <span style={{ color: this.state.colorData.title }}>
                          Need some info on good play schools in electronic city
                          phase-1 in Bengaluru
                        </span>
                      }
                      avatar={
                        <IconButton
                          aria-label="questions"
                          style={{ marginTop: -32, padding: 0 }}
                        >
                          <img src={PollIcon} height={20} alt="PollIcon" />
                        </IconButton>
                      }
                      subheader={
                        <span style={{ color: this.state.colorData.subtitle }}>
                          posted by manish 3w ago
                        </span>
                      }
                    />
                    <CardContent style={{ marginLeft: 37, marginTop: "10px" }}>
                      <div>
                        <Typography
                          className={subHeader}
                          gutterBottom
                          style={{ color: this.state.colorData.desc }}
                        >
                          Guys, I am looking for a good play school for my niece
                          (3 years) near electronic city phase-1. Any
                          suggestions would be great.
                        </Typography>
                        <Typography
                          className={classes.hashTag}
                          gutterBottom
                          style={{ marginTop: "10px" }}
                        >
                          {hashtags &&
                            hashtags.map((item, index) => (
                              <Link
                                style={{ marginLeft: index === 0 ? 0 : 10 }}
                              >
                                {item}
                              </Link>
                            ))}
                        </Typography>
                      </div>
                      <Typography
                        style={{ marginTop: "10px" }}
                        className={wrapIcon}
                        color="textSecondary"
                      >
                        <IconButton
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "0px",
                          }}
                          aria-label="vote"
                        >
                          <img
                            src={VoteIcon}
                            height={15}
                            style={{ marginRight: "3px" }}
                            alt="voteicon"
                          />
                          2300
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          flexItem
                          className={classes.marginLeftRight}
                        />
                        <IconButton
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "0px",
                          }}
                          aria-label="like"
                        >
                          <img
                            src={LikeIcon}
                            height={15}
                            style={{ marginRight: "3px" }}
                            alt="likeicon"
                          />
                          4600
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          flexItem
                          className={classes.marginLeftRight}
                        />
                        <img
                          src={MessageIcon}
                          height={15}
                          style={{ marginRight: "3px" }}
                          alt="message"
                        />
                        500
                        <Divider
                          orientation="vertical"
                          flexItem
                          className={classes.marginLeftRight}
                        />
                        <VisibilityIcon
                          className={classes.iconSize}
                          style={{ marginRight: "3px" }}
                        />
                        <span style={{ fontSize: 13, fontFamily: "poppins" }}>
                          245
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>

                  {!mediaQuery && (
                    <Card style={{ marginTop: "20px" }}>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} style={{ margin: 5 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.customButtonInfo}
                              size="small"
                              onClick={this.changeColor}
                            >
                              Color Theme
                              <ArrowForward className={classes.rightIcon} />
                            </Button>
                          </Grid>
                          <Grid item xs={12} style={{ margin: 5 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.customButtonInfo}
                              size="small"
                              onClick={this.uploadLogoIcon}
                            >
                              Upload Logo/Icon
                              <AddPhoto className={classes.rightIcon} />
                            </Button>
                          </Grid>
                          <Grid item xs={12} style={{ margin: 5 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.customButtonInfo}
                              size="small"
                              onClick={this.uploadCoverPic}
                            >
                              Upload Cover Pic
                              <AddPhoto className={classes.rightIcon} />
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className={classes.subTitle}
                  style={{
                    marginTop: 10,
                    textAlign: mediaQuery ? "right" : "left",
                  }}
                  color="textSecondary"
                >
                  By creating a community you are accepting Terms & Conditions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth={mediaQuery ? false : true}
                  variant="contained"
                  className={classes.customButtonFocus}
                  size="large"
                  onClick={this.createCommunity}
                  style={{ float: "right" }}
                >
                  Create Community
                </Button>
              </Grid>
              <Grid item xs={12}>
                {this.state.err_msg.length > 0 && (
                  <span className="error">{this.state.err_msg}</span>
                )}
              </Grid>
            </Grid>
          </Grid>
          {mediaQuery && (
            <Grid item xs={col3}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} style={{ margin: "10px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.customButtonInfo}
                        size="small"
                        onClick={this.changeColor}
                      >
                        Color Theme
                        <ArrowForward className={classes.rightIcon} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} style={{ margin: "10px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.customButtonInfo}
                        size="small"
                        onClick={this.uploadLogoIcon}
                      >
                        Upload Logo/Icon
                        <AddPhoto className={classes.rightIcon} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} style={{ margin: "10px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.customButtonInfo}
                        size="small"
                        onClick={this.uploadCoverPic}
                      >
                        Upload Cover Pic
                        <AddPhoto className={classes.rightIcon} />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const communityData = state.communityReducer;
  return { communityData };
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(
      connect(mapStateToProps)(PreviewCommunityTemplate)
    )
  )
);
