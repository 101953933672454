import React, { Component } from 'react';
import { 
    DialogContent, IconButton, Grid, Dialog, useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { 
    WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon,
    EmailShareButton, EmailIcon, TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon
} from 'react-share'
import { withRouter } from 'react-router-dom';

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { mediaQuery } = this.props;

        return (
            <React.Fragment>
                <Dialog fullWidth open={this.props.open}
                    onClose={this.props.handleReportClose} disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={ mediaQuery ? 11 : 10 }>
                                <span style={{ fontSize: 15 }}>Share Link To</span>
                            </Grid>
                            <Grid item xs={ mediaQuery ? 1 : 2 }>
                                <IconButton style={{ marginLeft: '22px', marginTop: '-20px' }} onClick={this.props.handleReportClose} 
                                    aria-label="settings">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} style={{ marginBottom: '10px', marginTop: '10px' }}>
                            <Grid item xs={12}>
                                <WhatsappShareButton
                                    url={this.props.shareUrl}
                                    title={this.props.title}
                                    separator=": "
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>

                                <span style={{ marginLeft: '10px' }}>
                                    <FacebookShareButton
                                        url={this.props.shareUrl}
                                        quote={this.props.title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                </span>

                                <span style={{ marginLeft: '10px' }}>
                                    <LinkedinShareButton 
                                        url={this.props.shareUrl}
                                        title={this.props.title}
                                        summary={this.props.desc}
                                        className="Demo__some-network__share-button"
                                    >
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                </span>

                                <span style={{ marginLeft: '10px' }}>
                                    <EmailShareButton
                                        url={this.props.shareUrl}
                                        subject={this.props.title}
                                        body={this.props.desc + ': '}
                                        className="Demo__some-network__share-button"
                                    >
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                                </span>

                                <span style={{ marginLeft: '10px' }}>
                                    <TwitterShareButton
                                        url={this.props.shareUrl}
                                        title={this.props.title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                </span>

                                <span style={{ marginLeft: '10px' }}>
                                    <TelegramShareButton
                                        url={this.props.shareUrl}
                                        title={this.props.title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                </span>
                            </Grid>
                        </Grid>                        
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withRouter(withMediaQuery('(min-width:600px)')(Share))