import React, { useEffect, useState } from 'react';
import {
    List, ListItem, Tooltip, ListItemText, Collapse, Divider, IconButton
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import IconExpandLess from '@material-ui/icons/ExpandLess'
import PremiumIcon from '@material-ui/icons/Stars';
import { useHistory } from 'react-router-dom';
import routeService from '../../services/Routing';
import constant from '../../components/common/Constants';
import ApplyColor from '../modal/ApplyColor';
import '../../components/common/Common.css'
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import UploadImage from '../modal/UploadImage';
import imageSizes from "../json/ImageSize.json";

export default function CommunityNavMenu(props) {
    const [openSettings, setOpenSettings] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [uploadType, setUploadType] = useState(null);
    const [imageSize, setImageSize] = useState({
        height: null,
        width: null
    })
    const [showApplyColor, setShowApplyColor] = useState(false);
    const history = useHistory();

    const handleSettings = () => {
        setOpenSettings(!openSettings);
    };

    useEffect(() => {
        sessionStorage.setItem('group_id', props.groupId);
        sessionStorage.setItem('is_community_admin', props.isCommunityAdmin);
    });

    const redirectToMP = () => {
        let pathName = constant.managePermissions + props.groupId;
        routeService.routeFuncPage(history, pathName);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const redirectToMU = () => {
        let pathName = constant.manageUsers + props.groupId;
        routeService.routeFuncPage(history, pathName);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const redirectToApproveUsers = () => {
        let pathName = constant.approveUsers + props.groupId;
        routeService.routeFuncPage(history, pathName);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const redirectToPremium = () => {
        let pathName = constant.premium + props.groupId;
        routeService.routeFuncPage(history, pathName);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const redirectToHome = () => {
        let pathName = constant.communityHome + props.groupId;
        routeService.routeFuncPage(history, pathName);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const redirectToAbout = () => {
        let pathName = constant.communityAbout + props.groupId;
        routeService.routeFuncPage(history, pathName);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const changeColor = () => {
        setShowApplyColor(true);
    }

    const uploadLogoIcon = () => {
        let imageSize = {
            height: imageSizes.logo_icon.height,
            width: imageSizes.logo_icon.width
        };

        setShowUpload(true);
        setUploadType('logo_icon');
        setImageSize(imageSize);
    }

    const uploadCoverPic = () => {
        let imageSize = {
            height: imageSizes.cover_pic.height,
            width: imageSizes.cover_pic.width
        };

        setShowUpload(true);
        setUploadType('cover_pic');
        setImageSize(imageSize);
    }

    const handleApplyClose = () => {
        setShowApplyColor(false);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const handleApplyColor = (colorData) => {
        setShowApplyColor(false);
        props.onApplyColorTheme(colorData);
    }

    const handleModalClose = () => {
        setShowUpload(false);
        setUploadType(null);
        if (props.isMobileView)
            props.toggleDrawer(false);
    }

    const handleFileSave = (type, imageUrl) => {
        setShowUpload(false);
        setUploadType(null);
        props.handleFileSave(type, imageUrl);
    }

    return (
        <React.Fragment>
            <ApplyColor
                showApplyColor={showApplyColor}
                handleApplyClose={handleApplyClose}
                onApplyColorTheme={handleApplyColor}>
            </ApplyColor>

            <UploadImage
                show={showUpload}
                type={uploadType}
                handleModalClose={handleModalClose}
                onFileSaveChange={handleFileSave}
                groupName={props.GroupName}
                imageSize={imageSize}>
            </UploadImage>

            <List>
                <ListItem button onClick={redirectToHome}>
                    <Tooltip title="Home">
                        <HomeIcon className="sideDrawerItems" style={{color: props.selectedMenu === 'home' ? '#c2272d' : null}} />
                    </Tooltip>
                    <ListItemText className="drawerItemsText">
                        <span className="sideDrawerItemsText" style={{color: props.selectedMenu === 'home' ? '#c2272d' : null}}>
                            Home
                        </span>
                    </ListItemText>
                    <IconButton aria-label="Continue">
                        <ArrowForward className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"} 
                            style={{color: props.selectedMenu === 'home' ? '#c2272d' : null}} />
                    </IconButton>
                </ListItem>
                <Divider />
                <ListItem button onClick={redirectToAbout}>
                    <Tooltip title="About">
                        <InfoIcon className="sideDrawerItems" style={{color: props.selectedMenu === 'about' ? '#c2272d' : null}} />
                    </Tooltip>
                    <ListItemText className="drawerItemsText">
                        <span className="sideDrawerItemsText" style={{color: props.selectedMenu === 'about' ? '#c2272d' : null}}>
                            About
                        </span>
                    </ListItemText>
                    <IconButton aria-label="Continue">
                        <ArrowForward className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"} 
                            style={{color: props.selectedMenu === 'about' ? '#c2272d' : null}} />
                    </IconButton>
                </ListItem>
                <Divider />
                {/* <ListItem button>
                    <Tooltip title="Events">
                        <EventIcon className="sideDrawerItems" />
                    </Tooltip>
                    <ListItemText className="drawerItemsText">
                        <span className="sideDrawerItemsText">Events</span>
                    </ListItemText>
                    <IconButton aria-label="Continue">
                        <ArrowForward className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"} />
                    </IconButton>
                </ListItem>
                <Divider /> */}
                {/* <ListItem button onClick={redirectToPremium}>
                    <Tooltip title="Premium">
                        <PremiumIcon className="sideDrawerItems" style={{color: props.selectedMenu === 'premium' ? '#c2272d' : null}} />
                    </Tooltip>
                    <ListItemText className="drawerItemsText">
                        <span className="sideDrawerItemsText" style={{color: props.selectedMenu === 'premium' ? '#c2272d' : null}}>
                            Premium
                        </span>
                    </ListItemText>
                    <IconButton aria-label="Continue">
                        <ArrowForward className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"}
                            style={{color: props.selectedMenu === 'premium' ? '#c2272d' : null}} />
                    </IconButton>
                </ListItem>
                <Divider /> */}
                {/* <ListItem button>
                    <Tooltip title="Members">
                        <GroupIcon className="sideDrawerItems" />
                    </Tooltip>
                    <ListItemText className="drawerItemsText">
                        <span className="sideDrawerItemsText">Members</span>
                    </ListItemText>
                    <IconButton aria-label="Continue">
                        <ArrowForward className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"} />
                    </IconButton>
                </ListItem> */}
                {props.isCommunityAdmin && <Divider />}
                {props.isCommunityAdmin &&
                    <ListItem button onClick={handleSettings}>
                        <Tooltip title="Settings">
                            <SettingsIcon className="sideDrawerItems" />
                        </Tooltip>
                        <ListItemText className="drawerItemsText">
                            <span className="sideDrawerItemsText">Settings</span>
                        </ListItemText>
                        <IconButton aria-label="Continue">
                            {openSettings ?
                                <IconExpandLess className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"} /> :
                                <ArrowForward className={props.isMobileView ? "icon-small-size-mobile" : "icon-small-size"} />
                            }
                        </IconButton>
                    </ListItem>}
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <ListItem button onClick={redirectToMP}>
                            <ListItemText inset className="drawerSubItemsText">
                                <span className="sideDrawerItemsText">Manage Permissions</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={redirectToMU}>
                            <ListItemText inset className="drawerSubItemsText">
                                <span className="sideDrawerItemsText">Manage Users</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={redirectToApproveUsers}>
                            <ListItemText inset className="drawerSubItemsText">
                                <span className="sideDrawerItemsText">Manage Requests</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={changeColor}>
                            <ListItemText inset className="drawerSubItemsText">
                                <span className="sideDrawerItemsText">Color Theme</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={uploadLogoIcon}>
                            <ListItemText inset className="drawerSubItemsText">
                                <span className="sideDrawerItemsText">Upload Icon</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={uploadCoverPic}>
                            <ListItemText inset className="drawerSubItemsText">
                                <span className="sideDrawerItemsText">Upload Cover Pic</span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </React.Fragment>
    );
}
