import React, { Component, Fragment } from 'react';
import { 
    withStyles, useMediaQuery, Backdrop
} from '@material-ui/core';
import { useStyles } from '../../components/common/useStyles';
import '../../components/common/Common.css'
import { withRouter } from 'react-router-dom';
import constant from '../../components/common/Constants';
import routeService from '../../services/Routing';
import api from "../../components/common/APIValues";
import { get } from "../../services/ApiService";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            htmlContent: '',
            loader: false,
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.getDefaultCommunityDetails();
    }

    getDefaultCommunityDetails = () => {
        this.setState({ loader: true });
        const originName = window.location.origin.replace('www.', '');
        const inputs = `env=${api.env}&originName=${originName}`;
        get("Home/GetDefaultCommunityDetails?" + inputs).then(
          (response) => {
            if(response) {
                const coverUrl = response.CoverUrl.replace('{device_type}', 'desktop');
                this.getHtmlContent(response.GroupDesc, coverUrl, response.GroupName);
                this.setState({ loader: false });
            }            
          }
        );
    };

    getHtmlContent = (url, coverUrl, title) => {
        fetch(url)
        .then((r) => r.text())
        .then((text) => {
            if(text) {
                this.setState({ htmlContent: text.replace('{cover_url}', coverUrl).replace('{title_name}', title) });
            }            
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.searchValue !== prevProps.searchValue) {
            routeService.routePage(this.props, constant.home);
        }
    }

    render() {
        const { classes, mediaQuery } = this.props;

        return (
            <Fragment>
                <Backdrop className={classes.backdrop} open={this.state.loader}>
                    <img src={EDWhatLogoIcon} height={50} alt="Logo" />
                </Backdrop>

                <h1 style={{marginTop: mediaQuery ? '65px' : '110px'}} 
                    className={classes.aboutTitle}>
                        ABOUT US
                </h1>

                <div dangerouslySetInnerHTML={{
                    __html: this.state.htmlContent
                        
                }} />
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(AboutUs)))