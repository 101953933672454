import React, { Component, Fragment } from "react";
import { withStyles, Divider, useMediaQuery, Grid } from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import { withRouter } from "react-router-dom";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class CommunityGuidelines extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.searchValue !== prevProps.searchValue) {
      routeService.routePage(this.props, constant.home);
    }
  };

  render() {
    const { classes, mediaQuery } = this.props;

    return (
      <Fragment>
        <h1
          style={{ marginTop: mediaQuery ? "65px" : "110px" }}
          className={classes.aboutTitle}
        >
          Community Guidelines
        </h1>

        <Divider orientation="horizontal" style={{ marginTop: 20 }} />

        <div className={classes.aboutContent} style={{ marginTop: 20 }}>
          <p>
            Creating a community on our website is an excellent way to connect
            with like-minded individuals, share ideas, and create a space where
            you can discuss topics that interest you. However, we want to ensure
            that all communities created on our website are safe, inclusive, and
            respectful. To that end, we have created the following guidelines
            for users who wish to create a community on our website.
          </p>
        </div>

        <div className={classes.aboutContent} style={{ marginTop: 20 }}>
          <ul>
            <li>
              Choose the type of community you want to create: Please make sure
              that the community you create aligns with our website's values and
              policies.
            </li>
            <li>
              Choose the privacy settings for your community: You can create a
              private community where only invited members can join, or a public
              community where anyone can join. Please keep in mind that private
              communities will be subject to additional scrutiny to ensure that
              they are not exclusionary or discriminatory.
            </li>
            <li>
              Create a clear and descriptive community name: Your community name
              should accurately reflect the content and purpose of your
              community.
            </li>
            <li>
              Be an active and responsible community admin: As the admin of your
              community, you will have the responsibility to moderate content,
              enforce community guidelines, and create a safe and respectful
              environment for all members. Please make sure that you are willing
              and able to take on these responsibilities before creating a
              community.
            </li>
            <li>
              Enforce community guidelines: You should create clear guidelines
              for your community, outlining what kind of content is acceptable
              and what is not. You should also be prepared to enforce these
              guidelines consistently and fairly.
            </li>
            <li>
              Foster a respectful and inclusive community culture: Your
              community should be a safe and respectful space for all members,
              regardless of their background, identity, or beliefs. You should
              actively promote inclusivity and respect in your community.
            </li>
            <li>
              Keep your community active: To keep your community engaged and
              active, you should regularly create and share content, and
              encourage members to do the same.
            </li>
          </ul>
        </div>

        <div className={classes.aboutContent} style={{ marginTop: 20 }}>
          <p>
            By following these guidelines, you can create a vibrant and engaging
            community on our website. Remember that creating a community is a
            responsibility, and you should be willing and able to dedicate the
            time and effort necessary to create a positive and inclusive
            environment for all members.
          </p>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(CommunityGuidelines)
  )
);
