import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Typography,
  useMediaQuery,
  Backdrop,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import "../../components/common/Common.css";
import { withRouter } from "react-router-dom";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import SuccessInfo from "../modal/SuccessInfo";
import constant from "../../components/common/Constants";
import routeService from "../../services/Routing";
import { get, update } from "../../services/ApiService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import ActionRenderer from "../../components/common/ActionRenderer";
import api from "../../components/common/APIValues";
import classNames from "classnames";
import imageSizes from "../json/ImageSize.json";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class ApproveRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      community: {},
      columnDefs: [
        {
          headerName: "User Name",
          field: "UserName",
          cellStyle: { "text-align": "center" },
        },
        {
          headerName: "Email Address",
          field: "EmailAddress",
          cellStyle: { "text-align": "center" },
        },
        {
          headerName: "Request Date",
          field: "RequestDate",
          cellStyle: { "text-align": "center" },
        },
        {
          headerName: "Actions",
          field: "Actions",
          sorting: false,
          filter: false,
          cellRenderer: "actionRenderer",
          cellStyle: { "text-align": "center" },
        },
      ],
      columnDefsMobileView: [
        {
          headerName: "Email Address",
          field: "EmailAddress",
          cellStyle: { "text-align": "center" },
          flex: 1,
        },
        {
          headerName: "Actions",
          field: "Actions",
          sorting: false,
          filter: false,
          cellRenderer: "actionRenderer",
          cellStyle: { "text-align": "center" },
          width: 120,
        },
      ],
      context: { componentParent: this },
      frameworkComponents: { actionRenderer: ActionRenderer },
      rowData: [],
      defaultColDef: { flex: 1, sortable: true, resizable: true, filter: true },
      defaultColDefMobileView: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowClassRules: {
        "grid-row-even": function (params) {
          return params.node.rowIndex % 2 === 0;
        },
        "grid-row-odd": function (params) {
          return params.node.rowIndex % 2 !== 0;
        },
      },
    };
  }

  componentDidMount() {
    if (!this.props.userEmail) {
      routeService.routePage(this.props, constant.home);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loadApprovalsData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName
    ) {
      routeService.routePage(this.props, constant.home);
    }
  }

  loadApprovalsData = () => {
    this.setState({ loader: true });
    const originName = window.location.origin.replace('www.', '');
    const groupId = this.props.match.params.groupId;
    const inputs = `groupId=${groupId}&env=${api.env}&userId=${this.props.userEmail}&originName=${originName}`;
    get("ApproveRequests/GetRequestedUsers?" + inputs).then(
      (response) => {
        this.setState({
          rowData: response.requests,
          community: response.community,
          loader: false,
        });
        if (response.community.UserGroupStatus !== "Owner") {
          routeService.routePage(this.props, constant.home);
        }
      }
    );
  };

  approveRequest = (row) => {
    this.approveRejectRequest(row, "Admin Approved");
  };

  rejectRequest = (row) => {
    this.approveRejectRequest(row, "Admin Denied");
  };

  approveRejectRequest = (row, status) => {
    let data = {
      Status: status,
      UpdatedUserId: this.props.userEmail,
      UserId: row.UserId,
      Env: api.env,
      GroupId: Number(this.props.match.params.groupId),
    };
    update(
      "ApproveRequests/ApproveRejectUser",
      data,
      row.UserGroupStatusId
    ).then((response) => {
      if (response.StatusCode === 200) {
        this.loadApprovalsData();
        //toaster
      }
    });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subTitleWithIcon = classNames(
      classes.subTitle,
      classes.alignTextWithIcon
    );

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <SuccessInfo
          open={this.state.openInfo}
          textMsg={this.state.textMsg}
          handleClose={this.handleClose}
          infoType='success'
        ></SuccessInfo>

        <Typography
          className={classes.title}
          style={{ marginTop: mediaQuery ? "55px" : "90px" }}
        >
          Manage Requests
        </Typography>

        <Grid container spacing={0} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography className={subTitleWithIcon}>
              <img
                src={this.state.community.LogoUrl}
                className={classes.leftIcon}
                style={{
                  width: imageSizes.logo_icon.width,
                  height: imageSizes.logo_icon.height,
                }}
                alt="logoicon"
              />
              <Typography>{this.state.community.GroupName}</Typography>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: 350, marginTop: 15 }}
            >
              <AgGridReact
                columnDefs={
                  mediaQuery
                    ? this.state.columnDefs
                    : this.state.columnDefsMobileView
                }
                rowData={this.state.rowData}
                onGridReady={this.onGridReady}
                defaultColDef={
                  mediaQuery
                    ? this.state.defaultColDef
                    : this.state.defaultColDefMobileView
                }
                frameworkComponents={this.state.frameworkComponents}
                context={this.state.context}
                pagination={true}
                gridOptions={this.gridOptions}
                paginationAutoPageSize={true}
                components={this.state.components}
                rowClassRules={this.state.rowClassRules}
                suppressClickEdit={true}
                overlayNoRowsTemplate={
                  "You are all caught up, no requests at the moment"
                }
              />
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(ApproveRequests))
);
