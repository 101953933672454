import React, { Component } from 'react';
import NavMenu from './NavMenu';
import SideNavMenu from './SideNavMenu';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import BottomAppBar from './BottomAppBar';

const useStyles = theme => ({
    root: {
        display: 'flex',
        paddingBottom: '5rem',
        backgroundColor: '#f6f7f2',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(5, 10, 2, 10),
        backgroundColor: '#f6f7f2',
    },
    contentsm: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 0, 2),
        backgroundColor: '#f6f7f2',
    },
});

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, mediaQuery } = this.props;

        return (
            <div style={{ position: 'relative', minHeight: '100vh', backgroundColor: '#f6f7f2' }}>
                <div className={classes.root}>
                    {mediaQuery ?
                        <NavMenu userLogin={this.props.userLogin}
                            userEmail={this.props.userEmail}
                            userName={this.props.userName}
                            userAliasName={this.props.userAliasName}
                            handleSortBy={this.props.handleSortBy}
                            prev_page={this.props.prev_page}
                            addQuestion={this.props.addQuestion}
                            sortBy={this.props.sortBy}
                            searchTopics={this.props.searchTopics}
                            searchValue={this.props.searchValue}
                            clearSearchInput={this.props.clearSearchInput}
                            handleMobileMenuOpen={this.props.handleMobileMenuOpen}
                            handleMobileMenuClose={this.props.handleMobileMenuClose}
                            anchorEl={this.props.anchorEl}
                            anchorElProfile={this.props.anchorElProfile}
                            webNotifications={this.props.webNotifications}
                            handleUserProfileOpen={this.props.handleUserProfileOpen}
                            handleUserProfileClose={this.props.handleUserProfileClose}
                            userLogout={this.props.userLogout}
                            handleNotificationClose={this.props.handleNotificationClose}
                            handleNotificationOpen={this.props.handleNotificationOpen}
                            redirectToCommunity={this.props.redirectToCommunity}
                            redirectToFeedbackForm={this.props.redirectToFeedbackForm}
                            redirectToMyProfile={this.props.redirectToMyProfile}
                            selectedMenu={this.props.selectedMenu}
                            redirectToTopCommunities={this.props.redirectToTopCommunities}
                        /> :
                        <SideNavMenu userLogin={this.props.userLogin}
                            userEmail={this.props.userEmail}
                            userName={this.props.userName}
                            userAliasName={this.props.userAliasName}
                            handleSortBy={this.props.handleSortBy}
                            prev_page={this.props.prev_page}
                            addQuestion={this.props.addQuestion}
                            sortBy={this.props.sortBy}
                            searchTopics={this.props.searchTopics}
                            searchValue={this.props.searchValue}
                            clearSearchInput={this.props.clearSearchInput}
                            handleMobileMenuOpen={this.props.handleMobileMenuOpen}
                            handleMobileMenuClose={this.props.handleMobileMenuClose}
                            anchorEl={this.props.anchorEl}
                            anchorElProfile={this.props.anchorElProfile}
                            webNotifications={this.props.webNotifications}
                            handleUserProfileOpen={this.props.handleUserProfileOpen}
                            handleUserProfileClose={this.props.handleUserProfileClose}
                            userLogout={this.props.userLogout}
                            handleNotificationClose={this.props.handleNotificationClose}
                            handleNotificationOpen={this.props.handleNotificationOpen}
                            redirectToCommunity={this.props.redirectToCommunity}
                            redirectToFeedbackForm={this.props.redirectToFeedbackForm}
                            updateCommunityGroup={this.props.updateCommunityGroup}                            
                        />}
                    <main className={mediaQuery ? classes.content : classes.contentsm}>
                        <div className={classes.toolbar} />
                        {this.props.children}
                    </main>
                </div>
                <BottomAppBar />
            </div>
        );
    }
}

export default withStyles(useStyles)(withMediaQuery('(min-width:600px)')(Layout))
