import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Button,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Divider,
  useMediaQuery,
  MenuItem,
  FormControl,
  Select,
  Backdrop,
  Link,
  Chip,
} from "@material-ui/core";
import { useStyles } from "../../components/common/useStyles";
import { CommentCount } from "@hyvor/hyvor-talk-react";
import "../../components/common/Common.css";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import DefaultCoverPic from "../../components/images/CoverPic.jpg";
import PollIcon from "../../components/images/poll.png";
import LikeIcon from "../../components/images/like.png";
import VoteIcon from "../../components/images/votes_icon.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import MessageIcon from "../../components/images/mesage.png";
import { connect } from "react-redux";
import api from "../../components/common/APIValues";
import axios from "axios";
import colorJsonData from "../json/CommunityColorTheme.json";
import EDWhatLogoIcon from "../../components/images/EdWhat-01-01.png";
import SuccessInfo from "../modal/SuccessInfo";
import QuestionIcon from "../../components/images/Question.png";
import ReactHtmlParser from "react-html-parser";
import CommunityNavMenu from "../users/CommunityNavMenu";
import LikeIconColor from "../../components/images/like_h.png";
import VoteIconColor from "../../components/images/votes_h.png";
import loginService from "../../services/Login";
import routeService from "../../services/Routing";
import constant from "../../components/common/Constants";
import CommonFunc from "../../components/common/CommonFunc";
import { update, create, get, remove } from "../../services/ApiService";
import ConfirmationDialog from "../modal/ConfirmationDialog";
import imageSizes from "../json/ImageSize.json";
import MemberIcon from "../../components/images/Member.png";
import RequestedIcon from "../../components/images/Requested.png";
import DeniedIcon from "../../components/images/Denied.png";
import AcceptedIcon from "../../components/images/Accepted.png";
import JoinIcon from "../../components/images/Join.png";
import OwnerIcon from "../../components/images/Owner.png";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class CommunityHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupId: 0,
      community: {},
      communityTopics: [],
      sortBy: 1,
      loader: false,
      openInfo: false,
      textMsg: "",
      openSettings: false,
      colorData: {},
      communityTopicsCount: 0,
      htmlUserToAdmin: "",
      htmlUserToUser: "",
      permissionLevel: "",
      openConfirmDialog: false,
      categoryName: "",
      defaultCommunity: {},
      isUserDefaultGroupPermission: false,
      websiteId: 0,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let groupId = Number(this.props.match.params.groupId);
    let communityTopicsCount = sessionStorage.getItem("communityTopicsCount")
      ? sessionStorage.getItem("communityTopicsCount")
      : 0;
    this.setState({
      groupId: groupId,
      communityTopicsCount: communityTopicsCount,
    });
    this.loadCommunity(groupId);
    this.validateUserPermission(groupId);
    this.getDefaultCommunityDetails(groupId, Number(communityTopicsCount));
    this.validateUserDefaultGroupPermission();
  }

  getHtmlText = (s3BaseUrl) => {
    const htmlUserToAdminUrl = `${s3BaseUrl}/email-template/UserToAdminOnJoin.txt`;
    const htmlUserToUserUrl = `${s3BaseUrl}/email-template/UserToUserOnJoin.txt`;

    fetch(htmlUserToAdminUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlUserToAdmin: text });
      });

    fetch(htmlUserToUserUrl)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ htmlUserToUser: text });
      });
  };

  validateUserDefaultGroupPermission = () => {
    const permissionName = "Create Topic";
    const email = this.props.userEmail;
    const originName = window.location.origin.replace("www.", "");
    const inputs = `GroupId=0&Env=${api.env}&UserId=${email}&PermissionName=${permissionName}&originName=${originName}`;

    get("Permission/ValidateUserPermission?" + inputs)
      .then((response) => {
        if (
          response.PermissionName &&
          response.PermissionName === "Create Topic"
        ) {
          this.setState({
            isUserDefaultGroupPermission: true,
          });
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  getDefaultCommunityDetails = (groupId, communityTopicsCount) => {
    this.setState({ loader: true });
    const originName = window.location.origin.replace("www.", "");
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then((response) => {
      this.setState({
        defaultCommunity: response,
        loader: false,
      });

      fetch(response.S3ConfigUrl)
        .then((r) => r.json())
        .then((jsonData) => {
          const websiteId = jsonData.websiteId;
          this.setState({ websiteId: websiteId });
          this.loadCommunityTopics(
            communityTopicsCount,
            groupId,
            Number(this.state.sortBy),
            undefined,
            websiteId
          );
        });

      const s3BaseUrl = response.S3ConfigUrl.replace(
        "/configuration/config.json",
        ""
      );
      this.getHtmlText(s3BaseUrl);
    });
  };

  validateUserPermission = (groupId) => {
    const originName = window.location.origin.replace("www.", "");
    const permissionName = "Create Topic";
    const inputs = `Env=${api.env}&GroupId=${groupId}&UserId=${this.props.userEmail}&PermissionName=${permissionName}&originName=${originName}`;
    get("Permission/ValidateUserPermission?" + inputs)
      .then((response) => {
        this.setState({ permissionLevel: response.PermissionName });
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName
    ) {
      this.loadCommunity(this.state.groupId);
      this.loadCommunityTopics(
        Number(this.state.communityTopicsCount),
        this.state.groupId,
        Number(this.state.sortBy),
        undefined,
        this.state.websiteId
      );
      this.validateUserPermission(this.state.groupId);
    }

    if (
      this.props.logoUrl !== prevProps.logoUrl ||
      this.props.coverUrl !== prevProps.coverUrl ||
      this.props.colorThemeName !== prevProps.colorThemeName
    ) {
      let community = this.state.community;
      if (this.props.logoUrl !== prevProps.logoUrl) {
        community.LogoUrl = this.props.logoUrl;
      }
      if (this.props.coverUrl !== prevProps.coverUrl) {
        community.CoverUrl = this.props.coverUrl;
      }
      if (this.props.colorThemeName !== prevProps.colorThemeName) {
        community.ColorThemeName = this.props.colorThemeName;
        let colorData = this.setColorData(community.ColorThemeName);
        this.setState({ colorData: colorData });
      }
      this.setState({ community: community });
    }
  };

  sendEmailToAdmin = () => {
    const originName = window.location.origin.replace("www.", "");
    const loginUrl = `${originName}/#`;
    let subject = "{user_name} has requested to join {community_name}";
    const groupId = Number(this.props.match.params.groupId);
    subject = subject
      .replace("{user_name}", this.props.userAliasName)
      .replace("{community_name}", this.state.community.GroupName);
    const htmlUserToAdmin = this.state.htmlUserToAdmin
      .replace("{admin_name}", this.state.community.CommunityOwnerName)
      .replace("{user_name}", this.props.userAliasName)
      .replaceAll("{community_name}", this.state.community.GroupName)
      .replace(
        "{logo_url}",
        this.state.defaultCommunity.LogoUrl.replace("{device_type}", "desktop")
      )
      .replace("{team_name}", this.state.defaultCommunity.GroupName)
      .replaceAll("{domain_name}", loginUrl)
      .replaceAll("{group_id}", groupId);

    const rowData = {
      HtmlBody: htmlUserToAdmin,
      Subject: subject,
      ToAddress: this.state.community.CommunityOwner,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmailToUser = () => {
    const originName = window.location.origin.replace("www.", "");
    let subject = "Thanks for showing interest to join {community_name}";
    subject = subject.replace(
      "{community_name}",
      this.state.community.GroupName
    );
    const htmlUserToUser = this.state.htmlUserToUser
      .replace("{user_name}", this.props.userAliasName)
      .replace(
        "{logo_url}",
        this.state.defaultCommunity.LogoUrl.replace("{device_type}", "desktop")
      )
      .replace("{team_name}", this.state.defaultCommunity.GroupName)
      .replaceAll("{community_name}", this.state.community.GroupName);

    const rowData = {
      HtmlBody: htmlUserToUser,
      Subject: subject,
      ToAddress: this.state.community.UserEmail,
      OriginName: originName,
      Env: api.env,
    };
    this.sendEmail(rowData);
  };

  sendEmail = (rowData) => {
    axios({
      method: "post",
      url: api.partialURL + "EmailService/SendEmail",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data === "error") {
            //log information in database table
          }
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response });
      });
  };

  loadCommunityTopics = (
    communityTopicsCount,
    groupId,
    sortBy,
    likeObj,
    websiteId
  ) => {
    const { hasCache, getCache } = this.props.cacheActions;
    const originName = window.location.origin.replace("www.", "");
    this.setState({ loader: true });
    let url =
      api.partialURL +
      "Community/GetCommunityTopics?GroupId=" +
      groupId +
      "&UserId=" +
      this.props.userEmail +
      "&ENV=" +
      api.env +
      "&SortInd=" +
      sortBy +
      "&originName=" +
      originName;
    let params = {
      UserId: this.props.userEmail,
      GroupId: groupId,
      SortInd: sortBy,
      CommunityTopicsCount: communityTopicsCount,
    };

    if (false) {
      let cachedData = getCache(url, params).data;
      if (likeObj) {
        cachedData.map((item, index) => {
          if (
            item.TOPIC_POST_TYPE === likeObj.postType &&
            item.TOPIC_ID === likeObj.topicId
          ) {
            if (cachedData[index].LIKE_IND === 0) {
              if (likeObj.postType === "POLL") {
                cachedData[index].TOPIC_POLL_LIKE =
                  cachedData[index].TOPIC_POLL_LIKE + 1;
              } else {
                cachedData[index].TOPIC_LIKE_VOTE =
                  cachedData[index].TOPIC_LIKE_VOTE + 1;
              }
              cachedData[index].LIKE_IND = 1;
            } else if (cachedData[index].LIKE_IND === 1) {
              if (likeObj.postType === "POLL") {
                cachedData[index].TOPIC_POLL_LIKE =
                  cachedData[index].TOPIC_POLL_LIKE - 1;
              } else {
                cachedData[index].TOPIC_LIKE_VOTE =
                  cachedData[index].TOPIC_LIKE_VOTE - 1;
              }
              cachedData[index].LIKE_IND = 0;
            }
          }
        });
      }

      this.setCommunityTopics(url, params, cachedData);
    } else {
      this.fetchCommunityTopics(url, params, websiteId);
    }
  };

  saveUserQueLike = (topicId, postType, title, likeInd) => {
    if (postType === "POLL") {
      this.viewTopicDetails(topicId, postType, title);
    } else {
      if (this.props.userEmail && this.props.userAliasName) {
        let rowData = {
          TOPIC_ID: topicId,
          TOPIC_UPDT_USER_ID: this.props.userEmail,
          TOPIC_UPDT_USER_NM: this.props.userAliasName,
          ENV: api.env,
        };

        axios({
          method: "post",
          url: api.partialURL + "UserQueTopic/SaveUserQueLike",
          data: JSON.stringify(rowData),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        })
          .then((res) => {
            if (res) {
              if (res.data === "success") {
              }
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });

        let queLikeObj = {
          topicId: topicId,
          postType: postType,
        };
        let groupId = Number(this.props.match.params.groupId);
        let sortBy = Number(this.state.sortBy);
        let communityTopicsCount = sessionStorage.getItem(
          "communityTopicsCount"
        )
          ? sessionStorage.getItem("communityTopicsCount")
          : 0;
        this.loadCommunityTopics(
          Number(communityTopicsCount),
          groupId,
          sortBy,
          queLikeObj,
          this.state.websiteId
        );
      } else if (!this.props.userEmail || !this.props.userAliasName) {
        loginService.redirectToLogin(this.props);
      }
    }
  };

  saveUserPollLike = (topicId, postType, likeInd) => {
    if (this.props.userEmail && this.props.userAliasName) {
      this.setState({ loader: true, scrollDownLoader: false });
      let rowData = {
        TOPIC_ID: topicId,
        TOPIC_VOTE_USER_ID: this.props.userEmail,
        TOPIC_VOTE_USER_NM: this.props.userAliasName,
        ENV: api.env,
      };

      axios({
        method: "post",
        url: api.partialURL + "UserPollTopic/SaveUserPollLike",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            if (res.data === "success") {
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });

      let pollLikeObj = {
        topicId: topicId,
        postType: postType,
      };
      let groupId = Number(this.props.match.params.groupId);
      let sortBy = Number(this.state.sortBy);
      let communityTopicsCount = sessionStorage.getItem("communityTopicsCount")
        ? sessionStorage.getItem("communityTopicsCount")
        : 0;
      this.loadCommunityTopics(
        Number(communityTopicsCount),
        groupId,
        sortBy,
        pollLikeObj,
        this.state.websiteId
      );
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      loginService.redirectToLogin(this.props);
    }
  };

  fetchCommunityTopics = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        let topicDetails = result.userTopics;

        topicDetails.forEach((topic, index) => {
          // Filter media link
          let desc = topic.TOPIC_DESC;
          let mediaLink = desc.slice(
            desc.indexOf('<oembed url="') + 13,
            desc.lastIndexOf('"></oembed>')
          );

          if (
            mediaLink.includes("https://youtube.com/") ||
            mediaLink.includes("https://youtu.be/") ||
            mediaLink.includes("https://www.youtube.com/") ||
            mediaLink.includes("https://www.youtu.be/")
          ) {
            let videoId = CommonFunc.getYoutubeId(mediaLink);
            let iframeMarkup =
              '<iframe frameborder="0" style="width:560px;height:315px;" src="https://www.youtube.com/embed/' +
              videoId +
              '" allowfullscreen></iframe>';
            let oembedMarkup =
              '<figure class="media"><oembed url="' +
              mediaLink +
              '"></oembed></figure>';

            topicDetails[index].TOPIC_DESC = desc.replace(
              oembedMarkup,
              iframeMarkup
            );
          }
        });

        this.setState({ communityTopicsCount: topicDetails.length });
        this.setCommunityTopics(url, params, topicDetails);
      })
      .catch((err) => console.log(err));
  };

  setCommunityTopics = (url, params, communityTopics) => {
    this.setState({
      communityTopics: communityTopics,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, communityTopics);
  };

  loadCommunity = (groupId) => {
    const originName = window.location.origin.replace("www.", "");
    this.setState({ loader: true });
    let url =
      api.partialURL +
      "Community/GetCommunity?GroupId=" +
      groupId +
      "&UserId=" +
      this.props.userEmail +
      "&ENV=" +
      api.env +
      "&originName=" +
      originName;
    this.fetchCommunity(url);
  };

  fetchCommunity = (url) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setCommunity(result);
      })
      .catch((err) => console.log(err));
  };

  setCommunity = (community) => {
    let colorData = this.setColorData(community.ColorThemeName);
    this.setState({
      community: community,
      loader: false,
      colorData: colorData,
    });
  };

  setColorData = (colorThemeName) => {
    let colorData = {};
    colorJsonData.map((color) => {
      if (color.name === colorThemeName) {
        colorData.title = color.title;
        colorData.subtitle = color.subtitle;
        colorData.desc = color.desc;
      }
    });
    return colorData;
  };

  joinCommunityGroup = () => {
    let userGroupStatus = this.state.community.UserGroupStatus;
    if (userGroupStatus === "Member") {
      this.setState({ openConfirmDialog: true });
    } else {
      let communityType = this.state.community.CommunityType;
      if (this.props.userEmail && this.props.userAliasName) {
        this.setState({ loader: true });
        let rowData = {
          GroupId: Number(this.state.groupId),
          SubscriptionId: 2,
          ExpiryDate: "8888-12-31",
          Status:
            communityType === "private" && userGroupStatus === ""
              ? "User Requested"
              : "Approved",
          CreatedUserId: this.props.userEmail,
          Env: api.env,
          CommunityType: communityType,
          UserGroupStatus: userGroupStatus,
        };

        axios({
          method: "post",
          url: api.partialURL + "Community/JoinCommunity",
          data: JSON.stringify(rowData),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        })
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              if (res.data === "success") {
                if (communityType === "private" && userGroupStatus === "") {
                  this.setState({
                    openInfo: true,
                    textMsg: "Your request is successfully sent to the admin !",
                  });

                  this.sendEmailToAdmin();
                  this.sendEmailToUser();
                  this.saveWebNotification();
                }
                this.loadCommunity(this.state.groupId);
                this.loadCommunityTopics(
                  Number(this.state.communityTopicsCount),
                  this.state.groupId,
                  Number(this.state.sortBy),
                  undefined,
                  this.state.websiteId
                );
                this.validateUserPermission(this.state.groupId);
              } else {
                this.setState({ err_msg: "Failed to join community group" });
              }
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });
      } else if (!this.props.userEmail || !this.props.userAliasName) {
        loginService.redirectToLogin(this.props);
      }
    }
  };

  handleSettings = () => {
    this.setState({
      openSettings: !this.state.openSettings,
    });
  };

  redirectToMP = () => {
    let pathName = constant.managePermissions + this.state.groupId;
    routeService.routePage(this.props, pathName);
  };

  redirectToMU = () => {
    let pathName = constant.manageUsers + this.state.groupId;
    routeService.routePage(this.props, pathName);
  };

  redirectToIU = () => {
    let pathName = constant.inviteUsers + this.state.groupId;
    routeService.routePage(this.props, pathName);
  };

  redirectToPremium = () => {
    let pathName = constant.premium + this.state.groupId;
    routeService.routePage(this.props, pathName);
  };

  redirectToAddQuestion = () => {
    sessionStorage.setItem(
      "communityTopicsCount",
      this.state.communityTopics.length + 1
    );
    let pathName = constant.addTopic + this.state.groupId;
    routeService.routePage(this.props, pathName);
  };

  handleSortBy = (event) => {
    let sortByVal = event.target.value;
    this.setState({ sortBy: sortByVal });
    let communityTopicsCount = sessionStorage.getItem("communityTopicsCount")
      ? sessionStorage.getItem("communityTopicsCount")
      : 0;
    this.loadCommunityTopics(
      Number(communityTopicsCount),
      Number(this.state.groupId),
      sortByVal,
      undefined,
      this.state.websiteId
    );
  };

  viewTopicDetails = (topicId, topicPostType, topicTitle) => {
    let pathName =
      topicPostType === "QUESTION"
        ? constant.queDetails +
          topicId +
          "/" +
          topicTitle.toLowerCase().replace(/ /g, "-")
        : constant.pollDetails +
          topicId +
          "/" +
          topicTitle.toLowerCase().replace(/ /g, "-");
    routeService.routePage(this.props, pathName);
  };

  saveWebNotification = () => {
    let rowData = {
      UserId: this.props.userEmail,
      CommunityOwner: this.state.community.CommunityOwnerId,
      GroupId: Number(this.props.match.params.groupId),
      Status: "User Requested",
      NotificationType: "Community",
      Env: api.env,
    };
    create("CommunityNotification/SaveCommunityNotification", rowData)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  onApplyColorTheme = (colorData) => {
    this.setState({ colorData: colorData });
    let rowData = {
      ColorThemeName: colorData.name,
      Env: api.env,
      UpdatedUserId: this.props.userEmail,
    };

    update("CommunitySettings/UpdateColorTheme", rowData, this.state.groupId)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  addToSearchInput = (searchHashtag) => {
    const { history } = this.props;
    if (history)
      history.push({ pathname: "/home", searchHashtagDtl: searchHashtag });
  };

  handleClose = () => {
    this.setState({ openInfo: false });
  };

  handleConfirmDialogClose = () => {
    this.setState({ openConfirmDialog: false });
  };

  handleDelete = () => {
    let groupId = this.props.match.params.groupId;
    this.removeUser(this.props.userEmail, groupId);
    sessionStorage.setItem("add_question_page_2", "delete_my_account");
    sessionStorage.setItem("home_page_action", "exit_from_community");
  };

  removeUser = (userId, groupId) => {
    let input = Number(groupId) + "/" + userId + "/" + api.env;
    remove("ManageUsers/DeleteUsers", input)
      .then((response) => {
        if (response.StatusCode === 200) {
          this.loadCommunity(groupId);
          this.loadCommunityTopics(
            Number(this.state.communityTopicsCount),
            groupId,
            Number(this.state.sortBy),
            undefined,
            this.state.websiteId
          );
          this.handleConfirmDialogClose();
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  handleFileSave = (type, imageUrl) => {
    let community = this.state.community;
    if (type === "cover_pic") {
      community.CoverUrl = imageUrl;
      this.updateCoverPic(imageUrl);
    } else {
      community.LogoUrl = imageUrl;
      this.updateLogoIcon(imageUrl);
    }
    this.setState({ community: community });
  };

  updateCoverPic = (coverUrl) => {
    let rowData = {
      CoverUrl: coverUrl,
      Env: api.env,
      UpdatedUserId: this.props.userEmail,
    };

    update("CommunitySettings/UpdateCoverPic", rowData, this.state.groupId)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  updateLogoIcon = (logoUrl) => {
    let rowData = {
      LogoUrl: logoUrl,
      Env: api.env,
      UpdatedUserId: this.props.userEmail,
    };

    update("CommunitySettings/UpdateLogoIcon", rowData, this.state.groupId)
      .then((response) => {})
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subTitleWithIcon = classNames(
      classes.subHeader,
      classes.alignTextWithIcon
    );
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const col3 = mediaQuery ? 3 : 12;
    const col9 = mediaQuery ? 9 : 12;
    const subHeaderX = classNames(classes.posTopX, classes.subHeader);

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        <SuccessInfo
          open={this.state.openInfo}
          textMsg={this.state.textMsg}
          handleClose={this.handleClose}
          infoType="success"
        ></SuccessInfo>

        <ConfirmationDialog
          open={this.state.openConfirmDialog}
          handleClose={this.handleConfirmDialogClose}
          titleText={"Exit from community?"}
          contentText1={
            "This action will allow you to exit from community. We cannot recover this later."
          }
          contentText2={"from this community?"}
          contentActionText={"exit"}
          actionText={"Yes, exit"}
          handleDelete={this.handleDelete}
        ></ConfirmationDialog>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            {mediaQuery ? (
              <Card
                style={{
                  backgroundColor: "#F9F9F9",
                  marginLeft: 135,
                  marginRight: 135,
                  marginTop: 55,
                }}
              >
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={col3}>
                      <Card>
                        <CardContent>
                          <CommunityNavMenu
                            groupId={this.props.match.params.groupId}
                            selectedMenu="home"
                            isCommunityAdmin={
                              this.state.community.UserGroupStatus === "Owner"
                                ? true
                                : false
                            }
                            onApplyColorTheme={this.onApplyColorTheme}
                            handleFileSave={this.handleFileSave}
                            GroupName={this.state.community.GroupName}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={col9}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} style={{ margin: "10px" }}>
                          <img
                            src={
                              this.state.community.CoverUrl
                                ? this.state.community.CoverUrl
                                : DefaultCoverPic
                            }
                            alt="img"
                            style={{
                              width: imageSizes.cover_pic.width,
                              height: imageSizes.cover_pic.height,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ margin: "10px" }}>
                          <Typography className={subTitleWithIcon}>
                            <img
                              src={this.state.community.LogoUrl}
                              alt="img"
                              className={classes.leftIcon}
                              style={{
                                width: imageSizes.logo_icon.width,
                                height: imageSizes.logo_icon.height,
                              }}
                            />
                            <Typography className={classes.subHeaderSemiBold}>
                              {this.state.community.GroupName}
                              <Chip
                                label={CommonFunc.Capitalize(
                                  this.state.community.CommunityType
                                )}
                                size="small"
                                className={classes.chipCard}
                              />
                            </Typography>
                            <Button
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              disabled={
                                this.state.community.UserGroupStatus === "" ||
                                this.state.community.UserGroupStatus ===
                                  "Accept" ||
                                this.state.community.UserGroupStatus ===
                                  "Member"
                                  ? false
                                  : true
                              }
                              className={
                                this.state.community.UserGroupStatus ===
                                  "Owner" ||
                                this.state.community.UserGroupStatus ===
                                  "Member"
                                  ? classes.buttonOwnerMember
                                  : this.state.community.UserGroupStatus ===
                                      "Requested" ||
                                    this.state.community.UserGroupStatus ===
                                      "Accept"
                                  ? classes.buttonAcceptRequested
                                  : classes.buttonJoinDenied
                              }
                              size="small"
                              onClick={this.joinCommunityGroup}
                            >
                              <img
                                className={classes.leftIcon}
                                alt="member"
                                src={
                                  this.state.community.UserGroupStatus ===
                                  "Member"
                                    ? MemberIcon
                                    : this.state.community.UserGroupStatus ===
                                      "Owner"
                                    ? OwnerIcon
                                    : this.state.community.UserGroupStatus ===
                                      "Requested"
                                    ? RequestedIcon
                                    : this.state.community.UserGroupStatus ===
                                      "Accept"
                                    ? AcceptedIcon
                                    : this.state.community.UserGroupStatus ===
                                      "Denied"
                                    ? DeniedIcon
                                    : JoinIcon
                                }
                              />
                              {this.state.community.UserGroupStatus === ""
                                ? "Join"
                                : this.state.community.UserGroupStatus}
                            </Button>
                          </Typography>
                        </Grid>
                        <Grid item xs={9} style={{ margin: "10px" }}>
                          <Typography>
                            <span
                              style={{
                                fontSize: 13,
                                color: "black",
                                fontFamily: "poppins",
                              }}
                            >
                              Sort By :
                            </span>
                            <FormControl
                              variant="outlined"
                              size="small"
                              style={{ marginLeft: 5 }}
                            >
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={this.state.sortBy}
                                onChange={this.handleSortBy}
                                style={{
                                  backgroundColor: "white",
                                  fontSize: 13,
                                  fontFamily: "poppins",
                                  height: 30,
                                }}
                                name="sortBy"
                              >
                                <MenuItem value="1">Popular</MenuItem>
                                <MenuItem value="0">New</MenuItem>
                              </Select>
                            </FormControl>
                          </Typography>
                        </Grid>

                        {(this.state.community.UserGroupStatus === "Member" ||
                          this.state.community.UserGroupStatus === "Owner") &&
                          this.state.permissionLevel === "Create Topic" && (
                            <Grid item xs={2} style={{ margin: "10px" }}>
                              <Button
                                variant="contained"
                                className={classes.customButtonError}
                                size="small"
                                onClick={this.redirectToAddQuestion}
                                style={{ float: "right" }}
                              >
                                Add Topic
                              </Button>
                            </Grid>
                          )}
                      </Grid>

                      {(this.state.community.CommunityType === "public" ||
                        (this.state.community.CommunityType === "private" &&
                          (this.state.community.UserGroupStatus === "Member" ||
                            this.state.community.UserGroupStatus ===
                              "Owner"))) && (
                        <Grid container spacing={2}>
                          {this.state.communityTopics.map((topic, row) => (
                            <Grid
                              item
                              xs={12}
                              key={topic.TOPIC_ID}
                              style={{ margin: "10px", marginBottom: "0px" }}
                            >
                              <Card>
                                <CardHeader
                                  style={{
                                    cursor: "pointer",
                                    padding: "16px 16px 0px 16px",
                                  }}
                                  classes={{
                                    title: classes.title,
                                    subheader: classes.subTitle,
                                  }}
                                  onClick={(e) =>
                                    this.viewTopicDetails(
                                      topic.TOPIC_ID,
                                      topic.TOPIC_POST_TYPE,
                                      topic.TOPIC_TITLE,
                                      e
                                    )
                                  }
                                  title={
                                    <span
                                      style={{
                                        color: this.state.colorData.title,
                                      }}
                                    >
                                      {topic.TOPIC_TITLE}
                                    </span>
                                  }
                                  avatar={
                                    <IconButton
                                      aria-label="questions"
                                      style={{ marginTop: -32, padding: 0 }}
                                    >
                                      {topic.TOPIC_POST_TYPE === "QUESTION" ? (
                                        <img
                                          src={QuestionIcon}
                                          height={20}
                                          alt="QuestionIcon"
                                        />
                                      ) : (
                                        <img
                                          src={PollIcon}
                                          height={20}
                                          alt="PollIcon"
                                        />
                                      )}
                                    </IconButton>
                                  }
                                  subheader={
                                    <span
                                      style={{
                                        color: this.state.colorData.subtitle,
                                      }}
                                    >
                                      {"posted by " +
                                        topic.TOPIC_UPDT_USER_NM +
                                        " " +
                                        topic.TOPIC_UPDT_DTM +
                                        " ago"}
                                    </span>
                                  }
                                />
                                <CardContent style={{ marginLeft: 37 }}>
                                  <div>
                                    <Typography
                                      className={
                                        topic.TOPIC_DESC.substring(0, 23) ===
                                        '<iframe frameborder="0"'
                                          ? subHeaderX
                                          : subHeader
                                      }
                                      gutterBottom
                                      onClick={(e) =>
                                        this.viewTopicDetails(
                                          topic.TOPIC_ID,
                                          topic.TOPIC_POST_TYPE,
                                          topic.TOPIC_TITLE,
                                          e
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div
                                        style={{
                                          color: this.state.colorData.desc,
                                        }}
                                      >
                                        {mediaQuery ? (
                                          <span className="topics-ellipsis">
                                            {ReactHtmlParser(topic.TOPIC_DESC)}
                                          </span>
                                        ) : (
                                          <span className="topics-ellipsis">
                                            {topic.TOPIC_DESC.indexOf(
                                              "https://www.youtube.com/embed/"
                                            ) > -1 ? (
                                              <span>
                                                {ReactHtmlParser(
                                                  topic.TOPIC_DESC.replace(
                                                    'style="width:560px;height:315px;"',
                                                    ""
                                                  )
                                                )}
                                              </span>
                                            ) : (
                                              <span>
                                                {ReactHtmlParser(
                                                  topic.TOPIC_DESC
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </div>
                                    </Typography>
                                    {topic.TOPIC_POST_TYPE === "QUESTION" &&
                                      topic.TOPIC_IMG_URL && topic.TOPIC_IMG_URL.split(".")[topic.TOPIC_IMG_URL.split(".").length -1] != "pdf" && (
                                        <Typography
                                          onClick={(e) =>
                                            this.viewTopicDetails(
                                              topic.TOPIC_ID,
                                              topic.TOPIC_POST_TYPE,
                                              topic.TOPIC_TITLE,
                                              e
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={topic.TOPIC_IMG_URL}
                                            style={{
                                              width: "100%",
                                              padding: mediaQuery
                                                ? "10px 150px 10px 150px"
                                                : "10px 10px 10px 10px",
                                            }}
                                            alt="topicimg"
                                          />
                                        </Typography>
                                       )
                                      }
                                      {topic.TOPIC_POST_TYPE === "QUESTION" &&
                                        topic.TOPIC_IMG_URL && topic.TOPIC_IMG_URL.split(".")[topic.TOPIC_IMG_URL.split(".").length -1] == "pdf" && (
                                          <Typography>
                                            <Link href={topic.TOPIC_IMG_URL} target="_blank">
                                              <PictureAsPdfIcon/>
                                              {topic.TOPIC_IMG_URL.split("/")[topic.TOPIC_IMG_URL.split("/").length - 1]}
                                            </Link>
                                          </Typography>
                                        )
                                      }
      
                                    <Typography
                                      className={classes.hashTag}
                                      gutterBottom
                                      style={{
                                        marginTop:
                                          topic.TOPIC_POST_TYPE ===
                                            "QUESTION" && topic.TOPIC_IMG_URL
                                            ? "0px"
                                            : "10px",
                                      }}
                                    >
                                      {topic.TOPIC_HASHTAG &&
                                        topic.TOPIC_HASHTAG.map(
                                          (item, index) => (
                                            <Link
                                              onClick={() =>
                                                this.addToSearchInput(item)
                                              }
                                              style={{
                                                cursor: "pointer",
                                                marginLeft:
                                                  index === 0 ? 0 : 10,
                                              }}
                                            >
                                              {item}
                                            </Link>
                                          )
                                        )}
                                    </Typography>
                                  </div>
                                  <Typography
                                    style={{ marginTop: "10px" }}
                                    className={wrapIcon}
                                    color="textSecondary"
                                  >
                                    <IconButton
                                      onClick={() =>
                                        this.saveUserQueLike(
                                          topic.TOPIC_ID,
                                          topic.TOPIC_POST_TYPE,
                                          topic.TOPIC_TITLE,
                                          topic.LIKE_IND
                                        )
                                      }
                                      style={{
                                        padding: 0,
                                        fontSize: 13,
                                        fontFamily: "poppins",
                                        marginLeft: "0px",
                                      }}
                                      aria-label="share"
                                    >
                                      <img
                                        src={
                                          topic.TOPIC_POST_TYPE === "QUESTION"
                                            ? topic.LIKE_IND === 1
                                              ? LikeIconColor
                                              : LikeIcon
                                            : topic.VOTE_IND === 1
                                            ? VoteIconColor
                                            : VoteIcon
                                        }
                                        height={15}
                                        style={{ marginRight: "3px" }}
                                        alt="quepoll"
                                      />
                                      {topic.TOPIC_LIKE_VOTE}{" "}
                                      {topic.TOPIC_POST_TYPE === "QUESTION"
                                        ? mediaQuery
                                          ? "Likes"
                                          : ""
                                        : topic.TOPIC_LIKE_VOTE > 1
                                        ? "Votes"
                                        : "Vote"}
                                    </IconButton>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      className={classes.marginLeftRight}
                                    />
                                    {topic.TOPIC_POST_TYPE === "POLL" && (
                                      <IconButton
                                        onClick={() =>
                                          this.saveUserPollLike(
                                            topic.TOPIC_ID,
                                            topic.TOPIC_POST_TYPE,
                                            topic.LIKE_IND
                                          )
                                        }
                                        style={{
                                          padding: 0,
                                          fontSize: 13,
                                          fontFamily: "poppins",
                                          marginLeft: "0px",
                                        }}
                                        aria-label="share"
                                      >
                                        <img
                                          src={
                                            topic.LIKE_IND === 1
                                              ? LikeIconColor
                                              : LikeIcon
                                          }
                                          height={15}
                                          style={{ marginRight: "3px" }}
                                          alt="likeicon"
                                        />
                                        {topic.TOPIC_POLL_LIKE}{" "}
                                        {mediaQuery ? " Likes" : ""}
                                      </IconButton>
                                    )}
                                    {topic.TOPIC_POST_TYPE === "POLL" && (
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                        className={classes.marginLeftRight}
                                      />
                                    )}
                                    <img
                                      src={MessageIcon}
                                      height={15}
                                      style={{ marginRight: "3px" }}
                                      alt="MessageIcon"
                                    />
                                    <CommentCount
                                      website-id={this.state.websiteId}
                                      page-id={
                                        topic.TOPIC_POST_TYPE === "POLL"
                                          ? "poll" + topic.TOPIC_ID
                                          : "que" + topic.TOPIC_ID
                                      }
                                    />
                                    &nbsp;
                                    {mediaQuery ? "Comments" : ""}
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      className={classes.marginLeftRight}
                                    />
                                    <VisibilityIcon
                                      className={classes.iconSize}
                                      style={{ marginRight: "3px" }}
                                    />
                                    <span
                                      style={{
                                        fontSize: 13,
                                        fontFamily: "poppins",
                                      }}
                                    >
                                      {topic.TOPIC_VIEW}
                                    </span>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}

                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <div className={classes.noTopics}>
                            {this.state.community.UserGroupStatus ===
                            "Requested"
                              ? "Posts are hidden waiting for admins approval"
                              : this.state.communityTopics &&
                                this.state.communityTopics.length === 0 &&
                                this.state.isUserDefaultGroupPermission
                              ? "No topics created, create by clicking Add Topic"
                              : this.state.communityTopics &&
                                this.state.communityTopics.length === 0
                              ? "No topics created"
                              : ""}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Grid container spacing={0} style={{ marginTop: 90 }}>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <img
                        src={
                          this.state.community.CoverUrl
                            ? this.state.community.CoverUrl
                            : DefaultCoverPic
                        }
                        alt="img"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Typography className={subTitleWithIcon}>
                        <img
                          src={this.state.community.LogoUrl}
                          alt="img"
                          className={classes.leftIcon}
                          style={{
                            width: imageSizes.logo_icon.width,
                            height: imageSizes.logo_icon.height,
                          }}
                        />
                        <Typography className={classes.subHeaderSemiBold}>
                          {this.state.community.GroupName}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Typography className={subTitleWithIcon}>
                        <Typography className={classes.subHeaderSemiBold}>
                          <Chip
                            label={CommonFunc.Capitalize(
                              this.state.community.CommunityType
                            )}
                            size="small"
                            className={classes.chipCardNoMargin}
                          />
                        </Typography>
                        <Button
                          style={{ marginLeft: 10 }}
                          variant="contained"
                          disabled={
                            this.state.community.UserGroupStatus === "" ||
                            this.state.community.UserGroupStatus === "Accept" ||
                            this.state.community.UserGroupStatus === "Member"
                              ? false
                              : true
                          }
                          className={
                            this.state.community.UserGroupStatus === "Owner" ||
                            this.state.community.UserGroupStatus === "Member"
                              ? classes.buttonOwnerMember
                              : this.state.community.UserGroupStatus ===
                                  "Requested" ||
                                this.state.community.UserGroupStatus ===
                                  "Accept"
                              ? classes.buttonAcceptRequested
                              : classes.buttonJoinDenied
                          }
                          size="small"
                          onClick={this.joinCommunityGroup}
                        >
                          <img
                            className={classes.leftIcon}
                            alt="member"
                            src={
                              this.state.community.UserGroupStatus === "Member"
                                ? MemberIcon
                                : this.state.community.UserGroupStatus ===
                                  "Owner"
                                ? OwnerIcon
                                : this.state.community.UserGroupStatus ===
                                  "Requested"
                                ? RequestedIcon
                                : this.state.community.UserGroupStatus ===
                                  "Accept"
                                ? AcceptedIcon
                                : this.state.community.UserGroupStatus ===
                                  "Denied"
                                ? DeniedIcon
                                : JoinIcon
                            }
                          />
                          {this.state.community.UserGroupStatus === ""
                            ? "Join"
                            : this.state.community.UserGroupStatus}
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ marginTop: "20px" }}>
                      <Typography>
                        <span style={{ fontSize: 11 }}>Sort By :</span>
                        <FormControl variant="outlined" size="small">
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={this.state.sortBy}
                            onChange={this.handleSortBy}
                            style={{
                              backgroundColor: "white",
                              fontSize: "0.7rem",
                            }}
                            name="sortBy"
                          >
                            <MenuItem value="1">Popular</MenuItem>
                            <MenuItem value="0">New</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </Grid>

                    {(this.state.community.UserGroupStatus === "Member" ||
                      this.state.community.UserGroupStatus === "Owner") &&
                      this.state.permissionLevel === "Create Topic" && (
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Button
                            variant="contained"
                            className={classes.customButtonError}
                            size="small"
                            onClick={this.redirectToAddQuestion}
                            style={{ float: "right" }}
                          >
                            Add Topic
                          </Button>
                        </Grid>
                      )}
                  </Grid>

                  {(this.state.community.CommunityType === "public" ||
                    (this.state.community.CommunityType === "private" &&
                      (this.state.community.UserGroupStatus === "Member" ||
                        this.state.community.UserGroupStatus === "Owner"))) && (
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                      {this.state.communityTopics.map((topic, row) => (
                        <Grid item xs={12} key={topic.TOPIC_ID}>
                          <Card>
                            <CardHeader
                              style={{
                                cursor: "pointer",
                                padding: "16px 16px 0px 16px",
                              }}
                              classes={{
                                title: classes.title,
                                subheader: classes.subTitle,
                              }}
                              onClick={(e) =>
                                this.viewTopicDetails(
                                  topic.TOPIC_ID,
                                  topic.TOPIC_POST_TYPE,
                                  topic.TOPIC_TITLE,
                                  e
                                )
                              }
                              title={
                                <span
                                  style={{ color: this.state.colorData.title }}
                                >
                                  {topic.TOPIC_TITLE}
                                </span>
                              }
                              avatar={
                                <IconButton
                                  aria-label="questions"
                                  style={{ marginTop: -32, padding: 0 }}
                                >
                                  {topic.TOPIC_POST_TYPE === "QUESTION" ? (
                                    <img
                                      src={QuestionIcon}
                                      height={20}
                                      alt="QuestionIcon"
                                    />
                                  ) : (
                                    <img
                                      src={PollIcon}
                                      height={20}
                                      alt="PollIcon"
                                    />
                                  )}
                                </IconButton>
                              }
                              subheader={
                                <span
                                  style={{
                                    color: this.state.colorData.subtitle,
                                  }}
                                >
                                  {"posted by " +
                                    topic.TOPIC_UPDT_USER_NM +
                                    " " +
                                    topic.TOPIC_UPDT_DTM +
                                    " ago"}
                                </span>
                              }
                            />
                            <CardContent style={{ marginLeft: 37 }}>
                              <div>
                                <Typography
                                  className={
                                    topic.TOPIC_DESC.substring(0, 23) ===
                                    '<iframe frameborder="0"'
                                      ? subHeaderX
                                      : subHeader
                                  }
                                  gutterBottom
                                  onClick={(e) =>
                                    this.viewTopicDetails(
                                      topic.TOPIC_ID,
                                      topic.TOPIC_POST_TYPE,
                                      topic.TOPIC_TITLE,
                                      e
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    style={{ color: this.state.colorData.desc }}
                                  >
                                    {mediaQuery ? (
                                      <span className="topics-ellipsis">
                                        {ReactHtmlParser(topic.TOPIC_DESC)}
                                      </span>
                                    ) : (
                                      <span className="topics-ellipsis">
                                        {topic.TOPIC_DESC.indexOf(
                                          "https://www.youtube.com/embed/"
                                        ) > -1 ? (
                                          <span>
                                            {ReactHtmlParser(
                                              topic.TOPIC_DESC.replace(
                                                'style="width:560px;height:315px;"',
                                                ""
                                              )
                                            )}
                                          </span>
                                        ) : (
                                          <span>
                                            {ReactHtmlParser(topic.TOPIC_DESC)}
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </Typography>
                                {topic.TOPIC_POST_TYPE === "QUESTION" &&
                                  topic.TOPIC_IMG_URL && topic.TOPIC_IMG_URL.split(".")[topic.TOPIC_IMG_URL.split(".").length -1] != "pdf" && (
                                    <Typography
                                      onClick={(e) =>
                                        this.viewTopicDetails(
                                          topic.TOPIC_ID,
                                          topic.TOPIC_POST_TYPE,
                                          topic.TOPIC_TITLE,
                                          e
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={topic.TOPIC_IMG_URL}
                                        style={{
                                          width: "100%",
                                          padding: mediaQuery
                                            ? "10px 150px 10px 150px"
                                            : "10px 10px 10px 10px",
                                        }}
                                        alt="topicimg"
                                      />
                                    </Typography>
                                   )
                                  }
                                  {topic.TOPIC_POST_TYPE === "QUESTION" &&
                                    topic.TOPIC_IMG_URL && topic.TOPIC_IMG_URL.split(".")[topic.TOPIC_IMG_URL.split(".").length -1] == "pdf" && (
                                      <Typography>
                                        <Link href={topic.TOPIC_IMG_URL} target="_blank">
                                          <PictureAsPdfIcon/>
                                          {topic.TOPIC_IMG_URL.split("/")[topic.TOPIC_IMG_URL.split("/").length - 1]}
                                        </Link>
                                      </Typography>
                                    )
                                  }
                                <Typography
                                  className={classes.hashTag}
                                  gutterBottom
                                  style={{
                                    marginTop:
                                      topic.TOPIC_POST_TYPE === "QUESTION" &&
                                      topic.TOPIC_IMG_URL
                                        ? "0px"
                                        : "10px",
                                  }}
                                >
                                  {topic.TOPIC_HASHTAG &&
                                    topic.TOPIC_HASHTAG.map((item, index) => (
                                      <Link
                                        onClick={() =>
                                          this.addToSearchInput(item)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: index === 0 ? 0 : 10,
                                        }}
                                      >
                                        {item}
                                      </Link>
                                    ))}
                                </Typography>
                              </div>
                              <Typography
                                style={{ marginTop: "10px" }}
                                className={wrapIcon}
                                color="textSecondary"
                              >
                                <IconButton
                                  onClick={() =>
                                    this.saveUserQueLike(
                                      topic.TOPIC_ID,
                                      topic.TOPIC_POST_TYPE,
                                      topic.TOPIC_TITLE,
                                      topic.LIKE_IND
                                    )
                                  }
                                  style={{
                                    padding: 0,
                                    fontSize: 13,
                                    fontFamily: "poppins",
                                    marginLeft: "0px",
                                  }}
                                  aria-label="share"
                                >
                                  <img
                                    src={
                                      topic.TOPIC_POST_TYPE === "QUESTION"
                                        ? topic.LIKE_IND === 1
                                          ? LikeIconColor
                                          : LikeIcon
                                        : topic.VOTE_IND === 1
                                        ? VoteIconColor
                                        : VoteIcon
                                    }
                                    height={15}
                                    style={{ marginRight: "3px" }}
                                    alt="likeicon"
                                  />
                                  {topic.TOPIC_LIKE_VOTE}{" "}
                                  {topic.TOPIC_POST_TYPE === "QUESTION"
                                    ? mediaQuery
                                      ? "Likes"
                                      : ""
                                    : topic.TOPIC_LIKE_VOTE > 1
                                    ? "Votes"
                                    : "Vote"}
                                </IconButton>
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  className={classes.marginLeftRight}
                                />
                                {topic.TOPIC_POST_TYPE === "POLL" && (
                                  <IconButton
                                    onClick={() =>
                                      this.saveUserPollLike(
                                        topic.TOPIC_ID,
                                        topic.TOPIC_POST_TYPE,
                                        topic.LIKE_IND
                                      )
                                    }
                                    style={{
                                      padding: 0,
                                      fontSize: 13,
                                      fontFamily: "poppins",
                                      marginLeft: "0px",
                                    }}
                                    aria-label="share"
                                  >
                                    <img
                                      src={
                                        topic.LIKE_IND === 1
                                          ? LikeIconColor
                                          : LikeIcon
                                      }
                                      height={15}
                                      style={{ marginRight: "3px" }}
                                      alt="likeicon"
                                    />
                                    {topic.TOPIC_POLL_LIKE}{" "}
                                    {mediaQuery ? " Likes" : ""}
                                  </IconButton>
                                )}
                                {topic.TOPIC_POST_TYPE === "POLL" && (
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.marginLeftRight}
                                  />
                                )}
                                <img
                                  src={MessageIcon}
                                  height={15}
                                  style={{ marginRight: "3px" }}
                                  alt="MessageIcon"
                                />
                                <CommentCount
                                  website-id={this.state.websiteId}
                                  page-id={
                                    topic.TOPIC_POST_TYPE === "POLL"
                                      ? "poll" + topic.TOPIC_ID
                                      : "que" + topic.TOPIC_ID
                                  }
                                />
                                &nbsp;
                                {mediaQuery ? "Comments" : ""}
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  className={classes.marginLeftRight}
                                />
                                <VisibilityIcon
                                  className={classes.iconSize}
                                  style={{ marginRight: "3px" }}
                                />
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontFamily: "poppins",
                                  }}
                                >
                                  {topic.TOPIC_VIEW}
                                </span>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className={classes.noTopics}>
                        {this.state.community.UserGroupStatus === "Requested"
                          ? "Posts are hidden waiting for admins approval"
                          : this.state.communityTopics &&
                            this.state.communityTopics.length === 0 &&
                            this.state.isUserDefaultGroupPermission
                          ? "No topics created, create by clicking Add Topic"
                          : this.state.communityTopics &&
                            this.state.communityTopics.length === 0
                          ? "No topics created"
                          : ""}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const communityData = state.communityReducer;
  return { communityData };
}

export default withRouter(
  withStyles(useStyles)(
    withMediaQuery("(min-width:600px)")(connect(mapStateToProps)(CommunityHome))
  )
);
