import React, { Component, Fragment } from 'react';
import {
    Grid, withStyles, Button, Card, CardContent, Typography, useMediaQuery
} from '@material-ui/core';
import { useStyles } from '../../components/common/useStyles';
import '../../components/common/Common.css'
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForward';
import routeService from '../../services/Routing';
import constant from '../../components/common/Constants';
import api from "../../components/common/APIValues";
import { get } from "../../services/ApiService";

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class CommunityTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if (!this.props.userEmail) {
            routeService.routePage(this.props, constant.home);
        }
        this.validateUserDefaultGroupPermission();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    validateUserDefaultGroupPermission = () => {
        const permissionName = 'Create Community';
        const originName = window.location.origin.replace('www.', '');
        const inputs = `GroupId=0&Env=${api.env}&UserId=${this.props.userEmail}&PermissionName=${permissionName}&originName=${originName}`;
    
        get("Permission/ValidateUserPermission?" + inputs)
          .then((response) => {
            if(!response.PermissionName) {
                routeService.routePage(this.props, constant.home);
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });
    };

    publicCommunity = () => {
        routeService.routePage(this.props, constant.publicCommunity);
    }

    privateCommunity = () => {
        routeService.routePage(this.props, constant.privateCommunity);
    }

    render() {
        const { classes, mediaQuery } = this.props;
        const subTitleWithIcon = classNames(classes.subHeader, classes.alignTextWithIcon);
        const col6 = mediaQuery ? 6 : 12;

        return (
            <Fragment>
                <Typography className={classes.title} style={{ marginTop: mediaQuery ? '55px' : '90px' }}>
                    Create Community
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={col6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} style={{ margin: '10px' }}>
                                        <Button fullWidth variant="contained" className={classes.customButtonInfo}
                                            size="large" onClick={this.publicCommunity}>
                                            Public
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '10px' }}>
                                        {!mediaQuery ?
                                            <Typography className={subTitleWithIcon}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        <ArrowForward className={classes.leftIcon} />
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        Any member can join the group automatically
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ArrowForward className={classes.leftIcon} />
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        Posts are publicly accessible
                                                    </Grid>
                                                </Grid>
                                            </Typography> :
                                            <Fragment>
                                                <Typography className={subTitleWithIcon}>
                                                    <ArrowForward className={classes.leftIcon} />
                                                    Any member can join the group automatically
                                                </Typography>
                                                <Typography className={subTitleWithIcon}>
                                                    <ArrowForward className={classes.leftIcon} />
                                                    Posts are publicly accessible
                                                </Typography>
                                            </Fragment>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={col6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} style={{ margin: '10px' }}>
                                        <Button fullWidth variant="contained" className={classes.customButtonFocus}
                                            size="large" onClick={this.privateCommunity}>
                                            Private
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '10px' }}>
                                        {!mediaQuery ?
                                            <Typography className={subTitleWithIcon}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        <ArrowForward className={classes.leftIcon} />
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        Member can join the group through invite or accepting the request
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ArrowForward className={classes.leftIcon} />
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        Posts are publicly not accessible
                                                    </Grid>
                                                </Grid>
                                            </Typography> :
                                            <Fragment>
                                                <Typography className={subTitleWithIcon}>
                                                    <ArrowForward className={classes.leftIcon} />
                                                    Member can join the group through invite or accepting the request
                                                </Typography>
                                                <Typography className={subTitleWithIcon}>
                                                    <ArrowForward className={classes.leftIcon} />
                                                    Posts are publicly not accessible
                                                </Typography>
                                            </Fragment>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(CommunityTypes)))