export const setLoginInfo = (name, googleId, imageUrl, email) => {
  localStorage.setItem("user_name", name);
  localStorage.setItem("user_id", googleId);
  localStorage.setItem("image_url", imageUrl);
  localStorage.setItem("user_encoded_email", email);
};

export const setUserEmail = (email) => {
  localStorage.setItem("user_email", email);
};

export const setUserAliasName = (aliasName) => {
  localStorage.setItem("user_alias_name", aliasName);
};

export const setHyvorTalkSignon = (hash, userData) => {
  localStorage.setItem("hash_hyvor_talk", hash);
  localStorage.setItem("user_data_hyvor_talk", userData);
};

export const setHyvorTalkWebsiteId = (websiteId) => {
  localStorage.setItem("htalkwebsite", btoa(websiteId));
};

export const getHyvorTalkWebsiteId = () => {
  return atob(localStorage.getItem("htalkwebsite"));
};
