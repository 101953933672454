import React, { Component, Fragment } from 'react';
import { 
    withStyles, Divider, useMediaQuery,
} from '@material-ui/core';
import { useStyles } from '../../components/common/useStyles';
import '../../components/common/Common.css'
import { withRouter } from 'react-router-dom';
import constant from '../../components/common/Constants';
import routeService from '../../services/Routing';

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);    
    return <Component mediaQuery={mediaQuery} {...props} />;
};

class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.searchValue !== prevProps.searchValue) {
            routeService.routePage(this.props, constant.home);
        }
    }

    render() {
        const { classes, mediaQuery } = this.props;

        return (
            <Fragment>
                <h1 style={{marginTop: mediaQuery ? '65px' : '110px'}} 
                    className={classes.aboutTitle}>
                        TERMS AND CONDITIONS
                </h1>

                <Divider orientation="horizontal" style={{marginTop: 20}} />

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <b>Last updated March 17, 2022</b>
                </div>
                
                <h3 className={classes.aboutSubTitle}>
                    1. AGREEMENT TO TERMS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        These Terms of Use constitute a legally binding agreement made between you, whether personally 
                        or on behalf of an entity (“you”) and __________ <b>("Company," “we," “us," or “our”)</b>, concerning 
                        your access to and use of the <a href='https://edwhat.com/'>https://edwhat.com</a> website as 
                        well as any other media form, media channel, mobile website or mobile application related, 
                        linked, or otherwise connected thereto (collectively, the “Site”). We are registered in India 
                        and have our registered office at __________, Bengaluru, Karnatka 560017. You agree that by 
                        accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. 
                        IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE 
                        SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>
                    <p>
                        Supplemental terms and conditions or documents that may be posted on the Site from time to time 
                        are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, 
                        to make changes or modifications to these Terms of Use at any time and for any reason. We will alert 
                        you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any 
                        right to receive specific notice of each such change. Please ensure that you check the applicable 
                        Terms every time you use our Site so that you understand which Terms apply. You will be subject to, 
                        and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms 
                        of Use by your continued use of the Site after the date such revised Terms of Use are posted.
                    </p>
                    <p>
                        The information provided on the Site is not intended for distribution to or use by any person or entity 
                        in any jurisdiction or country where such distribution or use would be contrary to law or regulation or 
                        which would subject us to any registration requirement within such jurisdiction or country. Accordingly, 
                        those persons who choose to access the Site from other locations do so on their own initiative and are solely 
                        responsible for compliance with local laws, if and to the extent local laws are applicable.
                    </p>
                    <p>
                        The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not 
                        permitted to use or register for the Site.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    2. INTELLECTUAL PROPERTY RIGHTS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        Unless otherwise indicated, the Site is our proprietary property and all source code, 
                        databases, functionality, software, website designs, audio, video, text, photographs, and 
                        graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and 
                        logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and 
                        are protected by copyright and trademark laws and various other intellectual property rights 
                        and unfair competition laws of the United States, international copyright laws, and 
                        international conventions. The Content and the Marks are provided on the Site “AS IS” for your 
                        information and personal use only. Except as expressly provided in these Terms of Use, no part 
                        of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, 
                        posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or 
                        otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                    </p>
                    <p>
                        Provided that you are eligible to use the Site, you are granted a limited license to access 
                        and use the Site and to download or print a copy of any portion of the Content to which you 
                        have properly gained access solely for your personal, non-commercial use. We reserve all 
                        rights not expressly granted to you in and to the Site, the Content and the Marks.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    3. USER REPRESENTATIONS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        By using the Site, you represent and warrant that: (1) all registration information you 
                        submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of 
                        such information and promptly update such registration information as necessary; (3) you 
                        have the legal capacity and you agree to comply with these Terms of Use; (4) you are not a 
                        minor in the jurisdiction in which you reside; (5)you will not access the Site through 
                        automated or non-human means, whether through a bot, script, or otherwise; (6) you will not 
                        use the Site for any illegal or unauthorized purpose; and (7) your use of the Site will not 
                        violate any applicable law or regulation.
                    </p>
                    <p>
                        If you provide any information that is untrue, inaccurate, not current, or incomplete, we 
                        have the right to suspend or terminate your account and refuse any and all current or future 
                        use of the Site (or any portion thereof).
                    </p>
                </div>
                <h3 className={classes.aboutSubTitle}>
                    4. USER REGISTRATION
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        You may be required to register with the Site. You agree to keep your password confidential 
                        and will be responsible for all use of your account and password. We reserve the right to 
                        remove, reclaim, or change a username you select if we determine, in our sole discretion, 
                        that such username is inappropriate, obscene, or otherwise objectionable.
                    </p>
                </div>
                <h3 className={classes.aboutSubTitle}>
                    5. PROHIBITED ACTIVITIES
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        You may not access or use the Site for any purpose other than that for which we make the 
                        Site available. The Site may not be used in connection with any commercial endeavors except 
                        those that are specifically endorsed or approved by us.
                    </p>
                    <p>
                        As a user of the Site, you agree not to:
                    </p>
                    <ul>
                        <li>
                            Systematically retrieve data or other content from the Site to create or compile, 
                            directly or indirectly, a collection, compilation, database, or directory without 
                            written permission from us.
                        </li>
                        <li>
                            Trick, defraud, or mislead us and other users, especially in any attempt to learn 
                            sensitive account information such as user passwords.
                        </li>
                        <li>
                            Circumvent, disable, or otherwise interfere with security-related features of the Site, 
                            including features that prevent or restrict the use or copying of any Content or enforce 
                            limitations on the use of the Site and/or the Content contained therein.
                        </li>
                        <li>
                            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.
                        </li>
                        <li>
                            Use any information obtained from the Site in order to harass, abuse, or harm another person.
                        </li>
                        <li>
                            Make improper use of our support services or submit false reports of abuse or misconduct.
                        </li>
                        <li>
                            Use the Site in a manner inconsistent with any applicable laws or regulations.
                        </li>
                        <li>
                            Engage in unauthorized framing of or linking to the Site.
                        </li>
                        <li>
                            Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or 
                            other material, including excessive use of capital letters and spamming (continuous 
                            posting of repetitive text), that interferes with any party’s uninterrupted use and 
                            enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the 
                            use, features, functions, operation, or maintenance of the Site.
                        </li>
                        <li>
                            Engage in any automated use of the system, such as using scripts to send comments or 
                            messages, or using any data mining, robots, or similar data gathering and extraction 
                            tools.
                        </li>
                        <li>
                            Delete the copyright or other proprietary rights notice from any Content.
                        </li>
                        <li>
                            Attempt to impersonate another user or person or use the username of another user.
                        </li>
                        <li>
                            Upload or transmit (or attempt to upload or to transmit) any material that acts as a 
                            passive or active information collection or transmission mechanism, including without 
                            limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, 
                            or other similar devices (sometimes referred to as “spyware” or “passive collection 
                            mechanisms” or “pcms”).
                        </li>
                        <li>
                            Interfere with, disrupt, or create an undue burden on the Site or the networks or services 
                            connected to the Site.
                        </li>
                        <li>
                            Harass, annoy, intimidate, or threaten any of our employees or agents engaged in 
                            providing any portion of the Site to you.
                        </li>
                        <li>
                            Attempt to bypass any measures of the Site designed to prevent or restrict access to 
                            the Site, or any portion of the Site.
                        </li>
                        <li>
                            Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
                        </li>
                        <li>
                            Except as permitted by applicable law, decipher, decompile, disassemble, or reverse 
                            engineer any of the software comprising or in any way making up a part of the Site.
                        </li>
                        <li>
                            Except as may be the result of standard search engine or Internet browser usage, use, 
                            launch, develop, or distribute any automated system, including without limitation, any 
                            spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or 
                            using or launching any unauthorized script or other software.
                        </li>
                        <li>
                            Use a buying agent or purchasing agent to make purchases on the Site.
                        </li>
                        <li>
                            Make any unauthorized use of the Site, including collecting usernames and/or email 
                            addresses of users by electronic or other means for the purpose of sending unsolicited 
                            email, or creating user accounts by automated means or under false pretenses.
                        </li>
                        <li>
                            Use the Site as part of any effort to compete with us or otherwise use the Site and/or 
                            the Content for any revenue-generating endeavor or commercial enterprise.
                        </li>
                        <li>
                            Use the Site to advertise or offer to sell goods and services.
                        </li>
                        <li>
                            Sell or otherwise transfer your profile.
                        </li>
                    </ul>
                </div>
                <h3 className={classes.aboutSubTitle}>
                    6. USER GENERATED CONTRIBUTIONS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        The Site may invite you to chat, contribute to, or participate in blogs, message boards, 
                        online forums, and other functionality, and may provide you with the opportunity to create, 
                        submit, post, display, transmit, perform, publish, distribute, or broadcast content and 
                        materials to us or on the Site, including but not limited to text, writings, video, audio, 
                        photographs, graphics, comments, suggestions, or personal information or other material 
                        (collectively, "Contributions"). Contributions may be viewable by other users of the Site 
                        and through third-party websites. As such, any Contributions you transmit may be treated as 
                        non-confidential and non-proprietary. When you create or make available any Contributions, 
                        you thereby represent and warrant that:
                    </p>
                    <ul>
                        <li>
                            The creation, distribution, transmission, public display, or performance, and the accessing, 
                            downloading, or copying of your Contributions do not and will not infringe the proprietary rights, 
                            including but not limited to the copyright, patent, trademark, trade secret, or moral rights of 
                            any third party.
                        </li>
                        <li>
                            You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms of Use.
                        </li>
                        <li>
                            You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.
                        </li>
                        <li>
                            Your Contributions are not false, inaccurate, or misleading.
                        </li>
                        <li>
                            Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
                        </li>
                        <li>
                            Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
                        </li>
                        <li>
                            Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
                        </li>
                        <li>
                            Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.
                        </li>
                        <li>
                            Your Contributions do not violate any applicable law, regulation, or rule.
                        </li>
                        <li>
                            Your Contributions do not violate the privacy or publicity rights of any third party.
                        </li>
                        <li>
                            Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.
                        </li>
                        <li>
                            Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
                        </li>
                        <li>
                            Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.
                        </li>
                    </ul>
                    <p>
                        Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    7. CONTRIBUTION LICENSE
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        By posting your Contributions to any part of the Site or making Contributions accessible to 
                        the Site by linking your account from the Site to any of your social networking accounts, 
                        you automatically grant, and you represent and warrant that you have the right to grant, 
                        to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, 
                        royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, 
                        disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly 
                        perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), 
                        and distribute such Contributions (including, without limitation, your image and voice) 
                        for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, 
                        or incorporate into other works, such Contributions, and grant and authorize sublicenses of 
                        the foregoing. The use and distribution may occur in any media formats and through any media 
                        channels.
                    </p>
                    <p>
                        This license will apply to any form, media, or technology now known or hereafter developed, 
                        and includes our use of your name, company name, and franchise name, as applicable, and any 
                        of the trademarks, service marks, trade names, logos, and personal and commercial images 
                        you provide. You waive all moral rights in your Contributions, and you warrant that moral 
                        rights have not otherwise been asserted in your Contributions.
                    </p>
                    <p>
                        We do not assert any ownership over your Contributions. You retain full ownership of all 
                        of your Contributions and any intellectual property rights or other proprietary rights 
                        associated with your Contributions. We are not liable for any statements or representations 
                        in your Contributions provided by you in any area on the Site. You are solely responsible 
                        for your Contributions to the Site and you expressly agree to exonerate us from any and all 
                        responsibility and to refrain from any legal action against us regarding your Contributions.
                    </p>
                    <p>
                        We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise 
                        change any Contributions; (2) to re-categorize any Contributions to place them in more 
                        appropriate locations on the Site; and (3) to pre-screen or delete any Contributions at 
                        any time and for any reason, without notice. We have no obligation to monitor your 
                        Contributions.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    8. GUIDELINES FOR REVIEWS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        We may provide you areas on the Site to leave reviews or ratings. When posting a review, 
                        you must comply with the following criteria: (1) you should have firsthand experience with 
                        the person/entity being reviewed; (2) your reviews should not contain offensive profanity, 
                        or abusive, racist, offensive, or hate language; (3) your reviews should not contain 
                        discriminatory references based on religion, race, gender, national origin, age, marital 
                        status, sexual orientation, or disability; (4) your reviews should not contain references 
                        to illegal activity; (5) you should not be affiliated with competitors if posting negative 
                        reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you may 
                        not post any false or misleading statements; and (8) you may not organize a campaign 
                        encouraging others to post reviews, whether positive or negative.
                    </p>
                    <p>
                        We may accept, reject, or remove reviews in our sole discretion. We have absolutely no 
                        obligation to screen reviews or to delete reviews, even if anyone considers reviews 
                        objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily 
                        represent our opinions or the views of any of our affiliates or partners. We do not assume 
                        liability for any review or for any claims, liabilities, or losses resulting from any review. 
                        By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, 
                        royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, 
                        modify, translate, transmit by any means, display, perform, and/or distribute all content 
                        relating to reviews.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    9. SOCIAL MEDIA
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        As part of the functionality of the Site, you may link your account with online accounts 
                        you have with third-party service providers (each such account, a “Third-Party Account”) 
                        by either: (1) providing your Third-Party Account login information through the Site; or (2) 
                        allowing us to access your Third-Party Account, as is permitted under the applicable terms 
                        and conditions that govern your use of each Third-Party Account. You represent and warrant 
                        that you are entitled to disclose your Third-Party Account login information to us and/or 
                        grant us access to your Third-Party Account, without breach by you of any of the terms and 
                        conditions that govern your use of the applicable Third-Party Account, and without obligating 
                        us to pay any fees or making us subject to any usage limitations imposed by the third-party 
                        service provider of the Third-Party Account. By granting us access to any Third-Party 
                        Accounts, you understand that (1) we may access, make available, and store (if applicable) any 
                        content that you have provided to and stored in your Third-Party Account (the “Social Network 
                        Content”) so that it is available on and through the Site via your account, including without 
                        limitation any friend lists and (2) we may submit to and receive from your Third-Party Account 
                        additional information to the extent you are notified when you link your account with the 
                        Third-Party Account. Depending on the Third-Party Accounts you choose and subject to the privacy 
                        settings that you have set in such Third-Party Accounts, personally identifiable information 
                        that you post to your Third-Party Accounts may be available on and through your account on the 
                        Site. Please note that if a Third-Party Account or associated service becomes unavailable or our 
                        access to such Third Party Account is terminated by the third-party service provider, then Social 
                        Network Content may no longer be available on and through the Site. You will have the ability to 
                        disable the connection between your account on the Site and your Third-Party Accounts at any time. 
                        PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR 
                        THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE 
                        PROVIDERS. We make no effort to review any Social Network Content for any purpose, including but 
                        not limited to, for accuracy, legality, or non-infringement, and we are not responsible for any 
                        Social Network Content. You acknowledge and agree that we may access your email address book 
                        associated with a Third-Party Account and your contacts list stored on your mobile device or tablet 
                        computer solely for purposes of identifying and informing you of those contacts who have also 
                        registered to use the Site. You can deactivate the connection between the Site and your Third-Party 
                        Account by contacting us using the contact information below or through your account settings 
                        (if applicable). We will attempt to delete any information stored on our servers that was obtained 
                        through such Third-Party Account, except the username and profile picture that become associated 
                        with your account.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    10. SUBMISSIONS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or 
                        other information regarding the Site ("Submissions") provided by you to us are 
                        non-confidential and shall become our sole property. We shall own exclusive rights, 
                        including all intellectual property rights, and shall be entitled to the unrestricted use 
                        and dissemination of these Submissions for any lawful purpose, commercial or otherwise, 
                        without acknowledgment or compensation to you. You hereby waive all moral rights to any 
                        such Submissions, and you hereby warrant that any such Submissions are original with you or 
                        that you have the right to submit such Submissions. You agree there shall be no recourse 
                        against us for any alleged or actual infringement or misappropriation of any proprietary 
                        right in your Submissions.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    11. SITE MANAGEMENT
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        We reserve the right, but not the obligation, to: (1) monitor the Site for violations of 
                        these Terms of Use; (2) take appropriate legal action against anyone who, in our sole 
                        discretion, violates the law or these Terms of Use, including without limitation, 
                        reporting such user to law enforcement authorities; (3) in our sole discretion and without 
                        limitation, refuse, restrict access to, limit the availability of, or disable (to the 
                        extent technologically feasible) any of your Contributions or any portion thereof; (4) in 
                        our sole discretion and without limitation, notice, or liability, to remove from the Site 
                        or otherwise disable all files and content that are excessive in size or are in any way 
                        burdensome to our systems; and (5) otherwise manage the Site in a manner designed to 
                        protect our rights and property and to facilitate the proper functioning of the Site.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    12. PRIVACY POLICY
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        We care about data privacy and security. Please review our Privacy 
                        Policy: <a href="https://edwhat.com/#/privacypolicy">https://edwhat.com/#/privacypolicy</a>. 
                        By using the Site, you agree to be bound by our Privacy 
                        Policy, which is incorporated into these Terms of Use. Please be advised the Site is 
                        hosted in India. If you access the Site from any other region of the world with laws or 
                        other requirements governing personal data collection, use, or disclosure that differ 
                        from applicable laws in India, then through your continued use of the Site, you are 
                        transferring your data to India, and you agree to have your data transferred to and 
                        processed in India.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    13. TERM AND TERMINATION
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT 
                        LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE 
                        DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING 
                        BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING 
                        WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN 
                        THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR 
                        PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU 
                        POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </p>
                    <p>
                        If we terminate or suspend your account for any reason, you are prohibited from registering 
                        and creating a new account under your name, a fake or borrowed name, or the name of any 
                        third party, even if you may be acting on behalf of the third party. In addition to 
                        terminating or suspending your account, we reserve the right to take appropriate legal 
                        action, including without limitation pursuing civil, criminal, and injunctive redress.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    14. MODIFICATIONS AND INTERRUPTIONS
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        We reserve the right to change, modify, or remove the contents of the Site at any time or 
                        for any reason at our sole discretion without notice. However, we have no obligation to 
                        update any information on our Site. We also reserve the right to modify or discontinue 
                        all or part of the Site without notice at any time. We will not be liable to you or any 
                        third party for any modification, price change, suspension, or discontinuance of the Site.
                    </p>
                    <p>
                        We cannot guarantee the Site will be available at all times. We may experience hardware, 
                        software, or other problems or need to perform maintenance related to the Site, resulting 
                        in interruptions, delays, or errors. We reserve the right to change, revise, update, 
                        suspend, discontinue, or otherwise modify the Site at any time or for any reason without 
                        notice to you. You agree that we have no liability whatsoever for any loss, damage, or 
                        inconvenience caused by your inability to access or use the Site during any downtime or 
                        discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us 
                        to maintain and support the Site or to supply any corrections, updates, or releases in 
                        connection therewith.
                    </p>
                </div>

                <h3 className={classes.aboutSubTitle}>
                    15. GOVERNING LAW 
                </h3>

                <div className={classes.aboutContent} style={{marginTop: 20}}>
                    <p>
                        These Terms shall be governed by and defined following the laws of India. __________ and 
                        yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to 
                        resolve any dispute which may arise in connection with these terms.
                    </p>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(withMediaQuery('(min-width:600px)')(TermsConditions)))