import React, { useEffect } from "react";

const FaviconChanger = () => {
    useEffect(() => {
        const updateFavicon = () => {            
            const originName = window.location.origin.replace('www.', '');                

            if(originName !== 'https://edwhat.com' && originName !== 'https://test.edwhat.com') {
                const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                const faviconName = originName.replace('https://', '').replace('.edwhat.com', '');
                link.href = `/home/${faviconName}.ico`;
                document.getElementsByTagName('head')[0].appendChild(link);
            }
        }

        updateFavicon();

        window.addEventListener('popstate', updateFavicon);

        return () => {
            window.removeEventListener('popstate', updateFavicon);
        }
    }, []);

    return <div></div>
}

export default FaviconChanger;