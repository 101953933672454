import React, { Component } from "react";
import { Route, Redirect } from "react-router";
import Layout from "./components/Layout";
import Topics from "./components/Topics";
import QuestionDetails from "./components/QuestionDetails";
import PollDetails from "./components/PollDetails";
import SocialLogin from "./components/navmenu/SocialLogin";
import {
  setHyvorTalkSignon,
  setHyvorTalkWebsiteId,
  setLoginInfo,
  setUserAliasName,
  setUserEmail,
} from "./components/utils/SetLocalStorage";
import MyProfile from "./components/MyProfile";
import AddQuestion from "./components/AddQuestion";
import { useMediaQuery } from "@material-ui/core";
import api from "./components/common/APIValues";
import { withRouter } from "react-router-dom";
import MyThreads from "./components/MyThreads";
import Cache from "react-api-cache";
import AboutUs from "./pages/footer/AboutUs";
import Privacy from "./pages/footer/Privacy";
import TermsConditions from "./pages/footer/TermsConditions";
import CommunityTypes from "./pages/community/CommunityTypes";
import CreateCommunity from "./pages/community/CreateCommunity";
import axios from "axios";
import CommunityTemplates from "./pages/community/CommunityTemplates";
import PreviewCommunityTemplate from "./pages/community/PreviewCommunityTemplate";
import CommunityHome from "./pages/community/CommunityHome";
import Communities from "./pages/community/Communities";
import InviteNewUsers from "./pages/users/InviteNewUsers";
import TopCommunities from "./pages/community/TopCommunities";
import ManagePermissions from "./pages/users/ManagePermissions";
import ManageUser from "./pages/users/ManageUser";
import Premium from "./pages/users/Premium";
import FeedbackForm from "./pages/header/FeedbackForm";
import routeService from "./services/Routing";
import constant from "./components/common/Constants";
import ApproveRequests from "./pages/users/ApproveRequests";
import Notifications from "./components/Notifications";
import CommunityAbout from "./pages/community/CommunityAbout";
import CommunityGuidelines from "./pages/footer/CommunityGuidelines";
import { get } from "./services/ApiService";
import SuccessInfo from "./pages/modal/SuccessInfo";
import { gapi } from "gapi-script";
import FaviconChanger from "./components/common/FaviconChanger";
import { handleHyvorTalkSignon } from "./components/utils/HyvorTalkEncryption";

var CryptoJS = require("crypto-js");

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      email: "",
      showAlias: false,
      showProfile: false,
      alias_name: "",
      alias_name_err: "",
      prev_page: "",
      sortBy: 1,
      searchValue: "",
      anchorEl: null,
      anchorElProfile: null,
      notification: false,
      readLoginUrl: false,
      hashCode: "",
      userData: "",
      colorThemeName: "",
      logoUrl: "",
      coverUrl: "",
      selectedMenu: "",
      openInfo: false,
      textMsg: "",
      s3ConfigUrl: "",
      googleClientId: "",
      encryptKey: "",
      privateKey: "",
      websiteId: 0,
    };
  }

  userLogin = () => {
    this.setState({ open: true, anchorEl: null, anchorElProfile: null });
    let name = localStorage.getItem("user_name");
    let email = localStorage.getItem("user_email");
    let alias_name = localStorage.getItem("user_alias_name");

    if (name && email && !alias_name) {
      this.setState({ showAlias: true });
    } else if (name && email && alias_name) {
      this.setState({ selectedMenu: "myprofile" });
      routeService.routePage(this.props, constant.myProfile);
    }
  };

  embedHyvorTalk = (isLoggedIn, userId, name, email, picture, privateKey) => {
    let rowData = {
      isLoggedIn: isLoggedIn,
      userId: userId,
      name: name,
      email: email,
      picture: picture,
      privateKey: privateKey,
    };

    function decodeEmailAddress(encryptKey) {
      const _email = localStorage.getItem("user_encoded_email");
      if (_email && _email !== "") {
        let bytes = CryptoJS.AES.decrypt(_email, encryptKey);
        let email_address = bytes.toString(CryptoJS.enc.Utf8);
        return email_address;
      }
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      rowData.email = decodeEmailAddress(this.state.encryptKey);
    }
    const hyvorTalkSSOData = handleHyvorTalkSignon(rowData);

    this.setState({
      hashCode: hyvorTalkSSOData.hash,
      userData: hyvorTalkSSOData.userData,
    });

    // Set the signon data wherever needed
    setHyvorTalkSignon(hyvorTalkSSOData.hash, hyvorTalkSSOData.userData);
  };

  handleClose = () => {
    this.setState({ open: false });
    this.redirectToOriginalUrl();
  };

  redirectToTermsConditions = () => {
    this.setState({ open: false });
    routeService.routePage(this.props, constant.termsConditions);
  };

  redirectToPrivacyPolicy = () => {
    this.setState({ open: false });
    routeService.routePage(this.props, constant.privacyPolicy);
  };

  redirectToOriginalUrl = () => {
    let pathname = this.props.location.pathname;
    let searchValue = this.props.location.search;
    if (
      pathname.indexOf("/is_login_url=true") > -1 ||
      searchValue.indexOf("is_login_url=true") > -1
    ) {
      routeService.routePage(
        this.props,
        pathname.replace("/is_login_url=true", "")
      );
    }
  };

  handleAliasClose = () => {
    this.setState({ showAlias: false });
    this.redirectToOriginalUrl();
  };

  handleChange = (event) => {
    this.setState({
      alias_name: event.target.value,
      alias_name_err:
        event.target.value.length <= 0 ? "Please enter alias name" : "",
    });
  };

  onGoogleLoginSuccess = (res) => {
    if (res.profileObj) {
      this.setState({ open: false, showAlias: true });

      let picture = res.profileObj.imageUrl;
      let userId = res.profileObj.googleId;
      let email = res.profileObj.email;
      let name = res.profileObj.name;
      let alias_name = res.profileObj.givenName
        ? res.profileObj.givenName
        : res.profileObj.name;
      let isLoggedIn = email && alias_name ? true : false;
      let encodedEmail = CryptoJS.AES.encrypt(email, this.state.encryptKey);
      const originName = window.location.origin.replace("www.", "");

      setLoginInfo(name, userId, picture, encodedEmail);
      setUserAliasName(alias_name);

      if (isLoggedIn) {
        const privateKey = this.state.privateKey;
        this.embedHyvorTalk(
          isLoggedIn,
          userId,
          alias_name,
          email,
          picture,
          privateKey
        );
      }

      let userData = {
        USER_ID: userId,
        USER_EMAIL_ID: email,
        USER_NM: name,
        ENV: api.env,
        ORGN_NM: originName,
      };

      axios({
        method: "post",
        url: api.partialURL + "UserProfile/CreateUserProfile",
        data: JSON.stringify(userData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            setUserEmail(res.data);
            this.setState({ anchorEl: null, anchorElProfile: null });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  onGoogleLoginFailure = (res) => {
    console.log("[Login Failed], the reason is: ", res);
  };

  startgapi = (googleClientId) => {
    gapi.client.init({
      clientId: googleClientId,
      scope: "",
    });
  };

  getDefaultCommunityDetails = () => {
    this.setState({ loader: true });
    const originName = window.location.origin.replace("www.", "");
    const inputs = `env=${api.env}&originName=${originName}`;
    get("Home/GetDefaultCommunityDetails?" + inputs).then((response) => {
      if (response) {
        fetch(response.S3ConfigUrl)
          .then((r) => r.json())
          .then((jsonData) => {
            const googleClientId = jsonData.googleClientId;
            const encryptKey = jsonData.encryptKey;
            const privateKey = jsonData.privateKey;
            const websiteId = jsonData.websiteId;
            this.setState({
              s3ConfigUrl: response.S3ConfigUrl,
              googleClientId: googleClientId,
              encryptKey: encryptKey,
              websiteId: websiteId,
            });

            setHyvorTalkWebsiteId(websiteId);

            const isLoggedIn =
              localStorage.getItem("user_email") &&
              localStorage.getItem("user_alias_name")
                ? true
                : false;
            this.embedHyvorTalk(
              isLoggedIn,
              localStorage.getItem("user_id"),
              localStorage.getItem("user_alias_name"),
              localStorage.getItem("user_email"),
              localStorage.getItem("image_url"),
              privateKey
            );

            gapi.load("client:auth2", () => this.startgapi(googleClientId));
          });
      }
    });
  };

  componentDidMount() {
    this.getDefaultCommunityDetails();
    this.setState({
      email: localStorage.getItem("user_email"),
      alias_name: localStorage.getItem("user_alias_name"),
    });
  }

  addQuestion = (isCommunities) => {
    this.setState({ anchorEl: null, anchorElProfile: null });
    this.validateUserDefaultGroupPermission(isCommunities, "Create Topic");
  };

  routeToCreateQuestion = (name, email, alias_name, isCommunities) => {
    if (!name && !email) {
      this.setState({ open: true });
    } else if (name && email && !alias_name) {
      this.setState({ showAlias: true });
    } else if (name && email && alias_name) {
      this.setState({ prev_page: this.props.location.pathname });
      routeService.routePage(
        this.props,
        isCommunities ? constant.communityTypes : constant.addTopic
      );
    }
  };

  validateUserDefaultGroupPermission = (isCommunities, permissionName) => {
    const name = localStorage.getItem("user_name");
    const email = localStorage.getItem("user_email");
    const alias_name = localStorage.getItem("user_alias_name");
    const originName = window.location.origin.replace("www.", "");
    const inputs = `GroupId=0&Env=${api.env}&UserId=${email}&PermissionName=${permissionName}&originName=${originName}`;

    get("Permission/ValidateUserPermission?" + inputs)
      .then((response) => {
        if (response.PermissionName) {
          if (response.PermissionName === "Create Topic")
            this.routeToCreateQuestion(name, email, alias_name, isCommunities);
          else routeService.routePage(this.props, constant.communityTypes);
        } else {
          this.setState({
            openInfo: true,
            textMsg:
              "You do not have permission to " + permissionName.toLowerCase(),
          });
        }
      })
      .catch(function (response) {
        this.setState({ err_msg: response, loader: false });
      });
  };

  handleInfoDialogClose = () => {
    this.setState({ openInfo: false });
  };

  handleSortBy = (event) => {
    let sortByVal = Number(event.target.value);
    this.setState({ sortBy: sortByVal });
  };

  searchTopics = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  clearSearchInput = () => {
    this.setState({ searchValue: "" });
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleUserProfileOpen = (event) => {
    this.setState({ anchorElProfile: event.currentTarget });
  };

  handleUserProfileClose = () => {
    this.setState({ anchorElProfile: null });
  };

  webNotifications = () => {
    this.setState({ notification: true });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    let array = this.props.location.pathname.split("/");
    let searchValue = this.props.location.search;
    let is_login_url = array[array.length - 1];

    if (
      is_login_url === "is_login_url=true" ||
      searchValue.indexOf("is_login_url=true") > -1
    ) {
      let name = localStorage.getItem("user_name");
      let email = localStorage.getItem("user_email");
      let alias_name = localStorage.getItem("user_alias_name");

      if (!name && !email && !this.state.open) {
        this.setState({ open: true });
      } else if (name && email && !alias_name && !this.state.showAlias) {
        this.setState({ showAlias: true });
      }
    }
  };

  userLogout = (pageName) => {
    localStorage.clear();

    this.setState({ anchorEl: null, anchorElProfile: null, open: false });
    let isLoggedIn =
      localStorage.getItem("user_email") &&
      localStorage.getItem("user_alias_name")
        ? true
        : false;
    this.embedHyvorTalk(
      isLoggedIn,
      localStorage.getItem("user_id"),
      localStorage.getItem("user_alias_name"),
      localStorage.getItem("user_email"),
      localStorage.getItem("image_url"),
      this.state.privateKey
    );

    if (
      pageName === "/myprofile" ||
      pageName === "/mythreads" ||
      pageName.indexOf("/create/question/ask") > -1 ||
      pageName === "/community/templates" ||
      pageName === "/community/types" ||
      pageName === "/community/template/preview" ||
      pageName.indexOf("/create/community/") > -1 ||
      pageName.indexOf("/community/inviteusers/") > -1 ||
      pageName.indexOf("/community/managepermissions/") > -1 ||
      pageName.indexOf("/community/manageusers/") > -1 ||
      pageName.indexOf("/community/premium/") > -1
    ) {
      routeService.routePage(this.props, constant.home);
    }
  };

  redirectToCommunity = () => {
    this.setState({
      anchorElProfile: null,
      anchorEl: null,
      openDrawer: false,
      selectedMenu: "create_community",
    });
    this.validateUserDefaultGroupPermission(undefined, "Create Community");
  };

  redirectToFeedbackForm = () => {
    this.setState({
      anchorElProfile: null,
      anchorEl: null,
      openDrawer: false,
      selectedMenu: "feedbackform",
    });
    routeService.routePage(this.props, constant.feedbackform);
  };

  redirectToMyProfile = () => {
    this.setState({
      anchorElProfile: null,
      anchorEl: null,
      openDrawer: false,
      selectedMenu: "mycommunities",
    });
    let inputData = { myCommunities: true };
    routeService.routePage(this.props, constant.myProfile, inputData);
  };

  redirectToTopCommunities = () => {
    this.setState({
      anchorElProfile: null,
      anchorEl: null,
      openDrawer: false,
      selectedMenu: "allcommunities",
    });
    routeService.routePage(this.props, constant.topCommunities);
  };

  updateCommunityGroup = (communityGroup) => {
    this.setState({
      colorThemeName: communityGroup.ColorThemeName,
      logoUrl: communityGroup.LogoUrl,
      coverUrl: communityGroup.CoverUrl,
    });
  };

  render() {
    const name = localStorage.getItem("user_name");
    const email = localStorage.getItem("user_email");
    const alias_name = localStorage.getItem("user_alias_name");

    return (
      <Cache>
        {({ store, actions }) => (
          <Layout
            userLogin={this.userLogin}
            userEmail={email}
            userName={name}
            userAliasName={alias_name}
            handleSortBy={this.handleSortBy}
            prev_page={this.state.prev_page}
            addQuestion={this.addQuestion}
            sortBy={this.state.sortBy}
            searchTopics={this.searchTopics}
            searchValue={this.state.searchValue}
            clearSearchInput={this.clearSearchInput}
            handleMobileMenuOpen={this.handleMobileMenuOpen}
            handleMobileMenuClose={this.handleMobileMenuClose}
            anchorEl={this.state.anchorEl}
            anchorElProfile={this.state.anchorElProfile}
            webNotifications={this.webNotifications}
            handleUserProfileOpen={this.handleUserProfileOpen}
            handleUserProfileClose={this.handleUserProfileClose}
            userLogout={this.userLogout}
            handleNotificationClose={this.handleNotificationClose}
            handleNotificationOpen={this.handleNotificationOpen}
            redirectToCommunity={this.redirectToCommunity}
            redirectToFeedbackForm={this.redirectToFeedbackForm}
            updateCommunityGroup={this.updateCommunityGroup}
            redirectToMyProfile={this.redirectToMyProfile}
            selectedMenu={this.state.selectedMenu}
            redirectToTopCommunities={this.redirectToTopCommunities}
          >
            <FaviconChanger />

            {this.state.open && !name && !email && (
              <SocialLogin
                open={this.state.open}
                handleClose={this.handleClose}
                onGoogleLoginSuccess={this.onGoogleLoginSuccess}
                onGoogleLoginFailure={this.onGoogleLoginFailure}
                redirectToTermsConditions={this.redirectToTermsConditions}
                redirectToPrivacyPolicy={this.redirectToPrivacyPolicy}
                googleClientId={this.state.googleClientId}
              />
            )}

            <SuccessInfo
              open={this.state.openInfo}
              textMsg={this.state.textMsg}
              handleClose={this.handleInfoDialogClose}
              infoType="error"
            ></SuccessInfo>

            <Route
              exact
              path="/"
              render={(props) => (
                <Topics
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  sortBy={this.state.sortBy}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            >
              <Redirect to="/home" />
            </Route>

            <Route
              exact
              path="/home"
              key="home1"
              render={(props) => (
                <Topics
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  sortBy={this.state.sortBy}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/home/:is_login_url"
              key="home2"
              render={(props) => (
                <Topics
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  sortBy={this.state.sortBy}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/topic/:topicId/:title"
              key="que1"
              render={(props) => (
                <QuestionDetails
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  hashCode={this.state.hashCode}
                  userData={this.state.userData}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  websiteId={this.state.websiteId}
                />
              )}
            />

            <Route
              exact
              path="/topic/:topicId/:title/:is_login_url"
              key="que2"
              render={(props) => (
                <QuestionDetails
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  hashCode={this.state.hashCode}
                  userData={this.state.userData}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  websiteId={this.state.websiteId}
                />
              )}
            />

            <Route
              exact
              path="/poll/:topicId/:title"
              key="poll1"
              render={(props) => (
                <PollDetails
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  hashCode={this.state.hashCode}
                  userData={this.state.userData}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  websiteId={this.state.websiteId}
                />
              )}
            />

            <Route
              exact
              path="/poll/:topicId/:title/:is_login_url"
              key="poll2"
              render={(props) => (
                <PollDetails
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  hashCode={this.state.hashCode}
                  userData={this.state.userData}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  websiteId={this.state.websiteId}
                />
              )}
            />

            <Route
              exact
              path="/myprofile"
              render={(props) => (
                <MyProfile
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/mythreads"
              render={(props) => (
                <MyThreads
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/create/question/ask/:groupId"
              render={(props) => (
                <AddQuestion
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/create/question/ask"
              render={(props) => (
                <AddQuestion
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/about"
              render={(props) => (
                <AboutUs {...props} searchValue={this.state.searchValue} />
              )}
            />

            <Route
              exact
              path="/privacypolicy"
              render={(props) => (
                <Privacy {...props} searchValue={this.state.searchValue} />
              )}
            />

            <Route
              exact
              path="/termsandconditions"
              render={(props) => (
                <TermsConditions
                  {...props}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/community/types"
              render={(props) => (
                <CommunityTypes
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/create/community/:communityType"
              render={(props) => (
                <CreateCommunity
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/community/templates"
              render={(props) => (
                <CommunityTemplates
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/community/template/preview"
              render={(props) => (
                <PreviewCommunityTemplate
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/community/home/:groupId"
              key="CH1"
              render={(props) => (
                <CommunityHome
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  colorThemeName={this.state.colorThemeName}
                  logoUrl={this.state.logoUrl}
                  coverUrl={this.state.coverUrl}
                />
              )}
            />

            <Route
              exact
              path="/community/home/:groupId/:is_login_url"
              key="CH2"
              render={(props) => (
                <CommunityHome
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  colorThemeName={this.state.colorThemeName}
                  logoUrl={this.state.logoUrl}
                  coverUrl={this.state.coverUrl}
                />
              )}
            />

            <Route
              exact
              path="/communities"
              key="C1"
              render={(props) => (
                <Communities
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  sortBy={this.state.sortBy}
                />
              )}
            />

            <Route
              exact
              path="/communities/:is_login_url"
              key="C2"
              render={(props) => (
                <Communities
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                  sortBy={this.state.sortBy}
                />
              )}
            />

            <Route
              exact
              path="/community/inviteusers/:groupId"
              render={(props) => (
                <InviteNewUsers
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/community/managepermissions/:groupId"
              render={(props) => (
                <ManagePermissions
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />

            <Route
              exact
              path="/topcommunities"
              key="TC1"
              render={(props) => (
                <TopCommunities
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/topcommunities/:is_login_url"
              key="TC2"
              render={(props) => (
                <TopCommunities
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />

            <Route
              exact
              path="/community/manageusers/:groupId"
              render={(props) => (
                <ManageUser
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                  cacheActions={actions}
                />
              )}
            />
            <Route
              exact
              path="/community/premium/:groupId"
              render={(props) => (
                <Premium
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />
            <Route
              exact
              path="/feedbackform"
              render={(props) => <FeedbackForm {...props} />}
            />
            <Route
              exact
              path="/community/requests/:groupId"
              render={(props) => (
                <ApproveRequests
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />
            <Route
              exact
              path="/notifications"
              render={(props) => <Notifications {...props} />}
            />
            <Route
              exact
              path="/community/about/:groupId"
              render={(props) => (
                <CommunityAbout
                  {...props}
                  userEmail={email}
                  userAliasName={alias_name}
                  searchValue={this.state.searchValue}
                />
              )}
            />
            <Route
              exact
              path="/community/guidelines"
              render={(props) => (
                <CommunityGuidelines
                  {...props}
                  searchValue={this.state.searchValue}
                />
              )}
            />
          </Layout>
        )}
      </Cache>
    );
  }
}

export default withRouter(withMediaQuery("(min-width:600px)")(App));
