import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  Backdrop,
  Divider,
  Link,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useStyles } from "./common/useStyles";
import "./common/Common.css";
import classNames from "classnames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ViewImage from "./ViewImage";
import ShareIcon from "@material-ui/icons/Share";
import LikeIcon from "./images/like.png";
import api from "./common/APIValues";
import FlagIcon from "@material-ui/icons/Flag";
import axios from "axios";
import { Comments } from "@hyvor/hyvor-talk-react";
import LikeIconColor from "./images/like_h.png";
import Report from "./Report";
import Share from "./Share";
import EDWhatLogoIcon from "./images/EdWhat-01-01.png";
import LinkIcon from "@material-ui/icons/Link";
import ReactHtmlParser from "react-html-parser";
import CommonFunc from "./common/CommonFunc";
import routeService from "../services/Routing";
import constant from "../components/common/Constants";
import loginService from "../services/Login";
import { searchByInputs, get } from "../services/ApiService";
import { Edit as EditIcon } from "@material-ui/icons"; // Import EditIcon
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { getHyvorTalkWebsiteId } from "./utils/SetLocalStorage";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class QuestionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTopic: {},
      comment_text: "",
      userTopicComments: [],
      loader: false,
      disableAddQue: false,
      likesInd: true,
      viewInd: true,
      hyvorTalkSignon: {},
      openReport: false,
      rptInd: true,
      ssoStateData: null,
      openShare: false,
      isFilterApplied: false,
      anchorElShare: null,
      viewImage: false,
      viewCountAfterRoute: 1,
      topicUserId: "",
      loginUrl: "",
      editDialogOpen: false,
      editedTitle: "",
      editedDescription: "",
      websiteId: "",
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const originName = window.location.origin.replace("www.", "");
    this.setState({ loginUrl: `${originName}/#` });
    let topicId = Number(this.props.match.params.topicId);
    this.validateUserPermission();
    this.updateViewCount(topicId);
    this.state.websiteId = getHyvorTalkWebsiteId();
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.state.websiteId = getHyvorTalkWebsiteId();
    if (this.props.searchValue !== prevProps.searchValue) {
      routeService.routePage(this.props, constant.home);
    }

    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName ||
      this.props.userData !== prevProps.userData
    ) {
      this.setState({
        isFilterApplied: true,
      });

      let topicId = Number(this.props.match.params.topicId);
      let pathName = this.props.location.pathname.replace(
        "/is_login_url=true",
        ""
      );
      let ssoData = {
        hash: this.props.hashCode,
        userData: this.props.userData,
        loginURL: this.state.loginUrl + pathName + "/is_login_url=true",
      };

      this.setState({ ssoStateData: ssoData });

      if (
        this.props.userEmail !== prevProps.userEmail ||
        this.props.userAliasName !== prevProps.userAliasName
      ) {
        this.loadUserTopicComments(topicId);
      }

      let is_login_url = this.props.match.params.is_login_url;
      if (
        is_login_url === "is_login_url=true" &&
        this.props.userEmail &&
        this.props.userAliasName
      ) {
        let topicTitle = this.props.match.params.title;
        let pathName = constant.queDetails + topicId + "/" + topicTitle;
        routeService.routePage(this.props, pathName);
      }
    }
  };

  updateViewCount = (topicId) => {
    const originName = window.location.origin.replace("www.", "");
    let rowData = {
      TOPIC_ID: topicId,
      ENV: api.env,
      ORIGIN_NM: originName,
    };
    axios({
      method: "post",
      url: api.partialURL + "UserQueTopic/UpdateViewCount",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data !== "") {
            this.setState({ topicUserId: res.data });
            this.loadUserTopicComments(topicId);
          }
        }
      })
      .catch(function (response) {});
  };

  validateUserPermission = () => {
    const pathName = this.props.location.pathname;
    if (pathName.indexOf("/topic/") > -1) {
      const pathParams = pathName.split("/");
      const inputs = pathParams[2] + "/" + pathParams[1] + "/" + api.env;
      searchByInputs("UserTopic/GetCommunityByTopic", inputs).then(
        (response) => {
          if (Number(response) > 0) {
            const groupId = Number(response);
            this.loadCommunity(groupId);
          }
        }
      );
    }
  };

  loadCommunity = (groupId) => {
    const originName = window.location.origin.replace("www.", "");
    let inputs = `GroupId=${groupId}&UserId=${this.props.userEmail}&ENV=${api.env}&originName=${originName}`;
    get("Community/GetCommunity?" + inputs).then((response) => {
      if (response) {
        const userGroupStatus = response.UserGroupStatus;
        if (
          userGroupStatus === "" ||
          userGroupStatus === null ||
          userGroupStatus === "Requested"
        ) {
          const pathName = constant.communityHome + groupId;
          routeService.routePage(this.props, pathName);
        }
      }
    });
  };

  loadUserTopicComments(topicId, likeInd, rptInd) {
    const originName = window.location.origin.replace("www.", "");
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    let url =
      api.partialURL +
      "UserQueTopic/GetUserQueTopic?TOPIC_ID=" +
      topicId +
      "&USER_ID=" +
      this.props.userEmail +
      "&ENV=" +
      api.env +
      "&ORIGIN_NM=" +
      originName;
    let params = {
      TOPIC_ID: topicId,
      USER_ID: this.props.userEmail,
      USER_NM: this.props.userAliasName,
    };

    if (hasCache(url, params)) {
      let cachedData = getCache(url, params).data;

      //Cached data for like
      if (likeInd && cachedData.TOPIC_LIKE_IND === 0) {
        cachedData.TOPIC_LIKE = cachedData.TOPIC_LIKE + 1;
        cachedData.TOPIC_LIKE_IND = 1;
      } else if (likeInd && cachedData.TOPIC_LIKE_IND === 1) {
        cachedData.TOPIC_LIKE = cachedData.TOPIC_LIKE - 1;
        cachedData.TOPIC_LIKE_IND = 0;
      }

      //Cached data for report
      if (rptInd && cachedData.TOPIC_RPT_IND === 0) {
        cachedData.TOPIC_RPT_IND = 1;
      }

      //Cached data for view
      if (this.state.viewCountAfterRoute === 1) {
        cachedData.TOPIC_VIEW = cachedData.TOPIC_VIEW + 1;
        this.setState({
          viewCountAfterRoute: this.state.viewCountAfterRoute + 1,
        });
      }

      this.setTopic(url, params, cachedData);
    } else {
      this.fetchUserQueTopic(url, params);
    }
  }

  setTopic = (url, params, topic) => {
    //filter media link
    console.warn(topic.TOPIC_DESC.substring(0, 23));
    let desc = topic.TOPIC_DESC;
    let mediaLink = desc.slice(
      desc.indexOf('<oembed url="') + 13,
      desc.lastIndexOf('"></oembed>')
    );
    if (
      mediaLink.indexOf("https://youtube.com/") > -1 ||
      mediaLink.indexOf("https://youtu.be/") ||
      mediaLink.indexOf("https://www.youtube.com/") > -1 ||
      mediaLink.indexOf("https://www.youtu.be/")
    ) {
      let videoId = CommonFunc.getYoutubeId(mediaLink);
      let iframeMarkup =
        '<iframe frameborder="0" style="width:560px;height:315px;" src="https://www.youtube.com/embed/' +
        videoId +
        '" title="YouTube Video" allowFullScreen webkitAllowFullScreen></iframe>';
      let oembedMarkup =
        '<figure class="media"><oembed url="' +
        mediaLink +
        '"></oembed></figure>';

      topic.TOPIC_DESC = desc.replace(oembedMarkup, iframeMarkup);
    }

    this.setState({
      userTopic: topic,
      likesInd: topic.TOPIC_LIKE_IND === 1 ? false : true,
      viewInd: topic.TOPIC_VIEW_IND === 1 ? false : true,
      rptInd: topic.TOPIC_RPT_IND === 1 ? false : true,
      isFilterApplied: true,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, topic);
  };

  fetchUserQueTopic = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setTopic(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  saveUserQueLike = () => {
    if (this.props.userEmail && this.props.userAliasName) {
      this.setState({ loader: true, isFilterApplied: true });
      let rowData = {
        TOPIC_ID: Number(this.state.userTopic.TOPIC_ID),
        TOPIC_UPDT_USER_ID: this.props.userEmail,
        TOPIC_UPDT_USER_NM: this.props.userAliasName,
        ENV: api.env,
      };

      axios({
        method: "post",
        url: api.partialURL + "UserQueTopic/SaveUserQueLike",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            if (res.data === "success") {
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });

      this.loadUserTopicComments(Number(this.props.match.params.topicId), true);
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      loginService.redirectToLogin(this.props);
    }
  };

  submitReport = () => {
    if (this.state.rptInd && this.props.userEmail && this.props.userAliasName) {
      this.setState({ openReport: true, isFilterApplied: true });
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      loginService.redirectToLogin(this.props);
    }
  };

  handleShareLink = (event) => {
    this.setState({
      anchorElShare: event.currentTarget,
      isFilterApplied: true,
    });
  };

  shareLink = () => {
    this.setState({ openShare: true, anchorElShare: null });
  };

  copyLink = () => {
    this.setState({ anchorElShare: null });
    let topicId = Number(this.props.match.params.topicId);
    let topicTitle = this.props.match.params.title.substring(0, 50);
    window.navigator.clipboard.writeText(
      this.state.loginUrl + "/topic/" + topicId + "/" + topicTitle
    );
  };

  handleShareAnchorClose = () => {
    this.setState({ anchorElShare: null });
  };

  handleReportClose = () => {
    this.setState({ openReport: false });
  };

  handleShareClose = () => {
    this.setState({ openShare: false });
  };

  handleStateUpdate = () => {
    this.setState({ openReport: false });
    this.loadUserTopicComments(
      Number(this.props.match.params.topicId),
      null,
      true
    );
  };

  showFullImage = () => {
    this.setState({ viewImage: true });
  };

  handleViewImgClose = () => {
    this.setState({ viewImage: false });
  };

  addToSearchInput = (searchHashtag) => {
    const { history } = this.props;
    if (history)
      history.push({ pathname: "/home", searchHashtagDtl: searchHashtag });
  };

  handleChangeCKEditor = (e, editor) => {
    let data = editor.getData();
    this.setState({ editedDescription: data });
    console.log("Change editor detected");
    // let errors = this.state.errors;
    // errors.topic_desc = data.length <= 0 ? 'Please enter question description' : '';
    //this.setState({ errors: errors, topic_desc: data });
  };

  handleReadyCKEditor = (editor) => {
    console.log("Editor is ready: ", this.state.editedDescription);
    let desc = this.state.editedDescription;
    desc = desc.replace(
      '<iframe frameborder="0" style="width:560px;height:315px;" src="',
      '<figure class="media"><oembed url="'
    );
    desc = desc.replace(
      '" title="YouTube Video" allowFullScreen webkitAllowFullScreen></iframe>',
      '"></oembed></figure>'
    );
    console.log("Description: ", desc);
    editor.setData(desc);
  };

  handleSaveUpdate = () => {
    if (!(this.state.editedTitle && this.state.editedDescription)) {
      alert("Title or Description cannot be empty");
      return;
    }

    this.setState({ editDialogOpen: false }); // Close the dialog after saving/updating
    const rowData = {
      topicId: this.props.match.params.topicId,
      title: this.state.editedTitle,
      description: this.state.editedDescription,
      userEnv: api.env,
    };

    axios({
      method: "post",
      url: api.partialURL + "EditQuestion/UpdateUserQuestion",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((response) => {
        // Handle success
        console.log("Update successful:", response.data);
        // Close the dialog after saving/updating
        this.setState({ editDialogOpen: false });
        let pathName =
          constant.queDetails +
          rowData.topicId +
          "/" +
          this.state.editedTitle.toLowerCase().replace(/ /g, "-");
        this.props.history.push(pathName);
        this.loadUserTopicComments(rowData.topicId, false, false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating question:", error);
      });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const subHeaderX = classNames(classes.posTopX, classes.subHeader);
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const topicId = Number(this.props.match.params.topicId);
    const topicTitle = this.props.match.params.title.substring(0, 50);
    const pathName = this.props.location.pathname.replace(
      "/is_login_url=true",
      ""
    );
    let ssoData = {
      hash: localStorage.getItem("hash_hyvor_talk"),
      userData: localStorage.getItem("user_data_hyvor_talk"),
      loginURL: this.state.loginUrl + pathName + "/is_login_url=true",
    };
    const sso = this.state.ssoStateData;

    const ssoConfig = {
      url: this.state.loginUrl + "/topic/" + topicId + "/" + topicTitle,
      id: "que" + topicId,
      sso: sso ? sso : ssoData,
    };

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        {/* Report Section */}
        {this.state.openReport && (
          <Report
            open={this.state.openReport}
            handleReportClose={this.handleReportClose}
            topicId={topicId}
            postType="question"
            handleStateUpdate={this.handleStateUpdate}
            userEmail={this.props.userEmail}
            userAliasName={this.props.userAliasName}
            cacheActions={this.props.cacheActions}
          />
        )}

        {/* Share Link */}
        {this.state.openShare && (
          <Share
            open={this.state.openShare}
            handleReportClose={this.handleShareClose}
            shareUrl={
              this.state.loginUrl + "/topic/" + topicId + "/" + topicTitle
            }
            title={this.state.userTopic.TOPIC_TITLE}
            desc={this.state.userTopic.TOPIC_DESC}
          />
        )}

        {this.state.viewImage && (
          <ViewImage
            open={this.state.viewImage}
            preViewImage={this.state.userTopic.TOPIC_IMG_URL}
            handleViewImgClose={this.handleViewImgClose}
          />
        )}

        {this.state.userTopic.TOPIC_ID && (
          <Grid
            container
            spacing={4}
            style={{ marginTop: mediaQuery ? "45px" : "90px" }}
            className={mediaQuery ? classes.contentHome : null}
          >
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} key={this.state.userTopic.TOPIC_ID}>
                  <Card>
                    <CardHeader
                      classes={{
                        title: classes.title,
                        subheader: classes.subTitle,
                      }}
                      title={this.state.userTopic.TOPIC_TITLE}
                      subheader={
                        "posted by " +
                        this.state.userTopic.TOPIC_UPDT_USER_NM +
                        " " +
                        this.state.userTopic.TOPIC_UPDT_DTM +
                        " ago"
                      }
                      style={{ padding: "16px 16px 0px 16px" }}
                      action={
                        this.props.userEmail ===
                          this.state.userTopic.TOPIC_UPDT_USER_ID && (
                          <IconButton
                            onClick={() => {
                              // Open the edit dialog
                              this.setState({
                                editDialogOpen: true,
                                // Pass the current title and description to the state
                                editedTitle: this.state.userTopic.TOPIC_TITLE,
                                editedDescription:
                                  this.state.userTopic.TOPIC_DESC,
                              });
                              console.log(this.props.userEmail);
                              console.log(
                                this.state.userTopic.TOPIC_UPDT_USER_ID
                              );
                              console.log(this.state.userTopic.TOPIC_DESC);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )
                      }
                    />

                    <CardContent>
                      <Typography
                        className={
                          this.state.userTopic.TOPIC_DESC.substring(0, 23) ===
                          '<iframe frameborder="0"'
                            ? subHeaderX
                            : subHeader
                        }
                        gutterBottom
                      >
                        <div>
                          {mediaQuery
                            ? ReactHtmlParser(this.state.userTopic.TOPIC_DESC)
                            : ReactHtmlParser(
                                this.state.userTopic.TOPIC_DESC.replace(
                                  'style="width:560px;height:315px;"',
                                  ""
                                )
                              )}
                        </div>
                      </Typography>
                      {console.log("Topic url: ", this.state.userTopic.TOPIC_IMG_URL)}
                      {this.state.userTopic.TOPIC_IMG_URL && this.state.userTopic.TOPIC_IMG_URL.split(".")[this.state.userTopic.TOPIC_IMG_URL.split(".").length -1] != "pdf" && (
                        <Typography>
                          <img
                            src={this.state.userTopic.TOPIC_IMG_URL}
                            onClick={this.showFullImage}
                            alt="img"
                            style={{
                              width: "100%",
                              padding: mediaQuery
                                ? "10px 150px 10px 150px"
                                : "10px 10px 10px 10px",
                            }}
                          />
                        </Typography>
                      )}
                       {this.state.userTopic.TOPIC_IMG_URL && this.state.userTopic.TOPIC_IMG_URL.split(".")[this.state.userTopic.TOPIC_IMG_URL.split(".").length -1] == "pdf" && (
                        <Typography>
                          <Link href={this.state.userTopic.TOPIC_IMG_URL} target="_blank">
                            <PictureAsPdfIcon/>
                            {this.state.userTopic.TOPIC_IMG_URL.split("/")[this.state.userTopic.TOPIC_IMG_URL.split("/").length - 1]}
                          </Link>
                        </Typography>
                      )
                      }
                      <Typography
                        className={classes.hashTag}
                        gutterBottom
                        style={{
                          marginTop: this.state.userTopic.TOPIC_IMG_URL
                            ? "0px"
                            : "10px",
                        }}
                      >
                        {this.state.userTopic.TOPIC_HASHTAG &&
                          this.state.userTopic.TOPIC_HASHTAG.map(
                            (item, index) => (
                              <Link
                                onClick={() => this.addToSearchInput(item)}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: index === 0 ? 0 : 10,
                                }}
                              >
                                {item}
                              </Link>
                            )
                          )}
                      </Typography>

                      <Typography
                        style={{ marginTop: "10px" }}
                        className={wrapIcon}
                        color="textSecondary"
                      >
                        <IconButton
                          onClick={this.saveUserQueLike}
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                          }}
                          aria-label="share"
                        >
                          <img
                            src={this.state.likesInd ? LikeIcon : LikeIconColor}
                            height={15}
                            style={{ marginRight: "5px" }}
                            alt="likes"
                          />
                          {this.state.userTopic.TOPIC_LIKE}{" "}
                          {mediaQuery ? "Likes" : ""}
                        </IconButton>

                        <VisibilityIcon
                          className={classes.iconSize}
                          style={{ marginRight: "5px", marginLeft: "10px" }}
                        />
                        <span style={{ fontSize: 13, fontFamily: "poppins" }}>
                          {this.state.userTopic.TOPIC_VIEW}
                        </span>

                        <IconButton
                          onClick={this.handleShareLink}
                          aria-label="share"
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "10px",
                          }}
                        >
                          <ShareIcon
                            className={classes.iconSize}
                            style={{ marginRight: "5px" }}
                          />
                          Share
                        </IconButton>

                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorElShare}
                          keepMounted
                          open={Boolean(this.state.anchorElShare)}
                          onClose={this.handleShareAnchorClose}
                        >
                          <MenuItem onClick={this.copyLink}>
                            <LinkIcon /> &nbsp;&nbsp;Copy Link
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={this.shareLink}>
                            <ShareIcon /> &nbsp;&nbsp;Share Link
                          </MenuItem>
                        </Menu>

                        <IconButton
                          onClick={this.submitReport}
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "10px",
                          }}
                        >
                          <FlagIcon
                            className={classes.iconSize}
                            style={{
                              marginRight: "5px",
                              color: !this.state.rptInd ? "#c2272d" : null,
                            }}
                          />
                          Report
                        </IconButton>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} style={{ marginTop: -15 }}>
                  <Comments
                    website-id={this.state.websiteId}
                    page-id={"que" + topicId}
                    sso-hash={ssoData.hash}
                    sso-user={ssoData.userData}
                    page-url={
                      this.state.loginUrl +
                      "/topic/" +
                      topicId +
                      "/" +
                      topicTitle
                    }
                    page-author={atob(this.state.topicUserId)}
                  />
                  {window.hyvor_talk &&
                    !this.state.isFilterApplied &&
                    window.hyvor_talk.reload(ssoConfig)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Dialog
          open={this.state.editDialogOpen}
          onClose={this.handleEditDialogClose}
          maxWidth="md"
        >
          <DialogTitle>Edit Topic</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              name="topic_title"
              label="Title"
              required
              onChange={(event) =>
                this.setState({ editedTitle: event.target.value })
              }
              noValidate
              value={this.state.editedTitle}
              size="small"
              variant="outlined"
              style={{ backgroundColor: "#e7e7e7", marginBottom: 20 }}
            />

            <CKEditor
              size="small"
              editor={ClassicEditor}
              data={this.state.editedDescription}
              onChange={this.handleChangeCKEditor}
              onReady={this.handleReadyCKEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "link",
                  "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                ],
                placeholder: "Type your description here *",
              }}
              style={{ marginTop: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ editDialogOpen: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={this.handleSaveUpdate} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(QuestionDetails))
);
