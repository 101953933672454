import React from 'react';
import { 
    DialogContent, IconButton, Grid, Dialog, DialogContentText, useMediaQuery, FormControlLabel, 
    Checkbox, Typography, Link
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';
import { GoogleLogin } from 'react-google-login';
import GoogleButton from 'react-google-button'
import '../common/Common.css'
import api from "../common/APIValues";

export default function SocialLogin(props) {
    const [open, setOpen] = React.useState(props.open);
    const [chkCondition, setChkCondition] = React.useState(true);
    const matches = useMediaQuery('(min-width:600px)');

    const handleCheckChange = (event) => {
        setChkCondition(event.target.checked);
    };

    return (
        <React.Fragment>
            <Dialog 
                fullWidth 
                open={open}
                onClose={props.handleClose} 
                disableBackdropClick
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: 20, maxWidth: 545
                    }
                }}
            >                
                <DialogContent style={{ backgroundColor: '#f6f7f2' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={ matches ? 10 : 9 } style={{marginLeft:'5%', marginTop:20}}>                            
                            <Typography style={{fontSize:16, textAlign: matches ? 'center' : 'left'}} 
                                color="textPrimary">
                                <b style={{
                                    marginLeft: matches ? '5%' : '-7%',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600
                                }}>
                                    { matches ? 'Please signup or login to continue' : 'Please login to continue' }
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={ matches ? 1 : 2 }>
                            <IconButton style={{ marginLeft: '22px', marginTop: '-20px' }} 
                                onClick={props.handleClose} aria-label="settings">
                                <CloseIcon style={{fontSize:36}}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div id='dialogContentDiv'>
                    <GoogleLogin
                        clientId={props.googleClientId}
                        buttonText='Continue with Google'
                        onSuccess={props.onGoogleLoginSuccess}
                        onFailure={props.onGoogleLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <GoogleButton disabled={!chkCondition} type="light" 
                                label='Continue with Google' onClick={renderProps.onClick} 
                                style={{ 
                                    width: matches ? '80%' : '100%', 
                                    height: 60, 
                                    lineHeight: 4,
                                    color: '#333233', 
                                    textAlign: 'center',
                                    borderRadius: 10, 
                                    marginLeft: matches ? '10%' : '0%', 
                                    marginTop: 10,
                                    fontWeight: 500,
                                    fontFamily: 'Poppins',
                                    fontSize: 15
                                }} />
                        )}
                    />
                    </div>
                    <DialogContentText style={{ marginTop: 20, textAlign: 'center' }} 
                        id="alert-dialog-description">
                        <FormControlLabel
                            id="chkTermsConditions"
                            control={
                                <Checkbox
                                    checked={chkCondition}
                                    onChange={handleCheckChange}
                                    name="chkCondition"
                                    color="primary"                                    
                                />
                            }
                        />
                        <span style={{fontSize:13, marginLeft: matches ? -15 : -21}}>
                            I agree to 
                            the <Link onClick={props.redirectToTermsConditions} style={{color:'#0000008A',textDecoration:'underline',textDecorationColor:'#c2272d',cursor:'pointer'}}>
                                Terms and Conditions
                            </Link> and <Link onClick={props.redirectToPrivacyPolicy} style={{color:'#0000008A',textDecoration:'underline',textDecorationColor:'#c2272d',cursor:'pointer'}}>
                                Privacy Policy
                            </Link>
                        </span>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
