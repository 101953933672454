export default {
    routePage(props, pathName, inputData) {
        const { history } = props;
        if (history) history.push({
            pathname: pathName,
            inputData: inputData
        });
    },

    routeFuncPage(history, pathName, inputData) {
        if (history) history.push({
            pathname: pathName,
            inputData: inputData
        });
    }
}