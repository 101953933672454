export default {
    inviteUsers: '/community/inviteusers/',
    managePermissions: '/community/managepermissions/',
    manageUsers: '/community/manageusers/',
    approveUsers: '/community/requests/',
    premium: '/community/premium/',
    addTopic: '/create/question/ask/',
    home: '/home',
    communityHome: '/community/home/',
    communityAbout: '/community/about/',
    templates: '/community/templates',
    communityTypes: '/community/types',
    publicCommunity: '/create/community/public',
    privateCommunity: '/create/community/private',
    previewTemplate: '/community/template/preview',
    myProfile: '/myprofile',
    termsConditions: '/termsandconditions',
    privacyPolicy: '/privacypolicy',
    communities: '/communities',
    notifications: '/notifications',
    feedbackform: '/feedbackform',
    queDetails: '/topic/',
    pollDetails: '/poll/',
    myThreads: '/mythreads',
    topCommunities: '/topcommunities',
    aboutUs: '/about',
    communityGuidelines: '/community/guidelines',
}