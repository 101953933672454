import React, { Component, Fragment } from "react";
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Backdrop,
  Menu,
  MenuItem,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useStyles } from "./common/useStyles";
import "./common/Common.css";
import classNames from "classnames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ShareIcon from "@material-ui/icons/Share";
import VoteIcon from "./images/votes_icon.png";
import api from "./common/APIValues";
import FlagIcon from "@material-ui/icons/Flag";
import CommonFunc from "./common/CommonFunc";
import axios from "axios";
import LikeIcon from "./images/like.png";
import ProgressBar from "@ramonak/react-progress-bar";
import LikeIconColor from "./images/like_h.png";
import VoteIconColor from "./images/votes_h.png";
import Report from "./Report";
import Share from "./Share";
import EDWhatLogoIcon from "./images/EdWhat-01-01.png";
import LinkIcon from "@material-ui/icons/Link";
import ReactHtmlParser from "react-html-parser";
import routeService from "../services/Routing";
import constant from "../components/common/Constants";
import loginService from "../services/Login";
import { searchByInputs, get } from "../services/ApiService";
import { Comments } from "@hyvor/hyvor-talk-react";
import { getHyvorTalkWebsiteId } from "./utils/SetLocalStorage";

const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

class PollDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTopic: {},
      comment_text: "",
      userTopicOptions: [],
      voteOption: "",
      loader: false,
      message: "",
      err_msg: "",
      success_msg: "",
      openVote: false,
      viewInd: true,
      totalVotes: 0,
      voteInd: false,
      likeInd: false,
      openReport: false,
      rptInd: false,
      ssoStateData: null,
      openShare: false,
      isFilterApplied: false,
      anchorElShare: null,
      errors: {
        voteOption: "",
      },
      viewCountAfterRoute: 1,
      topicUserId: "",
      loginUrl: "",
      websiteId: "",
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.state.websiteId = getHyvorTalkWebsiteId();
    const originName = window.location.origin.replace("www.", "");
    this.setState({ loginUrl: `${originName}/#` });
    let topicId = Number(this.props.match.params.topicId);
    this.validateUserPermission();
    this.updateViewCount(topicId);
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.state.websiteId = getHyvorTalkWebsiteId();
    if (this.props.searchValue !== prevProps.searchValue) {
      routeService.routePage(this.props, constant.home);
    }

    if (
      this.props.userEmail !== prevProps.userEmail ||
      this.props.userAliasName !== prevProps.userAliasName ||
      this.props.userData !== prevProps.userData
    ) {
      this.setState({
        isFilterApplied: false,
      });

      let topicId = Number(this.props.match.params.topicId);
      let pathName = this.props.location.pathname.replace(
        "/is_login_url=true",
        ""
      );
      let ssoData = {
        hash: this.props.hashCode,
        userData: this.props.userData,
        loginURL: this.state.loginUrl + pathName + "/is_login_url=true",
      };

      this.setState({ ssoStateData: ssoData });

      if (
        this.props.userEmail !== prevProps.userEmail ||
        this.props.userAliasName !== prevProps.userAliasName
      ) {
        this.loadUserTopicComments(topicId);
      }

      let is_login_url = this.props.match.params.is_login_url;
      if (
        is_login_url === "is_login_url=true" &&
        this.props.userEmail &&
        this.props.userAliasName
      ) {
        let topicTitle = this.props.match.params.title;
        let pathName = constant.pollDetails + topicId + "/" + topicTitle;
        routeService.routePage(this.props, pathName);
      }
    }
  };

  updateViewCount = (topicId) => {
    const originName = window.location.origin.replace("www.", "");
    let rowData = {
      TOPIC_ID: topicId,
      ENV: api.env,
      ORIGIN_NM: originName,
    };
    axios({
      method: "post",
      url: api.partialURL + "UserPollTopic/UpdateViewCount",
      data: JSON.stringify(rowData),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((res) => {
        if (res) {
          if (res.data !== "") {
            this.setState({ topicUserId: res.data });
            this.loadUserTopicComments(topicId);
          }
        }
      })
      .catch(function (response) {});
  };

  validateUserPermission = () => {
    const pathName = this.props.location.pathname;
    if (pathName.indexOf("/poll/") > -1) {
      const pathParams = pathName.split("/");
      const inputs = pathParams[2] + "/" + pathParams[1] + "/" + api.env;
      searchByInputs("UserTopic/GetCommunityByTopic", inputs).then(
        (response) => {
          if (Number(response) > 0) {
            const groupId = Number(response);
            this.loadCommunity(groupId);
          }
        }
      );
    }
  };

  loadCommunity = (groupId) => {
    const originName = window.location.origin.replace("www.", "");
    let inputs = `GroupId=${groupId}&UserId=${this.props.userEmail}&ENV=${api.env}&originName=${originName}`;
    get("Community/GetCommunity?" + inputs).then((response) => {
      if (response) {
        const userGroupStatus = response.UserGroupStatus;
        if (
          userGroupStatus === "" ||
          userGroupStatus === null ||
          userGroupStatus === "Requested"
        ) {
          const pathName = constant.communityHome + groupId;
          routeService.routePage(this.props, pathName);
        }
      }
    });
  };

  loadUserTopicComments(topicId, likeInd, rptInd, voteInd) {
    const originName = window.location.origin.replace("www.", "");
    const { hasCache, getCache } = this.props.cacheActions;
    this.setState({ loader: true });
    let url =
      api.partialURL +
      "UserPollTopic/GetUserPollTopic?TOPIC_ID=" +
      topicId +
      "&USER_ID=" +
      this.props.userEmail +
      "&ENV=" +
      api.env +
      "&ORIGIN_NM=" +
      originName;
    let params = {
      TOPIC_ID: topicId,
      USER_ID: this.props.userEmail,
      USER_NM: this.props.userAliasName,
    };

    if (hasCache(url, params)) {
      let cachedData = getCache(url, params).data;

      //Cached data for like
      if (likeInd && cachedData.TOPIC_LIKE_IND === 0) {
        cachedData.TOPIC_LIKE = cachedData.TOPIC_LIKE + 1;
        cachedData.TOPIC_LIKE_IND = 1;
      } else if (likeInd && cachedData.TOPIC_LIKE_IND === 1) {
        cachedData.TOPIC_LIKE = cachedData.TOPIC_LIKE - 1;
        cachedData.TOPIC_LIKE_IND = 0;
      }

      //Cached data for vote
      if (voteInd && cachedData.TOPIC_VOTE_IND === 0) {
        let voteOption = this.state.voteOption;
        cachedData.TOPIC_VOTE = cachedData.TOPIC_VOTE + 1;
        cachedData.TOPIC_VOTE_IND = 1;
        cachedData.TOPIC_VOTE_OPTN = voteOption;

        if (cachedData.TOPIC_VOTE_OPTNS) {
          cachedData.TOPIC_VOTE_OPTNS.map((option, row) => {
            if (voteOption === option.TOPIC_VOTE_OPTN_NM) {
              cachedData.TOPIC_VOTE_OPTNS[row].TOPIC_OPTN_VOTE =
                cachedData.TOPIC_VOTE_OPTNS[row].TOPIC_OPTN_VOTE + 1;
            }
          });
        }
      }

      //Cached data for report
      if (rptInd && cachedData.TOPIC_RPT_IND === 0) {
        cachedData.TOPIC_RPT_IND = 1;
      }

      //Cached data for view
      if (this.state.viewCountAfterRoute === 1) {
        cachedData.TOPIC_VIEW = cachedData.TOPIC_VIEW + 1;
        this.setState({
          viewCountAfterRoute: this.state.viewCountAfterRoute + 1,
        });
      }

      this.setTopic(url, params, cachedData);
    } else {
      this.fetchUserPollTopic(url, params);
    }
  }

  setTopic = (url, params, topic) => {
    let totalVotes = 0;
    if (topic.TOPIC_VOTE_OPTNS) {
      topic.TOPIC_VOTE_OPTNS.map(
        (option, row) => (totalVotes += option.TOPIC_OPTN_VOTE)
      );
    }

    //filter media link
    let desc = topic.TOPIC_DESC;
    let mediaLink = desc.slice(
      desc.indexOf('<oembed url="') + 13,
      desc.lastIndexOf('"></oembed>')
    );
    if (
      mediaLink.indexOf("https://youtube.com/") > -1 ||
      mediaLink.indexOf("https://youtu.be/") ||
      mediaLink.indexOf("https://www.youtube.com/") > -1 ||
      mediaLink.indexOf("https://www.youtu.be/")
    ) {
      let videoId = CommonFunc.getYoutubeId(mediaLink);
      let iframeMarkup =
        '<iframe frameborder="0" style="width:560px;height:315px;" src="https://www.youtube.com/embed/' +
        videoId +
        '" allowfullscreen></iframe>';
      let oembedMarkup =
        '<figure class="media"><oembed url="' +
        mediaLink +
        '"></oembed></figure>';

      topic.TOPIC_DESC = desc.replace(oembedMarkup, iframeMarkup);
    }

    this.setState({
      userTopic: topic,
      userTopicOptions: topic.TOPIC_VOTE_OPTNS,
      viewInd: topic.TOPIC_VIEW_IND === 1 ? false : true,
      voteInd: topic.TOPIC_VOTE_IND === 1 ? true : false,
      likeInd: topic.TOPIC_LIKE_IND === 1 ? true : false,
      rptInd: topic.TOPIC_RPT_IND === 1 ? true : false,
      totalVotes: totalVotes,
      isFilterApplied: true,
      loader: false,
    });
    this.props.cacheActions.setCache(url, params, topic);
  };

  fetchUserPollTopic = (url, params) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setTopic(url, params, result);
      })
      .catch((err) => console.log(err));
  };

  voteNow = () => {
    if (this.props.userEmail && this.props.userAliasName) {
      this.setState({ success_msg: "", err_msg: "" });
      if (
        CommonFunc.validateForm(this.state.errors) &&
        this.validateAllInputs()
      ) {
        this.setState({ loader: true, isFilterApplied: true });
        let rowData = {
          TOPIC_ID: Number(this.state.userTopic.TOPIC_ID),
          TOPIC_VOTE_OPTN: this.state.voteOption,
          TOPIC_VOTE_USER_ID: this.props.userEmail,
          TOPIC_VOTE_USER_NM: this.props.userAliasName,
          ENV: api.env,
        };

        axios({
          method: "post",
          url: api.partialURL + "UserPollTopic/SaveUserPollVote",
          data: JSON.stringify(rowData),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        })
          .then((res) => {
            if (res) {
              if (res.data === "success") {
              } else if (res.data === "error") {
                this.setState({ err_msg: "Allowed only once to vote" });
              } else {
                this.setState({ err_msg: "Failed to vote for poll" });
              }
              this.setState({ loader: false, voteOption: "" });
            }
          })
          .catch(function (response) {
            this.setState({ err_msg: response, loader: false });
          });

        this.loadUserTopicComments(
          Number(this.props.match.params.topicId),
          null,
          null,
          true
        );
      } else {
        let errors = this.state.errors;
        if (!this.state.voteOption) {
          errors.voteOption = "Please select vote option";
        }
        this.setState({ errors });
      }
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      loginService.redirectToLogin(this.props);
    }
  };

  validateAllInputs() {
    if (!this.state.voteOption) {
      return false;
    } else {
      return true;
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ isFilterApplied: true });

    switch (name) {
      case "voteOption":
        this.state.voteOption = value;
        errors.voteOption =
          value.length <= 0 ? "Please select vote option" : "";
        break;
    }
    this.setState({ errors, [name]: value });
    this.setState({ success_msg: "", err_msg: "" });
  };

  saveUserPollLike = () => {
    if (this.props.userEmail && this.props.userAliasName) {
      this.setState({ loader: true, isFilterApplied: true });
      let rowData = {
        TOPIC_ID: Number(this.state.userTopic.TOPIC_ID),
        TOPIC_VOTE_USER_ID: this.props.userEmail,
        TOPIC_VOTE_USER_NM: this.props.userAliasName,
        ENV: api.env,
      };

      axios({
        method: "post",
        url: api.partialURL + "UserPollTopic/SaveUserPollLike",
        data: JSON.stringify(rowData),
        headers: { "Content-Type": "application/json" },
        mode: "cors",
      })
        .then((res) => {
          if (res) {
            if (res.data === "success") {
            }
          }
        })
        .catch(function (response) {
          this.setState({ err_msg: response, loader: false });
        });

      this.loadUserTopicComments(Number(this.props.match.params.topicId), true);
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      loginService.redirectToLogin(this.props);
    }
  };

  submitReport = () => {
    if (
      !this.state.rptInd &&
      this.props.userEmail &&
      this.props.userAliasName
    ) {
      this.setState({ openReport: true, isFilterApplied: true });
    } else if (!this.props.userEmail || !this.props.userAliasName) {
      loginService.redirectToLogin(this.props);
    }
  };

  handleShareLink = (event) => {
    this.setState({
      anchorElShare: event.currentTarget,
      isFilterApplied: true,
    });
  };

  shareLink = () => {
    this.setState({ openShare: true, anchorElShare: null });
  };

  copyLink = () => {
    this.setState({ anchorElShare: null });
    let topicId = Number(this.props.match.params.topicId);
    let topicTitle = this.props.match.params.title.substring(0, 50);
    window.navigator.clipboard.writeText(
      this.state.loginUrl + "/poll/" + topicId + "/" + topicTitle
    );
  };

  handleShareAnchorClose = () => {
    this.setState({ anchorElShare: null });
  };

  handleReportClose = () => {
    this.setState({ openReport: false });
  };

  handleShareClose = () => {
    this.setState({ openShare: false });
  };

  handleStateUpdate = () => {
    this.setState({ openReport: false });
    this.loadUserTopicComments(
      Number(this.props.match.params.topicId),
      null,
      true
    );
  };

  addToSearchInput = (searchHashtag) => {
    const { history } = this.props;
    if (history)
      history.push({ pathname: "/home", searchHashtagDtl: searchHashtag });
  };

  render() {
    const { classes, mediaQuery } = this.props;
    const subHeader = classNames(classes.posTop, classes.subHeader);
    const subHeaderX = classNames(classes.posTopX, classes.subHeader);
    const wrapIcon = classNames(classes.wrapIcon, classes.likesComments);
    const topicId = Number(this.props.match.params.topicId);
    const topicTitle = this.props.match.params.title.substring(0, 50);
    let ssoData = {
      hash: localStorage.getItem("hash_hyvor_talk"),
      userData: localStorage.getItem("user_data_hyvor_talk"),
      loginURL:
        this.state.loginUrl +
        this.props.location.pathname +
        "/is_login_url=true",
    };
    const sso = this.state.ssoStateData;

    const ssoConfig = {
      url: this.state.loginUrl + "/poll/" + topicId + "/" + topicTitle,
      id: "poll" + topicId,
      sso: sso ? sso : ssoData,
    };

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loader}>
          <img src={EDWhatLogoIcon} height={50} alt="Logo" />
        </Backdrop>

        {/* Report Section */}
        {this.state.openReport && (
          <Report
            open={this.state.openReport}
            handleReportClose={this.handleReportClose}
            topicId={topicId}
            postType="poll"
            handleStateUpdate={this.handleStateUpdate}
            userEmail={this.props.userEmail}
            userAliasName={this.props.userAliasName}
            cacheActions={this.props.cacheActions}
          />
        )}

        {/* Share Link */}
        {this.state.openShare && (
          <Share
            open={this.state.openShare}
            handleReportClose={this.handleShareClose}
            shareUrl={
              this.state.loginUrl + "/poll/" + topicId + "/" + topicTitle
            }
            title={this.state.userTopic.TOPIC_TITLE}
            desc={this.state.userTopic.TOPIC_DESC}
          />
        )}

        {this.state.userTopic.TOPIC_ID && (
          <Grid
            container
            spacing={4}
            style={{ marginTop: mediaQuery ? "45px" : "90px" }}
            className={mediaQuery ? classes.contentHome : null}
          >
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} key={this.state.userTopic.TOPIC_ID}>
                  <Card>
                    <CardHeader
                      classes={{
                        title: classes.title,
                        subheader: classes.subTitle,
                      }}
                      title={this.state.userTopic.TOPIC_TITLE}
                      subheader={
                        "posted by " +
                        this.state.userTopic.TOPIC_UPDT_USER_NM +
                        " " +
                        this.state.userTopic.TOPIC_UPDT_DTM +
                        " ago"
                      }
                      style={{ padding: "16px 16px 0px 16px" }}
                    />
                    <CardContent>
                      <Typography
                        className={
                          this.state.userTopic.TOPIC_DESC.substring(0, 23) ===
                          '<iframe frameborder="0"'
                            ? subHeaderX
                            : subHeader
                        }
                        gutterBottom
                      >
                        <div>
                          {mediaQuery
                            ? ReactHtmlParser(this.state.userTopic.TOPIC_DESC)
                            : ReactHtmlParser(
                                this.state.userTopic.TOPIC_DESC.replace(
                                  'style="width:560px;height:315px;"',
                                  ""
                                )
                              )}
                        </div>
                      </Typography>
                      <Typography
                        className={classes.hashTag}
                        gutterBottom
                        style={{ marginTop: "10px" }}
                      >
                        {this.state.userTopic.TOPIC_HASHTAG &&
                          this.state.userTopic.TOPIC_HASHTAG.map(
                            (item, index) => (
                              <Link
                                onClick={() => this.addToSearchInput(item)}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: index === 0 ? 0 : 10,
                                }}
                              >
                                {item}
                              </Link>
                            )
                          )}
                      </Typography>
                      <Typography>
                        <Grid container spacing={1}>
                          {!this.state.voteInd && (
                            <Grid item xs={12}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="voteOption"
                                  value={this.state.voteOption}
                                  onChange={this.handleChange}
                                >
                                  {this.state.userTopicOptions &&
                                    this.state.userTopicOptions.map(
                                      (option, row) => (
                                        <FormControlLabel
                                          value={option.TOPIC_VOTE_OPTN_NM}
                                          control={<Radio color="primary" />}
                                          label={
                                            <span
                                              style={{
                                                fontSize: 13,
                                                fontFamily: "poppins",
                                              }}
                                            >
                                              {option.TOPIC_VOTE_OPTN_NM}
                                            </span>
                                          }
                                        />
                                      )
                                    )}
                                </RadioGroup>
                                {this.state.errors.voteOption.length > 0 && (
                                  <span className="error">
                                    {this.state.errors.voteOption}
                                  </span>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                          {this.state.totalVotes > 0 && this.state.voteInd && (
                            <Grid item xs={12}>
                              <Card
                                style={{
                                  backgroundColor: "#3179c1",
                                  color: "white",
                                }}
                              >
                                <CardContent
                                  style={{ padding: 2, marginLeft: 10 }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: 13,
                                      fontFamily: "poppins",
                                    }}
                                  >
                                    Poll Voting Result (%)
                                  </Typography>
                                </CardContent>
                              </Card>
                              {this.state.userTopicOptions &&
                                this.state.userTopicOptions.map(
                                  (option, row) => (
                                    <div style={{ marginTop: "10px" }}>
                                      <span
                                        style={{
                                          color:
                                            option.TOPIC_VOTE_OPTN_NM ===
                                            this.state.userTopic.TOPIC_VOTE_OPTN
                                              ? "#3179c1"
                                              : null,
                                          fontWeight:
                                            option.TOPIC_VOTE_OPTN_NM ===
                                            this.state.userTopic.TOPIC_VOTE_OPTN
                                              ? "bold"
                                              : null,
                                          fontSize: 13,
                                          fontFamily: "poppins",
                                        }}
                                      >
                                        {option.TOPIC_VOTE_OPTN_NM + " "}
                                      </span>
                                      <span
                                        style={{
                                          color: "gray",
                                          fontSize: 13,
                                          fontFamily: "poppins",
                                        }}
                                      >
                                        (
                                        {option.TOPIC_OPTN_VOTE +
                                          (option.TOPIC_OPTN_VOTE > 1
                                            ? " Votes"
                                            : " Vote")}
                                        )
                                      </span>
                                      <ProgressBar
                                        completed={Math.round(
                                          (option.TOPIC_OPTN_VOTE /
                                            this.state.totalVotes) *
                                            100
                                        )}
                                        bgColor={
                                          row === 0
                                            ? "#6a1b9a"
                                            : row === 1
                                            ? "#28A745"
                                            : row === 2
                                            ? "#17A2B8"
                                            : row === 3
                                            ? "#FFC107"
                                            : row === 4
                                            ? "#DC3545"
                                            : row === 5
                                            ? "#6C757D"
                                            : row === 6
                                            ? "#007BFF"
                                            : row === 7
                                            ? "#ff6600"
                                            : row === 8
                                            ? "#00ffff"
                                            : "#009999"
                                        }
                                      />
                                    </div>
                                  )
                                )}
                            </Grid>
                          )}
                        </Grid>
                      </Typography>
                      {!this.state.voteInd && (
                        <Typography style={{ marginTop: "10px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.customButtonPrimary}
                            onClick={this.voteNow}
                          >
                            Vote Now
                          </Button>
                          {this.state.err_msg.length > 0 && (
                            <span style={{ marginLeft: 10 }} className="error">
                              {this.state.err_msg}
                            </span>
                          )}
                          {this.state.success_msg.length > 0 && (
                            <span
                              style={{ marginLeft: 10 }}
                              className="success"
                            >
                              {this.state.success_msg}
                            </span>
                          )}
                        </Typography>
                      )}

                      <Typography
                        style={{ marginTop: "20px" }}
                        className={wrapIcon}
                        color="textSecondary"
                      >
                        <img
                          src={!this.state.voteInd ? VoteIcon : VoteIconColor}
                          height={15}
                          style={{ marginRight: "5px", marginLeft: "10px" }}
                          alt="likes"
                        />
                        {this.state.userTopic.TOPIC_VOTE}{" "}
                        {this.state.userTopic.TOPIC_VOTE > 1 ? "Votes" : "Vote"}
                        <IconButton
                          onClick={this.saveUserPollLike}
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "10px",
                          }}
                          aria-label="share"
                        >
                          <img
                            src={!this.state.likeInd ? LikeIcon : LikeIconColor}
                            height={15}
                            style={{ marginRight: "5px" }}
                            alt="likes"
                          />
                          {this.state.userTopic.TOPIC_LIKE}{" "}
                          {mediaQuery ? "Likes" : ""}
                        </IconButton>
                        <VisibilityIcon
                          className={classes.iconSize}
                          style={{ marginRight: "5px", marginLeft: "10px" }}
                        />
                        <span style={{ fontSize: 13, fontFamily: "poppins" }}>
                          {this.state.userTopic.TOPIC_VIEW}
                        </span>
                        <IconButton
                          onClick={this.handleShareLink}
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "10px",
                          }}
                          aria-label="share"
                        >
                          <ShareIcon
                            className={classes.iconSize}
                            style={{ marginRight: "5px" }}
                          />
                          Share
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorElShare}
                          keepMounted
                          open={Boolean(this.state.anchorElShare)}
                          onClose={this.handleShareAnchorClose}
                        >
                          <MenuItem onClick={this.copyLink}>
                            <LinkIcon /> &nbsp;&nbsp;Copy Link
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={this.shareLink}>
                            <ShareIcon /> &nbsp;&nbsp;Share Link
                          </MenuItem>
                        </Menu>
                        <IconButton
                          onClick={this.submitReport}
                          style={{
                            padding: 0,
                            fontSize: 13,
                            fontFamily: "poppins",
                            marginLeft: "10px",
                          }}
                          aria-label="report"
                        >
                          <FlagIcon
                            className={classes.iconSize}
                            style={{
                              marginRight: "5px",
                              color: this.state.rptInd ? "#c2272d" : null,
                            }}
                          />
                          Report
                        </IconButton>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} style={{ marginTop: -15 }}>
                  <Comments
                    website-id={this.state.websiteId}
                    page-id={"poll" + topicId}
                    sso-hash={ssoData.hash}
                    sso-user={ssoData.userData}
                    page-url={
                      this.state.loginUrl +
                      "/poll/" +
                      topicId +
                      "/" +
                      topicTitle
                    }
                    page-author={this.state.topicUserId}
                    loadMode="click"
                    clickId="button-id"
                  />
                  {window.hyvor_talk &&
                    !this.state.isFilterApplied &&
                    window.hyvor_talk.reload(ssoConfig)}
                </Grid>
              </Grid>
            </Grid>
            {/* { mediaQuery &&
                    <Grid item xs={col3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card style={{height:'300px'}}>
                                    <CardContent>
                                        <Typography>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card style={{height:'300px'}}>
                                    <CardContent>
                                        <Typography>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>} */}
          </Grid>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(useStyles)(withMediaQuery("(min-width:600px)")(PollDetails))
);
